import { useEffect, useState } from 'react';
import axios from 'axios';
import Customer from '../../../interfaces/customer';
import translate from '../../../translate/translate';

type Props = {
    customer: Customer;
};

export default function CustomerCardInfo({ customer }: Props) {
    const [customerCardInfo, setCustomerCardInfo] = useState<{
        created: string;
        PAN?: string;
        exp_year?: string;
        exp_month?: string;
        enabled: boolean;
        is_blocked: boolean;
        payment_provider?: string;
    }>();

    useEffect(() => {
        axios
            .post('/verslun/api/get_public_customer_card_info/', {
                customer_uuid: customer.uuid,
            })
            .then((rsp) => setCustomerCardInfo(rsp.data));
    }, [customer]);

    return (
        <div>
            <div className="text-center">
                <div>
                    <img
                        src="/static/images/svg/card.svg"
                        alt="Card"
                        style={{
                            maxWidth: 90,
                        }}
                    />
                </div>
                <br />

                {!!!customerCardInfo && (
                    <div>{translate('no_card_on_file', 'is')}</div>
                )}

                {!!customerCardInfo && (
                    <div>
                        {customerCardInfo.PAN && (
                            <div>{customerCardInfo.PAN}</div>
                        )}

                        {!!!customerCardInfo.PAN && (
                            <div>Kortið er á skrá hjá okkur</div>
                        )}

                        {customerCardInfo.exp_year &&
                            customerCardInfo.exp_month && (
                                <div>
                                    {translate('expires', 'is')}:{' '}
                                    <strong>
                                        {customerCardInfo.exp_month}/
                                        {customerCardInfo.exp_year}
                                    </strong>
                                </div>
                            )}
                    </div>
                )}
            </div>
        </div>
    );
}
