// BASIC REACT STUFF
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// LIBRARIES
import { ConfigProvider, Menu } from 'antd';
import isIS from 'antd/lib/locale/is_IS';
import 'antd/dist/antd.css';

import axios from 'axios';

// COMPONENTS
import HeaderAndContainerWrapper from './components/HeaderAndContainerWrapper/HeaderAndContainerWrapper';

// PAGES
import Frontpage from './Pages/Frontpage/Frontpage';
import About from './Pages/About/About';
import CreateProduct from './Pages/CreateProduct/CreateProduct';
import CreateContent from './Pages/CreateContent/CreateContent';
import CreateProductGiftCertificate from './Pages/CreateProduct/CreateProductGiftCertificate';
import Profile from './Pages/Profile/Profile';
import SalesPage from './Pages/SalesPage/SalesPage';
// import ProductPaymentForm from './components/ProductPaymentForm/ProductPaymentForm';

import SubscriptionOverview from './Pages/SubscriptionOverview/SubscriptionOverview';
import GiftCertificateOverview from './Pages/GiftCertificateOverview/GiftCertificateOverview';
import Terms from './Pages/Terms/Terms';
import PaymentOverview from './Pages/PaymentOverview/PaymentOverview';
import RedeemGiftCertificate from './Pages/RedeemGiftCertificate/RedeemGiftCertificate';
import Customer from './Pages/Customer/Customer';
import OrderOverview from './Pages/OrderOverview/OrderOverview';
import ImportSubscriptions from './Pages/ImportSubscriptions/ImportSubscriptions';
import UpdateCustomerCards from './Pages/UpdateCustomerCards/UpdateCustomerCards';
import EditCustomerPublic from './Pages/EditCustomerPublic/EditCustomerPublic';
import ShopStats from './Pages/ShopStats/ShopStats';
import Staff from './Pages/Staff/Staff';
import RepeatTerms from './Pages/RepeatTerms/RepeatTerms';
import EmailOverviewPage from './Pages/EmailOverviewPage/EmailOverviewPage';
import Swagger from './Pages/Swagger/Swagger';
import ConfirmedOrderPage from './Pages/ConfirmedOrderPage/ConfirmedOrderPage';
import Webhooks from './Pages/Webhooks/Webhooks';
import AppwriteConfig from './Pages/AppwriteConfig/AppwriteConfig';
import SubscriptionTransactionReceipt from './Pages/SubscriptionTransactionReceipt/SubscriptionTransactionReceipt';
import _RepeatCheckoutTest from './Pages/RepeatCheckout/_RepeatCheckoutTest';
import ContentOverview from './Pages/ContentOverview/ContentOverview';

// CSS

import './App.less';
import SubscriptionTransactionOverview from './Pages/Transactions/SubscriptionTransactionOverview';
import RepeatCheckout from './Pages/RepeatCheckout/RepeatCheckout';
import Geckoboard from './Pages/Geckoboard/Geckoboard';
import DashboardPicker from './Pages/DashboardPicker/DashboardPicker';
import ShopSettings from './Pages/ShopSettings/ShopSettings';
import GetCardTokenIframe from './Pages/GetCardTokenIframe/GetCardTokenIframe';
import ProductOverviewPage from './Pages/ProductOverviewPage/ProductOverviewPage';
import PaymentRequestReceipt from './Pages/PaymentRequestReceipt/PaymentRequestReceipt';
import Dashboard from './components/Dashboard/Dashboard';

// LIBRARIES SETTINGS
const el = document.querySelector(
    '[name=csrfmiddlewaretoken]'
) as HTMLInputElement;
axios.defaults.headers.common['x-csrftoken'] = el?.value;

export default function App() {
    return (
        <ConfigProvider locale={isIS}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/repeat_checkout/*"
                        element={<RepeatCheckout />}
                    />

                    <Route
                        path="/repeat_checkout_test"
                        element={<_RepeatCheckoutTest></_RepeatCheckoutTest>}
                    />
                    <Route path="/gjof/*" element={<RedeemGiftCertificate />} />
                    <Route path="/solusida/*" element={<SalesPage />} />
                    <Route
                        path="/get_card_token_iframe/*"
                        element={<GetCardTokenIframe />}
                    />

                    <Route
                        path="/repeat_geckobard/*"
                        element={<Geckoboard />}
                    />
                    <Route
                        path="/sending/kvittun/*"
                        element={<PaymentRequestReceipt />}
                    />

                    <Route
                        path="/askriftarfaersla/kvittun/*"
                        element={<SubscriptionTransactionReceipt />}
                    />

                    <Route
                        path="/pontun/kvittun/*"
                        element={<SubscriptionTransactionReceipt />}
                    />
                    <Route
                        path="/askrifandi/*"
                        element={<EditCustomerPublic />}
                    />

                    <Route
                        path="/minarsidur/*"
                        element={<EditCustomerPublic />}
                    />
                    <Route path="/pontun/*" element={<ConfirmedOrderPage />} />

                    <Route element={<HeaderAndContainerWrapper />}>
                        <Route index element={<Frontpage />} />

                        <Route
                            path="/vorur/*"
                            element={<ProductOverviewPage />}
                        />

                        <Route
                            path="/import_subscriptions/*"
                            element={<ImportSubscriptions />}
                        />

                        <Route
                            path="/update_customer_cards/*"
                            element={<UpdateCustomerCards />}
                        />

                        <Route path="/umokkur" element={<About />} />

                        <Route path="/stillingar/*" element={<Profile />} />

                        <Route
                            path="/efnisyfirlit/*"
                            element={<ContentOverview />}
                        />

                        <Route
                            path={'/breyta_voru/*'}
                            element={<CreateProduct isEdit={true} />}
                        />

                        <Route
                            path={'/ny_vara'}
                            element={<CreateProduct isEdit={false} />}
                        />
                        <Route
                            path={'/ny_vara/*'}
                            element={<CreateProduct isEdit={false} />}
                        />

                        <Route
                            path="/create_new"
                            element={<CreateProductGiftCertificate />}
                        />

                        <Route path="/skilmalar/*" element={<Terms />} />

                        <Route
                            path="/repeat_skilmalar"
                            element={<RepeatTerms />}
                        />

                        <Route path="/api/v1/" element={<Swagger />} />

                        <Route
                            path="/ny_verslun/*"
                            element={<ShopSettings />}
                        />

                        <Route
                            path="/sendingar/*"
                            element={<SubscriptionTransactionOverview />}
                        />

                        <Route path="/pantanir/*" element={<OrderOverview />} />

                        <Route
                            path="/tolvupostar/*"
                            element={<EmailOverviewPage />}
                        />

                        <Route
                            path="/askriftir/*"
                            element={<SubscriptionOverview />}
                        />

                        <Route
                            path="/gjafabref/*"
                            element={<GiftCertificateOverview />}
                        />

                        <Route
                            path="/faerslur/*"
                            element={<PaymentOverview />}
                        />

                        <Route
                            path="/vidskiptavinur/*"
                            element={<Customer />}
                        />

                        <Route
                            path="/stjornbord"
                            element={<DashboardPicker></DashboardPicker>}
                        />

                        <Route path="/stjornbord/*" element={<Dashboard />} />

                        <Route path="/tolfraedi/*" element={<ShopStats />} />

                        <Route path="/webhooks/*" element={<Webhooks />} />

                        <Route
                            path="/adgangar/*"
                            element={<AppwriteConfig />}
                        />

                        <Route path="/staff" element={<Staff />} />

                        <Route
                            path="/nytt_efni/*"
                            element={<CreateContent />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}
