import { useState, useEffect, useContext } from 'react';
import { Form, Card, Button, Tabs, UploadFile } from 'antd';
import {
    CodeOutlined,
    PushpinOutlined,
    DatabaseOutlined,
    SettingFilled,
    SettingOutlined,
    BuildOutlined,
    NotificationOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import axios from 'axios';

import CreateShop from './CreateShop/CreateShop';
import APIKeys from '../APIKeys/APIKeys';
import ShopifySettings from '../ShopifySettings/ShopifySettings';
import WoocommerceSettings from '../WoocommerceSettings/WoocommerceSettings';
import LogOverviewPage from '../LogOverviewPage/LogOverviewPage';
import NotificationSettings from '../NotifictionSettings/NotificationSettings';
import ShopDeliverySettings from './ShopDeliverySettings/ShopDeliverySettings';
import ShopActions from './ShopActions/ShopActions';
import ShopExtraSettings from './ShopExtraSettings/ShopExtraSettings';
import PaydaySettings from '../../components/PaydaySettings/PaydaySettings';
import AuthContext from '../../authContext';

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function ShopSettings() {
    const { selectedShop } = useContext(AuthContext);

    const items = [
        {
            key: '1',
            label: (
                <>
                    <SettingFilled></SettingFilled> Grunnstillingar
                </>
            ),
            children: <CreateShop></CreateShop>,
        },
    ];

    if (selectedShop) {
        items.push({
            key: '3',
            label: (
                <>
                    <SettingOutlined style={{ marginRight: 0 }} /> Ítarlegar
                    stillingar
                </>
            ) as any,
            children: <ShopExtraSettings></ShopExtraSettings>,
        });

        items.push({
            key: '2',
            label: (
                <>
                    <CodeOutlined /> API lyklar
                </>
            ) as any,
            children: <APIKeys shop={selectedShop}></APIKeys>,
        });

        items.push({
            key: '5',
            label: (
                <>
                    <DatabaseOutlined /> Loggar
                </>
            ) as any,
            children: <LogOverviewPage shop={selectedShop}></LogOverviewPage>,
        });

        items.push({
            key: '6',
            label: (
                <>
                    <NotificationOutlined /> Tilkynningar
                </>
            ) as any,
            children: (
                <NotificationSettings
                    shop={selectedShop}
                ></NotificationSettings>
            ),
        });

        items.push({
            key: '7',
            label: (
                <>
                    <PushpinOutlined /> Afhendingarmátar
                </>
            ) as any,
            children: (
                <ShopDeliverySettings
                    shop={selectedShop}
                ></ShopDeliverySettings>
            ),
        });

        items.push({
            key: '8',
            label: (
                <>
                    <BuildOutlined /> Reglur
                </>
            ) as any,
            children: <ShopActions shop={selectedShop}></ShopActions>,
        });

        items.push({
            key: '9',
            label: (
                <>
                    <div>
                        <PlusOutlined /> Samþættingar
                    </div>

                    <div>
                        <img
                            style={{
                                width: 18,
                                marginRight: 5,
                            }}
                            src="/static/images/svg/shopify.svg"
                            alt="Shopify logo"
                        ></img>{' '}
                        <img
                            alt="Woocommerce logo"
                            style={{ width: 24, marginRight: 7 }}
                            src="/static/images/svg/woocommerce.svg"
                        ></img>{' '}
                    </div>
                </>
            ) as any,
            children: (
                <>
                    <Tabs>
                        <Tabs.TabPane
                            key="1"
                            tab={
                                <div>
                                    <img
                                        style={{ width: 18, marginRight: 5 }}
                                        src="/static/images/svg/shopify.svg"
                                        alt="Shopify logo"
                                    ></img>{' '}
                                    Shopify
                                </div>
                            }
                        >
                            <ShopifySettings
                                shop={selectedShop}
                            ></ShopifySettings>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="2"
                            tab={
                                <div>
                                    <img
                                        alt="Woocommerce logo"
                                        style={{ width: 24, marginRight: 7 }}
                                        src="/static/images/svg/woocommerce.svg"
                                    ></img>{' '}
                                    Woocommerce
                                </div>
                            }
                        >
                            <WoocommerceSettings
                                shop={selectedShop}
                            ></WoocommerceSettings>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="3"
                            tab={
                                <div>
                                    <img
                                        alt="Payday logo"
                                        style={{
                                            width: 64,
                                            marginRight: 7,
                                            marginBottom: 5,
                                        }}
                                        src="/static/images/svg/payday_logo.svg"
                                    ></img>{' '}
                                </div>
                            }
                        >
                            <PaydaySettings
                                shop={selectedShop}
                            ></PaydaySettings>
                        </Tabs.TabPane>
                    </Tabs>
                </>
            ),
        });
    }

    return (
        <>
            <Card
                className="basePageCard"
                extra={
                    <>
                        {selectedShop && w.me === 1 && (
                            <Button
                                size="small"
                                href={`/bakendi/shop/shop/${selectedShop.id}/change/`}
                            >
                                Bakendi
                            </Button>
                        )}
                    </>
                }
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <SettingOutlined
                            style={{ fontSize: 35, marginRight: 10 }}
                        />{' '}
                        {w.uuid ? 'Stillingar' : 'Ný verslun'}
                    </div>
                }
            >
                <Card>
                    <div style={{ width: '100%' }}>
                        <Tabs tabPosition="right" items={items}></Tabs>
                    </div>
                </Card>
            </Card>
        </>
    );
}
