import { useState } from 'react';
import {
    Modal,
    Form,
    Select,
    Input,
    Button,
    Row,
    Col,
    message,
    Switch,
    InputNumber,
} from 'antd';
import Axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import Shop from '../../../interfaces/shop';
import Product from '../../../interfaces/product';

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function CreateCertificateModal(props: {
    shop: Shop;
    products: Product[];
    onSuccess: Function;
    open: boolean;
    onCancel: Function;
}) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [forceRerender, setForceRerender] = useState(false);

    const onSubmit = (values: any) => {
        setLoading(true);

        const createCertificate = async (values: any) => {
            values.credits = parseInt(values.credits);
            values.original_credits = parseInt(values.credits);
            values.shop = props.shop.id;

            if (!values.code) {
                const c = async function createRandomCode() {
                    const rsp = await Axios.post(
                        '/askrift/create_random_gift_certificate_code/',
                        { shop_uuid: w.uuid }
                    );
                    return rsp.data.code;
                };
                return Axios.post('/askrift/api/create_gift_certificate/', {
                    ...values,
                    code: c,
                });
            } else {
                return Axios.post(
                    '/askrift/api/create_gift_certificate/',
                    values
                );
            }
        };

        const product = props.products.find(
            (product) => product.id === values.product
        );

        if (!values.multiple || values.multiple_count < 0) {
            createCertificate(values).then((rsp) => {
                setLoading(false);
                rsp.data.product = product;
                props.onSuccess([rsp.data]);
            });
        } else {
            let i = 0;
            const createdCertificates: any = [];
            const createNextCertificate = async () => {
                i += 1;
                const cert = await createCertificate(values);

                createdCertificates.push(cert.data);
                if (i < values.multiple_count) {
                    createNextCertificate();
                } else {
                    setLoading(false);
                    props.onSuccess(
                        createdCertificates.map((cert: any) => {
                            return {
                                ...cert,
                                product: product,
                            };
                        })
                    );
                }
            };
            createNextCertificate();
        }
    };

    return (
        <Modal
            title="Búa til gjafabréf"
            maskClosable={false}
            footer={false}
            open={props.open}
            onCancel={() => {
                props.onCancel();
                form.resetFields();
            }}
        >
            <p>
                Hér getur þú búið til gjafabréf ef þú vilt gera það handvirkt af
                einhverjum ástæðum (t.d gömul gjafabréf úr öðru kerfi eða ef þú
                vilt gefa frítt gjafabréf).
            </p>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                onValuesChange={() => setForceRerender(!forceRerender)}
            >
                <Form.Item
                    valuePropName="checked"
                    label="Búa til mörg í einu"
                    name="multiple"
                    help="Ef þú býrð til mörg í einu þá býr kerfið til gjafakóða fyrir hvern og einn"
                    style={{
                        marginBottom: 40,
                    }}
                >
                    <Switch
                        checkedChildren="JÁ "
                        unCheckedChildren="NEI"
                    ></Switch>
                </Form.Item>

                {form.getFieldValue('multiple') && (
                    <Form.Item
                        name="multiple_count"
                        label="Hvað viltu búa til mörg?"
                    >
                        <InputNumber min={1} max={1000}></InputNumber>
                    </Form.Item>
                )}

                {!form.getFieldValue('multiple') && (
                    <Form.Item
                        label="Gjafakóði"
                        extra='Settu inn þinn eigin kóða eða smelltu á "Búa til kóða" ef þú vilt handahófskenndan kóða'
                    >
                        <Row gutter={8}>
                            <Col span={16}>
                                <Form.Item
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Þú verður að hafa gjafakóða.',
                                        },
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Button
                                    block
                                    size="large"
                                    onClick={() => {
                                        Axios.post(
                                            '/askrift/create_random_gift_certificate_code/',
                                            { shop_uuid: w.uuid }
                                        ).then((rsp) => {
                                            form.setFieldsValue({
                                                code: rsp.data.code,
                                            });
                                        });
                                    }}
                                >
                                    Búa til kóða
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                )}

                <Form.Item
                    initialValue="Leysist út til handhafa"
                    name="message"
                    label="Skilaboð á gjafabréf"
                >
                    <TextArea></TextArea>
                </Form.Item>

                <Form.Item
                    name="product"
                    label="Gjafabréf"
                    rules={[
                        { required: true, message: 'Þú að velja gjafabréf.' },
                    ]}
                >
                    <Select
                        options={props.products.map((product) => {
                            return { value: product.id, label: product.title };
                        })}
                        style={{ width: '100%' }}
                        size="large"
                        placeholder="Veldu tegund"
                    ></Select>
                </Form.Item>

                <Form.Item
                    name="credits"
                    label="Inneign (fjöldi sendinga)"
                    rules={[
                        {
                            required: true,
                            message: 'Þú verður að stilla inneign.',
                        },
                    ]}
                >
                    <Input type="number" size="large"></Input>
                </Form.Item>

                <Form.Item
                    name="unit_type"
                    label='Heiti skipta (t.d "mánuðir")'
                >
                    <Input size="large" defaultValue={'skipti'}></Input>
                </Form.Item>

                <Form.Item
                    name="is_paid"
                    label="Greitt/Ógreitt"
                    rules={[{ required: true, message: 'Þú verður að velja.' }]}
                >
                    <Select
                        size="large"
                        options={[
                            { value: true, label: 'Greitt' },
                            { value: false, label: 'Ógreitt' },
                        ]}
                    ></Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={loading}
                        block
                        size="large"
                        htmlType="submit"
                        type="primary"
                    >
                        Stofna
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
