import React, { ReactElement, useEffect } from 'react';
import { Modal } from 'antd';
import PersonalInformation from '../../interfaces/personalInformation';
import Product, {
    Currency,
    ProductAttributeWithAnswer,
} from '../../interfaces/product.js';
import ProductPaymentForm from '../ProductPaymentForm/ProductPaymentForm';
import translate, { LanguageKey } from '../../translate/translate';

interface Props {
    open: boolean;
    title: string;
    onCancel: () => void;
    onOpen: () => void;
    personal_info_list: Array<PersonalInformation>;
    product: Product;
    quantity: number;
    oneTimePurchase: boolean;
    cronSettings?: object;
    externalRef?: string;
    customerInfo?: {
        ssid?: string;
        name?: string;
        email?: string;
    };
    disableDetailInputs?: boolean;
    selectedCustomPrice?: number | null | undefined;
    lang?: LanguageKey;
    currency: Currency['currency_code'];
}

export default function ProductPaymentModal(props: Props): ReactElement {
    const optionAnswers = props.product.attributes
        ?.filter(
            (attr: ProductAttributeWithAnswer) =>
                attr.options.length > 0 && attr.option_answers
        )
        .map((attr: ProductAttributeWithAnswer) => attr.option_answers)
        .flat();

    useEffect(() => {
        if (props.onOpen) props.onOpen();
    }, []);

    const getOptionAnswerTitles = (
        option_answers: ProductAttributeWithAnswer['option_answers'] | any[]
    ) => {
        const options = props.product.attributes
            ?.map((attr) => attr.options)
            .flat();

        if (options) {
            return option_answers
                ?.map((option_answer) => {
                    const option = options.find(
                        (opt) => opt.id === option_answer
                    );
                    return option?.title;
                })
                .join(', ');
        } else {
            return [];
        }
    };
    return (
        <Modal
            title={
                <>
                    {props.title} - {props.product.title}{' '}
                    {(props.product.attributes?.length || 0) > 0 && (
                        <span>{getOptionAnswerTitles(optionAnswers)}</span>
                    )}
                </>
            }
            style={{ top: 20 }}
            open={props.open}
            maskClosable={false}
            onCancel={props.onCancel}
            maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            footer={
                <div
                    style={{
                        margin: 'auto',
                        textAlign: 'center',
                        borderRadius: '10px',
                    }}
                    id="payment_options"
                >
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/master_card.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/maestro.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/visa.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/visa_electron.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/union_pay.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/jcb.png"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/diners_club.svg"
                        alt=""
                    />
                    <img
                        style={{ width: '8%', margin: '2%' }}
                        src="/static/images/credit_card_icons/discover.png"
                        alt=""
                    />
                </div>
            }
        >
            <ProductPaymentForm
                externalRef={props.externalRef}
                selectedCustomPrice={props.selectedCustomPrice}
                product={props.product}
                personal_info_list={props.personal_info_list}
                quantity={props.quantity}
                oneTimePurchase={props.oneTimePurchase}
                cronSettings={props.cronSettings}
                disableDetailInputs={props.disableDetailInputs}
                customerInfo={props.customerInfo}
                lang={props.lang}
                currency={props.currency}
            ></ProductPaymentForm>
        </Modal>
    );
}
