import { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import Customer from '../../../interfaces/customer';
import axios from 'axios';

type Props = {
    customer: Customer;
    onCustomerChange: (customer: Customer) => void;
};

export default function CustomerDetailsForm({
    customer,
    onCustomerChange,
}: Props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (customer) {
            form.setFieldsValue(customer);
        }

        return () => {
            form.resetFields();
        };
    }, [customer]);

    return (
        <Form form={form} layout="vertical">
            <Form.Item name="name" label="Nafn">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="ssid" label="Kennitala">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="street" label="Heimilisfang">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="town" label="Bæjarfélag">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="postal_code" label="Póstnúmer">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="email" label="Netfang">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item name="phone" label="Símanúmer">
                <Input size="large" autoComplete="off"></Input>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={loading}
                    onClick={() => {
                        setLoading(true);

                        let values = JSON.parse(
                            JSON.stringify(form.getFieldsValue())
                        );

                        if (!values.name) {
                            message.error('Nafn vantar');
                            setLoading(false);
                            return;
                        }

                        if (!values.ssid) {
                            values.ssid = null;
                        }

                        axios
                            .patch(
                                `/verslun/api/customer/${customer.uuid}/`,
                                values
                            )
                            .then((rsp) => {
                                message.success(
                                    'Viðskiptavinur hefur verið uppfærður 🥳'
                                );
                                onCustomerChange({
                                    ...customer,
                                    ...values,
                                });
                            })
                            .catch((err) => {
                                if (err.response.data?.ssid) {
                                    message.error(
                                        'Kennitala: ' + err.response.data.ssid
                                    );
                                }

                                message.error('Eitthvað fór úrskeiðis');
                                setLoading(false);
                            });
                    }}
                    block
                    type="primary"
                    size="large"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
