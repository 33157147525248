import { useState, useEffect } from 'react';
import Axios from 'axios';
import Shop from '../../../interfaces/shop';
import { Button, Card, Divider, Form, message } from 'antd';

import { ShopOwnerDeliveryConfig } from '../../../interfaces/delivery';
import Product from '../../../interfaces/product';
import DeliveryConfigForm from './DeliveryConfigForm';

type Props = {
    shop: Shop;
};

export default function ShopDeliverySettings({ shop }: Props) {
    const [deliveryConfigs, setDeliveryConfigs] = useState<
        ShopOwnerDeliveryConfig[]
    >([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Axios.get(`/shipping/api/delivery_config_overview/${shop.uuid}/`).then(
            (rsp) => {
                setDeliveryConfigs(rsp.data);
                setLoading(false);
            }
        );

        Axios.get('/verslun/api/admin_product/?shop__uuid=' + shop.uuid).then(
            (rsp) => {
                setProducts(rsp.data.results);
            }
        );
    }, [shop]);

    return (
        <div>
            <Card
                style={{
                    boxShadow: 'none',
                    border: 'none',
                }}
                title={'Afhendingarvalmyndir'}
                extra={
                    <Button
                        onClick={() => {
                            setLoading(true);
                            Axios.post(
                                '/shipping/api/create_delivery_config/',
                                {
                                    shop: shop.id,
                                }
                            )
                                .then((rsp) => {
                                    setDeliveryConfigs([
                                        ...deliveryConfigs,
                                        rsp.data,
                                    ]);
                                })
                                .catch(() => {
                                    message.error(
                                        'Ekki tókst að bæta við afhendingarmöguleika, hafðu samband við tæknistjóra'
                                    );
                                })
                                .finally(() => {
                                    setLoading(false);
                                    // Scroll to the bottom of the page
                                    window.scrollTo(
                                        0,
                                        document.body.scrollHeight
                                    );
                                });
                        }}
                        loading={loading}
                    >
                        + Bæta við
                    </Button>
                }
            >
                {!loading && deliveryConfigs.length === 0 && (
                    <>
                        <p>
                            Verslunin þín er ekki með neina
                            afhendingarmöguleika.
                        </p>
                        <p>
                            Smelltu á takkann hér að neðan til að bæta við
                            afhendingarmöguleikum.
                        </p>
                    </>
                )}

                {deliveryConfigs.map((config, key) => {
                    return (
                        <Card
                            style={{
                                boxShadow: 'none',
                                border: 'none',
                            }}
                            key={config.id}
                            title={'Afhendingarvalmynd ' + (key + 1)}
                        >
                            <DeliveryConfigForm
                                key={key}
                                products={products}
                                config={config}
                            ></DeliveryConfigForm>
                        </Card>
                    );
                })}
            </Card>
        </div>
    );
}
