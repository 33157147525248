import { useState, useEffect } from 'react';
import Axios from 'axios';
import Product, { Currency } from '../../../interfaces/product';
import { Form, FormInstance, Input, InputNumber, Select, Space } from 'antd';

type Props = {
    product?: Product;
    currencies: Currency[];
    productType: 'ONE_TIME' | 'SUBSCRIPTION' | 'GIFT_CERTIFICATE';
    form: FormInstance<any>;
};

// interface Currency {
//     title: string;
//     currency_code: string;
//     iso_code: string;
//     prefix_symbol?: string;
//     suffix_symbol?: string;
// }

// interface ProductPrice {
//     amount?: number;
//     price_choices?: number[];
//     customer_can_set_price: boolean;
//     min_custom_price?: number;
//     currency: Currency;
// }
// [];

export default function ProductPriceSettings({ product, currencies }: Props) {
    const prices = product?.prices || [];

    const [selectedCurrencies, setSelectedCurrencies] = useState(
        prices.map((price) => price.currency.currency_code)
    );

    return (
        <>
            <Form.Item label="Gjaldmiðlar">
                <Select
                    id="currencies"
                    mode="multiple"
                    size="large"
                    placeholder="Veldu þá gjaldmiðla sem þú vilt bjóða upp á"
                    value={selectedCurrencies}
                    style={{ width: '100%' }}
                    defaultValue={selectedCurrencies}
                    onSelect={(value: Currency['currency_code']) => {
                        setSelectedCurrencies([...selectedCurrencies, value]);
                    }}
                    onDeselect={(value: Currency['currency_code']) => {
                        setSelectedCurrencies(
                            selectedCurrencies.filter((currency) => {
                                return currency !== value;
                            })
                        );
                    }}
                    options={currencies.map((currency) => {
                        return {
                            value: currency.currency_code,
                            label: currency.title,
                            className: 'currency-selector',
                        };
                    })}
                />
            </Form.Item>

            <Space>
                {selectedCurrencies.map((currency, key) => {
                    // amount?: number;
                    // //     price_choices?: number[];
                    // //     customer_can_set_price: boolean;
                    // //     min_custom_price?: number;
                    // //     currency: Currency;

                    return (
                        <Form.Item
                            key={key}
                            name={`amount_${currency}`}
                            label={`Verð (${currency})`}
                            initialValue={
                                prices.find(
                                    (price) =>
                                        price.currency.currency_code ===
                                        currency
                                )?.amount
                            }
                        >
                            <InputNumber
                                type="number"
                                size="large"
                                autoComplete="off"
                                addonBefore={
                                    currencies.find(
                                        (c) => c.iso_code === currency
                                    )?.prefix_symbol
                                }
                                addonAfter={
                                    currencies.find(
                                        (c) => c.iso_code === currency
                                    )?.suffix_symbol
                                }
                            />
                        </Form.Item>
                    );
                })}
            </Space>

            {/* <Form.Item
                name="customizable_price"
                label={
                    <>
                        <Popover content="Viðskiptavinur getur valið verðið sjálf/ur.">
                            <QuestionCircleTwoTone
                                twoToneColor="#afafaf"
                                style={{
                                    position: 'absolute',
                                    fontSize: 16,
                                }}
                            />
                        </Popover>
                        <span
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Breytilegt verð?
                        </span>
                    </>
                }
                valuePropName="checked"
            >
                <Switch
                    checkedChildren="JÁ "
                    unCheckedChildren="NEI"
                    onChange={(checked) => {
                        form.setFieldsValue({
                            customizable_price: checked,
                        });
                    }}
                />
            </Form.Item> */}

            {/* <Form.Item
                label="Verð"
                name="price"
                rules={[
                    {
                        required: true,
                        message: 'Þú verður að gefa vörunni verð.',
                    },
                ]}
            >
                <Input type="number" size="large" autoComplete="off" />
            </Form.Item> */}

            {/* {form.getFieldValue('customizable_price') && (
                <CreateProductPriceOptions
                    form={form}
                ></CreateProductPriceOptions>
            )} */}
        </>
    );
}
