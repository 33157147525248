import React, { ReactElement, useState } from 'react';
import { Modal, Button, message } from 'antd';
import Axios from 'axios';
import Product from '../../../interfaces/product';

interface Props {
    product: Product;
    onCancel: Function;
    open: boolean;
}

export default function DeleteProductModal(props: Props): ReactElement {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            footer={null}
            onCancel={() => {
                props.onCancel();
            }}
            title="Ertu alveg viss?"
            open={props.open}
        >
            <h3>Þessi aðgerð er ekki afturkræf!</h3>
            <br />

            <Button
                loading={loading}
                onClick={() => {
                    setLoading(true);

                    Axios.patch(
                        '/verslun/api/basic_product/' +
                            props.product.uuid +
                            '/',
                        { is_deleted: true }
                    )
                        .then((rsp) => {
                            message.success('Vörunni hefur verið eytt!');
                            setTimeout(() => {
                                window.location.href = '/';
                            }, 500);
                        })
                        .catch((err) => {
                            message.error('Úps, eitthvað fór úrskeiðis');
                            setLoading(false);
                        });
                }}
                block
                danger
            >
                Já, eyða vöru
            </Button>
        </Modal>
    );
}
