import { Alert, Button, message, Modal, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { SubscriptionTransactionFilterRequest } from '../../../interfaces/transaction';

type Props = {
    open: boolean;
    selection: SubscriptionTransactionFilterRequest;
    onSuccess: () => void;
    onCancel: () => void;
};

export default function CancelSubscriptionTransactionsModal({
    open,
    selection,
    onSuccess,
    onCancel,
}: Props) {
    const [cancelReason, setCancelReason] = useState('');
    const [loading, setLoading] = useState(false);
    const [notifyCustomer, setNotifyCustomer] = useState(true);

    return (
        <Modal
            footer={false}
            open={open}
            onCancel={() => onCancel()}
            title="Afvirkja áskriftir?"
        >
            <p>
                Ertu viss um að þú viljir afvirkja allar áskriftir tengdar
                völdum sendingum?
            </p>

            <p>
                Athugaðu að þú ert ekki að eyða sendingunum sjálfum, heldur
                slökknar á áskriftinni sem tengist þeim. Ef sendingin er ógreidd
                heldur kerfið samt áfram að reyna að innheimta greiðsluna.
            </p>

            <TextArea
                disabled={loading}
                placeholder="Skýring"
                rows={6}
                style={{ marginBottom: 20, marginTop: 20 }}
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
            ></TextArea>

            <label style={{ display: 'block', marginBottom: 10 }}>
                Viltu senda staðfestingarpóst á viðskiptavini?{' '}
            </label>

            <Switch
                checked={notifyCustomer}
                onChange={(checked) => setNotifyCustomer(checked)}
                checkedChildren="JÁ"
                unCheckedChildren="NEI"
                style={{ marginBottom: 20 }}
            ></Switch>

            <Button
                type="primary"
                loading={loading}
                size="large"
                block
                onClick={() => {
                    if (!cancelReason || cancelReason.length < 5) {
                        message.error(
                            'Vinsamlegast skrifaðu skýringu sem er að minnsta kosti 5 stafir'
                        );
                        return;
                    }

                    setLoading(true);

                    axios
                        .post(
                            '/askrift/cancel_subscriptions_from_selected_subscription_transactions/',
                            {
                                selection: selection,
                                cancel_reason: cancelReason,
                                notify_customer: notifyCustomer,
                            }
                        )
                        .then(() => {
                            setLoading(false);
                            message.success(
                                'Vinnsla hefur verið sett af stað, athugaðu að fjöldi sendinga hefur áhrif tíma. Aðgerðin getur tekið nokkrar mínútur ef fjöldinn er mikill.',
                                10
                            );
                            onSuccess();
                        })
                        .catch(() => {
                            message.error(
                                'Ekki tókst að afvirkja áskriftir, hafðu samband við kerfisstjóra'
                            );
                        });
                }}
            >
                Afvirkja áskriftir
            </Button>
        </Modal>
    );
}
