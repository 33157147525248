import { useEffect, useState } from 'react';
import { CronConfig, SubscriptionBundle } from '../../interfaces/subscription';
import SubscriptionCronSettings from '../../components/SubscriptionCronSettings/SubscriptionCronSettings';
import translate, { LanguageKey } from '../../translate/translate';
import { Button, Calendar, Space, message } from 'antd';
import moment from 'moment';
import { SelectedCronConfig } from './NewSubscriptionSettings';
import { RedoOutlined, ClockCircleOutlined } from '@ant-design/icons';

import Axios from 'axios';
import { getCronLabelSimple } from '../../utils';
import dayjs from 'dayjs';

type Props = {
    bundle: SubscriptionBundle;
    language: LanguageKey;
    onSuccess: (cronConfig: SelectedCronConfig) => void;
};

export default function ChangeBundleInterval({
    bundle,
    language,
    onSuccess,
}: Props) {
    const [selectedCronConfig, setSelectedCronConfig] =
        useState<SelectedCronConfig>();

    const [loading, setLoading] = useState(false);
    const [confirmationScreen, setConfirmationScreen] = useState(false);

    const firstProductWithIntervalCombination = bundle.subscriptions.find(
        (s) => s.product.interval_combinations?.length
    )?.product;

    useEffect(() => {
        setSelectedCronConfig({
            base_date_is_signup_date: false,
            base_date: moment().add(1, 'day').toISOString(),
        });
    }, []);

    return (
        <div>
            {!confirmationScreen && (
                <div>
                    <SubscriptionCronSettings
                        lang={language}
                        yearly={
                            firstProductWithIntervalCombination?.interval_combinations?.some(
                                (c) => c.type === 'YEAR'
                            )
                                ? true
                                : false
                        }
                        monthly={
                            firstProductWithIntervalCombination?.interval_combinations?.some(
                                (c) => c.type === 'MONTH'
                            )
                                ? true
                                : false
                        }
                        weekly={
                            firstProductWithIntervalCombination?.interval_combinations?.some(
                                (c) => c.type === 'WEEK'
                            )
                                ? true
                                : false
                        }
                        weeklyOptions={
                            firstProductWithIntervalCombination?.interval_combinations?.find(
                                (c) => c.type === 'WEEK'
                            )?.count_options
                        }
                        monthlyOptions={
                            firstProductWithIntervalCombination?.interval_combinations?.find(
                                (c) => c.type === 'MONTH'
                            )?.count_options
                        }
                        yearlyOptions={
                            firstProductWithIntervalCombination?.interval_combinations?.find(
                                (c) => c.type === 'YEAR'
                            )?.count_options
                        }
                        onChange={(config: CronConfig) => {
                            if (!config) {
                                setSelectedCronConfig({
                                    ...(selectedCronConfig || {}),
                                    interval: undefined,
                                    interval_type: undefined,
                                });
                                return;
                            } else {
                                setSelectedCronConfig({
                                    ...(selectedCronConfig || {}),
                                    interval: config.interval,
                                    interval_type: config.interval_type,
                                });
                            }
                        }}
                        showTitle={true}
                    ></SubscriptionCronSettings>

                    <>
                        {bundle.subscriptions.every(
                            (s) =>
                                s.product.cron_transaction
                                    ?.base_date_is_signup_date &&
                                s.product.cron_transaction?.is_active
                        ) && (
                            <>
                                <Calendar
                                    disabledDate={(current) => {
                                        return current.isBefore(
                                            moment().endOf('day')
                                        );
                                    }}
                                    defaultValue={moment().add(1, 'day')}
                                    fullscreen={false}
                                    onChange={(_moment) => {
                                        if (!_moment) {
                                            if (selectedCronConfig) {
                                                setSelectedCronConfig({
                                                    ...selectedCronConfig,
                                                    base_date: undefined,
                                                });
                                            }
                                            return;
                                        }
                                        setSelectedCronConfig({
                                            ...(selectedCronConfig || {}),
                                            base_date_is_signup_date: false,
                                            base_date: _moment.toISOString(),
                                        });
                                    }}
                                ></Calendar>

                                <p className="text-center mt10 mb20">
                                    {translate(
                                        'transactions_are_from_date',
                                        language
                                    )}
                                </p>
                            </>
                        )}

                        <Button
                            className="mt20"
                            type="primary"
                            block
                            size="large"
                            loading={loading}
                            onClick={() => {
                                if (
                                    selectedCronConfig?.base_date &&
                                    selectedCronConfig?.interval &&
                                    selectedCronConfig?.interval_type
                                ) {
                                    setConfirmationScreen(true);
                                } else {
                                    message.error(
                                        translate(
                                            'please_fill_out_all_fields',
                                            language
                                        )
                                    );
                                }
                            }}
                        >
                            Staðfesta
                        </Button>
                    </>
                </div>
            )}

            {confirmationScreen && selectedCronConfig && (
                <>
                    <div>
                        <Space
                            direction="vertical"
                            size={'large'}
                            style={{ display: 'flex' }}
                        >
                            {/* <div>Þú ert að skrá þig í áskrift að: </div>
                        <div>
                            <strong>
                                {getProductTranslation(product, language).title}
                            </strong>
                        </div> */}
                            {selectedCronConfig && (
                                <div className="infoBubble">
                                    <div className="infoBubbleTitle">
                                        <RedoOutlined />{' '}
                                        {translate(
                                            'shipment_frequecy',
                                            language
                                        )}
                                        :{' '}
                                    </div>
                                    <div className="infoBubbleData">
                                        {getCronLabelSimple(
                                            selectedCronConfig.interval,
                                            selectedCronConfig.interval_type,
                                            null,
                                            null,
                                            language
                                        )}
                                    </div>
                                </div>
                            )}

                            {selectedCronConfig &&
                                selectedCronConfig.base_date && (
                                    <div className="infoBubble">
                                        <div className="infoBubbleTitle">
                                            <ClockCircleOutlined />{' '}
                                            {translate('next', language)}:{' '}
                                        </div>
                                        <div className="infoBubbleData">
                                            {' '}
                                            <>
                                                {dayjs(
                                                    selectedCronConfig.base_date
                                                ).isSame(dayjs(), 'day') ? (
                                                    <>
                                                        {translate(
                                                            'today',
                                                            language
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {dayjs(
                                                            selectedCronConfig.base_date
                                                        ).format('D. MMM YYYY')}
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                )}

                            <Button
                                type="primary"
                                block
                                size="large"
                                onClick={() => {
                                    Axios.post(
                                        '/askrift/api/change_bundle_interval_public/',
                                        {
                                            subscriptions:
                                                bundle.subscriptions.map(
                                                    (s) => s.uuid
                                                ),
                                            cron_config: selectedCronConfig,
                                        }
                                    )
                                        .then((rsp) => {
                                            setLoading(false);
                                            if (rsp.data.success) {
                                                message.success(
                                                    translate(
                                                        'success',
                                                        language
                                                    )
                                                );

                                                onSuccess(selectedCronConfig);
                                            }
                                        })
                                        .catch((err) => {
                                            const msg =
                                                err.response.data.message;

                                            let errorMsg = translate(
                                                'error_came_up',
                                                language
                                            );

                                            if (msg) {
                                                errorMsg =
                                                    errorMsg + ': ' + msg;
                                            }

                                            message.error(errorMsg);
                                            setLoading(false);
                                        });
                                }}
                            >
                                Staðfesta
                            </Button>
                        </Space>
                    </div>
                </>
            )}
        </div>
    );
}
