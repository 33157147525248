import { Button, Form, Input, Modal, message } from 'antd';
import { useState } from 'react';
import translate from '../../../translate/translate';

type Props = {
    open: boolean;
    onSubmit: (data: any) => void;
    language?: 'is' | 'en';
};

export default function ThirdPartyModal({
    open,
    onSubmit,
    language = 'is',
}: Props) {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            title={translate('save_card', language)}
            footer={false}
            open={open}
        >
            <p>{translate('you_are_redirected_and_back', language)}</p>
            <Form
                size="large"
                onFinish={(values) => {
                    if (!values.email || !values.name) {
                        message.error(
                            translate('please_fill_out_all_fields', language)
                        );
                        return;
                    }

                    setLoading(true);

                    onSubmit(values);
                }}
            >
                <Form.Item name="name">
                    <Input
                        placeholder={translate('name', language).toString()}
                    />
                </Form.Item>

                <Form.Item name="email">
                    <Input
                        placeholder={translate('email', language).toString()}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={loading}
                        block
                        type="primary"
                        htmlType="submit"
                    >
                        {translate('go_to_issuer_to_save_card', language)}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
