// @ts-nocheck
import React, {useEffect} from 'react';
import {Button, Input, Form} from 'antd';

// Libraries
import Axios from 'axios';

/* Interfaces */
// import Product, {ProductAttribute} from '../../interfaces/product';

const {TextArea} = Input;

/* Types */
/* Interfaces */
interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function UpdateCustomerCards(props) {
    useEffect(() => {}, []);

    return (
        <>
            <>
                <div id='sales_page'>
                    <div id='sales_page_inner'>
                        <p>Uppfæra korta tokens</p>
                        <p>
                            Notaðu netfang, símanúmer eða kennitölu eftir því hvaða upplýsingar eru í boði í gagnagrunninum / gögnunum sem þú ert með....og svo augljóslega kortanúmerið. Þú þarft ekki CVC eða gildistíma
                            (Salt Pay).
                        </p>

                        <p>Settu strenginn "skip" ef þú vilt</p>
                        <div>
                            <Form
                                onFinish={(values) => {
                                    const cardNumbers = (values.card_number || '').split('\n');
                                    const ssids = (values.ssid || '').split('\n');
                                    const emails = (values.email || '').split('\n');
                                    const phones = (values.phone || '').split('\n');

                                    const customerData = cardNumbers.map((name, key) => {
                                        const cardNumber = cardNumbers[key] || '';
                                        const ssid = ssids[key] || '';
                                        const email = emails[key] || '';
                                        const phone = phones[key] || '';

                                        return {
                                            card_number: cardNumber,
                                            ssid: ssid,
                                            email: email,
                                            phone: phone,
                                        };
                                    });

                                    Axios.post('/askrift/update_customer_cards/', {data: customerData, shop_uuid: w.uuid});
                                }}
                                layout='vertical'
                            >
                                <div style={{display: 'flex', gap: 15}}>
                                    <Form.Item label='Kennitala' name='ssid'>
                                        <TextArea style={{minHeight: 300}}></TextArea>
                                    </Form.Item>

                                    <Form.Item label='Netfang' name='email'>
                                        <TextArea style={{minHeight: 300}}></TextArea>
                                    </Form.Item>

                                    <Form.Item label='Símanúmer' name='phone'>
                                        <TextArea style={{minHeight: 300}}></TextArea>
                                    </Form.Item>

                                    <Form.Item label='Kortanúmer' name='card_number'>
                                        <TextArea style={{minHeight: 300}}></TextArea>
                                    </Form.Item>
                                </div>

                                <Button htmlType='submit' className='btn-success' type='primary' size='large' id='product_buy_button'>
                                    Bæta við
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}
