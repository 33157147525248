import { Space, Tag } from 'antd';
import { OrderProductAttribute } from '../../interfaces/order';
import { ProductAttribute } from '../../interfaces/product';

type Props = {
    orderAttribute: OrderProductAttribute;
    attribute: ProductAttribute;
    tagBgColor?: string;
    key: string;
    fontSize?: number;
    hideLabel?: boolean;
    inlineBlock?: boolean;
};

export default function SelectedAttributeInfo({
    orderAttribute,
    attribute,
    tagBgColor,
    hideLabel,
    fontSize,
    inlineBlock,
}: Props) {
    return (
        <div
            style={{
                marginBottom: inlineBlock ? 0 : 10,
                display: inlineBlock ? 'inline-block' : 'block',
            }}
        >
            {!hideLabel && (
                <div style={{ marginTop: 10 }}>{attribute?.label}</div>
            )}

            {attribute?.type === 'SELECT' &&
                orderAttribute.option_answers?.map((option) => {
                    return (
                        <Tag
                            style={{
                                borderRadius: 10,
                                background: tagBgColor || 'inherit',
                                fontSize: fontSize || 12,
                                lineHeight: fontSize
                                    ? fontSize + 4 + 'px'
                                    : '12px',
                            }}
                            key={option.uuid}
                        >
                            {option.title}
                        </Tag>
                    );
                })}
        </div>
    );
}
