import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import translate from '../../translate/translate';
import { Currency } from '../../interfaces/product';

const ThreeDSecureAPIModal = (props: {
    cardData: {
        cardNumber: string;
        cvc: string;
        month: string;
        year: string;
    };
    shopUUID: string;
    onSuccess: ({}) => void;
    onCancel: ({}) => void;
    currencyCode: Currency['currency_code'];
    paymentProvider?: 'SALT_PAY' | 'VALITOR' | 'STRAUMUR';
    language: 'is' | 'en';
}) => {
    const { shopUUID, cardData, onSuccess } = props;
    const [iframeSrc, setIframeSrc] = useState<string | undefined | null>();

    useEffect(() => {
        const handlePostMessage = (e: MessageEvent) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setIframeSrc(null);
                onSuccess({
                    ...e.data.valitorMessage,
                });
            }

            if (e.data.hasOwnProperty('teyaMessage')) {
                setIframeSrc(null);
                onSuccess({
                    ...e.data.teyaMessage,
                    cardToken: window.teyaCardToken,
                });
            }
        };

        if (
            props.paymentProvider === 'VALITOR' ||
            props.paymentProvider === 'STRAUMUR'
        ) {
            initializeValitor();
        } else if (props.paymentProvider === 'SALT_PAY') {
            initializeSaltPay();
        }

        window.addEventListener('message', handlePostMessage);

        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, []);

    const initializeSaltPay = () => {
        Axios.post(`/payment/api/teya_three_d_secure/`, {
            cardNumber: cardData.cardNumber,
            expMonth: cardData.month,
            expYear: cardData.year,
            cvc: cardData.cvc,
            shop_uuid: shopUUID,
            currency_code: props.currencyCode,
        })
            .then((rsp) => {
                const teyaCardToken = rsp.data.cardToken;
                // Making card token available to the event listener
                window.teyaCardToken = teyaCardToken;

                if (['1', '2', '3', '4'].includes(rsp.data.res.MdStatus)) {
                    setIframeSrc(null);
                    onSuccess({
                        ...rsp.data.res,
                        cardToken: teyaCardToken,
                    });
                } else if (!rsp.data.res.RedirectToACSForm) {
                    setIframeSrc(null);
                    props.onCancel(rsp.data);
                    message.error(
                        translate('error_came_up') +
                            ': ' +
                            rsp.data.res.MdErrorMessage
                    );
                }

                setIframeSrc(rsp.data.res.RedirectToACSForm);
            })
            .catch((err) => {
                props.onCancel({
                    error: err,
                });
                setIframeSrc(null);
            });
    };

    const initializeValitor = () => {
        Axios.post(`/payment/api/valitor_three_d_secure/`, {
            cardNumber: cardData.cardNumber,
            expMonth: cardData.month,
            expYear: cardData.year,
            shop_uuid: shopUUID,
        }).then((rsp) => {
            setIframeSrc(rsp.data.res.cardVerificationRawResponse);
        });
    };

    return (
        <Modal
            open={true}
            footer={false}
            closable={false}
            onCancel={() => {
                props.onCancel({
                    error: translate(
                        'cancelled_by_user',
                        props.language || 'is'
                    ),
                });
            }}
        >
            {!iframeSrc && <LoadingOutlined />}

            {iframeSrc && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={iframeSrc}
                />
            )}
        </Modal>
    );
};

export default ThreeDSecureAPIModal;
