import React, { useState } from 'react';
import { Button, Form, Modal, Select, Switch, Table, message } from 'antd';
import { LockOutlined, DragOutlined, MenuOutlined } from '@ant-design/icons';
import Axios from 'axios';
import {
    DragDropContext,
    DragDropContextProps,
    DragUpdate,
    Draggable,
    Droppable,
} from '@hello-pangea/dnd';
import {
    DeliveryOption,
    ShopOwnerDeliveryConfig,
} from '../../../interfaces/delivery';
import Product from '../../../interfaces/product';
import { getDeliveryIcon, kr } from '../../../utils';
import DeliveryOptionForm from './DeliveryOptionForm';

type Props = {
    products: Product[];
    config: ShopOwnerDeliveryConfig;
};

export default function NewDeliveryMenu({ products, config }: Props) {
    const [form] = Form.useForm();
    const [reRender, setReRender] = React.useState(false);
    const [optionForm, setOptionForm] = useState<DeliveryOption | boolean>(
        false
    );
    const [loading, setLoading] = useState(false);
    const [configOptions, setConfigOptions] = useState(config.options);
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnd = (result: DragUpdate) => {
        setIsDragging(false);
        if (!result.destination) return;

        const items = Array.from(configOptions);

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setConfigOptions(items);

        const payload = items.map((item) => {
            return {
                uuid: item.uuid,
                weight: items.findIndex((i) => i.uuid === item.uuid),
            };
        });

        Axios.post('/shipping/api/save_delivery_option_order/', {
            shop_id: config.shop,
            items: payload,
        }).then((rsp) => {
            message.success('Röðun vistuð');
        });

        // Axios.patch(`/shipping/api/delivery_config_update/${config.uuid}/`, {
        //     options: items,
        //     shop: config.shop,
        // })
        //     .then(() => {
        //         message.success('Order saved');
        //     })
        //     .catch(() => {
        //         message.error('Failed to save order');
        //     });
    };

    return (
        <div>
            <Form
                initialValues={config}
                layout="vertical"
                form={form}
                onValuesChange={() => {
                    setReRender(!reRender);
                }}
                onFinish={(values) => {
                    setLoading(true);
                    Axios.patch(
                        `/shipping/api/delivery_config_update/${config.uuid}/`,
                        {
                            ...values,
                            shop: config.shop,
                        }
                    )
                        .then(() => {
                            message.success('Breytingar vistaðar');
                        })
                        .catch(() => {
                            message.error(
                                'Eitthvað fór úrskeiðis, ekki tókst að vista breytingar'
                            );
                        })
                        .finally(() => setLoading(false));
                }}
            >
                <Form.Item
                    label="Birta fyrir allar vörur?"
                    name="all_products"
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren="JÁ"
                        unCheckedChildren="NEI"
                    ></Switch>
                </Form.Item>

                {!form.getFieldValue('all_products') && (
                    <Form.Item
                        label="Aðeins í boðið fyrir þessar vörur"
                        name="products"
                    >
                        <Select
                            mode="tags"
                            placeholder="Veldu vörur sem þessir afhendingarmátar eiga að gilda fyrir"
                        >
                            {products.map((product) => (
                                <Select.Option
                                    key={product.id}
                                    value={product.id}
                                >
                                    {product.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <DragDropContext
                    onDragEnd={handleDragEnd}
                    onDragStart={() => setIsDragging(true)}
                >
                    <Droppable droppableId="configOptions" direction="vertical">
                        {(provided) => (
                            <Table
                                size="small"
                                className="white_table pointer_table"
                                pagination={false}
                                dataSource={configOptions}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            if (!isDragging)
                                                setOptionForm(record);
                                        },
                                    };
                                }}
                                rowKey="uuid"
                                components={{
                                    body: {
                                        wrapper: (
                                            props: JSX.IntrinsicAttributes &
                                                React.ClassAttributes<HTMLTableSectionElement> &
                                                React.HTMLAttributes<HTMLTableSectionElement>
                                        ) => (
                                            <tbody
                                                {...props}
                                                ref={provided.innerRef}
                                            />
                                        ),
                                        row: ({
                                            className,
                                            style,
                                            ...restProps
                                        }: {
                                            className: string;
                                            style: React.CSSProperties;
                                            [key: string]: any;
                                        }) => {
                                            const index =
                                                configOptions.findIndex(
                                                    (option) =>
                                                        option.uuid ===
                                                        restProps[
                                                            'data-row-key'
                                                        ]
                                                );
                                            return (
                                                <Draggable
                                                    key={
                                                        restProps[
                                                            'data-row-key'
                                                        ]
                                                    }
                                                    draggableId={
                                                        restProps[
                                                            'data-row-key'
                                                        ]
                                                    }
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <tr
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                className
                                                            }
                                                            style={{
                                                                ...style,
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                            }}
                                                            {...restProps}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        },
                                    },
                                }}
                                columns={[
                                    {
                                        title: 'Endurraða',
                                        align: 'center',
                                        render: (record) => {
                                            return (
                                                <MenuOutlined
                                                    style={{ cursor: 'grab' }}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: '',
                                        render: (record) => {
                                            return (
                                                <div className="text-center">
                                                    {getDeliveryIcon(
                                                        record.shipping_provider,
                                                        25
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Titill',
                                        render: (record) => {
                                            return (
                                                <>
                                                    {record.hidden_from_public && (
                                                        <LockOutlined />
                                                    )}{' '}
                                                    {record.title}
                                                </>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Öll póstnúmer',
                                        align: 'center',
                                        render: (record) => {
                                            return record.applicable_postal_codes &&
                                                record.applicable_postal_codes
                                                    .length > 0 ? (
                                                <small>Nei</small>
                                            ) : (
                                                <small>Já</small>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Frítt ef yfir',
                                        align: 'center',
                                        render: (record) => {
                                            return (
                                                <small>
                                                    {record.free_delivery_threshold
                                                        ? kr(
                                                              record.free_delivery_threshold
                                                          )
                                                        : ''}
                                                </small>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Verð',
                                        align: 'right',
                                        render: (record) => {
                                            return kr(record.price);
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Droppable>
                </DragDropContext>

                <div className="text-right">
                    <br />
                    <Button size="small" onClick={() => setOptionForm(true)}>
                        + Afhendingarmáti
                    </Button>
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Vista
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                open={!!optionForm}
                onCancel={() => setOptionForm(false)}
                footer={false}
            >
                {optionForm && (
                    <DeliveryOptionForm
                        config={config}
                        option={optionForm !== true ? optionForm : undefined}
                        onSuccess={(resData) => {
                            setOptionForm(false);
                            setConfigOptions([
                                ...configOptions.filter(
                                    (o) => o.uuid !== resData.uuid
                                ),
                                resData,
                            ]);
                        }}
                    ></DeliveryOptionForm>
                )}
            </Modal>
        </div>
    );
}
