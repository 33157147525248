import { useContext, useEffect, useState } from 'react';
import { Button, Card, Table } from 'antd';
import Dashboard from '../../components/Dashboard/Dashboard';
import AuthContext from '../../authContext';

type Props = {};

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function DashboardPicker({}: Props) {
    const { shops, selectedShop, setSelectedShop } = useContext(AuthContext);

    return (
        <>
            {(shops?.length || 0) > 1 && !selectedShop ? (
                <>
                    <Card
                        title="Veldu verslun"
                        className="basePageCard noShadow"
                        style={{ maxWidth: '500px' }}
                    >
                        <Table
                            pagination={false}
                            dataSource={shops}
                            columns={[
                                {
                                    title: 'Nafn',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: '',
                                    dataIndex: '',
                                    render: (record) => (
                                        <div className="text-right">
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    if (
                                                        record &&
                                                        setSelectedShop
                                                    ) {
                                                        setSelectedShop(record);
                                                    }
                                                }}
                                            >
                                                Opna
                                            </Button>
                                        </div>
                                    ),
                                },
                            ]}
                        ></Table>
                    </Card>
                </>
            ) : (
                <Dashboard></Dashboard>
            )}
        </>
    );
}
