import { Button, Table, message } from 'antd';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Product from '../../interfaces/product';

type Props = {
    data: any;
    shopUUID: string;
};

export default function ShopifyProductsOverivew({ data, shopUUID }: Props) {
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        Axios.get(`/verslun/api/admin_product/?shop__uuid=${shopUUID}`).then(
            (rsp) => {
                setProducts(rsp.data.results);
            }
        );
    }, []);

    return (
        <div>
            <Table
                pagination={false}
                dataSource={data.products?.products}
                columns={[
                    {
                        title: 'Vara',
                        dataIndex: 'title',
                    },
                    {
                        title: 'Variants',
                        render: (record: any) => {
                            return (
                                <div>
                                    {record.variants.map((variant: any) => {
                                        const product = products.find(
                                            (product) =>
                                                product.shopify_info
                                                    ?.shopify_variant_id ===
                                                variant.id
                                        );

                                        return (
                                            <div
                                                style={{
                                                    border: '1px solid #eee',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <>
                                                    <div>
                                                        <strong>
                                                            {variant.title}
                                                        </strong>
                                                    </div>
                                                    {variant.id}{' '}
                                                    {/* @ts-ignore */}
                                                    <CopyToClipboard
                                                        text={variant.id.toString()}
                                                        onCopy={() =>
                                                            message.success(
                                                                'Tókst, kóðinn hefur verið afritaður!'
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            style={{
                                                                background:
                                                                    '#96bf48',
                                                                color: 'white',
                                                            }}
                                                            size="small"
                                                        >
                                                            Afrita SHOPIFY
                                                            variant id
                                                        </Button>
                                                    </CopyToClipboard>
                                                    {product && (
                                                        <div>
                                                            <hr />
                                                            <div>
                                                                Þessi vara er
                                                                tengd í gegnum
                                                                Repeat.is
                                                            </div>

                                                            {/* @ts-ignore */}
                                                            <CopyToClipboard
                                                                text={
                                                                    product.uuid
                                                                }
                                                                onCopy={() =>
                                                                    message.success(
                                                                        'Tókst, kóðinn hefur verið afritaður!'
                                                                    )
                                                                }
                                                            >
                                                                <Button size="small">
                                                                    Afrita
                                                                    REPEAT
                                                                    vörunúmer
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </div>
                                                    )}
                                                    <br />
                                                </>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        },
                    },
                ]}
            ></Table>
        </div>
    );
}
