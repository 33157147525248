import { useState, useEffect } from 'react';
import { Form, Button, message, InputNumber, Space } from 'antd';
import Axios from 'axios';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

// Typescript
import Product from '../../../interfaces/product';

type Props = {
    product: Product;
};

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function ProductConfirmationSettings(props: Props) {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const { product } = props;
    document.documentElement.setAttribute('data-color-mode', 'dark');

    return (
        <Form
            form={form}
            initialValues={{
                confirmation_message: product.confirmation_message,
            }}
            layout="vertical"
            onFinish={(values) => {
                Axios.patch(
                    '/verslun/api/basic_product/' + product.uuid + '/',
                    {
                        confirmation_message: values.confirmation_message,
                    }
                ).then(() => {
                    message.success('Tókst!');
                });
            }}
        >
            <p>
                Hægt er a nota HTML. Þessi skilaboð birtast kaupendum á
                staðfestingarsíðu eftir að kaupum er lokið.
            </p>

            <Form.Item name="confirmation_message" label="Skilaboð">
                <AceEditor
                    mode="html"
                    theme="monokai"
                    showPrintMargin={false}
                    width="100%"
                    setOptions={{
                        useWorker: false,
                    }}
                ></AceEditor>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={loading}
                    type="primary"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
