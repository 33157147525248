import React, { ReactElement, useState, useEffect } from 'react';
import { Button, Form, Input, Alert, Select, message } from 'antd';
import axios from 'axios';
//@ts-ignore
import { Kennitala } from '../../kennitala.js';
import Order from '../../interfaces/order';
import Product, {
    CheckoutPaymentOption,
    Currency,
    ProductAttributeWithAnswer,
} from '../../interfaces/product.js';
import { StoreValue } from 'rc-field-form/lib/interface';
import { getAvailablePaymentMethods, getPostalName, kr } from '../../utils';
import PersonalInformation from '../../interfaces/personalInformation';
import translate, { LanguageKey } from '../../translate/translate.js';
import useDropp from '../../customHooks/Dropp/Dropp.js';
import { DeliveryOption, PostboxOption } from '../../interfaces/delivery.js';
import Shop from '../../interfaces/shop.js';

const { TextArea } = Input;

interface Props {
    personal_info_list: Array<PersonalInformation>;
    product: Product;
    externalRef?: string;
    quantity: number;
    oneTimePurchase: boolean;
    cronSettings?: object;
    customerInfo?: {
        ssid?: string;
        name?: string;
        email?: string;
    };
    disableDetailInputs?: boolean;
    selectedCustomPrice?: number | null | undefined;
    lang?: LanguageKey;
    currency: Currency['currency_code'];
}

interface IWindow extends Window {
    uuid?: string;
    shop_uuid?: string;
}

const w: IWindow = window;

const availableCardProviders = getAvailablePaymentMethods('cards');

export default function ProductPaymentForm(props: Props): ReactElement {
    const deliveryConfig = props.product.delivery_config;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [iframeSrc, setIframeSrc] = useState<string | undefined | null>();
    const [valitorData, setValitorData] = useState();
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<CheckoutPaymentOption>(
            props.product.checkout_payment_options[0]
        );
    const [formValues, setFormValues] = useState<
        StoreValue | undefined | null
    >();
    const [orderUuid, setOrderUuid] = useState<string>('');
    const [customerUuid, setCustomerUuid] = useState<string>('');
    const [personalInfoList, setPersonalInfoList] =
        useState<Array<PersonalInformation>>();
    const [product, setProduct] = useState<Product>();
    const [oneTimePurchase, setOneTimePurchase] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption | null>();
    const [postboxOptions, setPostboxOptions] = useState<PostboxOption[]>([]);
    const [parcelpointOptions, setParcelpointOptions] = useState<
        PostboxOption[]
    >([]);
    const [selectedDPOPostalCode, setSelectedDPOPostalCode] = useState<
        string | null
    >();

    const [selectedDPTPostalCode, setSelectedDPTPostalCode] = useState<
        string | null
    >();
    const [shop, setShop] = useState<Shop>();

    const {
        droppSelection,
        clearDroppSelection,
        openDroppModal,
        droppDeliveryZips,
    } = useDropp(deliveryConfig?.dropp?.store_id);

    const { lang } = props;

    useEffect(() => {
        if (
            !selectedDPOPostalCode ||
            selectedDPOPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpo_name: undefined,
            });
        } else if (selectedDPOPostalCode.toString().length === 3) {
            axios
                .post('/shipping/api/posturinn_postboxes', {
                    shop_uuid: w.shop_uuid,
                    postal_code: selectedDPOPostalCode,
                })
                .then((rsp) => {
                    setPostboxOptions(rsp.data || []);
                });
        }
    }, [selectedDPOPostalCode]);

    useEffect(() => {
        if (
            !selectedDPTPostalCode ||
            selectedDPTPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpt_name: undefined,
            });
        } else if (selectedDPTPostalCode?.toString().length === 3) {
            axios
                .post('/shipping/api/posturinn_parcelpoints', {
                    shop_uuid: w.shop_uuid,
                    postal_code: selectedDPTPostalCode,
                })
                .then((rsp) => {
                    setParcelpointOptions(rsp.data || []);
                });
        }
    }, [selectedDPTPostalCode]);

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setIframeSrc(null);
                setValitorData(e.data.valitorMessage);
            }
        });

        if (props.personal_info_list) {
            setPersonalInfoList(props.personal_info_list);
        }

        if (props.product) {
            form.setFieldsValue({
                payment_method: props.product.checkout_payment_options[0],
            });
            setProduct(props.product);
        }

        if (props.oneTimePurchase) {
            setOneTimePurchase(props.oneTimePurchase);
        }

        axios
            .get(`/verslun/api/shop_details/${props.product.shop_uuid}/`)
            .then((rsp) => {
                setShop(rsp.data);
            });
    }, []);

    useEffect(() => {
        if (valitorData) {
            payForOrder(orderUuid, customerUuid);
        }
    }, [valitorData]);

    useEffect(() => {
        let droppLocationId = droppSelection?.id;

        if (formValues && product) {
            const option = product.delivery_config?.options.find(
                (o) => o.id === formValues.delivery_option
            );

            if (
                option?.shipping_provider === 'DROPP_PICKUP' &&
                !!!droppSelection
            ) {
                setErrorMessage(
                    translate('shipping_method_required', lang).toString()
                );
                setLoading(false);
                return;
            }

            if (option?.applicable_postal_codes?.length) {
                if (
                    !option.applicable_postal_codes.includes(
                        (formValues.postal_code || '').toString()
                    )
                ) {
                    setErrorMessage(
                        translate('postal_code_not_accepted', lang).toString()
                    );
                    setLoading(false);
                    return;
                }
            }

            if (
                option?.shipping_provider === 'DROPP_HOMESENT' &&
                droppDeliveryZips
            ) {
                const droppHomesentCodes = droppDeliveryZips.codes.map((obj) =>
                    obj.code.toString()
                );

                const isDroppHome = droppHomesentCodes.includes(
                    (formValues.postal_code || '').toString()
                );

                const droppFlytjandicodes =
                    droppDeliveryZips.flytjandicodes.map((obj) =>
                        obj.code.toString()
                    );

                const isDroppFlytjandi = droppFlytjandicodes.includes(
                    (formValues.postal_code || '').toString()
                );

                // These are hardcoded ids for homedelivery and flytjandi, this comes from Dropp api docs
                if (isDroppHome || isDroppFlytjandi)
                    droppLocationId = isDroppHome
                        ? '9ec1f30c-2564-4b73-8954-25b7b3186ed3'
                        : 'a178c25e-bb35-4420-8792-d5295f0e7fcc';

                if (!isDroppHome && !isDroppFlytjandi) {
                    setErrorMessage(
                        translate('dropp_no_home', lang).toString()
                    );
                    setLoading(false);
                    return;
                }
            }

            const c = axios.post('/verslun/api/update_or_create_customer/', {
                ...formValues,
                shop: w.shop_uuid,
            });
            const o = axios.post('/verslun/api/order/', {
                shop: product.shop,
                currency_code: props.currency,
            });

            Promise.all([c, o])
                .then((rsp) => {
                    const customer = rsp[0].data;
                    const order = rsp[1].data;

                    setOrderUuid(order.uuid);
                    setCustomerUuid(customer.uuid);

                    axios
                        .patch('/verslun/api/order/' + order.uuid + '/', {
                            customer: customer.uuid,
                            currency_code: props.currency,

                            // Delivery
                            external_delivery_location_id: droppLocationId,
                            delivery_option: formValues.delivery_option,
                            delivery_postal_code:
                                formValues.posturinn_dpo_postal_code ||
                                formValues.posturinn_dpo_postal_code,
                            posturinn_location_name:
                                formValues.posturinn_dpt_name ||
                                formValues.posturinn_dpo_name,
                            // End of delivery

                            products: [
                                {
                                    cron_transaction: props.cronSettings,
                                    product: product.id,
                                    one_time_purchase: oneTimePurchase,
                                    mailing_list_consent:
                                        formValues.mailing_list_consent,
                                    // Delivery
                                    currency_code: props.currency,
                                    external_delivery_location_id:
                                        droppLocationId,
                                    delivery_option: formValues.delivery_option,
                                    delivery_postal_code:
                                        formValues.posturinn_dpt_postal_code ||
                                        formValues.posturinn_dpo_postal_code,
                                    posturinn_location_name:
                                        formValues.posturinn_dpt_name ||
                                        formValues.posturinn_dpo_name,
                                    // End of delivery

                                    quantity: props.quantity || 1,
                                    selected_custom_price:
                                        props.selectedCustomPrice,
                                    attributes:
                                        product.attributes &&
                                        product.attributes.map(
                                            (
                                                attribute: ProductAttributeWithAnswer
                                            ) => {
                                                if (
                                                    attribute.slug ===
                                                    'gift_certificate_message'
                                                ) {
                                                    return {
                                                        attribute,
                                                        text_answer:
                                                            formValues.gift_certificate_message,
                                                    };
                                                } else {
                                                    return {
                                                        attribute,
                                                        option_answers:
                                                            attribute.option_answers,
                                                    };
                                                }
                                            }
                                        ),
                                },
                            ],
                        })
                        .then((patch_res) => {
                            if (
                                ['VALITOR', 'STRAUMUR'].includes(
                                    product.payment_provider
                                )
                            ) {
                                valitorThreeDSecure(order);
                            } else {
                                payForOrder(order.uuid, customer.uuid);
                            }
                        })
                        .catch((err) => {
                            setFormValues(null);
                            setLoading(false);
                            setErrorMessage('Úps eitthvað fór úrskeiðis');
                        });
                })
                .catch((err) => {
                    setFormValues(null);
                    setErrorMessage('Úps eitthvað fór úrskeiðis');
                });
        }
    }, [formValues]);

    const payForOrder = (order_uuid: string, customer_uuid: string) => {
        axios
            .post('/verslun/api/pay_for_order/', {
                card_number: formValues.card_number,
                cvc: formValues.cvc,
                month: formValues.month,
                year: formValues.year,
                order_uuid: order_uuid,
                form: formValues,
                payment_method: selectedPaymentMethod,
                customer_uuid: customer_uuid,
                one_time_purchase: oneTimePurchase,
                external_ref: props.externalRef,
                currency_code: props.currency,
                ...(valitorData || {}),
            })
            .then((payment_res) => {
                if (shop && shop.checkout_success_return_url) {
                    window.open(shop.checkout_success_return_url, '_blank');
                }

                document.location.href = `/pontun/${order_uuid}/${
                    lang ? '?lang=' + lang : ''
                }`;
            })
            .catch((err) => {
                setLoading(false);
                try {
                    setErrorMessage(err.response.data.error);
                } catch (error) {
                    setErrorMessage(translate('card_error', lang).toString());
                }
            });
    };

    const valitorThreeDSecure = (order: Order) => {
        axios
            .post(`/payment/api/valitor_three_d_secure/`, {
                cardNumber: formValues.card_number,
                expMonth: formValues.month,
                expYear: '20' + formValues.year,
                order_uuid: order.uuid,
                currency_code: props.currency,
            })
            .then((rsp) => {
                if (!rsp.data.res.isSuccess) {
                    let msg = rsp.data.res.responseDescription;

                    if (
                        rsp.data.res.responseDescription ===
                        'Card check was not successful. ResponseCode : 51'
                    ) {
                        msg = 'Insufficient funds/over credit limit.';
                    }

                    setErrorMessage(msg);
                    setLoading(false);
                    return;
                }

                setIframeSrc(rsp.data.res.cardVerificationRawResponse);
            })
            .catch((err) => {
                setLoading(false);
                try {
                    setErrorMessage(err.response.data.error);
                } catch (error) {
                    const msg = translate('card_error', lang).toString();
                    setErrorMessage(msg);
                }
            });
    };

    return personalInfoList && product ? (
        <Form
            form={form}
            initialValues={props.customerInfo || {}}
            layout="vertical"
            name="basic"
            onValuesChange={(values) => {
                if (values.postal_code && values.postal_code.length === 3) {
                    const name = getPostalName(values.postal_code);
                    if (name) {
                        form.setFieldsValue({
                            town: name,
                        });
                    }
                }
            }}
            onFinish={(values) => {
                setErrorMessage(undefined);

                if (!shop?.skip_terms) {
                    if (!values.terms_checkbox) {
                        setErrorMessage(
                            translate('please_agree_to_terms', lang).toString()
                        );
                        return;
                    }
                }

                if (values.ssid) {
                    const kt = new Kennitala(values.ssid);
                    // @ts-ignore
                    if (!kt.valid) {
                        setErrorMessage('Ógild kennitala.');
                        return;
                    }
                    // @ts-ignore
                    values.ssid = kt.kt;
                }

                setFormValues(values);
                setLoading(true);
            }}
        >
            {!!personalInfoList.filter((obj) => obj.slug === 'name').length && (
                <Form.Item
                    name="name"
                    label={
                        translate('name', lang) +
                        (product.type === 'GIFT_CERTIFICATE' ? ' (þitt)' : '')
                    }
                    rules={[
                        {
                            required: true,
                            message: translate('name_required', lang),
                        },
                    ]}
                >
                    <Input
                        disabled={
                            !!props.disableDetailInputs &&
                            !!props.customerInfo?.name
                        }
                        type="name"
                        placeholder={translate('name', lang).toString()}
                        size="large"
                        autoComplete="name"
                    />
                </Form.Item>
            )}
            {(!!personalInfoList.filter((obj) => obj.slug === 'ssid').length ||
                selectedPaymentMethod === 'BANK_CLAIM') && (
                <Form.Item
                    name="ssid"
                    label={translate('ssid', lang)}
                    rules={[
                        {
                            required: true,
                            message: translate('ssid_is_required', lang),
                        },
                    ]}
                >
                    <Input
                        disabled={
                            !!props.disableDetailInputs &&
                            !!props.customerInfo?.ssid
                        }
                        placeholder={translate('ssid', lang).toString()}
                        size="large"
                        autoComplete="off"
                    />
                </Form.Item>
            )}
            {(!!personalInfoList.filter((obj) => obj.slug === 'address')
                .length ||
                selectedDeliveryOption?.requires_home_address) && (
                <>
                    <Form.Item
                        name="street"
                        label={translate('address', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                    >
                        <Input
                            placeholder={translate('address', lang).toString()}
                            size="large"
                            autoComplete="shipping street-address"
                        />
                    </Form.Item>

                    <Form.Item
                        name="postal_code"
                        label={translate('postal_code', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(40% - 12px)',
                        }}
                    >
                        <Input
                            placeholder={translate(
                                'postal_code',
                                lang
                            ).toString()}
                            autoComplete="shipping postal-code"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="town"
                        label={translate('town', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(60%)',
                            marginLeft: '12px',
                        }}
                    >
                        <Input
                            placeholder={translate('town', lang).toString()}
                            size="large"
                            autoComplete="shipping locality"
                        />
                    </Form.Item>
                </>
            )}
            {!!personalInfoList.filter((obj) => obj.slug === 'email')
                .length && (
                <Form.Item
                    name="email"
                    label={
                        translate('email', lang) +
                        (product.type === 'GIFT_CERTIFICATE' ? ' (þitt)' : '')
                    }
                    rules={[
                        {
                            required: true,
                            message: translate('email_required', lang),
                        },
                        {
                            type: 'email',
                            message: 'Þetta er ekki gilt netfang',
                        },
                    ]}
                >
                    <Input
                        disabled={
                            !!props.disableDetailInputs &&
                            !!props.customerInfo?.email
                        }
                        type="email"
                        placeholder={translate('email', lang).toString()}
                        size="large"
                        autoComplete="email"
                    />
                </Form.Item>
            )}
            {!!personalInfoList.filter((obj) => obj.slug === 'phone')
                .length && (
                <Form.Item
                    name="phone"
                    label={translate('phone', lang)}
                    rules={[
                        {
                            required: true,
                            message: translate('field_required', lang),
                        },
                    ]}
                >
                    <Input
                        type="number"
                        placeholder={translate('phone', lang).toString()}
                        size="large"
                        autoComplete="phone"
                    />
                </Form.Item>
            )}
            {product.type === 'GIFT_CERTIFICATE' && (
                <Form.Item
                    name="gift_certificate_message"
                    label="Skilaboð á gjafabréf"
                >
                    <TextArea placeholder="Skilaboð á gjafabréf" rows={5} />
                </Form.Item>
            )}
            {
                <Form.Item
                    label="Tegund greiðslu"
                    name="payment_method"
                    hidden={product.checkout_payment_options.length < 2}
                    rules={[
                        {
                            required: true,
                            message: translate('field_required', lang),
                        },
                    ]}
                >
                    <Select
                        size="large"
                        onChange={(paymentOption) => {
                            setSelectedPaymentMethod(paymentOption);
                        }}
                        defaultActiveFirstOption={true}
                        children={product.checkout_payment_options.map(
                            (option, key) => {
                                return (
                                    <Select.Option value={option} key={key}>
                                        {availableCardProviders.includes(
                                            option
                                        ) && <>Kreditkort</>}

                                        {option === 'BANK_CLAIM' && (
                                            <>Greiðsluseðill í heimabanka</>
                                        )}
                                    </Select.Option>
                                );
                            }
                        )}
                    />
                </Form.Item>
            }
            {product.delivery_config && (
                <Form.Item
                    name="delivery_option"
                    label={translate('delivery_method', lang)}
                    rules={[
                        {
                            required: true,
                            message: translate(
                                'delivery_method_required',
                                lang
                            ),
                        },
                    ]}
                >
                    <Select
                        size="large"
                        showSearch={product.delivery_config.options.length > 5}
                        filterOption={(input, option) => {
                            return (
                                option?.title?.toLowerCase() ?? ''
                            ).includes(input);
                        }}
                        onSelect={(id: number) => {
                            const option =
                                product.delivery_config?.options.find(
                                    (o) => o.id === id
                                );

                            if (option) {
                                if (option.shipping_provider) {
                                    setSelectedDeliveryOption(option);

                                    if (
                                        option.shipping_provider ===
                                        'DROPP_PICKUP'
                                    ) {
                                        openDroppModal();
                                    } else {
                                        clearDroppSelection();
                                    }
                                }
                            }
                        }}
                        children={product.delivery_config.options.map(
                            (option, i) => {
                                return (
                                    <Select.Option
                                        value={option.id}
                                        key={option.id}
                                        title={option.title}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{option.title}</div>

                                            {(option.shipping_provider ==
                                                'CUSTOM' ||
                                                option.shipping_provider ==
                                                    'POSTURINN_DPH' ||
                                                option.shipping_provider ==
                                                    'POSTURINN_DPO' ||
                                                option.shipping_provider ==
                                                    'POSTURINN_DPP' ||
                                                option.shipping_provider ==
                                                    'POSTURINN_DPT') && (
                                                <small>
                                                    {kr(option.price)}
                                                </small>
                                            )}

                                            {option.shipping_provider ==
                                                'DROPP_HOMESENT' &&
                                                deliveryConfig && (
                                                    <small>
                                                        {kr(option.price)}
                                                    </small>
                                                )}

                                            {option.shipping_provider ==
                                                'DROPP_PICKUP' &&
                                                deliveryConfig && (
                                                    <small>
                                                        {kr(option.price)}
                                                    </small>
                                                )}
                                        </div>
                                    </Select.Option>
                                );
                            }
                        )}
                    ></Select>
                </Form.Item>
            )}
            {selectedDeliveryOption?.shipping_provider === 'POSTURINN_DPO' && (
                // Póstbox
                <>
                    <Form.Item
                        name="posturinn_dpo_postal_code"
                        label={translate('posturinn_dpo_postal_code', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(40% - 12px)',
                        }}
                    >
                        <Input
                            onChange={(e) => {
                                const val = e.target.value;
                                setSelectedDPOPostalCode(val);
                            }}
                            placeholder={translate(
                                'posturinn_dpo_postal_code',
                                lang
                            ).toString()}
                            autoComplete="shipping postal-code"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="posturinn_dpo_name"
                        label={translate('posturinn_dpo', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(60%)',
                            marginLeft: '12px',
                        }}
                    >
                        <Select
                            placeholder={translate('posturinn_dpo', lang)}
                            size="large"
                            defaultActiveFirstOption={true}
                            notFoundContent={translate(
                                'nothing_found_for_postal_code',
                                lang
                            )}
                            children={postboxOptions.map((option, i) => {
                                return (
                                    <Select.Option
                                        value={option.name}
                                        key={option.postboxId}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{option.name}</div>
                                        </div>
                                    </Select.Option>
                                );
                            })}
                        ></Select>
                    </Form.Item>
                </>
            )}
            {selectedDeliveryOption?.shipping_provider === 'POSTURINN_DPT' && (
                // Pósturinn pakkaport sækja
                <>
                    <Form.Item
                        name="posturinn_dpt_postal_code"
                        label={translate('posturinn_dpt_postal_code', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(40% - 12px)',
                        }}
                    >
                        <Input
                            onChange={(e) => {
                                const val = e.target.value;
                                setSelectedDPTPostalCode(val);
                            }}
                            placeholder={translate(
                                'posturinn_dpt_postal_code',
                                lang
                            ).toString()}
                            autoComplete="shipping postal-code"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="posturinn_dpt_name"
                        label={translate('posturinn_dpt', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: 'calc(60%)',
                            marginLeft: '12px',
                        }}
                    >
                        <Select
                            placeholder={translate('posturinn_dpt', lang)}
                            size="large"
                            defaultActiveFirstOption={true}
                            notFoundContent={translate(
                                'nothing_found_for_postal_code',
                                lang
                            )}
                            children={parcelpointOptions.map((option, i) => {
                                return (
                                    <Select.Option
                                        value={option.name}
                                        key={option.postboxId}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{option.name}</div>
                                        </div>
                                    </Select.Option>
                                );
                            })}
                        ></Select>
                    </Form.Item>
                </>
            )}
            {droppSelection && (
                <div
                    style={{
                        border: '1px solid #07007e',
                        background: '#07007e0f',
                        color: '#07007e',
                        fontSize: 14,
                        padding: 5,
                        borderRadius: 3,
                        display: 'flex',
                        marginBottom: 20,
                        marginTop: -10,
                    }}
                >
                    <img
                        src="/static/images/global_logos/dropp.svg"
                        alt="Dropp logo"
                        style={{ width: 40, marginRight: 5 }}
                    />
                    <div>
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                            {droppSelection.name}
                        </div>
                        <div>{droppSelection.address}</div>
                    </div>
                </div>
            )}
            {availableCardProviders.includes(selectedPaymentMethod) && (
                <>
                    <Form.Item
                        name="card_number"
                        label={translate('card_number', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'card_number_required',
                                    lang
                                ),
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder={translate(
                                'card_number',
                                lang
                            ).toString()}
                            size="large"
                            autoComplete="cc-number"
                        />
                    </Form.Item>
                    <Form.Item
                        name="month"
                        label={translate('month', lang)}
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333%)',
                        }}
                        rules={[
                            {
                                len: 2,
                                message: 'Þessi reitur á að innihalda 2 stafi',
                            },
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={translate('month', lang).toString()}
                            maxLength={2}
                            autoComplete="cc-exp-month"
                        />
                    </Form.Item>
                    <Form.Item
                        name="year"
                        label={translate('year', lang)}
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333% - 12px)',
                            marginLeft: '12px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                            {
                                len: 2,
                                message: 'Þessi reitur á að innihalda 2 stafi',
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={2}
                            placeholder={translate('year', lang).toString()}
                            autoComplete="cc-exp-year"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cvc"
                        label="CVC"
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333% - 12px)',
                            marginLeft: '12px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={4}
                            placeholder="CVC"
                            autoComplete="cc-csc"
                        />
                    </Form.Item>
                </>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {!shop?.skip_terms && (
                    <Form.Item
                        style={{
                            alignItems: 'flex-start',
                            flex: 1,
                        }}
                        label={
                            <>
                                {product.terms_url && (
                                    <div>
                                        {translate('agree', lang)} {}
                                        <a
                                            href={product.terms_url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {translate('terms', lang)}
                                        </a>
                                    </div>
                                )}

                                {product.terms && !product.terms_url && (
                                    <div>
                                        {translate('agree', lang)} {}
                                        <a
                                            href={
                                                '/skilmalar/' +
                                                w.shop_uuid +
                                                '?product=' +
                                                product.uuid
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {translate('terms', lang)}
                                        </a>
                                    </div>
                                )}

                                {!product.terms && !product.terms_url && (
                                    <div>
                                        {translate('agree', lang)} {}
                                        <a
                                            href={'/skilmalar/' + w.shop_uuid}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {translate('terms', lang)}
                                        </a>
                                    </div>
                                )}
                            </>
                        }
                        name="terms_checkbox"
                    >
                        <Input
                            size="large"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const checked = e.target.checked;

                                form.setFieldsValue({
                                    terms_checkbox: checked,
                                });
                            }}
                            type="checkbox"
                        />
                    </Form.Item>
                )}

                {product.get_mailing_list_approval && (
                    <Form.Item
                        label={translate('mailing_list_signup', lang)}
                        name="mailing_list_consent"
                        style={{
                            alignItems: 'flex-start',
                            flex: 1,
                        }}
                    >
                        <Input
                            size="large"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const checked = e.target.checked;

                                form.setFieldsValue({
                                    mailing_list_consent: checked,
                                });
                            }}
                            type="checkbox"
                        />
                    </Form.Item>
                )}
            </div>
            {iframeSrc && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={iframeSrc}
                />
            )}
            {errorMessage && (
                <Alert
                    message={errorMessage}
                    type="error"
                    showIcon
                    closable
                    style={{ marginBottom: 10 }}
                />
            )}
            <Form.Item style={{ marginBottom: 0 }}>
                <Button
                    className="btn-success"
                    block
                    size="large"
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                >
                    {translate('confirm', lang)}
                </Button>
            </Form.Item>
        </Form>
    ) : (
        <></>
    );
}
