import React, { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

interface MyFormData {
    customer: {
        name: string;
        email: string;
        ssid?: string;
        street?: string;
        postal_code?: string;
        town?: string;
        phone?: string;
    };
    products: {
        uuid: string;
        quantity: number;
        custom_price?: number;
    }[];
    interval?: {
        count: number;
        type: 'MONTH' | 'YEAR' | 'WEEK';
        base_date: string;
    };
}

export default function App() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <form
            action="http://localhost:8000/repeat_checkout/7b507ddd-bf3d-4f7f-9130-4a2097d9e119/"
            method="POST"
        >
            {/* Customer */}
            <input value="Óli Tómas" {...register('customer.name')} />
            <input
                value="2202863399"
                placeholder="Kennitala"
                {...register('customer.ssid')}
            />
            <input
                value="olitomas@olitomas.com"
                placeholder="Netfang"
                {...register('customer.email')}
            />

            {/* Products */}
            {/* <input
                hidden
                value="1b21b578-34a2-430b-baa6-f54e108df31e"
                {...register('products[0].uuid')}
            />
            <input hidden value="3" {...register('products[0].quantity')} /> */}
            <input
                hidden
                value="7b18d61d-e734-479c-90c8-9253d324f901"
                {...register('products[0].uuid')}
            />
            <input hidden value="5" {...register('products[0].quantity')} />

            {/* Interval */}
            <input hidden value="YEAR" {...register('interval.type')} />
            <input hidden value="4" {...register('interval.count')} />
            <input hidden value="false" {...register('interval.editable')} />
            <input
                hidden
                value="some_identifier"
                {...register('external_ref')}
            />

            {/* {errors.lastName && <p>Last name is required.</p>}
            <input {...register('age', { pattern: /\d+/ })} />
            {errors.age && <p>Please enter number for age.</p>} */}
            <input type="submit" />
        </form>
    );
}
