import { Card } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import Order from '../../interfaces/order';
import Shop from '../../interfaces/shop';
import { kr } from '../../utils';
import SVG from 'react-inlinesvg';
import ApexCharts from 'react-apexcharts';
import brandingConfig from '../../utils/brandingConfig';
import dayjs from 'dayjs';
import Product from '../../interfaces/product';

interface NewestOrder extends Omit<Order, 'shop' | 'products'> {
    shop: Shop;
    products: Product[];
}

interface Stats {
    subscriptions: {
        cancelled: number;
        created: number;
        data: {
            count: number;
            created: string;
            title: string;
        };
    };
    subscription_transactions: {
        data: {
            card_sum: number;
            claim_sum: number;
            payment_received_date: string;
            payment_received_date__day: number;
            sum: number;
        }[];
        total: number;
    };
}

interface Res {
    all_time: Stats;
    last_month: Stats;
    this_month: Stats;
    newest_order: NewestOrder;
    total_active_subscriptions: number;
    total_shops_with_active_subscriptions: number;
    newest_active_shop: Shop;
}

const chartColors = [
    brandingConfig.primaryColor,
    '#878e88',
    '#96C0B7',
    '#D4DFC7',
    '#FEF6C9',
    '#2D2D2A',
    '#F7B1AB',
];

export default function Geckoboard() {
    const [res, setRes] = useState<Res>();

    useEffect(() => {
        Axios.get('/verslun/geckoboard_stats/').then(
            (response: AxiosResponse<Res>) => {
                setRes(response.data);
            }
        );
    }, []);

    const newestOrder = res?.newest_order;

    return (
        <div id="repeatGeckoboard">
            {res && newestOrder && (
                <>
                    <div className="geckoboardSection">
                        <Card
                            title={'Nýjasta pöntunin'}
                            extra={
                                <>
                                    <img
                                        alt="Logo"
                                        src={res.newest_order.shop?.logo.image}
                                        style={{ height: 50 }}
                                    />
                                </>
                            }
                        >
                            <div>
                                <h2>
                                    {newestOrder.products
                                        .map((product) => product.title)
                                        .join(', ')}
                                </h2>

                                <h3>{newestOrder.shop.name}</h3>

                                <div>
                                    <b>{newestOrder.customer.name}</b> (
                                    {newestOrder.customer.email})
                                </div>

                                <br />

                                <h2>{kr(newestOrder.final_full_price)}</h2>
                            </div>
                        </Card>

                        <Card
                            title={'Fjöldi viðskiptavina með sölu í mánuðinum'}
                            extra={res.total_shops_with_active_subscriptions}
                        >
                            <div>
                                <div>Alls sala í {dayjs().format('MMMM')}</div>
                                <h1>
                                    {kr(
                                        res.this_month.subscription_transactions
                                            .total
                                    )}
                                </h1>
                            </div>
                            <div>
                                <div>
                                    Alls sala í{' '}
                                    {dayjs()
                                        .subtract(1, 'month')
                                        .format('MMMM')}
                                </div>

                                <h3>
                                    {kr(
                                        res.last_month.subscription_transactions
                                            .total
                                    )}
                                </h3>
                            </div>

                            <div>
                                <hr />
                                <div>Alls frá upphafi</div>
                                <h3>
                                    {kr(
                                        res.all_time.subscription_transactions
                                            .total
                                    )}
                                </h3>
                            </div>
                        </Card>

                        <Card
                            title="Nýjasta verslunin með sölu"
                            bodyStyle={{ textAlign: 'center' }}
                        >
                            <h2>{res.newest_active_shop.name}</h2>
                            <img
                                src={res.newest_active_shop.logo.image}
                                alt="Logo"
                                style={{
                                    maxWidth: 200,
                                }}
                            ></img>
                        </Card>
                    </div>

                    <div className="geckoboardSection">
                        <Card
                            title={'Fjöldi áskrifta í gegnum repeat.is'}
                            bodyStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: 200,
                                flexDirection: 'column',
                            }}
                        >
                            <h1
                                style={{
                                    color: brandingConfig.successColor,
                                    fontSize: 38,
                                }}
                            >
                                {kr(res.total_active_subscriptions, '')}
                            </h1>

                            <div
                                style={{
                                    fontSize: 11,
                                    marginBottom: 10,
                                    borderTop: '1px dashed black',
                                    paddingTop: 20,
                                    width: 90,
                                }}
                            ></div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <h5 style={{ color: 'ligthgray' }}>
                                    Nýskráningar
                                </h5>
                                /
                                <h5 style={{ color: 'ligthgray' }}>
                                    Afskráningar
                                </h5>
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <h3
                                    style={{
                                        color: brandingConfig.successColor,
                                    }}
                                >
                                    {kr(
                                        res.this_month.subscriptions.created,
                                        ''
                                    )}
                                </h3>{' '}
                                /{' '}
                                <h3
                                    style={{
                                        color: brandingConfig.dangerColor,
                                    }}
                                >
                                    {kr(
                                        res.this_month.subscriptions.cancelled,
                                        ''
                                    )}
                                </h3>
                            </div>
                            <div
                                style={{
                                    fontSize: 11,
                                    marginBottom: 10,
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                í {dayjs().format('MMMM')}
                            </div>
                        </Card>

                        <Card>
                            <ApexCharts
                                options={{
                                    colors: chartColors,
                                    chart: {
                                        type: 'bar',
                                        height: 350,
                                        stacked: true,
                                        toolbar: {
                                            show: true,
                                        },
                                        zoom: {
                                            enabled: true,
                                        },
                                    },
                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                legend: {
                                                    position: 'bottom',
                                                    offsetX: -10,
                                                    offsetY: 0,
                                                },
                                            },
                                        },
                                    ],
                                    yaxis: {
                                        labels: {
                                            formatter: (val) => {
                                                return kr(val);
                                            },
                                        },
                                    },
                                    xaxis: {
                                        type: 'datetime',
                                        categories:
                                            res.last_month.subscription_transactions.data.map(
                                                (day: {
                                                    payment_received_date: string;
                                                }) => day.payment_received_date
                                            ),
                                        labels: {
                                            formatter: (val: string) => {
                                                console.log(val);
                                                return dayjs(val).format(
                                                    'D. MMM'
                                                );
                                            },
                                        },
                                    },
                                    legend: {
                                        position: 'top',
                                    },
                                    fill: {
                                        opacity: 1,
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        formatter: function (val) {
                                            // format the value here
                                            // for example, add a dollar sign and commas
                                            return kr(val);
                                        },
                                    },
                                }}
                                series={[
                                    {
                                        name: 'Bankakröfur',
                                        data: res.last_month.subscription_transactions.data.map(
                                            (transaction: {
                                                claim_sum: number;
                                            }) => transaction.claim_sum
                                        ),
                                    },
                                    {
                                        name: 'Kortagreiðslur',
                                        data: res.last_month.subscription_transactions.data.map(
                                            (transaction: {
                                                card_sum: number;
                                            }) => transaction.card_sum
                                        ),
                                    },
                                ]}
                                type="bar"
                                height={350}
                            />
                        </Card>

                        <Card
                            bodyStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                background: 'black',
                                boxShadow: 'none',
                                border: 'none',
                            }}
                            style={{ border: 'none' }}
                        >
                            <SVG
                                id="hero_logo"
                                baseURL="/"
                                cacheRequests={true}
                                description="Logo"
                                src={'/static/images/logo/logo.svg'}
                                title="Logo"
                                key="hero_logo"
                                uniqueHash="hero_logo"
                                uniquifyIDs={false}
                                fill={brandingConfig.primaryColor}
                                style={{ width: '70%' }}
                            />
                        </Card>
                    </div>
                </>
            )}
        </div>
    );
}
