import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Product, { Language } from '../../../interfaces/product';
import TabPane from 'antd/lib/tabs/TabPane';
import { Button, Form, Input, Tabs, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

type Props = {
    product: Product;
};

export default function ProductLanguages(props: Props) {
    const [product, setProduct] = useState(props.product);
    const [languages, setLanguages] = useState<Language[]>();

    useEffect(() => {
        Axios.get('/verslun/api/language_list/').then((response) => {
            setLanguages(response.data);
        });
    }, [product]);

    return (
        <div>
            <Tabs>
                {languages?.map((language: Language) => {
                    const currentTranslation = product.translations?.find(
                        (l) => l.language.code === language.code
                    );

                    return (
                        <TabPane
                            tab={
                                <>
                                    <img
                                        alt="Flag"
                                        src={`/static/images/svg/languages/${language.code.toLowerCase()}.svg`}
                                        style={{
                                            width: '20px',
                                            marginRight: '5px',
                                        }}
                                    ></img>{' '}
                                    {language.title}
                                </>
                            }
                            key={language.code}
                        >
                            <Form
                                size="large"
                                layout="vertical"
                                initialValues={
                                    currentTranslation ? currentTranslation : {}
                                }
                                onFinish={(values) => {
                                    const payload = values;

                                    if (currentTranslation) {
                                        payload.uuid = currentTranslation.uuid;
                                    }
                                    Axios.post(
                                        `/verslun/api/product/${product.uuid}/language/${language.code}/`,
                                        payload
                                    )
                                        .then((rsp) =>
                                            message.success('Tungumál vistað')
                                        )
                                        .catch(() =>
                                            message.error(
                                                'Villa við að vista tungumál'
                                            )
                                        );
                                }}
                            >
                                <Form.Item label="Titill" name="title">
                                    <Input type="text" />
                                </Form.Item>
                                <Form.Item label="Lýsing" name="description">
                                    <TextArea rows={20}></TextArea>
                                </Form.Item>

                                <Button type="primary" htmlType="submit">
                                    Vista
                                </Button>
                            </Form>
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
}
