import { useState, useEffect } from 'react';
import { Select, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { kr } from '../../utils';
import useDropp from '../../customHooks/Dropp/Dropp.js';
import {
    DeliveryOption,
    DeliveryConfig,
    DroppLocationRes,
    ParcelPointOption,
} from '../../interfaces/delivery.js';
import { PostboxOption } from '../../interfaces/delivery.js';
import usePosturinn from '../../customHooks/Posturinn/Posturinn';
import translate, { LanguageKey } from '../../translate/translate';
import PikkoloSelector from './PikkoloSelector';
import { FormInstance } from 'antd/es/form/Form.js';

type Props = {
    deliveryConfig: DeliveryConfig;
    onSelect: (option?: DeliveryOption) => void;
    onDroppChange: (droppSelection?: DroppLocationRes) => void;
    onPikkoloChange?: (station?: any) => void;
    shopUuid: string;
    totalProductPrice?: number;
    language?: LanguageKey;
    form?: FormInstance<any>;
    onValuesChange?: (changedValues: { [key: string]: any }) => void;
    freeDelivery?: boolean;
};

export default function DeliverySelector({
    deliveryConfig,
    shopUuid,
    onDroppChange,
    onPikkoloChange,
    onSelect,
    totalProductPrice,
    language,
    onValuesChange,
    freeDelivery,
}: Props) {
    const lang = language || 'is';

    const [selectedOption, setSelectedOption] = useState<DeliveryOption>();
    const [parcelPointOptions, setParcelPointOptions] = useState<
        ParcelPointOption[]
    >([]);
    const [postboxOptions, setPostboxOptions] = useState<PostboxOption[]>([]);

    const {
        droppSelection,
        clearDroppSelection,
        openDroppModal,
        droppDeliveryZips,
    } = useDropp(deliveryConfig.dropp?.store_id);

    const { getPostalCodeLocations } = usePosturinn(shopUuid);

    useEffect(() => {
        if (selectedOption) {
            const shippingProvider = selectedOption.shipping_provider;
            onSelect(selectedOption);

            if (shippingProvider === 'DROPP_PICKUP') {
                openDroppModal();
            } else {
                clearDroppSelection();
            }
        }
    }, [selectedOption]);

    useEffect(() => {
        onDroppChange(droppSelection || undefined);
    }, [droppSelection]);

    return (
        <div>
            <Form.Item
                label={translate('delivery_method', lang)}
                required={true}
                rules={[
                    {
                        required: true,
                        message: translate('delivery_method_required', lang),
                    },
                ]}
            >
                <Select
                    showSearch={deliveryConfig.options.length > 5}
                    filterOption={(input, option) => {
                        return (option?.title?.toLowerCase() ?? '').includes(
                            input
                        );
                    }}
                    placeholder={'Veldu afhendingarmáta'}
                    size="large"
                    onSelect={(id: number) => {
                        const option = deliveryConfig.options.find(
                            (o) => o.id === id
                        );

                        setSelectedOption(option);
                    }}
                    children={deliveryConfig.options.map((option, i) => {
                        let price = option.price;

                        if (
                            totalProductPrice &&
                            option.free_delivery_threshold
                        ) {
                            if (
                                freeDelivery ||
                                option.free_delivery_threshold <=
                                    totalProductPrice
                            )
                                price = 0;
                        }

                        return (
                            <Select.Option
                                value={option.id}
                                key={option.id}
                                title={option.title}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        {option.hidden_from_public && (
                                            <LockOutlined />
                                        )}{' '}
                                        {option.title}
                                    </div>

                                    {!freeDelivery && (
                                        <small>{kr(price)}</small>
                                    )}
                                </div>
                            </Select.Option>
                        );
                    })}
                ></Select>
            </Form.Item>

            {selectedOption?.shipping_provider === 'POSTURINN_DPT' && (
                <div style={{ display: 'flex', gap: 10 }}>
                    <Form.Item
                        style={{
                            flex: 1,
                        }}
                        name="posturinn_dpt_postal_code"
                        label={translate('posturinn_dpt_postal_code', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        labelCol={{ span: 24 }}
                    >
                        <Input
                            onChange={(e) => {
                                const val = e.target.value;

                                if (val.length === 3) {
                                    getPostalCodeLocations(val, 'DPT').then(
                                        (rsp) => {
                                            const locations = rsp.data;
                                            setParcelPointOptions(locations);
                                        }
                                    );
                                }
                            }}
                            placeholder={translate(
                                'posturinn_dpt_postal_code',
                                lang
                            ).toString()}
                            autoComplete="shipping postal-code"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="posturinn_dpt_id"
                        label={translate('posturinn_dpt', lang)}
                        style={{
                            flex: 3,
                        }}
                        labelCol={{ span: 24 }}
                    >
                        <Select
                            size="large"
                            placeholder={translate('posturinn_dpt', lang)}
                            defaultActiveFirstOption={true}
                            notFoundContent={translate(
                                'nothing_found_for_postal_code',
                                lang
                            )}
                            onChange={(val) => {
                                if (onValuesChange)
                                    onValuesChange({
                                        external_delivery_location_id: val,
                                    });
                            }}
                            children={parcelPointOptions.map((option, i) => {
                                return (
                                    <Select.Option
                                        value={option.parcelPointId}
                                        key={option.parcelPointId}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{option.name}</div>
                                        </div>
                                    </Select.Option>
                                );
                            })}
                        ></Select>
                    </Form.Item>
                </div>
            )}

            {selectedOption?.shipping_provider === 'POSTURINN_DPO' && (
                // Póstbox
                <div style={{ display: 'flex', gap: 10 }}>
                    <Form.Item
                        name="posturinn_dpo_postal_code"
                        label={translate('posturinn_dpo_postal_code', lang)}
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            flex: 1,
                        }}
                    >
                        <Input
                            onChange={(e) => {
                                const val = e.target.value;

                                if (val.length === 3) {
                                    getPostalCodeLocations(val, 'DPO').then(
                                        (rsp) => {
                                            const locations = rsp.data;
                                            setPostboxOptions(locations);
                                        }
                                    );
                                }
                            }}
                            placeholder={translate(
                                'posturinn_dpo_postal_code',
                                lang
                            ).toString()}
                            autoComplete="shipping postal-code"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="posturinn_dpo_id"
                        label={translate('posturinn_dpo', lang)}
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', lang),
                            },
                        ]}
                        style={{
                            flex: 3,
                        }}
                    >
                        <Select
                            size="large"
                            placeholder={translate('posturinn_dpo', lang)}
                            defaultActiveFirstOption={true}
                            onChange={(val) => {
                                if (onValuesChange)
                                    onValuesChange({
                                        external_delivery_location_id: val,
                                    });
                            }}
                            notFoundContent={translate(
                                'nothing_found_for_postal_code',
                                lang
                            )}
                            children={postboxOptions.map((option, i) => {
                                return (
                                    <Select.Option
                                        value={option.postboxId}
                                        key={option.postboxId}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{option.name}</div>
                                        </div>
                                    </Select.Option>
                                );
                            })}
                        ></Select>
                    </Form.Item>
                </div>
            )}

            {selectedOption?.shipping_provider === 'PIKKOLO' && (
                <PikkoloSelector
                    language={lang}
                    onStationSelected={(station) => {
                        if (station) {
                            onPikkoloChange && onPikkoloChange(station);
                        }
                    }}
                />
            )}

            {droppSelection && (
                <div
                    style={{
                        background: 'rgb(240 240 240)',
                        fontSize: 14,
                        padding: 5,
                        borderRadius: 10,
                        display: 'flex',
                        marginBottom: 20,
                    }}
                >
                    <img
                        src="/static/images/global_logos/dropp.svg"
                        alt="Dropp logo"
                        style={{ width: 40, marginRight: 5 }}
                    />
                    <div>
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                            {droppSelection.name}
                        </div>
                        <div>{droppSelection.address}</div>
                    </div>
                </div>
            )}
        </div>
    );
}
