import Axios from 'axios';
import { useState } from 'react';
import { Form, Button, message, Input, Modal } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';
import translate, { LanguageKey } from '../../translate/translate';

import 'dayjs/locale/is'; //

import Customer, { SimpleCustomer } from '../../interfaces/customer';

type Props = {
    language: LanguageKey;
    open: boolean;
    customer: Customer | SimpleCustomer;
    onClose: () => void;
};

export default function CreateAppwriteUserAccountModal({
    language,
    open,
    customer,
    onClose,
}: Props) {
    const [password, setPassword] = useState<string>();

    return (
        <div>
            <Modal
                title={translate('select_password', language)}
                footer={null}
                maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                open={open}
            >
                <p>{translate('select_password_message', language)}</p>

                {customer && (
                    <Form
                        layout="vertical"
                        initialValues={{ email: customer.email || '' }}
                    >
                        <Form.Item
                            name="email"
                            label={translate('email', language)}
                        >
                            <Input size="large" disabled />
                        </Form.Item>

                        <Form.Item
                            label={translate('password', language)}
                            name="password"
                        >
                            <Input
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                size="large"
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Item>

                        {/* @ts-ignore */}
                        <PasswordStrengthBar
                            minLength={8}
                            scoreWords={[
                                translate('bad', language),
                                translate('barely_passes', language),
                                translate('just_ok', language),
                                translate('good', language),
                                translate('strong', language),
                            ]}
                            shortScoreWord={translate('too_short', language)}
                            password={password}
                        />

                        <Button
                            disabled={!!!((password || '').length > 7)}
                            onClick={() => {
                                Axios.post(
                                    `/verslun/api/get_or_create_appwrite_user_for_customer/${customer.uuid}/`,
                                    {
                                        password,
                                    }
                                ).then((rsp) => {
                                    onClose();
                                    message.success(
                                        translate(
                                            'account_created_message',
                                            language
                                        )
                                    );
                                });
                            }}
                            type="primary"
                            block
                            size="large"
                        >
                            {translate('create_account', language)}
                        </Button>
                    </Form>
                )}
            </Modal>
        </div>
    );
}
