import React, {useState} from 'react';
import {Upload, UploadFile} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';

type Props = {
    helpText?: string;
    onSuccess: Function;
    onRemove?: Function;
    defaultFileList?: UploadFile[];
    listType?: 'text' | 'picture' | 'picture-card';
};

export default function UploadImage(props: Props) {
    const [loading, setLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [fileList, setFileList] = useState<UploadFile<any>[]>();

    return (
        <Upload
            name='image'
            listType={props.listType || 'picture-card'}
            className='avatar-uploader'
            showUploadList={true}
            fileList={fileList || props.defaultFileList}
            action='/account/api/image/create/'
            headers={{
                'x-csrftoken': (document.getElementsByName('csrfmiddlewaretoken')[0] as HTMLInputElement).value,
            }}
            onRemove={(e) => {
                if (props.onRemove) props.onRemove(e);
            }}
            onChange={(change) => {
                let fL = change.fileList.slice(-1);

                fL.map((file) => {
                    if (file.response) {
                        // Component will show file.url as link
                        file.url = file.response.image;
                    }
                    return file;
                });

                setFileList(fL);

                if (change.file.status === 'uploading') setLoading(true);

                if (change.file.status === 'done') {
                    setLoading(false);
                    setImgUrl(change.file.response.image);

                    props.onSuccess(change.file.response.id, change.file.response, change.fileList[0]);
                }

                if (change.file.status === 'removed') {
                    setFileList([]);
                }
            }}
            multiple={false}
        >
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className='ant-upload-text'>{imgUrl ? 'Uppfæra' : props.helpText || 'Hlaða inn mynd'}</div>
            </div>
        </Upload>
    );
}
