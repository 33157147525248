import { useState, useEffect } from 'react';
import { SubscriptionTransaction } from '../../interfaces/transaction';
import { Button, Space } from 'antd';
import Axios from 'axios';
import brandingConfig from '../../utils/brandingConfig';

type Props = {
    subscriptionTransaction: SubscriptionTransaction;
    isPublic: boolean;
    className?: string;
};

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function TransactionDeliveryInfo(props: Props) {
    // useEffect(() => {
    //     Axios.post('/shipping/api/dropp_order_details/', {
    //         order_id: props.subscriptionTransaction.dropp_order_id,
    //         shop_uuid: props.subscriptionTransaction.,
    //     }).then((rsp) => {
    //         setDroppLocationRes(rsp.data);
    //     });
    // }, [third])
    const isLocalhost = w.top?.location.host.includes('localhost');

    return (
        <div className={props.className ? props.className : ''}>
            {props.subscriptionTransaction.posturinn_shipment_id && (
                <div style={{ margin: 20, textAlign: 'center' }}>
                    <Button
                        target="_blank"
                        size="small"
                        href={`https://posturinn.is/einstaklingar/mottaka/finna-sendingu/?q=${props.subscriptionTransaction.posturinn_shipment_id}`}
                    >
                        Rekja sendingu
                    </Button>
                </div>
            )}

            {props.subscriptionTransaction.dropp_order_id && (
                <div>
                    <div
                        style={{
                            textAlign: 'center',
                            background: brandingConfig.primaryColor,
                            borderRadius: 20,
                        }}
                    >
                        <Space>
                            <img
                                src="/static/images/global_logos/dropp.svg"
                                alt="Dropp"
                            />

                            <Button
                                target="_blank"
                                size="small"
                                href={`https://${
                                    isLocalhost ? 'stage' : 'api'
                                }.dropp.is/dropp/api/v1/web/pdf/getpdf/${
                                    props.subscriptionTransaction.dropp_order_id
                                }/${
                                    props.subscriptionTransaction.dropp_barcode
                                }/`}
                            >
                                PDF
                            </Button>
                            <Button
                                target="_blank"
                                size="small"
                                href={`https://${
                                    isLocalhost ? 'stage' : 'api'
                                }.dropp.is/dropp/tracking/${
                                    props.subscriptionTransaction.dropp_order_id
                                }`}
                            >
                                Rekja sendingu
                            </Button>
                        </Space>
                    </div>
                </div>
            )}
        </div>
    );
}
