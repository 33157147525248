import React, { useState, useEffect } from 'react';
import CopyToClipboardModal from '../CopyToClipboardModal/CopyToClipboardModal';
import { Space, Checkbox, Input, Modal } from 'antd';

type Props = {
    uuid: string;
    type: 'gift_certificate' | 'product';
    onCancel: () => void;
    open: boolean;
    // onChange: (obj: SalesPageUrlParams) => void;
};

export default function CopyIframeCodeModal(props: Props) {
    const [quantityEditable, setQuantityEditable] = useState(true);
    const [defaultQuantity, setDefaultQuantity] = useState(1);
    const [allowCronSettings, setAllowCronSettings] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [weeklyCron, setWeeklyCron] = useState(false);
    const [monthlyCron, setMonthlyCron] = useState(false);
    const [yearlyCron, setYearlyCron] = useState(false);
    const [template, setTemplate] = useState('');
    const [showQuantity, setShowQuantity] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        let params = '';

        if (props.type === 'product') {
            if (showQuantity) {
                if (quantityEditable) {
                    params = '&quantity_editable=true';
                } else {
                    params = '&quantity_editable=false';
                }

                if (defaultQuantity) params += '&quantity=' + defaultQuantity;
            } else {
                params = '';
            }

            if (weeklyCron) params += '&weekly_cron=true';
            if (monthlyCron) params += '&monthly_cron=true';
            if (yearlyCron) params += '&yearly_cron=true';
        }

        params = params ? '?' + params.substring(1) : '';

        // one_time_purchase;
        // monthly_cron;
        // weekly_cron;
        // yearly_cron;
        const _url = `${window.top?.location.origin}/${
            props.type === 'product' ? 'solusida' : 'gjof'
        }/${props.uuid}/${params}`;
        setUrl(_url);

        setTemplate(`
<div>${
            showLoading
                ? `
    <style>
        #repeatLoadingIndicatorContainer{text-align: center;}
        .repeat_loader_ {display: inline-block;position: relative;width: 80px;height: 80px;}
        .repeat_loader_ div {position: absolute;top: 33px;width: 13px;height: 13px;border-radius: 50%;background:rgba(0,0,0,0.2);animation-timing-function: cubic-bezier(0, 1, 1, 0);}
        .repeat_loader_ div:nth-child(1) {left: 8px;animation: repeat_loader_1 0.6s infinite;}
        .repeat_loader_ div:nth-child(2) {left: 8px;animation: repeat_loader_2 0.6s infinite;}
        .repeat_loader_ div:nth-child(3) {left: 32px;animation: repeat_loader_2 0.6s infinite;}
        .repeat_loader_ div:nth-child(4) {left: 56px;animation: repeat_loader_3 0.6s infinite;}

        @keyframes repeat_loader_1 {0% {transform: scale(0);}100% {transform: scale(1);}}
        @keyframes repeat_loader_3 {0% {transform: scale(1);}100% {transform: scale(0);}}
        @keyframes repeat_loader_2 {0% {transform: translate(0, 0);}100% {transform: translate(24px, 0);}}
    </style>
    <div id="repeatLoadingIndicatorContainer">
        <div id="loadingIndicator" class="repeat_loader_"><div></div><div></div><div></div><div></div></div>
    </div>
    `
                : ''
        }
    <iframe 
        id="${props.uuid}"
        width="100%"
        height="100%"
        style="min-height: 850px; background-color: transparent; overflow: hidden;"
        src="${_url}"
        frameborder="0"
    ></iframe>
    <script>
    // <![CDATA[
        window.addEventListener('message', (e) => {
            if (e.data.hasOwnProperty('frameHeight')) {
                if(e.data.uuid === "${props.uuid}"){
                    document.getElementById('${
                        props.uuid
                    }').style.height = e.data.frameHeight + 'px';
                }
            }
            
            if (e.data.hasOwnProperty('scrollToTop')) {
                if(e.data.uuid === "${props.uuid}"){
                    document.getElementById('${props.uuid}').scrollIntoView();
                }
            }
        });
        ${
            showLoading
                ? `document.getElementById('${props.uuid}').onload = function() {document.getElementById('loadingIndicator').style.display = 'none';}`
                : ''
        }
    // ]]>
    </script>
</div>
        `);
    }, [
        quantityEditable,
        defaultQuantity,
        allowCronSettings,
        weeklyCron,
        monthlyCron,
        yearlyCron,
        showQuantity,
        showLoading,
        props.type,
        props.uuid,
    ]);

    return (
        <Modal
            style={{ width: '700px!important' }}
            open={props.open}
            footer={null}
            onCancel={() => {
                props.onCancel();
            }}
        >
            {props.type === 'product' && (
                <Space direction="vertical">
                    <div>
                        <Checkbox
                            checked={showQuantity}
                            onChange={(e) => {
                                setShowQuantity(e.target.checked);
                            }}
                        >
                            Sýna magn
                        </Checkbox>
                    </div>

                    {showQuantity && (
                        <>
                            <div>
                                Sjálfgefið magn:
                                <Input
                                    style={{
                                        display: 'inline-block',
                                        width: 80,
                                        textAlign: 'center',
                                        marginLeft: 10,
                                    }}
                                    type="number"
                                    value={defaultQuantity}
                                    onChange={(e) => {
                                        setDefaultQuantity(
                                            parseInt(e.target.value)
                                        );
                                    }}
                                ></Input>
                            </div>

                            <div>
                                <Checkbox
                                    checked={quantityEditable}
                                    onChange={(e) => {
                                        setQuantityEditable(e.target.checked);
                                    }}
                                >
                                    Viðskiptavinur getur breytt magni
                                </Checkbox>
                            </div>
                        </>
                    )}

                    <div>
                        <Checkbox
                            checked={allowCronSettings}
                            onChange={(e) => {
                                setAllowCronSettings(e.target.checked);
                            }}
                        >
                            Viðskiptavinur getur stillt tíðni sendinga
                        </Checkbox>
                    </div>

                    <div>
                        <Checkbox
                            checked={showLoading}
                            onChange={(e) => {
                                setShowLoading(e.target.checked);
                            }}
                        >
                            Sýna hleðslutákn ("loading icon") á meðan vefsíðan
                            klárar að birtast
                        </Checkbox>
                    </div>

                    {allowCronSettings && (
                        <>
                            <div>
                                <Checkbox
                                    checked={weeklyCron}
                                    onChange={(e) => {
                                        setWeeklyCron(e.target.checked);
                                    }}
                                >
                                    Má velja X <strong>vikna</strong> fresti
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox
                                    checked={monthlyCron}
                                    onChange={(e) => {
                                        setMonthlyCron(e.target.checked);
                                    }}
                                >
                                    Má velja velja X <strong>mánaða</strong>{' '}
                                    fresti
                                </Checkbox>
                            </div>

                            <div>
                                <Checkbox
                                    checked={yearlyCron}
                                    onChange={(e) => {
                                        setYearlyCron(e.target.checked);
                                    }}
                                >
                                    Má velja velja X <strong>ára</strong> fresti
                                </Checkbox>
                            </div>
                        </>
                    )}
                </Space>
            )}
            <CopyToClipboardModal
                title={'Afrita kóða'}
                content={template}
                open={true}
                onCancel={() => {}}
                onChange={() => {}}
                justContents={true}
            ></CopyToClipboardModal>

            <div className="mt20 text-center">eða</div>
            <div className="text-center">
                <a href={url} target="_blank" rel="noopener noreferrer">
                    Sjá útlit
                </a>
            </div>
        </Modal>
    );
}
