import Transaction, {
    SubscriptionTransaction,
} from '../../interfaces/transaction';
import { Space, Badge, Tooltip, Popover } from 'antd';
import { getAvailablePaymentMethods } from '../../utils';
import TransactionClaimStatus from '../TransactionClaimStatus/TransactionClaimStatus';

const cardMethods = getAvailablePaymentMethods('cards');

type Props = {
    transaction: SubscriptionTransaction;
};

export default function TransactionPaymentStatus(props: Props) {
    const { transaction } = props;
    const paymentMethod =
        transaction.payment_method || transaction.subscription.payment_method;
    return (
        <Space size="large" style={{ whiteSpace: 'nowrap' }}>
            {transaction.is_free && <Badge status="success" text="Frítt" />}
            {transaction.is_refunded && (
                <>
                    {paymentMethod === 'BANK_CLAIM' ? (
                        <Badge status="warning" text="Krafa niðurfelld" />
                    ) : (
                        <Badge status="warning" text="Endurgreitt" />
                    )}
                </>
            )}

            {!transaction.is_refunded && !transaction.is_free && (
                <>
                    {
                        // !!!!!!!!!!!!!
                        // Card payments
                        // !!!!!!!!!!!!!

                        cardMethods.includes(paymentMethod) && (
                            <>
                                {!transaction.is_paid && (
                                    <Badge status="error" text="Ógreitt" />
                                )}

                                {transaction.is_paid && (
                                    <Badge status="success" text="Greitt" />
                                )}
                            </>
                        )
                    }

                    {paymentMethod === 'BANK_CLAIM' && (
                        // !!!!!!!!!!!
                        // Bank claims
                        // !!!!!!!!!!!
                        <>
                            {
                                // Bank claim paid
                                transaction.is_paid && (
                                    <Badge
                                        status="success"
                                        text="Krafa greidd"
                                    />
                                )
                            }

                            {
                                // Bank claim creation requested but unknown if successfully created
                                !transaction.is_paid &&
                                    transaction.bank_claim_creation_requested &&
                                    !transaction.bank_claim_created &&
                                    !transaction.bank_claim_failed && (
                                        <Popover
                                            placement="top"
                                            content={
                                                <TransactionClaimStatus
                                                    subscriptionTransaction={
                                                        transaction
                                                    }
                                                ></TransactionClaimStatus>
                                            }
                                            trigger="click"
                                        >
                                            <Badge
                                                status="error"
                                                text="Krafa óbókuð"
                                            />
                                        </Popover>
                                    )
                            }

                            {
                                // Bank claim successfull but unpaid
                                !transaction.is_paid &&
                                    transaction.bank_claim_created && (
                                        <Badge
                                            status="error"
                                            text="Krafa ógreidd"
                                        />
                                    )
                            }

                            {
                                // Bank claim creation failed
                                !transaction.is_paid &&
                                    transaction.bank_claim_failed && (
                                        <Popover
                                            placement="top"
                                            content={
                                                <TransactionClaimStatus
                                                    subscriptionTransaction={
                                                        transaction
                                                    }
                                                ></TransactionClaimStatus>
                                            }
                                            trigger="click"
                                        >
                                            <Badge
                                                style={{ cursor: 'pointer' }}
                                                status="error"
                                                text="Villa: Kröfustofnun"
                                            />
                                        </Popover>
                                    )
                            }
                        </>
                    )}
                </>
            )}
        </Space>
    );
}
