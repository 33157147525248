import { useEffect, useState, useCallback, useContext } from 'react';
import {
    Avatar,
    Button,
    Card,
    Input,
    InputNumber,
    message,
    Space,
    Table,
    Tabs,
    Tag,
} from 'antd';
import {
    LockOutlined,
    UnlockOutlined,
    MinusOutlined,
    LineChartOutlined,
    SoundOutlined,
    YoutubeOutlined,
    VideoCameraOutlined,
    FileImageOutlined,
    FilePdfOutlined,
    EditOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Content, ContentAsset } from '../../interfaces/content';
import ContentStats from './ContentStats/ContentStats';
import AddTagModal from '../CreateContent/AddTagModal';
import AuthContext from '../../authContext';

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

type Props = {};

const getTypeIcon = (type: ContentAsset['type']) => {
    if (type === 'REPEAT_MEDIA' || type === 'CUSTOM_VIMEO' || type === 'VIMEO')
        return <VideoCameraOutlined />;
    if (type === 'CUSTOM_YOUTUBE') return <YoutubeOutlined />;
    if (type === 'AUDIO') return <SoundOutlined />;
    if (type === 'IMAGE') return <FileImageOutlined />;
    if (type === 'PDF') return <FilePdfOutlined />;
};

const pageSize = 25;

export default function ContentOverview({}: Props) {
    const { selectedShop } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [addTagModalVisible, setAddTagModalVisible] = useState(false);
    const [contents, setContents] = useState<Content[]>([]);
    const [baseUrl, setBaseUrl] = useState<string>();

    const [pagination, setPagination] = useState<{
        count: number;
        next: string;
        next_page_number: number;
        previous: string;
        previous_page_number: number;
        total_pages: number;
        results: Content[];
    }>();

    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (selectedShop) {
            setBaseUrl(
                '/efni/api/contents/' +
                    selectedShop?.uuid +
                    '/?page_size=' +
                    pageSize +
                    '&ordering=-date&include_assets=true'
            );
        }
    }, [selectedShop]);

    useEffect(() => {
        if (!baseUrl) return;
        axios.get(baseUrl).then((res) => {
            setContents(res.data.results);
            setLoading(false);
            setPagination(res.data);
        });
    }, [baseUrl]);

    const searchContents = useCallback(
        (term: string) => {
            if (!baseUrl) return;
            if (!baseUrl) return;
            axios.get(baseUrl + '&search=' + term).then((res) => {
                setContents(res.data.results);
                setSearchTerm(term);
            });
        },
        [baseUrl]
    );

    const handler = useCallback(debounce(searchContents, 400), [baseUrl]);

    return (
        <>
            <Card
                loading={loading}
                title={
                    <>
                        <img
                            style={{ height: 40, marginRight: 5 }}
                            src="/static/images/svg/media.svg"
                            alt=""
                        />{' '}
                        Streymisveita
                    </>
                }
                className="basePageCard"
                extra={
                    <Space>
                        {selectedShop && (
                            <>
                                <Button
                                    onClick={() => {
                                        setAddTagModalVisible(true);
                                    }}
                                    type="dashed"
                                    size="small"
                                >
                                    <EditOutlined /> Sýsla með flokka
                                </Button>
                                <Button
                                    type="dashed"
                                    size="small"
                                    href={
                                        '/nytt_efni/' + selectedShop.uuid + '/'
                                    }
                                >
                                    + Bæta við færslu
                                </Button>
                            </>
                        )}
                    </Space>
                }
            >
                <Card>
                    <div className="text-center">
                        <Input
                            style={{
                                margin: 'auto',
                                marginBottom: '10px',
                                width: '100%',
                                maxWidth: '500px',
                                borderRadius: 10,
                            }}
                            allowClear
                            placeholder="Leita að færslu"
                            prefix={<SearchOutlined />}
                            onChange={(e) => {
                                const term = e.currentTarget.value;

                                if (term && term.length > 1) {
                                    handler(term);
                                } else {
                                    setContents(pagination?.results || []);
                                    setSearchTerm('');
                                }
                            }}
                        />
                    </div>

                    <Table
                        dataSource={contents}
                        size="small"
                        rowKey="uuid"
                        className="white_table"
                        pagination={
                            searchTerm
                                ? false
                                : {
                                      pageSize,
                                      total: pagination?.count,
                                      showSizeChanger: false,
                                      onChange: (page) => {
                                          axios
                                              .get(baseUrl + '&page=' + page)
                                              .then((res) => {
                                                  setContents(res.data.results);
                                                  setPagination(res.data);
                                              });
                                      },
                                  }
                        }
                        expandable={{
                            expandedRowRender: (record: Content) => {
                                return selectedShop ? (
                                    <ContentStats
                                        shopUUID={selectedShop.uuid || ''}
                                        content={record}
                                    ></ContentStats>
                                ) : null;
                            },
                            expandIcon: ({ expanded, onExpand, record }) => {
                                console.log(record);
                                if (
                                    !record.assets?.some(
                                        (a) => a.type === 'REPEAT_MEDIA'
                                    )
                                ) {
                                    return null;
                                }

                                return expanded ? (
                                    <Button
                                        type="text"
                                        size="small"
                                        onClick={(e) => onExpand(record, e)}
                                    >
                                        <MinusOutlined />
                                    </Button>
                                ) : (
                                    <Button
                                        type="text"
                                        size="small"
                                        onClick={(e) => onExpand(record, e)}
                                    >
                                        <LineChartOutlined />
                                    </Button>
                                );
                            },
                        }}
                        columns={[
                            {
                                title: '',
                                render: (item) => (
                                    <Avatar
                                        style={{
                                            width: '160px',
                                            height: '86px',
                                            marginRight: '20px',
                                        }}
                                        src={item.main_image}
                                        shape="square"
                                    ></Avatar>
                                ),
                            },
                            {
                                title: 'Titill',
                                className: 'contentTitle',
                                render: (item) => {
                                    return (
                                        <div>
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '200px',
                                                }}
                                            >
                                                {item.title}
                                            </div>
                                            <div
                                                style={{
                                                    color: 'gray',
                                                    fontSize: '11px',
                                                }}
                                            >
                                                Fylgiskjöl:
                                                {item.assets.map(
                                                    (asset: ContentAsset) => {
                                                        return (
                                                            <Tag
                                                                style={{
                                                                    margin: '2px 2px',
                                                                    padding:
                                                                        '1px 5px',
                                                                    lineHeight:
                                                                        '15px',
                                                                    fontSize:
                                                                        '12px',
                                                                    borderRadius:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {getTypeIcon(
                                                                    asset.type
                                                                )}
                                                            </Tag>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: 'Dags',
                                render: (item) => (
                                    <div
                                        style={{
                                            color: 'gray',
                                            fontSize: '12px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {dayjs(item.date).format('D. MMM YYYY')}
                                    </div>
                                ),
                            },
                            {
                                title: 'Aðgengi',
                                render: (item) => {
                                    return (
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '12px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.type === 'PUBLIC' ? (
                                                <>
                                                    <UnlockOutlined /> Opið
                                                    öllum
                                                </>
                                            ) : item.type ===
                                              'ALL_SUBSCRIPTIONS' ? (
                                                <>
                                                    <LockOutlined /> Allar
                                                    áskriftir
                                                </>
                                            ) : (
                                                <>
                                                    <LockOutlined /> Sumar
                                                    áskriftir
                                                </>
                                            )}
                                        </div>
                                    );
                                },
                                className: 'contentType',
                            },
                            {
                                title: 'Flokkar',
                                render: (item) => {
                                    return (
                                        <>
                                            {item.tags
                                                ? item.tags.map((t: string) => {
                                                      return (
                                                          <Tag
                                                              style={{
                                                                  margin: '2px 2px',
                                                                  padding:
                                                                      '1px 5px',
                                                                  lineHeight:
                                                                      '15px',
                                                                  fontSize:
                                                                      '12px',
                                                                  borderRadius:
                                                                      '5px',
                                                              }}
                                                          >
                                                              {t}
                                                          </Tag>
                                                      );
                                                  })
                                                : 'Óflokkað'}
                                        </>
                                    );
                                },
                            },
                            {
                                title: 'Staða',
                                render: (item) => {
                                    return (
                                        <Tag
                                            style={{ borderRadius: '5px' }}
                                            color={
                                                item.is_draft ? 'red' : 'green'
                                            }
                                        >
                                            {item.is_draft ? 'Drög' : 'Birt'}
                                        </Tag>
                                    );
                                },
                            },
                            {
                                title: 'Röðun',
                                render: (item) => {
                                    return (
                                        <InputNumber
                                            style={{
                                                width: '70px',
                                                textAlign: 'center',
                                            }}
                                            onChange={(value) => {
                                                axios
                                                    .patch(
                                                        `/efni/api/content/${item.uuid}/`,
                                                        {
                                                            order: value,
                                                        }
                                                    )
                                                    .then(() => {
                                                        message.success(
                                                            'Röðun uppfærð í: ' +
                                                                value
                                                        );
                                                    });
                                            }}
                                            defaultValue={item.order}
                                        ></InputNumber>
                                    );
                                },
                            },
                            {
                                title: 'Breyta',
                                render: (item) => {
                                    return (
                                        <Button size="small" type="dashed">
                                            <a
                                                href={`/nytt_efni/${selectedShop?.uuid}/?uuid=${item.uuid}`}
                                            >
                                                Breyta
                                            </a>
                                        </Button>
                                    );
                                },
                            },
                        ]}
                    ></Table>
                </Card>
            </Card>

            {selectedShop && addTagModalVisible && (
                <AddTagModal
                    shop={selectedShop}
                    open={addTagModalVisible}
                    onClose={() => {
                        setAddTagModalVisible(false);
                    }}
                ></AddTagModal>
            )}
        </>
    );
}
