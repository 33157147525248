import React, {ReactElement} from 'react';
import {Card, Layout, Row, Col} from 'antd';
const {Content} = Layout;

export default function RepeatTerms(): ReactElement {
    return (
        <Layout>
            <Content style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Row style={{margin: 10, display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
                    <Col xs={24} sm={18} md={16} lg={14} xl={10} xxl={8}>
                        <Card title='Repeat.is - Skilmálar'>
                            <h1>Skilmálar</h1>
                            <h2>Inngangur</h2>
                            <p>Repeat ehf (440521-2660), hér eftir nefnt leigusali og leigukaupi gera með sér svohljóðandi samning.</p>
                            <p>Tilgangur samkomulagsins er að leigusali selji leigukaupa áskrift að repeat.is</p>
                            <h2>Gildistími og uppsögn samnings</h2>
                            <ul>
                                <li>Samningur þessi tekur gildi við stofnun notanda á vefsíðunni repeat.is</li>

                                <li>Samningur þessi er uppsegjanlegur með 1 mánaðar fyrirvara.</li>

                                <li>Uppsögnin skal miðast við mánaðarmót.</li>

                                <li>Leigukaupi skal greiða mánaðargjald fram að þeim tíma þegar samningur fellur úr gildi.</li>

                                <li>Vilji leigukaupi segja upp samningnum ber honum að gera það með því að senda tölvupóst á repeat@repeat.is</li>
                            </ul>
                            <h2>Notkunarskilmálar vegna afnota af kerfi repeat.is</h2>
                            <ul>
                                <li>Repeat.is er hýst hjá viðurkenndum hýsingaraðila sem hefur öryggisvottun.</li>
                                <li>
                                    Kerfið eru rekið efir hefðbundnum viðurkenndum reglum með öflugri afritatöku. Rekstraraðili mun leitast við eftir fremsta megni að halda uppi óskertri þjónustu, þó er ekki unnt að
                                    ábyrgjast 100% upptíma þar sem upp geta komið ófyrirséðar bilanir en reynt verður að koma í veg fyrir slíkt eftir fremsta megni.
                                </li>
                                <li>Reynt verður að framkvæma viðhald og uppfærslur utan hefðbundins vinnutíma.</li>
                                <li>
                                    Takmörkun á ábyrgð Repeat ehf telst ekki skaðabótaskyld vegna ófyrirséðra bilana í vél- eða hugbúnaði. Verði gögn fyrir skemmdum vegna slíkra atvika eða villu í hugbúnaði mun leigusali
                                    aðstoða við enduruppsetningu afrits og leitast við að lagfæra þá villu sem komið hefur upp.
                                </li>
                                <li>leigusali telst ekki ábyrgur vegna notkunar leigukaupa á hugbúnaðinum né afleiddu tjóni er kann að hljótast af notkun hans.</li>
                                <li>Starfsmenn Repeat eru bundnir trúnaði um þau gögn sem þeir meðhöndla og munu ekki afhenda utanaðkomandi aðilum upplýsingar nema gegn dómsúrskurði.</li>
                                <li>leigusali hefur heimild til að keyra ópersónugreinanlega tölfræði á heildargögn í grunni vegna reikningagerðar, álagsstýringar og þróunar.</li>
                                <li>Dráttarvextir greiðast á vanskil.</li>
                                <li>Greiði leigutaki ekki afnotagjöld í 2 mánuði verður aðgengi að kerfinu lokað og gögn ekki afhent nema eldri skuld hafi verið gerð upp.</li>

                                <li>Leigusali getur breytt þessum skilmálum, en þeir skulu ávallt vera aðgengilegir á heimasíðu repeat.is</li>

                                <li>Verði ágreiningur munu aðilar leitast við að leysa hann sín á milli en að öðrum kosti fyrir Héraðsdómi Reykjavíkur.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
