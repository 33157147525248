import { useState, useEffect } from 'react';
import {
    Layout,
    List,
    Space,
    Card,
    Avatar,
    Row,
    Col,
    Button,
    Modal,
    Typography,
    Image,
} from 'antd';
import {
    SettingOutlined,
    RetweetOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { displayCurrencyPrice, getDeliveryIcon } from '../../utils';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import './ConfirmedOrderPage.less';
import { ConfirmedOrder, OrderProduct } from '../../interfaces/order';
import translate, { LanguageKey } from '../../translate/translate';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import { DroppLocationRes } from '../../interfaces/delivery';
import CustomProductForm from '../../components/CustomProductForm/CustomProductForm';
import PodcastInstructions from '../../components/PodcastInstructions/PodcastInstructions';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import CreateAppwriteUserAccountModal from '../../components/CreateAppwriteUserAccountModal/CreateAppwriteUserAccountModal';
import CreateCustomUserAccountModal from '../../components/CreateCustomUserAccountModal/CreateCustomUserAccountModal.tsx';

dayjs.locale('is'); // use locale

// VARIABLES
const { Content } = Layout;
const { Paragraph } = Typography;

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function ConfirmedOrderPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const [order, setOrder] = useState<ConfirmedOrder>();
    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState(500);
    const [createAppwriteUserModal, setCreateAppwriteUserModal] =
        useState(false);

    const [createExternalUserModal, setCreateExternalUserModal] =
        useState(false);
    const [productDetailsModal, setProductDetailsModal] = useState<
        false | OrderProduct['product']
    >(false);

    const [unAnsweredOrderProducts, setUnAnsweredOrderProducts] = useState<
        OrderProduct[]
    >([]);

    const [droppLocationRes, setDroppLocationRes] =
        useState<DroppLocationRes>();

    const [language, setSelectedLanguage] = useState<'is' | 'en'>(
        (urlParams.get('lang') ||
            urlParams.get('language') ||
            'is') as LanguageKey
    );

    const [paymentRequestLog, setPaymentRequestLog] =
        useState<PaymentRequest>();

    useEffect(() => {
        axios
            .get('/verslun/api/get_confirmed_order/' + w.uuid + '/')
            .then((rsp) => {
                setOrder(rsp.data);
                setLoading(false);

                const useAppwriteUserProfile = rsp.data.products.some(
                    (orderProduct: OrderProduct) => {
                        return orderProduct.product.appwrite_user_profiles;
                    }
                );

                if (useAppwriteUserProfile) {
                    const appwriteUserId = rsp.data.customer.appwrite_user_id;

                    if (!appwriteUserId) {
                        setCreateAppwriteUserModal(true);
                    }
                }

                const externalUserProfiles = rsp.data.products.some(
                    (orderProduct: OrderProduct) => {
                        return orderProduct.product.external_user_profiles;
                    }
                );

                if (
                    externalUserProfiles &&
                    !rsp.data.customer.external_user_password_created
                ) {
                    setCreateExternalUserModal(true);
                }

                const customFormProducts = rsp.data.products.filter(
                    (orderProduct: OrderProduct) => {
                        return !!orderProduct.product.custom_form;
                    }
                );

                setUnAnsweredOrderProducts(
                    customFormProducts.filter((orderProduct: OrderProduct) => {
                        return (
                            !!!orderProduct.custom_form_entry &&
                            orderProduct.product.custom_form
                                ?.when_to_collect === 'ORDER_CONFIRMATION_PAGE'
                        );
                    })
                );

                if (rsp.data.final_full_price > 0) {
                    axios
                        .get(
                            '/payment/api/payment_requests_public/?customer=' +
                                rsp.data.customer.uuid +
                                '&order__products=' +
                                rsp.data.products[0].id +
                                '&request_type=PAYMENT' +
                                '&success=true'
                        )
                        .then((rsp) => {
                            setPaymentRequestLog(rsp.data.results[0]);
                        });
                }
            });

        w.onload = () => {
            sendPostMessage();
            setTimeout(() => {
                sendPostMessage();
            }, 200);
        };
        window.onresize = () => sendPostMessage();
    }, []);

    useEffect(() => {
        if (order?.external_delivery_location_id) {
            axios
                .post('/shipping/api/dropp_store_details/', {
                    location_id: order.external_delivery_location_id,
                    shop_uuid: order.shop.uuid,
                })
                .then((rsp) => {
                    setDroppLocationRes(rsp.data);
                });
        }
    }, [order?.external_delivery_location_id]);

    useEffect(() => {
        w.parent.postMessage(
            {
                frameHeight: height,
                uuid: order?.products[0].product.uuid,
            },
            '*'
        );
    }, [height, order]);

    const sendPostMessage = () => {
        setTimeout(() => {
            try {
                const currentHeight = Math.max(
                    document.getElementById('confirmed_container')
                        ?.offsetHeight || 0
                );

                if (height !== currentHeight) {
                    setHeight(currentHeight);
                }
            } catch (error) {
                console.log(error);
            }
        }, 20);
    };

    const customer = order?.customer;

    return (
        <div id="confirmed_container">
            <Content
                style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Col>
                        <Space
                            direction="vertical"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20,
                            }}
                        >
                            <div className="text-center">
                                <img src={order?.shop?.logo} alt="" />
                            </div>
                            <h1 className="text-center">
                                {order?.purchase_date ||
                                order?.creation_method === 'GIFT_CERTIFICATE'
                                    ? translate('order_confirmation', language)
                                    : translate('order_unpaid', language)}
                            </h1>

                            {order?.creation_method === 'GIFT_CERTIFICATE' && (
                                <h3 className="text-center">
                                    {translate(
                                        'gift_certificate_success_message',
                                        language
                                    )}
                                </h3>
                            )}

                            <SelectLanguage
                                defaultValue={language}
                                onChange={(val) => setSelectedLanguage(val)}
                            ></SelectLanguage>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 20,
                                    gap: 10,
                                }}
                            >
                                {order?.shop.show_order_receipt &&
                                    paymentRequestLog && (
                                        <Button
                                            href={`/sending/kvittun/${paymentRequestLog.id}/${order.customer.uuid}/?lang=${language}`}
                                            id="orderReceiptButton"
                                            size="large"
                                        >
                                            <FileTextOutlined />{' '}
                                            {translate('receipt', language)}
                                        </Button>
                                    )}
                                {order?.shop.expose_customer_config &&
                                    order.creation_method !==
                                        'GIFT_CERTIFICATE' && (
                                        <Button
                                            href={`/askrifandi/${order.shop.uuid}/${order.customer.uuid}/?lang=${language}`}
                                            id="mySettingsButton"
                                            size="large"
                                        >
                                            <SettingOutlined />{' '}
                                            {translate('my_settings', language)}
                                        </Button>
                                    )}
                            </div>
                        </Space>
                    </Col>
                </Row>

                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xs={24} sm={20} md={18} lg={6} xl={6} xxl={4}>
                        <Card className="m10">
                            <List>
                                {!!order?.final_shipping_price && (
                                    <>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={translate(
                                                    'product_price',
                                                    language
                                                )}
                                            />
                                            <div>
                                                {displayCurrencyPrice(
                                                    order?.final_product_price ||
                                                        0,
                                                    order.currency_code
                                                )}
                                            </div>
                                        </List.Item>

                                        <List.Item>
                                            <List.Item.Meta
                                                title={translate(
                                                    'shipping_price',
                                                    language
                                                )}
                                            />
                                            <div>
                                                {displayCurrencyPrice(
                                                    order?.final_shipping_price,
                                                    order.currency_code
                                                )}
                                            </div>
                                        </List.Item>
                                    </>
                                )}

                                <List.Item>
                                    <List.Item.Meta
                                        title={translate('total', language)}
                                    />
                                    <div>
                                        {order &&
                                            displayCurrencyPrice(
                                                order?.final_full_price || 0,
                                                order?.currency_code
                                            )}
                                    </div>
                                </List.Item>

                                {order?.delivery_option && (
                                    <>
                                        <div
                                            style={{
                                                marginTop: 20,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {translate(
                                                'delivery_method',
                                                language
                                            )}
                                        </div>
                                        <List.Item style={{ fontSize: 12 }}>
                                            {getDeliveryIcon(
                                                order.delivery_option
                                                    .shipping_provider,
                                                35
                                            )}

                                            <div
                                                style={{
                                                    padding: '0 15px',
                                                    width: '100%',
                                                }}
                                            >
                                                <div>
                                                    {
                                                        order.delivery_option
                                                            .title
                                                    }
                                                </div>
                                                <div>
                                                    {droppLocationRes && (
                                                        <>
                                                            <div>
                                                                {
                                                                    droppLocationRes.name
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    droppLocationRes.address
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </List.Item>
                                    </>
                                )}
                            </List>
                        </Card>

                        <Card
                            className="m10"
                            loading={loading}
                            title={translate('customer', language)}
                        >
                            <List>
                                {customer?.name && (
                                    <List.Item className="capitalize">
                                        {customer?.name}
                                    </List.Item>
                                )}
                                {customer?.ssid && (
                                    <List.Item>{customer?.ssid}</List.Item>
                                )}
                                {customer?.phone && (
                                    <List.Item>{customer?.phone}</List.Item>
                                )}
                                {customer?.email && (
                                    <List.Item>{customer?.email}</List.Item>
                                )}
                                {customer?.street && (
                                    <List.Item>{customer?.street}</List.Item>
                                )}
                                {customer?.town && (
                                    <List.Item>
                                        {customer?.postal_code} {customer?.town}
                                    </List.Item>
                                )}
                            </List>
                        </Card>
                    </Col>

                    <Col xs={24} sm={20} md={18} lg={12} xl={10} xxl={8}>
                        <Card
                            className="m10"
                            loading={loading}
                            title={translate('order', language)}
                            extra={
                                order && (
                                    <div className="text-center">
                                        <small>
                                            {order.creation_method !==
                                                'GIFT_CERTIFICATE' && (
                                                <>
                                                    {order.payment_method ===
                                                    'BANK_CLAIM'
                                                        ? translate(
                                                              'claim_created',
                                                              language
                                                          )
                                                        : translate(
                                                              'paid',
                                                              language
                                                          )}
                                                    :{' '}
                                                    {order?.purchase_date ? (
                                                        <span>
                                                            {dayjs(
                                                                order?.purchase_date
                                                            ).format(
                                                                'D. MMM YYYY - HH:mm'
                                                            )}
                                                        </span>
                                                    ) : (
                                                        'Ógreitt og ófrágengið'
                                                    )}
                                                </>
                                            )}
                                        </small>
                                    </div>
                                )
                            }
                        >
                            {order?.shop.order_confirmation_page_message && (
                                <>
                                    <Card
                                        style={{
                                            background: '#f3f3f3',
                                            fontSize: '1.1em',
                                        }}
                                    >
                                        {
                                            ReactHtmlParser(
                                                order.shop
                                                    .order_confirmation_page_message
                                            ) as React.ReactNode
                                        }
                                    </Card>
                                    <br />
                                </>
                            )}
                            <List
                                dataSource={order?.products || []}
                                renderItem={(order_product) => {
                                    const { product } = order_product;

                                    let intro = product.description;

                                    //Sometimes the description is a html string. If so then we need the first 100 characters
                                    const isHtmlRegex = /<[a-z][\s\S]*>/i;
                                    const isHtml = isHtmlRegex.test(intro);

                                    if (isHtml) {
                                        try {
                                            const parser = new DOMParser();
                                            const htmlDoc =
                                                parser.parseFromString(
                                                    intro,
                                                    'text/html'
                                                );
                                            intro =
                                                htmlDoc.body.textContent || '';

                                            if (intro.length > 900) {
                                                intro = intro.slice(0, 900);
                                            }
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    }

                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        size="large"
                                                        src={
                                                            product.main_image
                                                                .image
                                                        }
                                                    />
                                                }
                                                title={
                                                    <>
                                                        <div>
                                                            {(order_product.quantity ||
                                                                1) > 1 && (
                                                                <span
                                                                    style={{
                                                                        fontSize: 14,
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {
                                                                        order_product.quantity
                                                                    }
                                                                    x{' '}
                                                                </span>
                                                            )}
                                                            {product.title}
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize: 14,
                                                                textAlign:
                                                                    'right',
                                                                position:
                                                                    'absolute',
                                                                top: 10,
                                                                right: 0,
                                                                backgroundColor:
                                                                    'white',
                                                                padding:
                                                                    '0 10px',
                                                            }}
                                                        >
                                                            {order &&
                                                                displayCurrencyPrice(
                                                                    order_product.price,
                                                                    order?.currency_code
                                                                )}

                                                            {order &&
                                                                (order_product.quantity ||
                                                                    1) > 1 &&
                                                                order_product.price && (
                                                                    <div
                                                                        style={{
                                                                            fontWeight:
                                                                                'normal',
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        (
                                                                        {
                                                                            order_product.quantity
                                                                        }
                                                                        x{' '}
                                                                        {displayCurrencyPrice(
                                                                            order_product.price /
                                                                                (order_product.quantity ||
                                                                                    1),
                                                                            order?.currency_code
                                                                        )}
                                                                        )
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </>
                                                }
                                                description={
                                                    <>
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'justify',
                                                                paddingRight: 15,
                                                            }}
                                                        >
                                                            <Space direction="vertical">
                                                                {product?.confirmation_message && (
                                                                    <>
                                                                        <Card
                                                                            style={{
                                                                                background:
                                                                                    '#f3f3f3',
                                                                                whiteSpace:
                                                                                    'pre-wrap',
                                                                            }}
                                                                        >
                                                                            {
                                                                                ReactHtmlParser(
                                                                                    product?.confirmation_message
                                                                                ) as React.ReactNode
                                                                            }
                                                                        </Card>
                                                                    </>
                                                                )}

                                                                {product.type ===
                                                                    'GIFT_CERTIFICATE' && (
                                                                    <div>
                                                                        <Button type="primary">
                                                                            <a
                                                                                href={`/askrift/pdf_bref/${order_product.gift_certificate_code}/`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                Prenta
                                                                                gjafabréf
                                                                            </a>
                                                                        </Button>
                                                                    </div>
                                                                )}

                                                                {order_product.custom_form_entry && (
                                                                    <div
                                                                        style={{
                                                                            border: '1px solid gray',
                                                                            padding:
                                                                                '10px',
                                                                            borderRadius: 5,
                                                                        }}
                                                                    >
                                                                        {order_product.custom_form_entry.text_answers.map(
                                                                            (
                                                                                t,
                                                                                key
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        key={
                                                                                            key
                                                                                        }
                                                                                    >
                                                                                        <strong>
                                                                                            {
                                                                                                t
                                                                                                    .parent
                                                                                                    .question
                                                                                            }

                                                                                            :
                                                                                        </strong>{' '}
                                                                                        {
                                                                                            t.answer
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {(order_product.is_podcast ||
                                                                    order_product
                                                                        .product
                                                                        .repeat_rss_feed) && (
                                                                    <PodcastInstructions
                                                                        language={
                                                                            language
                                                                        }
                                                                        onChange={() => {
                                                                            sendPostMessage();
                                                                        }}
                                                                        orderProducts={[
                                                                            order_product,
                                                                        ]}
                                                                    ></PodcastInstructions>
                                                                )}

                                                                {!order_product.is_podcast && (
                                                                    <>
                                                                        <Paragraph
                                                                            className="productDescription"
                                                                            ellipsis={{
                                                                                rows: 4,
                                                                                // @ts-ignore
                                                                                suffix: (
                                                                                    <span
                                                                                        key={
                                                                                            product.uuid
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            size="small"
                                                                                            type="link"
                                                                                            style={{
                                                                                                color: 'gray',
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setProductDetailsModal(
                                                                                                    product
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {translate(
                                                                                                'read_more',
                                                                                                language
                                                                                            )}
                                                                                        </Button>
                                                                                    </span>
                                                                                ),
                                                                            }}
                                                                        >
                                                                            {
                                                                                ReactHtmlParser(
                                                                                    intro
                                                                                ) as React.ReactNode
                                                                            }
                                                                        </Paragraph>
                                                                    </>
                                                                )}

                                                                <>
                                                                    {order_product.cron_transaction && (
                                                                        <div className="productIntervalDetails">
                                                                            <RetweetOutlined />{' '}
                                                                            {translate(
                                                                                'subscription'
                                                                            )}{' '}
                                                                            :{' '}
                                                                            {
                                                                                order_product
                                                                                    .cron_transaction
                                                                                    .interval
                                                                            }{' '}
                                                                            {order_product
                                                                                .cron_transaction
                                                                                .interval_type ===
                                                                            'WEEK'
                                                                                ? translate(
                                                                                      'weeks',
                                                                                      language
                                                                                  )
                                                                                : order_product
                                                                                        .cron_transaction
                                                                                        .interval_type ===
                                                                                    'YEAR'
                                                                                  ? translate(
                                                                                        'years',
                                                                                        language
                                                                                    )
                                                                                  : translate(
                                                                                        'months',
                                                                                        language
                                                                                    )}{' '}
                                                                            {translate(
                                                                                'apart',
                                                                                language
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            </Space>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            ></List>

                            {(order?.extra_shopify_products?.length || 0) >
                                0 && (
                                <List
                                    style={{
                                        borderTop: '1px solid #f0f0f0',
                                    }}
                                    dataSource={order?.extra_shopify_products}
                                    renderItem={(shopifyProduct) => {
                                        return (
                                            <List.Item
                                                style={{ position: 'relative' }}
                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar
                                                            size="large"
                                                            src={
                                                                shopifyProduct.image
                                                            }
                                                        ></Avatar>
                                                    }
                                                    title={
                                                        <>
                                                            <div>
                                                                {(shopifyProduct.quantity ||
                                                                    1) > 1 && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 14,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        {
                                                                            shopifyProduct.quantity
                                                                        }
                                                                        x{' '}
                                                                    </span>
                                                                )}
                                                                {
                                                                    shopifyProduct.title
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize: 14,
                                                                    textAlign:
                                                                        'right',
                                                                    position:
                                                                        'absolute',
                                                                    top: -10,
                                                                    right: 0,
                                                                    backgroundColor:
                                                                        'white',
                                                                    padding:
                                                                        '0 10px',
                                                                }}
                                                            >
                                                                {order &&
                                                                    displayCurrencyPrice(
                                                                        shopifyProduct.price,
                                                                        order?.currency_code
                                                                    )}

                                                                {order &&
                                                                    (shopifyProduct.quantity ||
                                                                        1) >
                                                                        1 &&
                                                                    shopifyProduct.price && (
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    'normal',
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            (
                                                                            {
                                                                                shopifyProduct.quantity
                                                                            }
                                                                            x{' '}
                                                                            {displayCurrencyPrice(
                                                                                shopifyProduct.price /
                                                                                    (shopifyProduct.quantity ||
                                                                                        1),
                                                                                order?.currency_code
                                                                            )}
                                                                            )
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </>
                                                    }
                                                ></List.Item.Meta>
                                            </List.Item>
                                        );
                                    }}
                                ></List>
                            )}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <div
                        className="text-center"
                        style={{ width: '100%', marginTop: 30 }}
                    >
                        <a
                            style={{ color: 'rgba(0,0,0,0.35)' }}
                            href="http://repeat.is"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>
                                <SVG
                                    id="repeat_page_logo"
                                    style={{ width: 120, opacity: 0.2 }}
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="Repeat.is logo"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /fill:.?#181818/g,
                                            'fill:#b001ff'
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/logo/logo.svg'}
                                    title="Logo"
                                    key={'logo'}
                                    uniqueHash={'logo'}
                                    uniquifyIDs={true}
                                />
                            </div>

                            <div>
                                {translate(
                                    'repeat_confirmation_footer',
                                    language
                                )}
                            </div>
                        </a>
                    </div>
                </Row>
            </Content>

            <Modal
                closable={false}
                footer={null}
                maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                open={!!unAnsweredOrderProducts.length}
            >
                {unAnsweredOrderProducts.map((orderProduct, key) => {
                    return orderProduct.product.custom_form?.uuid ? (
                        <CustomProductForm
                            key={key}
                            uuid={orderProduct.product.custom_form?.uuid}
                            orderProduct={orderProduct}
                            onSuccess={() => {
                                setUnAnsweredOrderProducts(
                                    unAnsweredOrderProducts.filter(
                                        (product) =>
                                            product.id !== orderProduct.id
                                    )
                                );
                            }}
                        ></CustomProductForm>
                    ) : (
                        <></>
                    );
                })}
            </Modal>

            {customer && (
                <CreateAppwriteUserAccountModal
                    language={language}
                    open={createAppwriteUserModal}
                    customer={customer}
                    onClose={() => {
                        setCreateAppwriteUserModal(false);
                    }}
                ></CreateAppwriteUserAccountModal>
            )}

            <Modal
                open={!!productDetailsModal}
                onCancel={() => setProductDetailsModal(false)}
                footer={null}
                title={(productDetailsModal && productDetailsModal.title) || ''}
            >
                <Space direction="vertical" size="large">
                    {productDetailsModal && (
                        <div style={{ textAlign: 'center' }}>
                            <Image
                                style={{ maxHeight: 350, width: 'auto' }}
                                src={productDetailsModal.main_image.image}
                                preview={{
                                    mask: (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.5)',
                                                padding: '5px 15px',
                                            }}
                                        >
                                            {translate(
                                                'click_to_enlarge',
                                                language
                                            )}
                                        </div>
                                    ),
                                }}
                            ></Image>
                        </div>
                    )}

                    <Paragraph>
                        {
                            ReactHtmlParser(
                                productDetailsModal
                                    ? productDetailsModal.description
                                    : ''
                            ) as React.ReactNode
                        }
                    </Paragraph>
                </Space>
            </Modal>

            {customer && (
                <CreateCustomUserAccountModal
                    language={language}
                    open={createExternalUserModal}
                    customer={customer}
                    onClose={() => {
                        setCreateExternalUserModal(false);
                    }}
                />
            )}
        </div>
    );
}
