import React from 'react';
import { ReactFlow, Edge } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import SVG from 'react-inlinesvg';

const RepeatFlow: React.FC = () => {
    const nodes = [
        {
            id: 'main',
            type: 'input',
            data: {
                label: (
                    <div>
                        <div>
                            {/* <SVG
                                baseURL="/"
                                cacheRequests={true}
                                description="Logo"
                                src={'/static/images/logo/logo.svg'}
                                title="Logo"
                                key="hero_logo"
                                uniqueHash="hero_logo"
                                uniquifyIDs={false}
                                fill={'white'}
                            /> */}

                            <div>Repeat tengist öllum helstu þjónustum</div>
                        </div>
                    </div>
                ),
            },
            position: { x: 390, y: 0 },
            style: {
                border: 'none',

                padding: '10px',
                // width: '100%',
                // opacity: 0,
                // position: 'absolute',
            },
            className: 'repeatFlowPlatform',
        },
        {
            id: 'platform1',
            data: {
                label: (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                        }}
                    >
                        <div>
                            <SVG
                                style={{ width: 40 }}
                                baseURL="/"
                                cacheRequests={true}
                                description="dropp_logo"
                                src={
                                    '/static/images/global_logos/dropp_white.svg'
                                }
                                title="Dropp"
                                key="xdropp_logo"
                                uniqueHash="xdropp_logo"
                                uniquifyIDs={false}
                            />
                        </div>
                        <div>
                            <SVG
                                baseURL="/"
                                cacheRequests={true}
                                description="posturinn_logo"
                                src={
                                    '/static/images/global_logos/posturinn.svg'
                                }
                                title="Pósturinn"
                                key="posturinn_logo"
                                uniqueHash="posturinn_logo"
                                uniquifyIDs={false}
                                preProcessor={(code) => {
                                    code = code.replace(
                                        /<path/g,
                                        '<path fill="#fff" '
                                    );
                                    return code;
                                }}
                            />
                        </div>

                        <div>
                            <img
                                width={40}
                                src="/static/images/partner_logos/gorilla.png"
                                alt="Shopify"
                            />
                        </div>
                    </div>
                ),
            },
            position: { x: 100, y: 200 }, // Absolute positioning
            className: 'repeatFlowPlatform',
        },
        {
            id: 'platform2',
            data: {
                label: (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                        }}
                    >
                        <div>
                            <img
                                width={90}
                                src="/static/images/global_logos/squarepace.png"
                                alt="Squarespace"
                            />
                        </div>

                        <div>
                            <img
                                width={90}
                                src="/static/images/global_logos/wordpress.png"
                                alt="Wordpress"
                            />
                        </div>

                        <div>
                            <img
                                width={90}
                                src="/static/images/global_logos/shopify.png"
                                alt="Shopify"
                            />
                        </div>

                        <div>
                            <img
                                width={90}
                                src="/static/images/global_logos/wix.png"
                                alt="Wix"
                            />
                        </div>
                    </div>
                ),
            },
            position: { x: 390, y: 210 },
            className: 'repeatFlowPlatform',
        },
        {
            id: 'platform3',
            data: {
                label: (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                        }}
                    >
                        <div>
                            <img
                                width={70}
                                src="/static/images/partner_logos/podbean.png"
                                alt="Squarespace"
                            />
                        </div>

                        <div>
                            <img
                                width={70}
                                src="/static/images/partner_logos/libsyn.webp"
                                alt="Libsyn"
                            />
                        </div>

                        <div>
                            <SVG
                                baseURL="/"
                                cacheRequests={true}
                                description="mindbody"
                                src={
                                    '/static/images/partner_logos/mindbody.svg'
                                }
                                title="mindbody"
                                key="mindbody"
                                uniqueHash="mindbody"
                                uniquifyIDs={false}
                                style={{
                                    width: 70,
                                }}
                            />
                        </div>

                        <small>... og margt, margt fleira</small>
                    </div>
                ),
            },
            position: { x: 700, y: 210 }, // Absolute positioning

            className: 'repeatFlowPlatform',
        },
        // {
        //     id: 'platform4',
        //     data: {
        //         label: (
        //             <div style={{ display: 'flex', alignItems: 'center' }}>
        //                 <img
        //                     src="/static/images/global_logos/wix.png"
        //                     alt="Wix"
        //                 />
        //             </div>
        //         ),
        //     },
        //     position: { x: 700, y: 200 }, // Absolute positioning
        //     style: {
        //         background: '#151313',

        //         padding: '10px',
        //         borderRadius: '10px',
        //         border: '1px solid #5a5a5a',

        //         display: 'flex',
        //         justifyContent: 'center',
        //     },
        // },
    ];

    const edges: Edge[] = [
        {
            id: 'e1',
            source: 'main',
            target: 'platform1',
            animated: true,
            className: 'nowheel',
            style: {
                stroke: '#5a5a5a',
                strokeWidth: 1,
                pointerEvents: 'none',
            },
        },
        {
            id: 'e2',
            source: 'main',
            target: 'platform2',
            animated: true,
            className: 'nowheel',
            style: {
                stroke: '#5a5a5a',
                strokeWidth: 1,
                pointerEvents: 'none',
            },
        },
        {
            id: 'e3',
            source: 'main',
            target: 'platform3',
            animated: true,
            className: 'nowheel',
            style: {
                stroke: '#5a5a5a',
                strokeWidth: 1,
                pointerEvents: 'none',
            },
        },
        // {
        //     id: 'e4',
        //     source: 'main',
        //     target: 'platform4',
        //     animated: true,
        //     className: 'nowheel',
        //     style: {
        //         stroke: '#5a5a5a',
        //         strokeWidth: 1,
        //         pointerEvents: 'none',
        //     },
        // },
    ];

    return (
        <ReactFlow
            // style={{
            //     top: 0,
            //     position: 'absolute',
            //     // width: '100%',
            //     // // height: '100%',
            //     // background: 'transparent',
            //     // borderRadius: '10px',
            //     // display: 'flex',
            //     // justifyContent: 'center',
            // }}
            fitView
            defaultViewport={{ x: 0, y: 0, zoom: 1 }}
            nodes={nodes}
            edges={edges}
            zoomOnScroll={false}
            zoomOnPinch={false}
            zoomOnDoubleClick={false}
            draggable={false}
            preventScrolling={true}
            proOptions={{ hideAttribution: true }}
        />
    );
};

export default RepeatFlow;
