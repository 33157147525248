import { Modal, Form, Input, Button } from 'antd';
import { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { format } from 'prettier/standalone';
import parserHtml from 'prettier/plugins/html';
import axios from 'axios';

type Props = {
    html?: string;
    subject?: string;
    onCancel: () => void;
    onSuccess: ({
        title,
        subject,
        html,
        id,
    }: {
        title: string;
        subject: string;
        html: string;
        id: number;
    }) => void;
    shop_uuid: string;
};

export default function SaveEmailTemplateModal({
    html,
    subject,
    onCancel,
    onSuccess,
    shop_uuid,
}: Props) {
    const [form] = Form.useForm();
    const [forceReRender, setForceReRender] = useState(false);
    const [loading, setLoading] = useState(false);

    const formatHtml = async (value: string) => {
        if (!value) return '';

        try {
            return format(value, {
                parser: 'html',
                plugins: [parserHtml],
                printWidth: 80,
                tabWidth: 4,
                htmlWhitespaceSensitivity: 'ignore',
            });
        } catch (error) {
            console.error('HTML formatting failed:', error);
            return value;
        }
    };

    useEffect(() => {
        const initializeForm = async () => {
            // Format HTML on load
            const formattedHtml = await formatHtml(html || '');

            form.setFieldsValue({
                html: formattedHtml,
                subject: subject,
            });
            setForceReRender(!forceReRender);
        };

        initializeForm();

        return () => {
            form.resetFields();
        };
    }, [html, subject]);

    return (
        <Modal
            width={800}
            open={true}
            title="Vista sniðmát"
            onCancel={() => {
                onCancel();
            }}
            footer={null}
        >
            <Form
                size="large"
                form={form}
                layout="vertical"
                onFinish={(values) => {
                    setLoading(true);
                    axios
                        .post('/tolvupostur/save_email_template/', {
                            ...values,
                            shop: shop_uuid,
                        })
                        .then((rsp) => {
                            setLoading(false);
                            onSuccess(rsp.data);
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.error('Villa kom upp:', err);
                        });
                }}
            >
                <Form.Item
                    label="Nafn á sniðmáti"
                    rules={[
                        {
                            required: true,
                            message: 'Nafn á sniðmáti má ekki vera tómt',
                        },
                    ]}
                    name="title"
                >
                    <Input></Input>
                </Form.Item>

                <Form.Item
                    name="subject"
                    label="Titill á tölvupósti (subject)"
                    rules={[
                        {
                            required: true,
                            message: 'Titill á tölvupósti má ekki vera tómur',
                        },
                    ]}
                >
                    <Input></Input>
                </Form.Item>

                <Form.Item
                    name="html"
                    label="HTML"
                    rules={[
                        {
                            required: true,
                            message: 'HTML má ekki vera tómt',
                        },
                    ]}
                    extra={
                        <Button
                            style={{
                                margin: 10,
                                marginLeft: 0,
                            }}
                            size="small"
                            type="text"
                            onClick={async () => {
                                const value = form.getFieldValue('html');
                                const formattedHtml = await formatHtml(value);
                                form.setFieldsValue({
                                    html: formattedHtml,
                                });
                                setForceReRender(!forceReRender);
                            }}
                        >
                            Fegra HTML (format)
                        </Button>
                    }
                >
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        showPrintMargin={false}
                        width="100%"
                        setOptions={{
                            useWorker: false,
                        }}
                    ></AceEditor>
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary">
                        Vista
                    </Button>
                </Form.Item>

                <Form.Item hidden>{forceReRender}</Form.Item>
            </Form>
        </Modal>
    );
}
