//SCSS
import { useState, useEffect, useContext } from 'react';
import Axios, { AxiosResponse } from 'axios';
import Shop from '../../interfaces/shop';
import './CreateProduct.less';

import {
    Form,
    Input,
    Card,
    Space,
    Button,
    Select,
    Checkbox,
    message,
    Popover,
    Switch,
    Tabs,
    UploadFile,
} from 'antd';
import {
    PlusOutlined,
    QuestionCircleTwoTone,
    CloseCircleTwoTone,
    DeleteOutlined,
    FlagOutlined,
    TagOutlined,
    RedoOutlined,
    SettingOutlined,
    AppstoreOutlined,
    CodeOutlined,
    MessageOutlined,
    CalendarOutlined,
} from '@ant-design/icons';

// Components
import TextArea from 'antd/lib/input/TextArea';
import UploadImage from '../../components/UploadImage/UploadImage';
import Inventory from './components/Inventory';
import TransactionCronSettings from './components/TransactionCronSettings';
import DeleteProductModal from './DeleteProductModal/DeleteProductModal';
import ProductShopifySettings from '../../components/ProductShopifySettings/ProductShopifySettings';
import ProductWoocommerceSettings from '../../components/ProductWoocommerceSettings/ProductWoocommerceSettings';
import Product, {
    Currency,
    ProductAttribute,
    Option,
} from '../../interfaces/product';
import { UploadChangeParam } from 'antd/lib/upload';

import ProductLanguages from './components/ProductLanguages';
import AdvancedSettings from './components/AdvancedSettings';
import ProductPriceSettings from './components/ProductPriceSettings';
import CustomProductDataSettings from './components/CustomProductDataSettings';
import ProductConfirmationSettings from './components/ProductConfirmationSettings';
import ProductFrequencySettings from './components/ProductFrequencySettings';
import AuthContext from '../../authContext';

const { TabPane } = Tabs;
/* const Option = Select; */

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function CreateProduct(props: { isEdit: boolean }) {
    const { selectedShop } = useContext(AuthContext);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState<Product>();
    const [personalInfoList, setPersonalInfoList] = useState<
        {
            created: string;
            id: number;
            name: string;
            slug: string;
            updated: string;
        }[]
    >([]);
    const [personalNameId, setPersonalNameId] = useState<number>();
    const [personalEmailId, setPersonalEmailId] = useState<number>();
    const [subscriptionProducts, setSubscriptionProducts] = useState<Product[]>(
        []
    );
    const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>([]);
    const [deleteProductModal, setDeleteProductModal] = useState(false);
    const [forceRender, setForceRender] = useState(true);

    const [productCategories, setProductCategories] = useState<
        { id: number; title: string }[]
    >([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const getEditProduct = () => {
        if (!selectedShop) return;

        Axios.get('/verslun/api/admin_product/' + w.uuid).then((rsp) => {
            const productRes = rsp.data;
            form.setFieldsValue({
                ...rsp.data,
                is_podcast: !!productRes.podcast_rss_feed,
                price: productRes.price.base_price,
                main_image: productRes.main_image
                    ? parseInt(productRes.main_image.id)
                    : null,
            });

            getShop();

            setLoading(false);
            setProduct(productRes);
            setImageFileList([
                {
                    uid: productRes.main_image.id,
                    url: productRes.main_image.image,
                    name: 'logo',
                    status: 'done',
                },
            ]);
        });
    };

    useEffect(() => {
        Axios.get('/verslun/api/personal_info_list/').then((rsp) => {
            setPersonalInfoList(rsp.data.results);
            setPersonalNameId(
                rsp.data.results.filter(
                    (info: { slug: string }) => info.slug === 'name'
                )[0].id
            );
            setPersonalEmailId(
                rsp.data.results.filter(
                    (info: { slug: string }) => info.slug === 'email'
                )[0].id
            );
        });

        Axios.get('/verslun/api/currency_list/').then((response) => {
            setCurrencies(response.data);
        });

        if (props.isEdit) {
            getEditProduct();
        } else {
            getShop();
            setTimeout(() => {
                form.setFieldsValue({
                    attributes: [],
                });
            }, 500);
        }
    }, [selectedShop]);

    /*  create_options(attributes) { */
    /* 1 and 2 because non existing options can also be added to already existing attributes */
    /*         const new_options_1 = existing_attributes.map(attr => attr.options).filter(option => !option.shop);
        const new_options_2 = new_attributes.map(attr => attr.options);
        
        const new_options = [...new_options_1, ...new_options_2];
        const existing_options = existing_attributes.map(attr => attr.options).filter(option => option.shop)
    } */

    const getShop = () => {
        if (!selectedShop) return;

        setLoading(false);

        Axios.get(
            `/verslun/api/product/?shop__uuid=${selectedShop.uuid}&type=SUBSCRIPTION&page_size=9999`
        ).then((rsp) => {
            setSubscriptionProducts(rsp.data.results);
        });

        // Mix því ég vil halda product categories inni en vil ekki flækja platformið með categories (allavega ekki strax)
        // Ég ákvað því að búa til "aðal vörur" sjálfkrafa

        const shopId = selectedShop.id;
        Axios.get('/verslun/api/product_category/?shop=' + shopId).then(
            (res) => {
                const categories = res.data.results;

                if (categories.length < 2) {
                    Axios.post('/verslun/api/create_product_category/', {
                        shop: shopId,
                        title: 'Aðal vörur',
                        label: 'Aðal vörur',
                    })
                        .then(
                            (
                                res: AxiosResponse<{
                                    id: number;
                                    title: string;
                                }>
                            ) => {
                                setProductCategories([res.data]);
                            }
                        )
                        .catch((err) => {
                            message.error('Eitthvað fór úrskeiðis :/');
                        });
                } else {
                    setProductCategories(categories);
                }
            }
        );
    };

    const createOptions = (attributes: ProductAttribute[]) => {
        if (selectedShop) {
            const create_option = (option: Option) => {
                option.shop = selectedShop.id;
                return Axios.post('/verslun/api/option_create/', { ...option });
            };

            const getOption = (option: Option) => {
                return new Promise((resolve) => {
                    resolve({ data: option });
                });
            };

            return attributes.map((attribute) => {
                // @ts-ignore
                attribute.promise = new Promise((resolve) => {
                    const option_promises = attribute.options?.map((option) => {
                        if (!option.shop) {
                            // @ts-ignore
                            option.id = undefined;
                            return create_option(option);
                        } else {
                            return getOption(option);
                        }
                    });

                    Promise.all(
                        option_promises || [
                            new Promise((resolve) => {
                                resolve({ data: attribute });
                            }),
                        ]
                    ).then(() => {
                        resolve(attribute);
                    });
                });

                return attribute;

                /* if(attribute.shop){
                        const p = Axios.patch(`/verslun/api/patch_attribute/${attribute.id}/`, attribute);
                        all_promises.push(p);
                    }else{
                        const p = Axios.patch(`/verslun/api/create_attribute/`, attribute);
                        all_promises.push(p);
                    } */
            });
        }
    };

    const cleanAttributes = (attrs: ProductAttribute[]) => {
        return new Promise((resolve, reject) => {
            const create_attribute = (attribute: ProductAttribute) => {
                if (selectedShop) {
                    attribute.shop = selectedShop.id;
                    return Axios.post(
                        '/verslun/api/attribute_create/',
                        attribute
                    );
                }
            };

            const get_attribute = (attribute: ProductAttribute) => {
                return new Promise((resolve) => {
                    resolve({ data: attribute });
                });
            };

            // @ts-ignore
            const attributePromises = [];
            const attributes = createOptions(attrs) || [];

            if (!(attributes.length > 0)) {
            } else {
                attributes.forEach((attribute) => {
                    // @ts-ignore
                    attribute.promise.then((attribute) => {
                        if (!attribute.shop) {
                            attributePromises.push(create_attribute(attribute));
                        } else {
                            attributePromises.push(get_attribute(attribute));
                        }

                        if (attributePromises.length === attributes.length) {
                            // @ts-ignore
                            Promise.all(attributePromises).then((rsp) => {
                                const _attributes = rsp.map((res) => res.data);
                                resolve(_attributes);
                            });
                        }
                    });
                });
            }
        });
    };

    const productType = form?.getFieldValue('type');

    return (
        <>
            <Card
                className="basePageCard"
                title={
                    <>
                        <TagOutlined />{' '}
                        <>
                            {props.isEdit ? 'Breyta vöru' : 'Stofna nýja vöru'}{' '}
                        </>
                    </>
                }
                extra={
                    <>
                        {w.me === 1 && product && (
                            <Button
                                href={`/bakendi/shop/product/${product.id}/change/`}
                            >
                                Breyta
                            </Button>
                        )}
                    </>
                }
            >
                <Card loading={loading} style={{ maxWidth: 1100 }}>
                    <Tabs defaultActiveKey="1" tabPosition="right">
                        <TabPane
                            tab={
                                <>
                                    <TagOutlined style={{ marginRight: 0 }} />{' '}
                                    Grunnupplýsingar
                                </>
                            }
                            key="info"
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                name="basic"
                                onFinish={(values) => {
                                    const keys = Object.keys(values);

                                    // if key starts with amount_ then it's a price
                                    const priceKeys = keys.filter((key) =>
                                        key.startsWith('amount_')
                                    );

                                    const prices = priceKeys.map((key) => {
                                        const currencyCode = key.split('_')[1];
                                        const currencyId = currencies.find(
                                            (currency) =>
                                                currency.currency_code ===
                                                currencyCode
                                        )?.id;

                                        const obj: {
                                            amount: number;
                                            currency?: number;
                                            id?: number;
                                        } = {
                                            amount: values[key],
                                            currency: currencyId,
                                        };

                                        const existingProduct =
                                            product?.prices.find(
                                                (p) =>
                                                    p.currency.id ===
                                                    obj.currency
                                            );

                                        if (existingProduct) {
                                            obj['id'] = existingProduct.id;
                                        }

                                        return obj;
                                    });

                                    if (values.type !== 'GIFT_CERTIFICATE') {
                                        if (!prices.length) {
                                            message.error(
                                                'Þú verður að gefa vörunni verð.'
                                            );
                                            return;
                                        }

                                        if (
                                            prices.some(
                                                (price) => !price.amount
                                            )
                                        ) {
                                            message.error(
                                                'Þú verður að setja verð fyrir allar valda gjaldmiðla.'
                                            );
                                            return;
                                        }

                                        values.category =
                                            productCategories.find(
                                                (category) =>
                                                    category.title ===
                                                    'Aðal vörur'
                                            )?.id;

                                        values.prices = prices;
                                    } else {
                                        values.prices = [];
                                    }

                                    if (selectedShop) {
                                        setLoading(true);

                                        const name_and_email = [
                                            personalNameId,
                                            personalEmailId,
                                        ];
                                        values.info_to_collect =
                                            values.info_to_collect
                                                ? [
                                                      ...name_and_email,
                                                      ...values.info_to_collect,
                                                  ]
                                                : name_and_email;
                                        values.shop = selectedShop.id;

                                        if (values.price_options) {
                                            values.price_options =
                                                values.custom_price_options.map(
                                                    (obj: {
                                                        price: string;
                                                    }) => {
                                                        return {
                                                            price: parseInt(
                                                                obj.price
                                                            ),
                                                        };
                                                    }
                                                );
                                        }

                                        const p = new Promise(
                                            (resolve, reject) => {
                                                if (
                                                    values.type ===
                                                    'GIFT_CERTIFICATE'
                                                ) {
                                                    if (!props.isEdit) {
                                                        values.attributes.push({
                                                            label: 'Skilaboð á gjafabréf',
                                                            type: 'TEXT',
                                                            slug: 'gift_certificate_message',
                                                        });
                                                        values.buy_button_text =
                                                            'Kaupa';
                                                    }

                                                    cleanAttributes(
                                                        values.attributes
                                                    ).then((attributes) => {
                                                        let formVals = values;
                                                        formVals.attributes =
                                                            attributes;

                                                        if (
                                                            props.isEdit &&
                                                            product
                                                        ) {
                                                            Axios.patch(
                                                                '/verslun/api/patch_product_category/' +
                                                                    product
                                                                        .category
                                                                        .id +
                                                                    '/',
                                                                {
                                                                    shop: selectedShop.id,
                                                                    title: 'Gjafabréf',
                                                                    label: 'Gjafabréf',
                                                                    attributes:
                                                                        attributes,
                                                                }
                                                            )
                                                                .then((rsp) => {
                                                                    formVals.category =
                                                                        rsp.data.id;
                                                                    formVals.price = 0;

                                                                    resolve(
                                                                        formVals
                                                                    );
                                                                })
                                                                .catch(
                                                                    (err) => {
                                                                        message.error(
                                                                            'Eitthvað fór úrskeiðis :/'
                                                                        );
                                                                    }
                                                                );
                                                        } else {
                                                            Axios.post(
                                                                '/verslun/api/create_product_category/',
                                                                {
                                                                    shop: selectedShop.id,
                                                                    title: 'Gjafabréf',
                                                                    label: 'Gjafabréf',
                                                                    attributes:
                                                                        attributes,
                                                                }
                                                            )
                                                                .then((rsp) => {
                                                                    formVals.category =
                                                                        rsp.data.id;
                                                                    formVals.price = 0;
                                                                    resolve(
                                                                        formVals
                                                                    );
                                                                })
                                                                .catch(
                                                                    (err) => {
                                                                        message.error(
                                                                            'Eitthvað fór úrskeiðis :/'
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                    });
                                                } else {
                                                    resolve(values);
                                                }
                                            }
                                        );

                                        p.then((vals: any) => {
                                            const attrs =
                                                vals.attributes ||
                                                product?.attributes;

                                            if (attrs) {
                                                vals.attributes = (
                                                    vals.attributes ||
                                                    product?.attributes
                                                ).map(
                                                    (
                                                        attr: ProductAttribute
                                                    ) => {
                                                        return attr.id;
                                                    }
                                                );
                                            }

                                            if (props.isEdit && product) {
                                                Axios.patch(
                                                    '/verslun/api/basic_product/' +
                                                        product.uuid +
                                                        '/',
                                                    vals
                                                ).then((rsp) => {
                                                    message.success(
                                                        'Tókst! Varan hefur verið uppfærð.'
                                                    );
                                                    setTimeout(() => {
                                                        w.location.reload();
                                                    }, 500);
                                                });
                                            } else {
                                                Axios.post(
                                                    '/verslun/api/create_product/',
                                                    vals
                                                ).then((rsp) => {
                                                    message.success(
                                                        'Tókst, varan hefur verið stofnuð!'
                                                    );
                                                    setTimeout(() => {
                                                        w.location.href =
                                                            '/breyta_voru/' +
                                                            rsp.data.uuid;
                                                    }, 500);
                                                });
                                            }
                                        });
                                    }
                                }}
                                onValuesChange={() => {
                                    setForceRender(!forceRender);
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Form.Item
                                        style={{
                                            display: 'block',
                                            width: 75,
                                            textAlign: 'right',
                                            marginBottom: 0,
                                        }}
                                        name="is_available"
                                        label={
                                            <div>
                                                <Popover
                                                    content={
                                                        <>
                                                            <div>
                                                                Ef þú velur NEI
                                                                þá birtist varan
                                                                ekki á
                                                                vöruyfirlitum.
                                                                T.d undir "Mínar
                                                                síður".
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <QuestionCircleTwoTone
                                                        twoToneColor="#afafaf"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            fontSize: 16,
                                                        }}
                                                    />
                                                </Popover>
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        left: 20,
                                                        top: -5,
                                                    }}
                                                >
                                                    Sýnileg
                                                </div>
                                            </div>
                                        }
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checkedChildren="JÁ "
                                            unCheckedChildren="NEI"
                                            onChange={(checked) => {
                                                form.setFieldsValue({
                                                    is_available: checked,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    label="Titill"
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Þú verður að gefa vörunni titil.',
                                        },
                                    ]}
                                >
                                    <Input size="large" autoComplete="off" />
                                </Form.Item>

                                <Form.Item
                                    name="description"
                                    label="Vörulýsing"
                                >
                                    <TextArea rows={8}></TextArea>
                                </Form.Item>

                                <Form.Item
                                    label="Vörumynd"
                                    name="main_image"
                                    valuePropName="fileList"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Allar vörur þurfa að hafa mynd',
                                        },
                                    ]}
                                >
                                    <UploadImage
                                        defaultFileList={imageFileList}
                                        onSuccess={(
                                            imageId: string,
                                            resObj: UploadChangeParam<
                                                UploadFile<any>
                                            >
                                        ) => {
                                            form.setFieldsValue({
                                                main_image: parseInt(imageId),
                                            });
                                        }}
                                        helpText="Hlaða inn mynd"
                                    ></UploadImage>
                                </Form.Item>

                                <Form.Item
                                    label="Tegund"
                                    initialValue={'SUBSCRIPTION'}
                                    name="type"
                                >
                                    <Select
                                        onChange={(change) => {
                                            form.setFieldsValue({
                                                attributes:
                                                    change ===
                                                    'GIFT_CERTIFICATE'
                                                        ? [
                                                              {
                                                                  label: 'Stærð gjafabréfs',
                                                                  slug: 'gift_certificate_credits',
                                                                  options: [
                                                                      {
                                                                          title: '3 mánuðir',
                                                                          value: 3,
                                                                          extra_price: 5990,
                                                                      },
                                                                  ],
                                                              },
                                                          ]
                                                        : [],
                                            });
                                        }}
                                        size="large"
                                        disabled={props.isEdit}
                                        style={{ width: '100%' }}
                                    >
                                        <Select.Option value={'SUBSCRIPTION'}>
                                            Áskrift (regluleg greiðsla)
                                        </Select.Option>
                                        <Select.Option
                                            value={'GIFT_CERTIFICATE'}
                                        >
                                            Gjafabréf fyrir áskriftir (dæmi: 3
                                            mán gjafabréf)
                                        </Select.Option>
                                        <Select.Option value={'ONE_TIME'}>
                                            Hefðbundin vara (ekki áskrift)
                                        </Select.Option>
                                        <Select.Option value={'ADD_ON'}>
                                            Viðbótarvara ("Add-on")
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                {productType === 'ADD_ON' && (
                                    <>
                                        <Form.Item
                                            label="Við hvaða áskriftir má bætta þessari aukavöru?"
                                            name="add_on_available_for"
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                optionFilterProp="children"
                                                showSearch
                                                size="large"
                                                notFoundContent={
                                                    'Engin áskrift fannst. '
                                                }
                                            >
                                                {subscriptionProducts.map(
                                                    (subscription, key) => {
                                                        return (
                                                            <Select.Option
                                                                key={key}
                                                                value={
                                                                    subscription.id
                                                                }
                                                            >
                                                                {
                                                                    subscription.title
                                                                }
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </>
                                )}

                                {productType === 'SUBSCRIPTION' && (
                                    <>
                                        <Form.Item
                                            name="bypass_checkout"
                                            label={
                                                <>
                                                    <Popover
                                                        content={
                                                            <>
                                                                Ef þú velur já
                                                                þá stofnast
                                                                áskrifandi og
                                                                kortanúmerið er
                                                                vistað, en engin
                                                                rukkun á sér
                                                                stað.
                                                            </>
                                                        }
                                                    >
                                                        <QuestionCircleTwoTone
                                                            twoToneColor="#afafaf"
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    </Popover>
                                                    <span
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: 20,
                                                        }}
                                                    >
                                                        Sleppa rukkun við
                                                        skráningu (og ekki búa
                                                        til sendingu)
                                                    </span>
                                                </>
                                            }
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checkedChildren="JÁ "
                                                unCheckedChildren="NEI"
                                                onChange={(checked) => {
                                                    form.setFieldsValue({
                                                        bypass_checkout:
                                                            checked,
                                                    });
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="auto_mark_as_fullfilled"
                                            label={
                                                <>
                                                    <Popover
                                                        content={
                                                            <>
                                                                <p>
                                                                    Ef þú velur
                                                                    já þá
                                                                    merkist
                                                                    sendingin
                                                                    alltaf
                                                                    sjálfkrafa
                                                                    sem
                                                                    "afgreidd".
                                                                </p>

                                                                <p>
                                                                    Þessi
                                                                    möguleiki
                                                                    hentar vel
                                                                    fyrir þá sem
                                                                    þurfa ekki
                                                                    sérstaklega
                                                                    að halda
                                                                    utan um
                                                                    afgreiddar
                                                                    vörur.
                                                                </p>

                                                                <p>
                                                                    Styrkir (t.d
                                                                    góðgerðasamtök)
                                                                    og hlaðvörp
                                                                    eru gott
                                                                    dæmi.
                                                                </p>
                                                            </>
                                                        }
                                                    >
                                                        <QuestionCircleTwoTone
                                                            twoToneColor="#afafaf"
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    </Popover>
                                                    <span
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: 20,
                                                        }}
                                                    >
                                                        Merkja sendingar sem
                                                        afgreiddar sjálfkrafa.
                                                    </span>
                                                </>
                                            }
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checkedChildren="JÁ "
                                                unCheckedChildren="NEI"
                                                onChange={(checked) => {
                                                    form.setFieldsValue({
                                                        auto_mark_as_fullfilled:
                                                            checked,
                                                    });
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            name="interval_editable"
                                            label={
                                                <>
                                                    <Popover
                                                        content={
                                                            <div>
                                                                Þá er t.d átt
                                                                við að velja á
                                                                milli 1 og 2
                                                                mánaða fresti.
                                                                Eða breyta frá 1
                                                                mánaða yfir í 3
                                                                mánaða fresti á
                                                                "Mínum síðum"
                                                            </div>
                                                        }
                                                    >
                                                        <QuestionCircleTwoTone
                                                            twoToneColor="#afafaf"
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    </Popover>
                                                    <span
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: 20,
                                                        }}
                                                    >
                                                        Geta viðskiptavinir
                                                        valið tíðni sendinga
                                                        sjálfir?
                                                    </span>
                                                </>
                                            }
                                        >
                                            <Switch
                                                checkedChildren="JÁ "
                                                unCheckedChildren="NEI"
                                                onChange={(checked) => {
                                                    form.setFieldsValue({
                                                        interval_editable:
                                                            checked,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                )}

                                {productType === 'GIFT_CERTIFICATE' && (
                                    <>
                                        <Form.Item
                                            label={
                                                <>
                                                    <Popover
                                                        content={
                                                            <>
                                                                Ef þú velur
                                                                fleiri en eina
                                                                vöru þá fær
                                                                gjafaþegi að
                                                                velja þegar
                                                                hann/hún virkjar
                                                                gjafabréfið
                                                                sitt.
                                                            </>
                                                        }
                                                        title="Vörur sem gjafabréf gildir fyrir"
                                                    >
                                                        <QuestionCircleTwoTone
                                                            twoToneColor="#afafaf"
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    </Popover>
                                                    <span
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: 20,
                                                        }}
                                                    >
                                                        Vörur sem gjafabréf
                                                        gildir fyrir
                                                    </span>
                                                </>
                                            }
                                            name="gift_certificate_valid_products"
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                optionFilterProp="children"
                                                showSearch
                                                size="large"
                                                notFoundContent={
                                                    'Engin áskrift fannst. Stofnaðu fyrst áskrift svo þú getir valið fyrir hvaða áskriftir þetta gjafabréf gildir.'
                                                }
                                            >
                                                {subscriptionProducts.map(
                                                    (subscription, key) => {
                                                        return (
                                                            <Select.Option
                                                                key={key}
                                                                value={
                                                                    subscription.id
                                                                }
                                                            >
                                                                {
                                                                    subscription.title
                                                                }
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>

                                        <Form.List name="attributes">
                                            {(fields, { add, remove }) => {
                                                const form_attributes =
                                                    form?.getFieldValue(
                                                        'attributes'
                                                    );
                                                return (
                                                    <Space direction="vertical">
                                                        {fields.map(
                                                            (
                                                                field,
                                                                fieldKey
                                                            ) => {
                                                                return form_attributes[
                                                                    fieldKey
                                                                ].slug !==
                                                                    'gift_certificate_message' ? (
                                                                    <div
                                                                        key={
                                                                            fieldKey
                                                                        }
                                                                        className="attribute_block"
                                                                    >
                                                                        <h5>
                                                                            Eigindi
                                                                        </h5>
                                                                        <>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[
                                                                                    field.name,
                                                                                    'label',
                                                                                ]}
                                                                                fieldKey={[
                                                                                    fieldKey,
                                                                                    'label',
                                                                                ]}
                                                                                label="Titill á eigindi"
                                                                            >
                                                                                <Input
                                                                                    size="large"
                                                                                    placeholder="Titill"
                                                                                />
                                                                            </Form.Item>

                                                                            {/* <MinusCircleOutlined
                                                                                onClick={() => {
                                                                                    remove(field.name);
                                                                                }}
                                                                            /> */}
                                                                        </>

                                                                        {
                                                                            <Form.List
                                                                                name={[
                                                                                    field.name,
                                                                                    'options',
                                                                                ]}
                                                                            >
                                                                                {(
                                                                                    options,
                                                                                    {
                                                                                        add,
                                                                                        remove,
                                                                                    }
                                                                                ) => {
                                                                                    return (
                                                                                        <div>
                                                                                            {options.map(
                                                                                                (
                                                                                                    option,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <Space
                                                                                                        className="create_nested_option"
                                                                                                        key={
                                                                                                            option.key
                                                                                                        }
                                                                                                        align="start"
                                                                                                    >
                                                                                                        <Form.Item
                                                                                                            name={[
                                                                                                                index,
                                                                                                                'title',
                                                                                                            ]}
                                                                                                            label="Titill"
                                                                                                        >
                                                                                                            <Input
                                                                                                                size="large"
                                                                                                                placeholder="Titill"
                                                                                                            />
                                                                                                        </Form.Item>

                                                                                                        <Form.Item
                                                                                                            name={[
                                                                                                                index,
                                                                                                                'value',
                                                                                                            ]}
                                                                                                            label={
                                                                                                                <>
                                                                                                                    <Popover
                                                                                                                        content={
                                                                                                                            <>
                                                                                                                                <p>
                                                                                                                                    Inneignin
                                                                                                                                    lækkar
                                                                                                                                    um
                                                                                                                                    1
                                                                                                                                </p>{' '}
                                                                                                                                <p>
                                                                                                                                    í
                                                                                                                                    hvert
                                                                                                                                    skipti
                                                                                                                                    sem
                                                                                                                                    gjafaþegi
                                                                                                                                    fær
                                                                                                                                    sendingu
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                        title="Inneign"
                                                                                                                    >
                                                                                                                        <QuestionCircleTwoTone
                                                                                                                            twoToneColor="#afafaf"
                                                                                                                            style={{
                                                                                                                                position:
                                                                                                                                    'absolute',
                                                                                                                                fontSize: 16,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </Popover>{' '}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            position:
                                                                                                                                'relative',
                                                                                                                            left: 20,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {' '}
                                                                                                                        Inneign
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            }
                                                                                                        >
                                                                                                            <Input
                                                                                                                size="large"
                                                                                                                placeholder="Inneign"
                                                                                                            />
                                                                                                        </Form.Item>

                                                                                                        <Form.Item
                                                                                                            name={[
                                                                                                                option.name,
                                                                                                                'extra_price',
                                                                                                            ]}
                                                                                                            fieldKey={[
                                                                                                                fieldKey,
                                                                                                                'extra_price',
                                                                                                            ]}
                                                                                                            label="Verð"
                                                                                                        >
                                                                                                            <Input
                                                                                                                size="large"
                                                                                                                placeholder="Verð"
                                                                                                            />
                                                                                                        </Form.Item>

                                                                                                        {options.length >
                                                                                                            1 && (
                                                                                                            <CloseCircleTwoTone
                                                                                                                twoToneColor="red"
                                                                                                                style={{
                                                                                                                    fontSize: 18,
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    remove(
                                                                                                                        option.name
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Space>
                                                                                                )
                                                                                            )}

                                                                                            <Form.Item>
                                                                                                <Button
                                                                                                    type="dashed"
                                                                                                    onClick={() => {
                                                                                                        add();
                                                                                                    }}
                                                                                                >
                                                                                                    <PlusOutlined />{' '}
                                                                                                    Bæta
                                                                                                    við
                                                                                                    valmöguleika
                                                                                                </Button>
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                            </Form.List>
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        key={
                                                                            fieldKey
                                                                        }
                                                                    ></div>
                                                                );
                                                            }
                                                        )}

                                                        {/* <hr></hr>

                                                                <Form.Item>
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() => {
                                                                            add();
                                                                        }}
                                                                    >
                                                                        <PlusOutlined /> Bæta við eigindi
                                                                    </Button>
                                                                </Form.Item> */}
                                                    </Space>
                                                );
                                            }}
                                        </Form.List>

                                        <Form.Item
                                            label={
                                                <>
                                                    <Popover
                                                        content={
                                                            <>
                                                                <p>
                                                                    Skilaboðin
                                                                    sem
                                                                    gjafaþegi
                                                                    sér eftir að
                                                                    hafa virkjað
                                                                    gjafabréfið
                                                                    sitt.
                                                                </p>
                                                                <br></br>
                                                                <p>
                                                                    <strong>
                                                                        Dæmi:
                                                                    </strong>{' '}
                                                                    Til
                                                                    hamingju,
                                                                    gjafabréfið
                                                                    þitt hefur
                                                                    verið
                                                                    virkjað! Þú
                                                                    mátt búast
                                                                    við fyrstu
                                                                    sendingunni
                                                                    þinni í
                                                                    næsta
                                                                    mánuði.
                                                                </p>
                                                            </>
                                                        }
                                                        title="Skilaboð til gjafaþega"
                                                    >
                                                        <QuestionCircleTwoTone
                                                            twoToneColor="#afafaf"
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    </Popover>
                                                    <span
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: 10,
                                                        }}
                                                    >
                                                        Skilaboð til gjafaþega
                                                    </span>
                                                </>
                                            }
                                            name="gift_certificate_activated_message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Þú verður að útvega skilaboð til gjafaþega.',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={5}></TextArea>
                                        </Form.Item>
                                    </>
                                )}

                                {currencies &&
                                    product?.type !== 'GIFT_CERTIFICATE' && (
                                        <ProductPriceSettings
                                            currencies={currencies}
                                            form={form}
                                            product={product}
                                            productType={productType}
                                        ></ProductPriceSettings>
                                    )}

                                {productType !== 'ADD_ON' &&
                                    personalInfoList &&
                                    personalEmailId &&
                                    personalNameId && (
                                        <>
                                            <Form.Item
                                                label="Hvaða upplýsingum á að safna?"
                                                name="basic_info_to_collect"
                                                initialValue={[
                                                    personalNameId,
                                                    personalEmailId,
                                                ]}
                                                style={{
                                                    marginBottom: 10,
                                                }}
                                            >
                                                <Checkbox.Group
                                                    disabled={true}
                                                    options={[
                                                        {
                                                            label: 'Nafn',
                                                            value: personalNameId,
                                                        },
                                                        {
                                                            label: 'Netfang',
                                                            value: personalEmailId,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="info_to_collect"
                                                initialValue={
                                                    product?.info_to_collect
                                                }
                                            >
                                                <Checkbox.Group
                                                    options={personalInfoList
                                                        .filter((option) => {
                                                            const is_name =
                                                                option.slug ===
                                                                'name';
                                                            const is_email =
                                                                option.slug ===
                                                                'email';

                                                            return is_name ||
                                                                is_email
                                                                ? 0
                                                                : -1;
                                                        })
                                                        .map((option) => {
                                                            const required =
                                                                option.name ===
                                                                    'name' ||
                                                                option.name ===
                                                                    'email';

                                                            let obj: {
                                                                label: string;
                                                                value: number;
                                                                checked?: boolean;
                                                                disabled?: boolean;
                                                            } = {
                                                                label: option.name,
                                                                value: option.id,
                                                            };

                                                            if (required)
                                                                obj = {
                                                                    checked:
                                                                        true,
                                                                    disabled:
                                                                        true,
                                                                    ...obj,
                                                                };

                                                            return obj;
                                                        })}
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                <Form.Item>
                                    <Button
                                        className="mt20"
                                        size="large"
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Vista
                                    </Button>
                                </Form.Item>
                            </Form>
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <RedoOutlined style={{ marginRight: 0 }} />{' '}
                                    Sjálfvirkni
                                </>
                            }
                            key="paymentSubscriptions"
                        >
                            {product && (
                                <TransactionCronSettings
                                    product={product}
                                ></TransactionCronSettings>
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <FlagOutlined style={{ marginRight: 0 }} />{' '}
                                    Tungumál / þýðingar
                                </>
                            }
                            key="languages"
                        >
                            {product && (
                                <ProductLanguages
                                    product={product}
                                ></ProductLanguages>
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <AppstoreOutlined
                                        style={{ marginRight: 0 }}
                                    />{' '}
                                    Birgðastaða
                                </>
                            }
                            key="inventory"
                        >
                            {product && (
                                <Inventory product={product}></Inventory>
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <SettingOutlined
                                        style={{ marginRight: 0 }}
                                    />{' '}
                                    Ítarlegar stillingar
                                </>
                            }
                            key="delivery"
                        >
                            {product && selectedShop && (
                                <AdvancedSettings
                                    product={product}
                                    shop={selectedShop}
                                ></AdvancedSettings>
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <CodeOutlined />
                                    Sérsniðin gögn (JSON)
                                </>
                            }
                            key="custom_json"
                        >
                            {product && (
                                <CustomProductDataSettings product={product} />
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <MessageOutlined />
                                    Staðfestingarskilaboð
                                </>
                            }
                            key="confirmation_message"
                        >
                            {product && (
                                <ProductConfirmationSettings
                                    product={product}
                                />
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <CalendarOutlined /> Leyfileg tíðni sendinga
                                </>
                            }
                        >
                            {product && (
                                <ProductFrequencySettings
                                    product={product}
                                ></ProductFrequencySettings>
                            )}
                        </TabPane>

                        <TabPane
                            disabled={!props.isEdit}
                            tab={
                                <>
                                    <div>
                                        <PlusOutlined /> Samþættingar
                                    </div>

                                    <div>
                                        <img
                                            style={{
                                                width: 18,
                                                marginRight: 5,
                                            }}
                                            src="/static/images/svg/shopify.svg"
                                            alt="Shopify logo"
                                        ></img>{' '}
                                        <img
                                            alt="Woocommerce logo"
                                            style={{
                                                width: 24,
                                                marginRight: 7,
                                            }}
                                            src="/static/images/svg/woocommerce.svg"
                                        ></img>{' '}
                                    </div>
                                </>
                            }
                            key="shopify"
                        >
                            <Tabs size="small" defaultActiveKey="1">
                                <TabPane
                                    tab={
                                        <>
                                            <img
                                                style={{
                                                    width: 18,
                                                    marginRight: 5,
                                                }}
                                                src="/static/images/svg/shopify.svg"
                                                alt="Shopify logo"
                                            ></img>{' '}
                                            Shopify
                                        </>
                                    }
                                    key="1"
                                >
                                    {product && (
                                        <ProductShopifySettings
                                            product={product}
                                        ></ProductShopifySettings>
                                    )}
                                </TabPane>

                                <TabPane
                                    tab={
                                        <>
                                            <img
                                                alt="Woocommerce logo"
                                                style={{
                                                    width: 24,
                                                    marginRight: 7,
                                                }}
                                                src="/static/images/svg/woocommerce.svg"
                                            ></img>{' '}
                                            Woocommerce
                                        </>
                                    }
                                    key="2"
                                >
                                    {product && (
                                        <ProductWoocommerceSettings
                                            product={product}
                                        ></ProductWoocommerceSettings>
                                    )}
                                </TabPane>
                            </Tabs>
                        </TabPane>

                        {/* //Adding tabpane button */}
                        {props.isEdit && (
                            <TabPane
                                tab={
                                    <div style={{ color: 'gray' }}>
                                        <DeleteOutlined
                                            style={{ marginRight: 0 }}
                                        />{' '}
                                        Eyða vöru
                                    </div>
                                }
                                key="delete"
                            >
                                <Button
                                    block
                                    size="large"
                                    onClick={() => {
                                        setDeleteProductModal(true);
                                    }}
                                    danger
                                >
                                    <DeleteOutlined /> Eyða vöru
                                </Button>
                            </TabPane>
                        )}
                    </Tabs>
                </Card>

                {deleteProductModal && product && (
                    <DeleteProductModal
                        onCancel={() => {
                            setDeleteProductModal(false);
                        }}
                        open={deleteProductModal}
                        product={product}
                    ></DeleteProductModal>
                )}
            </Card>
        </>
    );
}
