import Axios from 'axios';
import { useState, useEffect } from 'react';
import { Form, Select, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { DeliveryOption } from '../../interfaces/delivery.js';
import { kr } from '../../utils';
import useDropp from '../../customHooks/Dropp/Dropp.js';
import translate from '../../translate/translate.js';

import Product from '../../interfaces/product.js';
import Subscription from '../../interfaces/subscription.js';

interface PostboxOption {
    postboxId: string;
    name: string;
    address: string;
    postcode: string;
    town: string;
    latitude: string;
    longitude: string;
}

export default function DeliveryChoices(props: {
    subscription: Subscription;
    onSuccess?: (res: any) => void;
    size?: 'small' | 'large';
    returnType?: 'response' | 'payload';
}) {
    const [form] = Form.useForm();
    const [product, setProduct] = useState<Product>();
    const [loading, setLoading] = useState(false);
    const deliveryConfig = product?.delivery_config;
    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption | null>();
    const [postboxOptions, setPostboxOptions] = useState<PostboxOption[]>([]);
    const [parcelpointOptions, setParcelpointOptions] = useState<
        PostboxOption[]
    >([]);
    const [selectedDPOPostalCode, setSelectedDPOPostalCode] = useState<
        string | null
    >();

    const [selectedDPTPostalCode, setSelectedDPTPostalCode] = useState<
        string | null
    >();

    const [isChanged, setIsChanged] = useState(false);

    const {
        droppSelection,
        clearDroppSelection,
        openDroppModal,
        setDroppLocationId,
    } = useDropp(deliveryConfig?.dropp?.store_id);

    const lang = 'is';

    useEffect(() => {
        if (!product) {
            Axios.get(
                `/verslun/api/product/${props.subscription.product.uuid}/`
            ).then((rsp) => {
                setProduct(rsp.data);
            });
        }
    }, [props.subscription]);

    useEffect(() => {
        if (
            props.subscription.subscription_product
                ?.external_delivery_location_id &&
            deliveryConfig?.dropp?.store_id
        ) {
            setDroppLocationId(
                props.subscription.subscription_product
                    .external_delivery_location_id,
                deliveryConfig?.dropp?.store_id
            );
        }
    }, [deliveryConfig]);

    useEffect(() => {
        if (
            !selectedDPOPostalCode ||
            selectedDPOPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpo_name: undefined,
            });
        } else if (selectedDPOPostalCode.toString().length === 3) {
            Axios.post('/shipping/api/posturinn_postboxes', {
                shop_uuid: props.subscription.customer.shop_uuid,
                postal_code: selectedDPOPostalCode,
            }).then((rsp) => {
                setPostboxOptions(rsp.data || []);
            });
        }
    }, [selectedDPOPostalCode]);

    useEffect(() => {
        if (
            !selectedDPTPostalCode ||
            selectedDPTPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpt_name: undefined,
            });
        } else if (selectedDPTPostalCode?.toString().length === 3) {
            Axios.post('/shipping/api/posturinn_parcelpoints', {
                shop_uuid: props.subscription.customer.shop_uuid,
                postal_code: selectedDPTPostalCode,
            }).then((rsp) => {
                setParcelpointOptions(rsp.data || []);
            });
        }
    }, [selectedDPTPostalCode]);

    return (
        <div>
            <Form
                size={props.size || 'large'}
                layout="vertical"
                initialValues={{
                    delivery_option:
                        props.subscription.subscription_product
                            ?.delivery_option,
                }}
                onFinish={(values) => {
                    const payload = {
                        // Delivery
                        external_delivery_location_id: droppSelection?.id,
                        delivery_option: values.delivery_option,
                        delivery_postal_code:
                            values.posturinn_dpo_postal_code ||
                            values.posturinn_dpo_postal_code,
                        posturinn_location_name:
                            values.posturinn_dpt_name ||
                            values.posturinn_dpo_name,
                        shipping_provider:
                            selectedDeliveryOption?.shipping_provider,
                        title: selectedDeliveryOption?.title,

                        // End of delivery
                    };

                    if (props.returnType === 'payload' && props.onSuccess) {
                        props.onSuccess(payload);
                        return;
                    } else {
                        Axios.patch(
                            `/askrift/api/subscription_product/${props.subscription.subscription_product.id}/`,
                            payload
                        )
                            .then((rsp) => {
                                setLoading(false);

                                message.success(
                                    'Tókst, afhendingarmáta hefur verið breytt'
                                );

                                if (props.onSuccess) props.onSuccess(rsp.data);
                            })
                            .catch((err) => {
                                setLoading(false);
                                message.error('Eitthvað fór úrskeiðis :/');
                            });
                    }
                }}
            >
                {product?.delivery_config && (
                    <Form.Item
                        name="delivery_option"
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'delivery_method_required',
                                    lang
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={translate(
                                'select_delivery_option',
                                lang
                            )}
                            size={props.size || 'large'}
                            onSelect={(id: number) => {
                                setIsChanged(true);
                                const option =
                                    product.delivery_config?.options.find(
                                        (o) => o.id === id
                                    );

                                if (option) {
                                    if (option.shipping_provider) {
                                        setSelectedDeliveryOption(option);

                                        if (
                                            option.shipping_provider ===
                                            'DROPP_PICKUP'
                                        ) {
                                            openDroppModal();
                                        } else {
                                            clearDroppSelection();
                                        }
                                    }
                                }
                            }}
                            children={product.delivery_config.options.map(
                                (option, i) => {
                                    return (
                                        <Select.Option
                                            value={option.id}
                                            key={option.id}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>
                                                    {option.hidden_from_public && (
                                                        <LockOutlined />
                                                    )}{' '}
                                                    {option.title}
                                                </div>

                                                <small>
                                                    {kr(option.price)}
                                                </small>
                                            </div>
                                        </Select.Option>
                                    );
                                }
                            )}
                        ></Select>
                    </Form.Item>
                )}
                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPO' && (
                    // Póstbox
                    <>
                        <Form.Item
                            name="posturinn_dpo_postal_code"
                            label={translate('posturinn_dpo_postal_code', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedDPOPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpo_postal_code',
                                    lang
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpo_name"
                            label={translate('posturinn_dpo', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate('posturinn_dpo', lang)}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    lang
                                )}
                                children={postboxOptions.map((option, i) => {
                                    return (
                                        <Select.Option
                                            value={option.name}
                                            key={option.postboxId}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>{option.name}</div>
                                            </div>
                                        </Select.Option>
                                    );
                                })}
                            ></Select>
                        </Form.Item>
                    </>
                )}
                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPT' && (
                    // Pósturinn pakkaport sækja
                    <>
                        <Form.Item
                            name="posturinn_dpt_postal_code"
                            label={translate('posturinn_dpt_postal_code', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedDPTPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpt_postal_code',
                                    lang
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpt_name"
                            label={translate('posturinn_dpt', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate('posturinn_dpt', lang)}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    lang
                                )}
                                children={parcelpointOptions.map(
                                    (option, i) => {
                                        return (
                                            <Select.Option
                                                value={option.name}
                                                key={option.name}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>{option.name}</div>
                                                </div>
                                            </Select.Option>
                                        );
                                    }
                                )}
                            ></Select>
                        </Form.Item>
                    </>
                )}
                {droppSelection && (
                    <div
                        style={{
                            background: 'rgb(239 239 239)',
                            fontSize: 14,
                            padding: 5,
                            borderRadius: 20,
                            display: 'flex',
                            marginBottom: 20,
                            marginTop: -10,
                            color: '#3d3d3d',
                        }}
                    >
                        <img
                            src="/static/images/global_logos/dropp.svg"
                            alt="Dropp logo"
                            style={{ width: 40, marginRight: 5 }}
                        />
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                {droppSelection.name}
                            </div>
                            <div>{droppSelection.address}</div>
                        </div>
                    </div>
                )}

                {isChanged && (
                    <Button htmlType="submit" block loading={loading}>
                        Vista
                    </Button>
                )}
            </Form>
        </div>
    );
}
