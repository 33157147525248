import React, { useState, useEffect } from 'react';
import { Space, Card, Table } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import Axios from 'axios';
import Shop from '../../interfaces/shop';

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function APIKeys() {
    const [shop, setShop] = useState<Shop | undefined>();

    useEffect(() => {
        Axios.get(`/verslun/api/shop/${w.uuid}/`).then((rsp) => {
            setShop(rsp.data);
        });
    }, []);

    return (
        <>
            {shop && (
                <Card
                    className="basePageCard"
                    title={
                        <>
                            <CodeOutlined /> Webhooks
                        </>
                    }
                >
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Card>
                            <Table
                                className="white_table"
                                dataSource={[
                                    {
                                        title: 'Áskrift stofnuð',
                                        key: 'new_subscription',
                                        example: `

                                        updated = models.DateTimeField(auto_now=True)
                                        uuid = models.UUIDField(default=uuid.uuid4, editable=False)
                                        created = models.DateTimeField(auto_now_add=True)
                                        customer = models.ForeignKey(Customer, on_delete=models.CASCADE)
                                        """ order (and order product in there) is the product as it was when the order was made """
                                        order = models.ForeignKey(
                                            Order, on_delete=models.CASCADE, null=True, blank=True)
                                        product = models.ForeignKey(Product, on_delete=models.CASCADE)
                                        """ Subscription product is the up to date one (the one we change if something needs to be changed) """
                                        subscription_product = models.ForeignKey(SubscriptionProduct, on_delete=models.CASCADE, null=True)
                                        payment_method = models.CharField(max_length=10, choices=PAYMENT_METHODS, default="SALT_PAY")
                                        card_token = models.ForeignKey(
                                            SaltPayCardToken, on_delete=models.CASCADE, null=True, blank=True)
                                        active = models.BooleanField(default=True)
                                        resign_date = models.DateTimeField(blank=True, null=True)
                                        cancel_reason = models.TextField(null=True, blank=True)
                                        staff_comments = models.ManyToManyField('account.StaffComment', blank=True, null=True)
                                        history = HistoricalRecords()
                                        type = models.ForeignKey(
                                            SubscriptionType, on_delete=models.CASCADE, null=True, blank=True)
                                        is_deleted = models.BooleanField(default=False)

                                        `,
                                    },
                                ]}
                                columns={[
                                    {
                                        title: 'Titill',
                                        dataIndex: 'title',
                                    },
                                    {
                                        title: 'Dæmi',
                                        render: (key) => {
                                            return key.active ? 'Nei' : 'Já';
                                        },
                                    },
                                    {
                                        title: 'Slóð',
                                        render: (key) => {
                                            return key.is_public
                                                ? 'Opið efni'
                                                : 'Læst efni';
                                        },
                                    },
                                ]}
                                pagination={false}
                            ></Table>
                        </Card>
                    </Space>
                </Card>
            )}
        </>
    );
}
