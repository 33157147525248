import { ReactElement, useEffect, useState } from 'react';
import Axios from 'axios';
import {
    Select,
    Table,
    Modal,
    Button,
    Space,
    Form,
    InputNumber,
    Input,
    message,
} from 'antd';
import Customer from '../../interfaces/customer';
import CardToken from '../../interfaces/cardToken';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import { kr } from '../../utils';
dayjs.locale('is'); // use locale
const { Option } = Select;

interface Props {
    customer: Customer;
    onSelect?: Function;
    width?: number | string;
    type: 'select' | 'list';
}

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

export default function SavedCardsOverview(props: Props): ReactElement {
    const [tokens, setTokens] = useState<CardToken[]>([]);
    const [cardModal, setCardModal] = useState<CardToken | false>(false);
    const [chargeCardModal, setChargeCardModal] = useState<false | CardToken>(
        false
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Axios.get(
            `/payment/api/card_tokens?customer=${props.customer.uuid}&ordering=-created`
        ).then((rsp: { data: { results: CardToken[] } }) => {
            const cards = [
                ...new Map(
                    rsp.data.results.map((card: CardToken) => [
                        card['id'],
                        card,
                    ])
                ).values(),
            ];
            setTokens(cards);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {props.type === 'select' ? (
                <Select
                    size="large"
                    placeholder="Veldu kort"
                    onSelect={(value: number) => {
                        if (props.onSelect) {
                            props.onSelect(
                                tokens.find(
                                    (token: CardToken) => token.id === value
                                )
                            );
                        }
                    }}
                    style={{ width: props.width || 200 }}
                >
                    {tokens.map((token: CardToken) => {
                        return (
                            <Option value={token.id} key={token.id}>
                                <div>
                                    {token.PAN} ({token.exp_month}/
                                    {token.exp_year})
                                </div>
                                <div>
                                    Stofnað:{' '}
                                    <div>
                                        {dayjs(token.created).format(
                                            'D. MMM YYYY - HH:mm'
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <strong className="capitalize">
                                        {props.customer.name}
                                    </strong>
                                </div>
                            </Option>
                        );
                    })}
                </Select>
            ) : (
                <>
                    <Table
                        style={{ width: '100%' }}
                        className="white_table"
                        dataSource={tokens}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <div>
                                    <h3>Engar kort fundust</h3>
                                </div>
                            ),
                        }}
                        rowKey="id"
                        columns={[
                            {
                                title: 'PAN',
                                render: (card: CardToken) => {
                                    return card.PAN;
                                },
                            },
                            {
                                title: 'Rennur út',
                                render: (card: CardToken) => {
                                    return `${card.exp_month} / ${card.exp_year}`;
                                },
                            },
                            {
                                title: 'Nánar',
                                render: (card: CardToken) => {
                                    return (
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setCardModal(card);
                                            }}
                                        >
                                            Nánar
                                        </Button>
                                    );
                                },
                            },
                            {
                                title: 'Bakendi',
                                render: (card: CardToken) => (
                                    <Space
                                        direction="vertical"
                                        className="text-center"
                                    >
                                        <Button
                                            size="small"
                                            href={`/bakendi/payment/saltpaycardtoken/${card.id}/change/`}
                                        >
                                            Bakendi
                                        </Button>

                                        <Button
                                            size="small"
                                            onClick={() =>
                                                setChargeCardModal(card)
                                            }
                                        >
                                            Rukka kort
                                        </Button>
                                    </Space>
                                ),
                            },
                        ].filter((col) =>
                            w.me !== 1 && col.title === 'Bakendi' ? false : true
                        )}
                    ></Table>

                    {!!cardModal && (
                        <Modal
                            footer={null}
                            title="Upplýsingar um kort"
                            open={!!cardModal}
                            onCancel={() => setCardModal(false)}
                        >
                            <div>
                                <strong>Kortanúmer:</strong> {cardModal.PAN}
                            </div>
                            <div>
                                <strong>Gildistími:</strong>{' '}
                                {`${cardModal.exp_month} / ${cardModal.exp_year}`}
                            </div>

                            <br></br>

                            <div>
                                <strong>Vistað:</strong>{' '}
                                <div>
                                    {dayjs(cardModal.created).format(
                                        'D. MMM YYYY - HH:mm'
                                    )}
                                </div>
                            </div>
                            <br></br>

                            {cardModal.virtual_number && (
                                <div>
                                    <strong>
                                        Sýndarnúmer (Virtual Number):
                                    </strong>{' '}
                                    {cardModal.virtual_number}
                                </div>
                            )}

                            {cardModal.token && (
                                <div>
                                    <strong>Sýndarnúmer (Card Token):</strong>{' '}
                                    {cardModal.token}
                                </div>
                            )}
                        </Modal>
                    )}

                    <Modal
                        open={!!chargeCardModal}
                        onCancel={() => setChargeCardModal(false)}
                        footer={null}
                    >
                        <Form
                            onFinish={(values) => {
                                console.log(values);

                                if (isNaN(values.amount)) {
                                    message.error('Upphæð verður að vera tala');
                                    return;
                                }

                                if (values.amount < 1) {
                                    message.error(
                                        'Upphæð verður að vera meira en 0'
                                    );
                                    return;
                                }

                                if (chargeCardModal) {
                                    setLoading(true);
                                    Axios.post(`/payment/api/charge_card/`, {
                                        amount: values.amount,
                                        description: values.description,
                                        card_token: chargeCardModal?.id,
                                    })
                                        .then((rsp) => {
                                            setLoading(false);
                                            message.success('Kort rukkað');
                                            setChargeCardModal(false);
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            message.error('Villa kom upp');
                                        });
                                }
                            }}
                            size="large"
                            layout="vertical"
                        >
                            <Form.Item name="amount" label="Upphæð">
                                <InputNumber min={0} />
                            </Form.Item>

                            <Form.Item name="description" label="Lýsing">
                                <Input.TextArea />
                            </Form.Item>

                            <Button
                                htmlType="submit"
                                size="large"
                                block
                                type="primary"
                                loading={loading}
                            >
                                Staðfesta
                            </Button>
                        </Form>
                    </Modal>
                </>
            )}
        </div>
    );
}
