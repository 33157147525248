import { useEffect, useState } from 'react';
import Customer from '../../../interfaces/customer';
import Axios from 'axios';
import { Alert, Badge, Button, Card, Form, Input, Modal, message } from 'antd';

import { EditOutlined } from '@ant-design/icons';

type Props = {
    customer: Customer;
};

type AppwriteCustomer = {
    $id: string;
    $createdAt?: string;
    $updatedAt: string;
    name: string;
    email: string;
    status: number;
    password: string;
    emailVerification: boolean;
    hash: string;
    registration: string;
};

export default function CustomerAppwriteDetails({ customer }: Props) {
    const [appwriteCustomer, setAppwriteCustomer] =
        useState<AppwriteCustomer>();
    const [createUserModal, setCreateUserModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [changeLoginEmailModal, setChangeLoginEmailModal] = useState(false);

    useEffect(() => {
        Axios.get('/efni/get_appwrite_user_from_customer_uuid/' + customer.uuid)
            .then((res) => {
                setAppwriteCustomer(res.data.user);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <Card
                title={
                    <>
                        <Badge
                            status={appwriteCustomer ? 'success' : 'error'}
                        />{' '}
                        {appwriteCustomer
                            ? 'Mitt svæði notanda er til'
                            : 'Notandi ekki til'}
                    </>
                }
                headStyle={{
                    textAlign: 'center',
                }}
                bodyStyle={{
                    padding: 20,
                    paddingTop: 0,
                }}
            >
                <h4 className="text-center">
                    {appwriteCustomer ? (
                        <>
                            {appwriteCustomer?.email}{' '}
                            <Button
                                size="small"
                                type="text"
                                onClick={() => setChangeLoginEmailModal(true)}
                            >
                                <EditOutlined />
                            </Button>{' '}
                        </>
                    ) : (
                        <Button onClick={() => setCreateUserModal(true)}>
                            Búa til notanda og lykilorð
                        </Button>
                    )}
                </h4>

                <p
                    style={{
                        background: '#f9f9f9',
                        padding: 10,
                        borderRadius: 10,
                        color: 'gray',
                        marginTop: 10,
                        textAlign: 'center',
                    }}
                >
                    Hér er átt við notendaupplýsingar fyrir {customer.name} til
                    að skrá sig inn á vefsíðuna þína.
                </p>

                {appwriteCustomer ? (
                    <div>
                        <div className="mt10 text-center">
                            <Button
                                size="small"
                                onClick={() => setChangePasswordModal(true)}
                                type="text"
                            >
                                Breyta lykilorði
                            </Button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </Card>

            {appwriteCustomer && (
                <Modal
                    title="Breyta lykilorði notanda"
                    open={changePasswordModal}
                    onCancel={() => setChangePasswordModal(false)}
                    footer={false}
                >
                    <Alert description='Athugaðu að þú getur einnig bent viðkomandi á að breyta lykilorðinu sjálf/ur með því að smella á "Gleymt lykilorð" á vefsíðunni þinni.'></Alert>
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            Axios.post(
                                '/efni/change_appwrite_user_password/' +
                                    appwriteCustomer.$id +
                                    '/',
                                {
                                    password: values.password,
                                }
                            )
                                .then((res) => {
                                    message.success('Tókst, lykilorði breytt!');
                                    setChangePasswordModal(false);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item label="Nýtt lykilorð" name="password">
                            <Input type="password" />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                </Modal>
            )}

            {appwriteCustomer && (
                <Modal
                    title="Breyta innskráninganetfangi notanda"
                    open={changeLoginEmailModal}
                    onCancel={() => setChangeLoginEmailModal(false)}
                    footer={false}
                >
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            Axios.post(
                                '/efni/change_appwrite_user_email/' +
                                    appwriteCustomer.$id +
                                    '/',
                                {
                                    email: values.email,
                                }
                            )
                                .then((res) => {
                                    message.success('Tókst, netfangi breytt!');
                                    setChangeLoginEmailModal(false);
                                    setAppwriteCustomer({
                                        ...appwriteCustomer,
                                        email: values.email,
                                    });
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item label="Nýtt netfang" name="email">
                            <Input type="text" />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                </Modal>
            )}

            <Modal
                title="Búa til notanda og lykilorð"
                open={createUserModal}
                footer={false}
                onCancel={() => setCreateUserModal(false)}
            >
                <Form
                    size="large"
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);
                        Axios.post(
                            '/efni/create_appwrite_user/' + customer.uuid + '/',
                            {
                                password: values.password,
                                email: values.email,
                            }
                        )
                            .then((res) => {
                                message.success(
                                    'Tókst, notandi hefur verið stofnaður!'
                                );
                                setCreateUserModal(false);

                                Axios.get(
                                    '/efni/get_appwrite_user_from_customer_uuid/' +
                                        customer.uuid
                                )
                                    .then((res) => {
                                        setAppwriteCustomer(res.data.user);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            })
                            .catch((err) => {
                                message.error(err.response.data.error);
                                setLoading(false);
                            });
                    }}
                >
                    <Form.Item
                        name="email"
                        label="Netfang"
                        initialValue={customer.email}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item label="Veldu lykilorð" name="password">
                        <Input type="password" />
                    </Form.Item>

                    <Button
                        disabled={loading}
                        type="primary"
                        htmlType="submit"
                        block
                    >
                        Staðfesta
                    </Button>
                </Form>
            </Modal>
        </>
    );
}
