import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import Axios, { AxiosResponse } from 'axios';

// Interfaces
import Shop from '../../../interfaces/shop';
import PaymentProviderLogo from '../../PaymentProviderLogo/PaymentProviderLogo';
import { SimpleCustomer } from '../../../interfaces/customer';

import ThreeDSecureModal from '../../../Pages/RepeatCheckout/3DSecureModal/3DSecureModal';
import { Currency } from '../../../interfaces/product';
import translate, { LanguageKey } from '../../../translate/translate';

interface Props {
    customer_uuid: string;
    useTermsCheckbox: boolean;
    shop: SimpleCustomer['shop'] | Shop;
    onSuccess: (res: AxiosResponse) => void;
    subscription_uuid?: string;
    pay_unpaid_subscription_transactions: boolean;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}

export default function SaveSaltPayInfo(props: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [threeDSecureModal, setThreeDSecureModal] = useState<{
        cardNumber: string;
        cvc: string;
        month: string;
        year: string;
        onSuccess: ({}) => void;
    }>();

    const [form] = Form.useForm();

    const termsUrl = props.shop?.terms_url
        ? props.shop.terms_url
        : '/skilmalar/' + (props.shop?.uuid || '');

    const submit = (values: any) => {
        Axios.post('/payment/api/save_card_as_token/', {
            ...values,
            customer_uuid: props.customer_uuid,
            subscription_uuid: props.subscription_uuid,
            pay_unpaid_subscription_transactions:
                props.pay_unpaid_subscription_transactions || false,
        })
            .then((rsp) => {
                if (rsp.data.card_token) {
                    props.onSuccess(rsp);
                } else {
                    try {
                        message.error(rsp.data.message);
                    } catch {
                        // Err
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                message.error(
                    err?.data?.message ||
                        translate('error_came_up', props.language)
                );
                setLoading(false);
            });
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={(values) => {
                    if (props.useTermsCheckbox) {
                        if (!values.terms_checkbox) {
                            message.error(
                                translate(
                                    'please_agree_to_terms',
                                    props.language
                                )
                            );
                            return;
                        }
                    }

                    setLoading(true);
                    if (
                        props.shop?.salt_pay &&
                        props.shop?.use_three_d_secure
                    ) {
                        setThreeDSecureModal({
                            cardNumber: values.card_number,
                            cvc: values.cvc,
                            month: values.month,
                            year: values.year,
                            onSuccess: (threeDSecureData) => {
                                setThreeDSecureModal(undefined);
                                submit({
                                    ...values,
                                    ...threeDSecureData,
                                });
                            },
                        });
                    } else {
                        submit(values);
                    }
                }}
            >
                <Form.Item
                    name="card_number"
                    label={translate('card_number', props.language)}
                    rules={[
                        {
                            required: true,
                            message: translate(
                                'field_required',
                                props.language
                            ),
                        },
                    ]}
                >
                    <Input
                        type="number"
                        placeholder={
                            translate('card_number', props.language) as string
                        }
                        size="large"
                        autoComplete="cc-number"
                    />
                </Form.Item>

                <div>
                    <Form.Item
                        name="month"
                        label={translate('month', props.language)}
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333%)',
                        }}
                        rules={[
                            {
                                len: 2,
                                message: translate(
                                    'should_contain_two',
                                    props.language
                                ),
                            },
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.language
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={
                                translate('month', props.language) as string
                            }
                            maxLength={2}
                            autoComplete="cc-exp-month"
                        />
                    </Form.Item>

                    <Form.Item
                        name="year"
                        label={translate('year', props.language)}
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333% - 12px)',
                            marginLeft: '12px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.language
                                ),
                            },
                            {
                                len: 2,
                                message: translate(
                                    'should_contain_two',
                                    props.language
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={2}
                            placeholder={
                                translate('year', props.language) as string
                            }
                            autoComplete="cc-exp-year"
                        />
                    </Form.Item>

                    <Form.Item
                        name="cvc"
                        label="CVC"
                        style={{
                            display: 'inline-block',
                            width: 'calc(33.333% - 12px)',
                            marginLeft: '12px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.language
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={4}
                            placeholder="CVC"
                            autoComplete="cc-csc"
                        />
                    </Form.Item>
                </div>

                {props.useTermsCheckbox && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Form.Item
                            label={
                                <div>
                                    <div>
                                        {translate('agree', props.language)}{' '}
                                        <a
                                            href={termsUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {translate('terms', props.language)}
                                        </a>
                                    </div>
                                </div>
                            }
                            name="terms_checkbox"
                            valuePropName="checked"
                        >
                            <Input size="large" type="checkbox" />
                        </Form.Item>

                        {props.shop && (
                            <span style={{ maxWidth: 70 }}>
                                <PaymentProviderLogo
                                    shop={props.shop}
                                    maxWidth={70}
                                    margin={'10px auto 5px auto'}
                                ></PaymentProviderLogo>
                            </span>
                        )}
                    </div>
                )}

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        className="btn-success"
                        block
                        size="large"
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        {translate('confirm', props.language)}
                    </Button>
                </Form.Item>
            </Form>

            <div
                style={{
                    margin: 'auto',
                    textAlign: 'center',
                    borderRadius: '10px',
                }}
            >
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/master_card.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/maestro.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/visa.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/visa_electron.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/union_pay.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/jcb.png"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/diners_club.svg"
                    alt=""
                />
                <img
                    style={{ width: '8%', margin: '2%' }}
                    src="/static/images/credit_card_icons/discover.png"
                    alt=""
                />
            </div>

            {threeDSecureModal && props.shop && (
                <ThreeDSecureModal
                    cardNumber={threeDSecureModal.cardNumber}
                    cvc={threeDSecureModal.cvc}
                    month={threeDSecureModal.month}
                    year={threeDSecureModal.year}
                    shop={props.shop}
                    onSuccess={(val) => {
                        threeDSecureModal.onSuccess(val);
                    }}
                    onCancel={() => {
                        setThreeDSecureModal(undefined);
                        setLoading(false);
                    }}
                    currencyCode={props.currencyCode}
                ></ThreeDSecureModal>
            )}
        </div>
    );
}
