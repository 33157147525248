import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Button, Form, Input } from 'antd';
import { OrderProduct } from '../../interfaces/order';
import ReactHtmlParser from 'react-html-parser';

type Props = {
    uuid: string;
    orderProduct?: OrderProduct;
    onSuccess?: (uuid: string) => void;
};

type QuestionBase = {
    uuid: string;
    updated: string;
    created: string;
    weight: number;
};

interface TextQuestion extends QuestionBase {
    uuid: string;
    question: string;
    required: boolean;
    multi_line: boolean;
}

// interface RatingQuestion extends QuestionBase {
//     question: string;
//     max_value: number;
//     required: boolean;
// }

// interface Statement extends QuestionBase {
//     title: string;
// }

interface CustomForm {
    title: string;
    class_name?: string;
    texts?: TextQuestion[];
    intro?: string;
}

export default function CustomProductForm(props: Props) {
    // const [questions, setQuestions] = useState<TextQuestion[]>();
    const [form, setForm] = useState<CustomForm>();

    // useState<(TextQuestion | RatingQuestion | Statement)[]>();

    useEffect(() => {
        Axios.get(`/forms/api/form/${props.uuid}`).then((rsp) => {
            setForm(rsp.data);
        });
    }, []);

    return (
        <div className="customForms">
            <div className={form?.class_name ? form.class_name : ''}>
                <Form
                    layout="vertical"
                    onFinish={(values) => {
                        const textUUIDs = Object.keys(values).filter((key) =>
                            form?.texts?.filter((text) => text.uuid == key)
                        );

                        const textAnswers = textUUIDs.map((uuid) => {
                            return {
                                answer: values[uuid],
                                parent: uuid,
                            };
                        });

                        Axios.post('/forms/api/form_entry/', {
                            form: props.uuid,
                            multiple_choice_answers: [],
                            text_answers: textAnswers,
                            boolean_answers: [],
                            rating_answers: [],
                            order_product: props.orderProduct?.id,
                        }).then((rsp) => {
                            props.onSuccess && props.onSuccess(rsp.data.uuid);
                        });
                    }}
                >
                    <h2>{form?.title}</h2>

                    {form?.intro && (
                        <div>
                            {ReactHtmlParser(form.intro) as React.ReactNode}
                        </div>
                    )}
                    {form?.texts?.map((question, i) => {
                        return (
                            <Form.Item
                                key={i}
                                name={question.uuid}
                                label={question.question}
                                rules={[
                                    {
                                        required: question.required,
                                        message:
                                            'Vinsamlegast fylltu þennan reit út',
                                    },
                                ]}
                            >
                                <Input size="large"></Input>
                            </Form.Item>
                        );
                    })}

                    <Button size="large" type="primary" block htmlType="submit">
                        Staðfesta
                    </Button>
                </Form>
            </div>
        </div>
    );
}
