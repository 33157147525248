import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { Table, Button, Col, Row, Layout, Card, Badge, message } from 'antd';
import { FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import CreateCertificateModal from './CreateCertificateModal/CreateCertificateModal';
import { Key } from 'antd/lib/table/interface';
import Shop from '../../interfaces/shop';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import Customer from '../../interfaces/customer';
dayjs.locale('is'); // use locale

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

interface GiftCertificate {
    id: string;
    buyer_name: string;
    product: {
        title: string;
        gift_certificate_activated_message: string;
        gift_certificate_valid_products: {
            title: string;
            uuid: string;
            info_to_collect: {
                slug: string;
            }[];
        }[];
    };
    subscription: {
        active: boolean;
        customer: {
            name: string;
            ssid: string;
            email: string;
            phone: string;
            uuid: string;
        };
    };
    customer: Customer;
    created: string;
    is_deleted: boolean;
    is_paid: boolean;
    original_credits: number;
    credits: number;
    unit_type: string;
    continue_when_done: boolean;
    activated_date: string;
    code: string;
    message: string;
    order_product: number;
    shop: number;
    order: string;
}

const w: IWindow = window;

const { Content } = Layout;

export default function GiftCertificateOverview() {
    const [giftCertificates, setGiftCertificates] = useState<GiftCertificate[]>(
        []
    );
    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectRowKeys] = useState<Key[]>([]);
    /*     const [activeChecked] = useState(true);
    const [inactiveChecked] = useState(false); */
    const [count, setCount] = useState(0);
    const [createCertificateModal, setCreateCertificateModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [shop, setShop] = useState<Shop>();

    const get_gift_certificates = () => {
        Axios.get(
            `/askrift/api/gift_certificate/?page_size=10000&ordering=-created&shop__uuid=` +
                w.uuid
        ).then((rsp) => {
            const { data } = rsp;
            setGiftCertificates(data.results);
            setCount(data.count);
            setLoading(false);
        });

        Axios.get(
            '/verslun/api/product/?type=GIFT_CERTIFICATE&shop__uuid=' + w.uuid
        ).then((rsp) => {
            setProducts(rsp.data.results);
        });

        Axios.get('/verslun/api/shop/' + w.uuid).then((rsp) => {
            setShop(rsp.data);
        });
    };

    const onSelectChange = (selectedKeys: Key[]) => {
        setSelectRowKeys(selectedKeys);
    };

    useEffect(() => {
        get_gift_certificates();
    }, []);

    /*     useEffect(() => {
        get_gift_certificates();
    }, [activeChecked, inactiveChecked]); */

    return (
        <>
            <Layout id="layout_wrapper">
                <Content>
                    <Row gutter={[10, 10]} className="main_row">
                        <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={16}>
                            <div
                                className="text-center"
                                style={{ fontSize: 32, margin: 20 }}
                            >
                                <>
                                    <div>
                                        <img
                                            style={{ height: 55 }}
                                            src="/static/images/svg/surprise.svg"
                                            alt=""
                                        />{' '}
                                        Gjafabréf ({count})
                                    </div>

                                    <div>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setCreateCertificateModal(true);
                                            }}
                                            icon={'+'}
                                            style={{ marginRight: 10 }}
                                        >
                                            Bæta við
                                        </Button>
                                    </div>
                                </>
                            </div>
                            <Card loading={loading}>
                                {selectedRowKeys.length > 0 && (
                                    <Button
                                        style={{
                                            marginBottom: 20,
                                        }}
                                        href={`/askrift/pdf_bref/${selectedRowKeys
                                            .map((key) => {
                                                return giftCertificates.find(
                                                    (certificate) =>
                                                        certificate.code === key
                                                )?.code;
                                            })
                                            .join(',')}`}
                                    >
                                        <PrinterOutlined></PrinterOutlined>
                                        Prenta valin gjafabréf (
                                        {selectedRowKeys.length})
                                    </Button>
                                )}
                                <Table
                                    rowSelection={{
                                        selectedRowKeys,
                                        onChange: onSelectChange,
                                    }}
                                    pagination={false}
                                    locale={{
                                        emptyText: (
                                            <div>
                                                <h2>Engin gjafabréf fundust</h2>
                                                <p
                                                    style={{ color: 'gray' }}
                                                ></p>
                                            </div>
                                        ),
                                    }}
                                    columns={[
                                        {
                                            title: 'Kaupandi',
                                            render: (gc: GiftCertificate) => {
                                                const customer = gc.customer;

                                                return (
                                                    <>
                                                        {customer ? (
                                                            <a
                                                                className="capitalize"
                                                                href={`/vidskiptavinur/${customer.uuid}/`}
                                                                style={{
                                                                    color: 'rgba(0,0,0,0.65)',
                                                                }}
                                                            >
                                                                {customer.name}
                                                            </a>
                                                        ) : (
                                                            <>
                                                                Stofnað í
                                                                bakenda
                                                            </>
                                                        )}
                                                        {w.me === 1 && (
                                                            <>
                                                                {' '}
                                                                <Button
                                                                    href={`/bakendi/subscription/giftcertificate/${gc.id}/change/`}
                                                                    size="small"
                                                                >
                                                                    Gjafabréf
                                                                </Button>
                                                            </>
                                                        )}
                                                    </>
                                                );
                                            },
                                        },
                                        {
                                            title: 'Keypt',
                                            dataIndex: 'created',
                                            render: (created) => {
                                                return (
                                                    <div>
                                                        {dayjs(created).format(
                                                            'D. MMM YYYY - HH:mm'
                                                        )}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            title: 'Tegund',
                                            dataIndex: 'product',
                                            render: (product) => {
                                                return product.title;
                                            },
                                        },

                                        {
                                            title: 'Stofn inneign',
                                            dataIndex: 'original_credits',
                                        },

                                        {
                                            title: 'Inneign eftir',
                                            dataIndex: 'credits',
                                        },

                                        {
                                            title: 'Kóði',
                                            dataIndex: 'code',
                                        },
                                        {
                                            title: 'PDF',
                                            render: (item) => {
                                                return (
                                                    <Button size="small">
                                                        <a
                                                            href={`/askrift/pdf_bref/${item.code}`}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                        >
                                                            <FilePdfOutlined
                                                                style={{
                                                                    color: '#b50706',
                                                                }}
                                                            />
                                                        </a>
                                                    </Button>
                                                );
                                            },
                                        },
                                        {
                                            title: 'Virkjað',
                                            dataIndex: 'subscription',
                                            render: (subscription) => {
                                                console.log(subscription);
                                                const status = subscription
                                                    ? 'success'
                                                    : 'error';
                                                return subscription ? (
                                                    <a
                                                        style={{
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                        }}
                                                        href={`/vidskiptavinur/${subscription.customer.uuid}/`}
                                                    >
                                                        <Badge
                                                            status={status}
                                                        />{' '}
                                                        {
                                                            subscription
                                                                .customer.name
                                                        }
                                                    </a>
                                                ) : (
                                                    <div>
                                                        <Badge
                                                            status={status}
                                                        />{' '}
                                                        Óvirkjað
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    /* expandable={{
                                        expandedRowRender: (customer) => (
                                            <>
                                                <Card>asdfasdfa</Card>
                                            </>
                                        ),
                                    }} */
                                    rowKey="code"
                                    dataSource={giftCertificates}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>

            {createCertificateModal && shop && (
                <CreateCertificateModal
                    open={createCertificateModal}
                    onCancel={() => {
                        setCreateCertificateModal(false);
                    }}
                    shop={shop}
                    onSuccess={(certificates: any) => {
                        setGiftCertificates([
                            ...certificates,
                            ...giftCertificates,
                        ]);
                        setCreateCertificateModal(false);
                        setCount(count + certificates.length);
                        message.success('Tókst');
                    }}
                    products={products}
                ></CreateCertificateModal>
            )}
        </>
    );
}
