import { useEffect, useState } from 'react';
import { Button, DatePicker, Space, Table, message } from 'antd';
import Axios from 'axios';
import { kr } from '../../utils';
import dayjs from 'dayjs';
import moment from 'moment';

type Props = {
    shopUUID: string;
};

type Claim = {
    // ['Unpaid', 'Paid', 'Cancelled', 'Invalid', 'Unknown'],
    Status: 0 | 1 | 2 | 3 | 4;
    //  ['PrimaryCollection', 'SecondaryCollection', 'LegalCollection']
    State: 0 | 1 | 2;

    CategoryCode: string;
    TotalAmountDue: number;
    NoticeChargeAmount: number;
    DefaultChargeAmount: number;
    OtherCostsAmount: number;
    OtherDefaultCostsAmount: number;
    DefaultInterestAmount: number;
    DiscountAmount: number;
    SecondaryCollectionIdentifier?: string;
    LegalCollectionIdentifier?: string;
    IsPartiallyPaid: boolean;
    Key: {
        Claimant: string;
        Account: string;
        DueDate: string;
    };
    PayorID: string;
    PayorName: string;
    ClaimantName: string;
    CancellationDate: string;
    Identifier: string;
    Amount: number;
    Reference: string;
    FinalDueDate: string;
    BillNumber: string;
    CustomerNumber: string;
    NoticeAndPaymentFee?: number;
    DefaultCharge?: number;
    OtherCosts: number;
    OtherDefaultCosts: number;
    DefaultInterest?: number;
    CurrencyInformation: {
        ReferenceRate: number;
        Currency: string;
        PaymentRate: number;
    };
    PermitOutOfSequencePayment: boolean;
    Discount?: number;
    IsPartialPaymentAllowed: boolean;
    BillPresentmentSystem?: string;
    Printing?: string;
};

export default function ShopClaimPaymentOverview(props: Props) {
    const [claims, setClaims] = useState<Claim[]>([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [unpaidTotal, setUnpaidTotal] = useState(0);
    const [repeatTotal, setRepeatTotal] = useState(0);
    const [statusFilter, setStatusFilter] = useState<Claim['Status'][]>([
        0, 1, 2, 3, 4,
    ]);
    const [dates, setDates] = useState({
        from: moment().subtract(1, 'day'),
        to: moment(),
    });

    // useEffect(() => {
    //     getClaimData();
    // }, []);

    useEffect(() => {
        setTotal(
            claims.reduce(
                (acc: number, obj: Claim) =>
                    (obj.Status === 1 ? obj.Amount : 0) + acc,
                0
            )
        );

        setUnpaidTotal(
            claims.reduce(
                (acc: number, obj: Claim) =>
                    (obj.Status === 0 ? obj.Amount : 0) + acc,
                0
            )
        );

        setRepeatTotal(
            claims.reduce(
                (acc: number, obj: Claim) =>
                    (obj.Status === 0 && obj.Reference === 'Repeat'
                        ? obj.Amount
                        : 0) + acc,
                0
            )
        );

        return () => {
            setTotal(0);
            setRepeatTotal(0);
        };
    }, [claims]);

    const getClaimData = () => {
        Axios.post('/payment/api/get_shop_claims_created/', {
            uuid: props.shopUUID,
            from: dates.from,
            to: dates.to,
        }).then((rsp) => {
            setClaims(rsp.data);
            setLoading(false);
        });
    };

    return (
        <Space direction="vertical" style={{ display: 'flex' }}>
            <div>
                Alls greitt: {kr(total)} (þar af repeat.is: {kr(repeatTotal)})
            </div>

            <div>Alls ógreitt : {kr(unpaidTotal)}</div>
            <div>
                Fjöldi: {claims.length} (þar af repeat.is:{' '}
                {claims.filter((obj) => obj.Reference === 'Repeat').length} )
            </div>
            <hr />
            <Space>
                <label>Frá: </label>
                <DatePicker
                    defaultValue={dates.from}
                    onChange={(val) => val && setDates({ ...dates, from: val })}
                ></DatePicker>

                <label>Til: </label>
                <DatePicker
                    defaultValue={dates.to}
                    onChange={(val) => val && setDates({ ...dates, to: val })}
                ></DatePicker>

                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                        if (!dates.from || !dates.to) {
                            message.error('Þú verður að velja dagsetningar');
                            return;
                        }
                        setLoading(true);
                        getClaimData();
                    }}
                >
                    Leita
                </Button>
            </Space>

            <Table
                loading={loading}
                pagination={false}
                dataSource={claims.filter((claim) =>
                    statusFilter.includes(claim.Status)
                )}
                rowKey={(claim) => claim.Key.Account}
                onChange={(pagination, filter, sorter, extra) => {
                    const status = (filter.status || [
                        0, 1, 2, 3, 4,
                    ]) as Claim['Status'][];

                    setStatusFilter(status);
                }}
                columns={[
                    {
                        title: 'Upphæð',
                        render: (claim: Claim) => {
                            return <div>{kr(claim.Amount)}</div>;
                        },
                    },
                    {
                        title: 'Gjalddagi',
                        render: (claim: Claim) => {
                            return (
                                <div>
                                    {dayjs(claim.Key.DueDate).format(
                                        'D. MMM YYYY'
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Eindagi',
                        render: (claim: Claim) => {
                            return (
                                <div>
                                    {dayjs(claim.FinalDueDate).format(
                                        'D. MMM YYYY'
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Staða',
                        key: 'status',
                        filters: [
                            {
                                value: 0,
                                text: 'Ógreitt',
                            },
                            {
                                value: 1,
                                text: 'Greitt',
                            },
                            {
                                value: 2,
                                text: 'Niðurfelld',
                            },
                            {
                                value: 3,
                                text: 'Ógild',
                            },
                            {
                                value: 4,
                                text: 'Óvitað',
                            },
                        ],
                        render: (claim: Claim) => {
                            // ['Unpaid', 'Paid', 'Cancelled', 'Invalid', 'Unknown'],
                            return (
                                <div>
                                    {claim.Status === 0 && <div>Ógreitt</div>}
                                    {claim.Status === 1 && <div>Greitt</div>}
                                    {claim.Status === 2 && (
                                        <div>Felld niður</div>
                                    )}
                                    {claim.Status === 3 && <div>Ógild</div>}
                                    {claim.Status === 4 && <div>Óvitað</div>}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Kröfunúmer',
                        render: (claim: Claim) => {
                            return <div>{claim.Key.Account}</div>;
                        },
                    },
                    {
                        title: 'Ref',
                        render: (claim: Claim) => {
                            return <div>{claim.Reference}</div>;
                        },
                    },
                ]}
            ></Table>
        </Space>
    );
}
