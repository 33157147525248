import React from 'react';
import { SubscriptionTransactionFilterRequest } from '../../../interfaces/transaction';
import { Alert, Button, Space, message } from 'antd';
import Axios from 'axios';

type Props = {
    selection: SubscriptionTransactionFilterRequest;
    closeModal?: () => void;
};

export default function ConvertPaymentMethod(props: Props) {
    return (
        <Space direction="vertical">
            <p>
                Breytir einungis völdum <strong>sendingum</strong> en ekki{' '}
                <strong>áskriftinni</strong> (og þar af leiðandi ekki
                framtíðarsendingum).
            </p>

            <p>
                Kröfurnar verða sendar strax í vinnslu og eru stofnaðar í
                framhaldinu.
            </p>

            <Alert description="Athugaðu að ef viðskiptavinurinn er ekki með skráða kennitölu mun ekki takast að stofna kröfuna"></Alert>

            <Button
                size="large"
                onClick={() => {
                    Axios.post(
                        '/askrift/convert_subscription_transactions_to_claims/',
                        {
                            selection: props.selection,
                        }
                    )
                        .then((rsp) => {
                            message.success('Aðgerð hefur verið sett af stað.');
                        })
                        .catch((err) => {
                            message.error('Úps, eitthvað fór úrskeiðis');
                        })
                        .finally(() => props.closeModal && props.closeModal());
                }}
                type="primary"
                block
            >
                Stofna kröfur
            </Button>
        </Space>
    );
}
