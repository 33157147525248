import { useEffect, useState } from 'react';
import Subscription, { CronConfig } from '../../interfaces/subscription';
import { Space, Calendar, Button, message, Modal, Alert, Switch } from 'antd';
import Axios from 'axios';
import { getSubscriptionCronDetails } from '../../utils/subscriptionUtils';

import moment, { Moment } from 'moment';

import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import SubscriptionCronSettings from '../SubscriptionCronSettings/SubscriptionCronSettings';
dayjs.locale('is'); // use locale

interface Props {
    subscription: Subscription;
}

export interface UpdatedCronConfig {
    interval?: number;
    interval_type?: 'MONTH' | 'WEEK' | 'YEAR';
    base_date?: string;
    base_date_is_signup_date?: boolean;
    uuid?: string;
}

// interface ComponentMenuItem extends Props {
//     component: any;
// }

// interface ClickMenuItem extends Props {
//     click: any;
// }

// type MenuItem = ComponentMenuItem | ClickMenuItem;

export default function FrequencyDisplayCard(props: Props) {
    const [productConfig, setProductConfig] = useState<CronConfig>();
    const [subscriptionConfig, setSubscriptionConfig] = useState<CronConfig>();
    const [updatedConfig, setUpdatedConfig] = useState<UpdatedCronConfig>();
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedCalendarDate, setSelectedCalendarDate] = useState<Moment>();
    const [loading, setLoading] = useState(false);
    const [customCron, setCustomCron] = useState(false);

    useEffect(() => {
        getSubscriptionCronDetails(props.subscription).then((rsp) => {
            setSubscriptionConfig(rsp?.subscriptionConfig);
            setProductConfig(rsp?.productConfig);
        });
    }, [props.subscription]);

    useEffect(() => {
        if (subscriptionConfig) {
            setCustomCron(true);
        }
    }, [subscriptionConfig]);

    const returnString = (conf: CronConfig | UpdatedCronConfig) => {
        const fresti =
            (conf.interval_type === 'MONTH'
                ? 'mán'
                : conf.interval_type === 'YEAR'
                ? 'árs '
                : 'vik') + ' fresti';

        const x = conf.base_date_is_signup_date
            ? '(miðast við daginn sem viðkomandi skráði sig)'
            : 'alltaf sama dag ' +
              (conf.interval_type === 'MONTH' ? 'mánaðar (' : 'árs (');

        const y = !conf.base_date_is_signup_date
            ? conf.interval_type === 'MONTH'
                ? dayjs(conf.base_date).format('D') + ')'
                : dayjs(conf.base_date).format('D. MMMM') + ')'
            : '';

        return `${conf.interval} ${fresti} ${x}${y}`;
    };

    const defaultBaseDate = subscriptionConfig
        ? moment(
              subscriptionConfig?.base_date_is_signup_date ||
                  !!!subscriptionConfig
                  ? props.subscription.created
                  : subscriptionConfig.base_date
          )
        : undefined;

    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            {
                <>
                    {!productConfig ? (
                        <div style={{ marginBottom: 20 }}>
                            ATH. Varan fyrir þessa áskrift er ekki með kveikt á
                            sjálfkrafa færslum og því ekki hægt að breyta þessum
                            stillingum.
                        </div>
                    ) : (
                        <div>
                            {!customCron && (
                                <>
                                    <Alert
                                        description={
                                            <>
                                                Þessi áskrift fylgir
                                                grunnstillingu vöru:{' '}
                                                {returnString(productConfig)}
                                            </>
                                        }
                                    ></Alert>
                                    <br />
                                </>
                            )}

                            {!customCron && (
                                <>
                                    <h5>Sérsníða tíðni / grunndagsetningu</h5>
                                    <Space direction="vertical">
                                        <Switch
                                            onChange={(bool) => {
                                                setCustomCron(bool);
                                            }}
                                        ></Switch>
                                    </Space>
                                </>
                            )}

                            {customCron && productConfig && (
                                <>
                                    <h3>Tíðni sendinga</h3>

                                    <SubscriptionCronSettings
                                        yearly={true}
                                        monthly={true}
                                        weekly={true}
                                        initalFrequency={
                                            subscriptionConfig?.interval
                                        }
                                        initalFrequencyType={
                                            subscriptionConfig?.interval_type
                                        }
                                        onChange={(config: CronConfig) => {
                                            if (
                                                config.interval &&
                                                (config.interval !==
                                                    subscriptionConfig?.interval ||
                                                    config.interval_type !==
                                                        subscriptionConfig.interval_type)
                                            ) {
                                                setUpdatedConfig({
                                                    ...updatedConfig,
                                                    ...config,
                                                });
                                            } else {
                                                setUpdatedConfig(undefined);
                                            }
                                        }}
                                        showTitle={false}
                                    ></SubscriptionCronSettings>

                                    <div style={{ marginTop: 20 }}>
                                        <>
                                            <h3>Grunn dagsetning</h3>

                                            <p>
                                                Áskriftarfærslur eru stofnaðar{' '}
                                                <strong>frá og með</strong>{' '}
                                                þessum degi
                                            </p>

                                            <Calendar
                                                fullscreen={false}
                                                defaultValue={defaultBaseDate}
                                                onChange={(_moment) => {
                                                    setUpdatedConfig({
                                                        ...updatedConfig,
                                                        base_date_is_signup_date:
                                                            false,
                                                        base_date:
                                                            _moment.toISOString(),
                                                    });
                                                    setShowCalendar(false);
                                                }}
                                            ></Calendar>
                                            <br />
                                            <hr style={{ opacity: 0.3 }} />
                                            <p>
                                                Ef þú velur dagsetningu fram í
                                                tímann þá fer áskriftin í pásu
                                                þangað til.
                                            </p>
                                        </>
                                    </div>

                                    {updatedConfig && (
                                        <>
                                            <Button
                                                type="primary"
                                                loading={loading}
                                                onClick={() => {
                                                    if (
                                                        !subscriptionConfig &&
                                                        (!updatedConfig.base_date ||
                                                            !updatedConfig.interval ||
                                                            !updatedConfig.interval_type)
                                                    ) {
                                                        message.error(
                                                            'Þú verður að velja bæði tíðni og grunn dagsetningu'
                                                        );
                                                        return;
                                                    }

                                                    setLoading(true);

                                                    if (
                                                        subscriptionConfig?.uuid
                                                    ) {
                                                        Axios.patch(
                                                            `/verslun/api/transaction_cron/${subscriptionConfig.uuid}/`,
                                                            updatedConfig
                                                        ).then((rsp) => {
                                                            message.success(
                                                                'Tókst! Tíðni hefur verið breytt.'
                                                            );
                                                            setSubscriptionConfig(
                                                                {
                                                                    ...subscriptionConfig,
                                                                    ...rsp.data,
                                                                }
                                                            );
                                                            setUpdatedConfig(
                                                                undefined
                                                            );
                                                            setLoading(false);
                                                        });
                                                    } else {
                                                        Axios.post(
                                                            `/verslun/api/create_transaction_cron/`,
                                                            updatedConfig
                                                        ).then((rsp) => {
                                                            Axios.patch(
                                                                `/askrift/api/subscription_product/${props.subscription.subscription_product.id}/`,
                                                                {
                                                                    cron_transaction:
                                                                        {
                                                                            uuid: rsp
                                                                                .data
                                                                                .uuid,
                                                                        },
                                                                }
                                                            ).then((res) => {
                                                                message.success(
                                                                    'Tókst! Tíðni hefur verið breytt.'
                                                                );
                                                                setSubscriptionConfig(
                                                                    {
                                                                        ...subscriptionConfig,
                                                                        ...rsp.data,
                                                                    }
                                                                );
                                                                setUpdatedConfig(
                                                                    undefined
                                                                );
                                                                setLoading(
                                                                    false
                                                                );
                                                            });
                                                        });
                                                    }
                                                }}
                                                block
                                            >
                                                Vista breytingar
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            }
        </div>
    );
}
