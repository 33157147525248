import React, { ReactElement, useState } from 'react';
import { Modal, message } from 'antd';
import Axios from 'axios';
import { SubscriptionTransactionFilterRequest } from '../../../interfaces/transaction';

interface Props {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
    selection: SubscriptionTransactionFilterRequest;
}

export default function DeleteTransactionsModal(props: Props): ReactElement {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            open={props.open}
            onCancel={() => {
                props.onCancel();
            }}
            maskClosable={false}
            title={'Ertu alveg viss?'}
            cancelText="Hætta við"
            onOk={() => {
                setLoading(true);
                Axios.post('/askrift/update_subscription_transactions/', {
                    is_deleted: true,
                    selection: props.selection,
                }).then((res) => {
                    message.success('Tókst!');
                    props.onOk();
                });
            }}
            okText="Staðfesta"
            okType="primary"
            confirmLoading={loading}
        >
            Þessi aðgerð er ekki afturkræf!
        </Modal>
    );
}
