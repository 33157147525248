// Fengið beint frá antd docs: https://ant.design/components/select/
import React, { useState, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}

export default function DebounceSelect<
    ValueType extends {
        key?: string;
        label: React.ReactNode;
        value: string | number;
    } = any
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ValueType[]>([]);
    const [initializedState, setInitializedState] = useState('notReady');
    const fetchRef = React.useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value || '').then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        if (initializedState === 'makingFirstRequest') {
            loadOptions('');
        }

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout, initializedState]);

    return (
        <Select<ValueType>
            labelInValue
            filterOption={false}
            onFocus={() => {
                if (initializedState === 'notReady') {
                    setInitializedState('makingFirstRequest');
                }
            }}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}
