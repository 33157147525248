// @ts-nocheck
import { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Card,
    Layout,
    Row,
    Col,
    Space,
    Button,
    Select,
    Checkbox,
    message,
    Popover,
    Switch,
    UploadFile,
} from 'antd';
import {
    PlusOutlined,
    QuestionCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons';

// Components
import TextArea from 'antd/lib/input/TextArea';
import UploadImage from '../../components/UploadImage/UploadImage';
import { getUrlParameter } from '../../utils';
import AdvancedSettings from './components/AdvancedSettings';

// Libraries
import Axios from 'axios';

//SCSS
import './CreateProduct.less';

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

const { Content } = Layout;

export default function CreateProductGiftCertificate() {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({ type: 'SUBSCRIPTION' });
    const [advancedSettings, setAdvancedSettings] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [personalInfoList, setPersonalInfoList] = useState();
    const [personalNameId, setPersonalNameId] = useState();
    const [personalEmailId, setPersonalEmailId] = useState();
    const [shop, setShop] = useState();
    const [productCategories, setProductCategories] = useState();
    const [subscriptions, setSubscriptions] = useState();
    const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>();
    const [form] = Form.useForm();

    useEffect(() => {
        getPersonalInfoList();
        getSubscriptions();
        getShop();

        const product_uuid = getUrlParameter('uuid');
        if (product_uuid) {
            getEditProduct(product_uuid);
        } else {
            form.setFieldsValue({
                attributes: [
                    {
                        label: 'Stærð gjafabréfs',
                        slug: 'gift_certificate_credits',
                        options: [
                            { title: '3 mánuðir', value: 3, extra_price: 5990 },
                        ],
                    },
                ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPersonalInfoList = () => {
        Axios.get('/verslun/api/personal_info_list/').then((rsp) => {
            setPersonalInfoList(rsp.data.results);
            setPersonalNameId(
                rsp.data.results.filter(
                    (info: { slug: string; id: string }) => info.slug === 'name'
                )[0].id
            );
            setPersonalEmailId(
                rsp.data.results.filter(
                    (info: { slug: string; id: string }) =>
                        info.slug === 'email'
                )[0].id
            );
        });
    };

    const getShop = () => {
        Axios.get('/verslun/api/shop/' + w.uuid).then((res) => {
            setShop(res.data);
            setLoading(false);

            // Mix því ég vil halda product categories inni en vil ekki flækja platformið með categories (allavega ekki strax)
            // Ég ákvað því að búa til "aðal vörur" sjálfkrafa
            Axios.get(
                '/verslun/api/product_category/?shop=' + res.data.id
            ).then((rsp) => {
                const categories = rsp.data.results;

                if (categories.length < 2) {
                    Axios.post('/verslun/api/create_product_category/', {
                        shop: res.data.id,
                        title: 'Aðal vörur',
                        label: 'Aðal vörur',
                    })
                        .then((rsp) => {
                            // @ts-ignore
                            setProductCategories([rsp.data]);
                        })
                        .catch((err) => {
                            message.error('Eitthvað fór úrskeiðis :/');
                        });
                } else {
                    setProductCategories(categories);
                }
            });
        });
    };

    const getSubscriptions = () => {
        Axios.get(
            `/verslun/api/product/?shop__uuid=${w.uuid}&type=SUBSCRIPTION&page_size=9999`
        ).then((rsp) => {
            setSubscriptions(rsp.data.results);
        });
    };

    const getEditProduct = (product_uuid: string) => {
        Axios.get('/verslun/api/admin_product/' + product_uuid).then((rsp) => {
            const product = rsp.data;

            form.setFieldsValue({
                ...product,
                is_podcast: !!product.podcast_rss_feed,
                price: product.price.base_price,
                main_image: parseInt(product.main_image.id),
            });

            setIsEdit(true);
            setLoading(false);
            setProduct({ ...product });
            setImageFileList([
                {
                    uid: product.main_image.id,
                    url: product.main_image.image,
                    name: 'logo',
                    status: 'done',
                },
            ]);
        });
    };

    const onImageUpload = (image_id: number | string, res_obj: any) => {
        form.setFieldsValue({
            main_image: parseInt(image_id.toString()),
        });
        // @ts-ignore
        setProduct({ ...product, main_image: res_obj });
    };

    const onFormChange = (obj, whole_obj) => {
        /* obj er key val bara með breytingunum
        dæmi: ef notandinn breytir title þá kemur {title: 'Yooo'} */

        /* whole_obj er allt formið */
        let p = product;

        if (!obj.attributes) {
            p = { ...product, ...obj };
        } else {
            p.attributes = whole_obj.attributes;

            obj.attributes.forEach((attribute, attribute_key) => {
                if (attribute.options) {
                    attribute.options.forEach((option, option_key) => {
                        if (option) {
                            const k = Object.keys(option)[0];
                            const v = option[k];
                            p.attributes[attribute_key].options[option_key][k] =
                                v;
                        }
                    });

                    attribute.slug = 'gift_certificate_credits';
                }
            });
        }

        if (obj.type === 'GIFT_CERTIFICATE') {
            p.attributes = [
                {
                    label: 'Stærð gjafabréfs',
                    type: 'SELECT',
                    slug: 'gift_certificate_credits',
                    options: [
                        { title: '3 mánuðir', extra_price: 999, value: 3 },
                    ],
                },
            ];
        }
        if (obj.type === 'SUBSCRIPTION') {
            p.attributes = [];
        }

        setProduct({ product });
    };

    /*  createOptions(attributes) { */
    /* 1 and 2 because non existing options can also be added to already existing attributes */
    /*         const new_options_1 = existing_attributes.map(attr => attr.options).filter(option => !option.shop);
        const new_options_2 = new_attributes.map(attr => attr.options);
        
        const new_options = [...new_options_1, ...new_options_2];
        const existing_options = existing_attributes.map(attr => attr.options).filter(option => option.shop)
    } */

    const createOptions = (attributes) => {
        const create_option = (option) => {
            option.shop = shop.id;
            return Axios.post('/verslun/api/option_create/', { ...option });
        };

        const get_option = (option) => {
            return new Promise((resolve) => {
                resolve({ data: option });
            });
        };

        return attributes.map((attribute) => {
            attribute.promise = new Promise((resolve) => {
                const option_promises = attribute.options?.map((option) => {
                    if (!option.shop) {
                        option.id = undefined;
                        return create_option(option);
                    } else {
                        return get_option(option);
                    }
                });

                Promise.all(
                    option_promises || [
                        new Promise((resolve) => {
                            resolve({ data: attribute });
                        }),
                    ]
                ).then(() => {
                    resolve(attribute);
                });
            });

            return attribute;

            /* if(attribute.shop){
                    const p = Axios.patch(`/verslun/api/patch_attribute/${attribute.id}/`, attribute);
                    all_promises.push(p);
                }else{
                    const p = Axios.patch(`/verslun/api/create_attribute/`, attribute);
                    all_promises.push(p);
                } */
        });
    };

    const cleanAttributes = (attrs) => {
        return new Promise((resolve, reject) => {
            const create_attribute = (attribute) => {
                attribute.shop = shop.id;
                return Axios.post('/verslun/api/attribute_create/', attribute);
            };

            const get_attribute = (attribute) => {
                return new Promise((resolve) => {
                    resolve({ data: attribute });
                });
            };

            const attribute_promises = [];
            const attributes = createOptions(attrs);

            attributes.forEach((attribute) => {
                attribute.promise.then((attribute) => {
                    if (!attribute.shop) {
                        attribute_promises.push(create_attribute(attribute));
                    } else {
                        attribute_promises.push(get_attribute(attribute));
                    }

                    if (attribute_promises.length === attributes.length) {
                        Promise.all(attribute_promises).then((rsp) => {
                            const _attributes = rsp.map((res) => res.data);
                            resolve(_attributes);
                        });
                    }
                });
            });
        });
    };

    const onFinish = (values) => {
        setLoading(true);

        const nameAndEmail = [personalNameId, personalEmailId];
        values.info_to_collect = values.info_to_collect
            ? [...nameAndEmail, ...values.info_to_collect]
            : nameAndEmail;
        values.shop = shop.id;

        if (!isEdit) values.category = productCategories[0].id;

        const p = new Promise((resolve, reject) => {
            if (values.type === 'GIFT_CERTIFICATE') {
                if (!isEdit)
                    values.attributes.push({
                        label: 'Skilaboð á gjafabréf',
                        type: 'TEXT',
                        shop: shop.id,
                        slug: 'gift_certificate_message',
                    });

                cleanAttributes(values.attributes).then((attributes) => {
                    if (isEdit) {
                        Axios.patch(
                            '/verslun/api/patch_product_category/' +
                                product.category.id +
                                '/',
                            {
                                shop: shop.id,
                                title: 'Gjafabréf',
                                label: 'Gjafabréf',
                                attributes: attributes,
                            }
                        )
                            .then((rsp) => {
                                values.category = rsp.data.id;
                                values.price = 0;
                                resolve(values);
                            })
                            .catch((err) => {
                                message.error('Eitthvað fór úrskeiðis :/');
                            });
                    } else {
                        Axios.post('/verslun/api/create_product_category/', {
                            shop: shop.id,
                            title: 'Gjafabréf',
                            label: 'Gjafabréf',
                            attributes: attributes,
                        })
                            .then((rsp) => {
                                values.category = rsp.data.id;
                                values.price = 0;
                                resolve(values);
                            })
                            .catch((err) => {
                                message.error('Eitthvað fór úrskeiðis :/');
                            });
                    }
                });
            } else {
                resolve(values);
            }
        });

        p.then((vals) => {
            if (isEdit) {
                Axios.patch(
                    '/verslun/api/basic_product/' + product.uuid + '/',
                    vals
                ).then((rsp) => {
                    message.success('Vúúúhúú, varan hefur verið uppfærð!');
                    setTimeout(() => {
                        w.top.location.href = '/';
                    }, 500);
                });
            } else {
                Axios.post('/verslun/api/create_product/', vals).then((rsp) => {
                    message.success('Vúúúhúú, varan hefur verið stofnuð!');
                    setTimeout(() => {
                        w.top.location.href = '/';
                    }, 500);
                });
            }
        });
    };

    return (
        <>
            <Layout>
                <Content>
                    <Row gutter={[10, 10]} style={{ margin: 10 }}>
                        <Col xs={24} sm={24} md={18} lg={14} xl={10} xxl={8}>
                            <Form
                                form={form}
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                onValuesChange={onFormChange}
                            >
                                <Space
                                    direction="vertical"
                                    style={{ display: 'flex' }}
                                >
                                    <Card
                                        loading={loading}
                                        title="Stofna nýja vöru"
                                        type="inner"
                                        extra={
                                            <Checkbox
                                                onChange={(val) => {
                                                    setAdvancedSettings(
                                                        val.target.checked
                                                    );
                                                }}
                                            >
                                                Ítarlegar stillingar
                                            </Checkbox>
                                        }
                                    >
                                        {advancedSettings && (
                                            <AdvancedSettings
                                                form={form}
                                                onFontChange={(
                                                    custom_font_css,
                                                    fontFamily
                                                ) => {
                                                    form.setFieldsValue({
                                                        custom_font_css,
                                                    });
                                                    setProduct({
                                                        ...product,
                                                        custom_font_css,
                                                    });
                                                }}
                                                onCustomCSSChange={(
                                                    custom_css
                                                ) => {
                                                    form.setFieldsValue({
                                                        custom_css,
                                                    });
                                                    setProduct({
                                                        ...product,
                                                        custom_css,
                                                    });
                                                }}
                                            ></AdvancedSettings>
                                        )}

                                        <Form.Item
                                            label="Titill (sýnilegt viðskiptavinum)"
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Þú verður að gefa vörunni titil.',
                                                },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="description"
                                            label="Vörulýsing"
                                        >
                                            <TextArea rows="8"></TextArea>
                                        </Form.Item>

                                        <Form.Item
                                            label="Vörumynd"
                                            name="main_image"
                                            valuePropName="fileList"
                                            getValueFromEvent={
                                                UploadImage.onChange
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Allar vörur þurfa að hafa mynd',
                                                },
                                            ]}
                                        >
                                            <UploadImage
                                                defaultFileList={imageFileList}
                                                onSuccess={onImageUpload}
                                            ></UploadImage>
                                        </Form.Item>

                                        <Form.Item
                                            label="Tegund"
                                            initialValue={'SUBSCRIPTION'}
                                            name="type"
                                        >
                                            <Select
                                                size="large"
                                                disabled={isEdit}
                                                style={{ width: '100%' }}
                                            >
                                                <Select.Option
                                                    value={'SUBSCRIPTION'}
                                                >
                                                    Áskrift (regluleg greiðsla)
                                                </Select.Option>
                                                <Select.Option
                                                    value={'GIFT_CERTIFICATE'}
                                                >
                                                    Gjafabréf
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        {form?.getFieldValue('type') ===
                                            'SUBSCRIPTION' && (
                                            <>
                                                <Form.Item
                                                    name="bypass_checkout"
                                                    label={
                                                        <>
                                                            <Popover
                                                                content={
                                                                    <>
                                                                        Ef þú
                                                                        velur já
                                                                        þá
                                                                        stofnast
                                                                        áskrifandi
                                                                        og
                                                                        kortanúmerið
                                                                        er
                                                                        vistað,
                                                                        en engin
                                                                        rukkun á
                                                                        sér
                                                                        stað.
                                                                    </>
                                                                }
                                                            >
                                                                <QuestionCircleTwoTone
                                                                    twoToneColor="#afafaf"
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </Popover>
                                                            <span
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    left: 20,
                                                                }}
                                                            >
                                                                Sleppa rukkun
                                                                sendingar við
                                                                skráningu
                                                            </span>
                                                        </>
                                                    }
                                                    valuePropName="checked"
                                                >
                                                    <Switch
                                                        checkedChildren="JÁ "
                                                        unCheckedChildren="NEI"
                                                        onChange={(checked) => {
                                                            form.setFieldsValue(
                                                                {
                                                                    bypass_checkout:
                                                                        checked,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="auto_mark_as_fullfilled"
                                                    label={
                                                        <>
                                                            <Popover
                                                                content={
                                                                    <>
                                                                        <p>
                                                                            Ef
                                                                            þú
                                                                            velur
                                                                            já
                                                                            þá
                                                                            merkist
                                                                            sendingin
                                                                            alltaf
                                                                            sjálfkrafa
                                                                            sem
                                                                            "afgreidd".
                                                                        </p>

                                                                        <p>
                                                                            Þessi
                                                                            möguleiki
                                                                            hentar
                                                                            vel
                                                                            fyrir
                                                                            þá
                                                                            sem
                                                                            þurfa
                                                                            ekki
                                                                            sérstaklega
                                                                            að
                                                                            halda
                                                                            utan
                                                                            um
                                                                            afgreiddar
                                                                            vörur.
                                                                        </p>

                                                                        <p>
                                                                            Styrkir
                                                                            (t.d
                                                                            góðgerðasamtök)
                                                                            og
                                                                            hlaðvörp
                                                                            eru
                                                                            gott
                                                                            dæmi.
                                                                        </p>
                                                                    </>
                                                                }
                                                            >
                                                                <QuestionCircleTwoTone
                                                                    twoToneColor="#afafaf"
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </Popover>
                                                            <span
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    left: 20,
                                                                }}
                                                            >
                                                                Merkja sendingu
                                                                sem afgreidda
                                                                sjálfkrafa.
                                                            </span>
                                                        </>
                                                    }
                                                    valuePropName="checked"
                                                >
                                                    <Switch
                                                        checkedChildren="JÁ "
                                                        unCheckedChildren="NEI"
                                                        onChange={(checked) => {
                                                            form.setFieldsValue(
                                                                {
                                                                    auto_mark_as_fullfilled:
                                                                        checked,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}

                                        {form?.getFieldValue('type') ===
                                            'GIFT_CERTIFICATE' && (
                                            <>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <Popover
                                                                content={
                                                                    <>
                                                                        Ef þú
                                                                        velur
                                                                        fleiri
                                                                        en eina
                                                                        vöru þá
                                                                        fær
                                                                        gjafaþegi
                                                                        að velja
                                                                        þegar
                                                                        hann/hún
                                                                        virkjar
                                                                        gjafabréfið
                                                                        sitt.
                                                                    </>
                                                                }
                                                                title="Gjafabréf gildir fyrir"
                                                            >
                                                                <QuestionCircleTwoTone
                                                                    twoToneColor="#afafaf"
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </Popover>
                                                            <span
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    left: 20,
                                                                }}
                                                            >
                                                                Gjafabréf gildir
                                                                fyrir
                                                            </span>
                                                        </>
                                                    }
                                                    name="gift_certificate_valid_products"
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        size="large"
                                                        notFoundContent={
                                                            'Engin áskrift fannst. Stofnaðu fyrst áskrift svo þú getir valið fyrir hvaða áskriftir þetta gjafabréf gildir.'
                                                        }
                                                    >
                                                        {subscriptions?.map(
                                                            (
                                                                subscription,
                                                                key
                                                            ) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={
                                                                            key
                                                                        }
                                                                        value={
                                                                            subscription.id
                                                                        }
                                                                    >
                                                                        {
                                                                            subscription.title
                                                                        }
                                                                    </Select.Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>

                                                <Form.List name="attributes">
                                                    {(
                                                        fields,
                                                        { add, remove }
                                                    ) => {
                                                        const form_attributes =
                                                            form?.getFieldValue(
                                                                'attributes'
                                                            );
                                                        return (
                                                            <Space direction="vertical">
                                                                {fields.map(
                                                                    (
                                                                        field,
                                                                        field_key
                                                                    ) => {
                                                                        return form_attributes[
                                                                            field_key
                                                                        ]
                                                                            .slug !==
                                                                            'gift_certificate_message' ? (
                                                                            <div
                                                                                key={
                                                                                    field_key
                                                                                }
                                                                                className="attribute_block"
                                                                            >
                                                                                <h5>
                                                                                    Eigindi
                                                                                </h5>
                                                                                <>
                                                                                    <Form.Item
                                                                                        {...field}
                                                                                        name={[
                                                                                            field.name,
                                                                                            'label',
                                                                                        ]}
                                                                                        fieldKey={[
                                                                                            field.fieldKey,
                                                                                            'label',
                                                                                        ]}
                                                                                        label="Titill á eigindi"
                                                                                    >
                                                                                        <Input
                                                                                            size="large"
                                                                                            placeholder="Titill"
                                                                                        />
                                                                                    </Form.Item>

                                                                                    {/* <MinusCircleOutlined
                                                                    onClick={() => {
                                                                        remove(field.name);
                                                                    }}
                                                                /> */}
                                                                                </>

                                                                                {
                                                                                    <Form.List
                                                                                        name={[
                                                                                            field.name,
                                                                                            'options',
                                                                                        ]}
                                                                                    >
                                                                                        {(
                                                                                            options,
                                                                                            {
                                                                                                add,
                                                                                                remove,
                                                                                            }
                                                                                        ) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    {options.map(
                                                                                                        (
                                                                                                            option,
                                                                                                            option_key
                                                                                                        ) => (
                                                                                                            <Space
                                                                                                                className="create_nested_option"
                                                                                                                key={
                                                                                                                    option_key
                                                                                                                }
                                                                                                                align="start"
                                                                                                            >
                                                                                                                <Form.Item
                                                                                                                    {...option}
                                                                                                                    name={[
                                                                                                                        option.name,
                                                                                                                        'title',
                                                                                                                    ]}
                                                                                                                    fieldKey={[
                                                                                                                        option.fieldKey,
                                                                                                                        'title',
                                                                                                                    ]}
                                                                                                                    label="Titill"
                                                                                                                >
                                                                                                                    <Input
                                                                                                                        size="large"
                                                                                                                        placeholder="Titill"
                                                                                                                    />
                                                                                                                </Form.Item>

                                                                                                                <Form.Item
                                                                                                                    {...option}
                                                                                                                    name={[
                                                                                                                        option.name,
                                                                                                                        'value',
                                                                                                                    ]}
                                                                                                                    fieldKey={[
                                                                                                                        option.fieldKey,
                                                                                                                        'value',
                                                                                                                    ]}
                                                                                                                    label={
                                                                                                                        <>
                                                                                                                            <Popover
                                                                                                                                content={
                                                                                                                                    <>
                                                                                                                                        <p>
                                                                                                                                            Inneignin
                                                                                                                                            lækkar
                                                                                                                                            um
                                                                                                                                            1
                                                                                                                                        </p>{' '}
                                                                                                                                        <p>
                                                                                                                                            í
                                                                                                                                            hvert
                                                                                                                                            skipti
                                                                                                                                            sem
                                                                                                                                            gjafaþegi
                                                                                                                                            fær
                                                                                                                                            sendingu
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                                title="Inneign"
                                                                                                                            >
                                                                                                                                <QuestionCircleTwoTone
                                                                                                                                    twoToneColor="#afafaf"
                                                                                                                                    style={{
                                                                                                                                        position:
                                                                                                                                            'absolute',
                                                                                                                                        fontSize: 16,
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </Popover>{' '}
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    position:
                                                                                                                                        'relative',
                                                                                                                                    left: 20,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {' '}
                                                                                                                                Inneign
                                                                                                                            </span>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Input
                                                                                                                        size="large"
                                                                                                                        placeholder="Inneign"
                                                                                                                    />
                                                                                                                </Form.Item>

                                                                                                                <Form.Item
                                                                                                                    {...option}
                                                                                                                    name={[
                                                                                                                        option.name,
                                                                                                                        'extra_price',
                                                                                                                    ]}
                                                                                                                    fieldKey={[
                                                                                                                        option.fieldKey,
                                                                                                                        'extra_price',
                                                                                                                    ]}
                                                                                                                    label="Verð"
                                                                                                                >
                                                                                                                    <Input
                                                                                                                        size="large"
                                                                                                                        placeholder="Verð"
                                                                                                                    />
                                                                                                                </Form.Item>

                                                                                                                {options.length >
                                                                                                                    1 && (
                                                                                                                    <CloseCircleTwoTone
                                                                                                                        twoToneColor="red"
                                                                                                                        style={{
                                                                                                                            fontSize: 30,
                                                                                                                            opacity: 0.3,
                                                                                                                        }}
                                                                                                                        onClick={() => {
                                                                                                                            remove(
                                                                                                                                option.name
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                            </Space>
                                                                                                        )
                                                                                                    )}

                                                                                                    <Form.Item>
                                                                                                        <Button
                                                                                                            type="dashed"
                                                                                                            onClick={() => {
                                                                                                                add();
                                                                                                            }}
                                                                                                        >
                                                                                                            <PlusOutlined />{' '}
                                                                                                            Bæta
                                                                                                            við
                                                                                                            valmöguleika
                                                                                                        </Button>
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            );
                                                                                        }}
                                                                                    </Form.List>
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                key={
                                                                                    field_key
                                                                                }
                                                                            ></div>
                                                                        );
                                                                    }
                                                                )}

                                                                {/* <hr></hr>

                                                    <Form.Item>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                add();
                                                            }}
                                                        >
                                                            <PlusOutlined /> Bæta við eigindi
                                                        </Button>
                                                    </Form.Item> */}
                                                            </Space>
                                                        );
                                                    }}
                                                </Form.List>

                                                <Form.Item
                                                    label={
                                                        <>
                                                            <Popover
                                                                content={
                                                                    <>
                                                                        <p>
                                                                            Skilaboðin
                                                                            sem
                                                                            gjafaþegi
                                                                            sér
                                                                            eftir
                                                                            að
                                                                            hafa
                                                                            virkjað
                                                                            gjafabréfið
                                                                            sitt.
                                                                        </p>
                                                                        <br></br>
                                                                        <p>
                                                                            <strong>
                                                                                Dæmi:
                                                                            </strong>{' '}
                                                                            Til
                                                                            hamingju,
                                                                            gjafabréfið
                                                                            þitt
                                                                            hefur
                                                                            verið
                                                                            virkjað!
                                                                            Þú
                                                                            mátt
                                                                            búast
                                                                            við
                                                                            fyrstu
                                                                            sendingunni
                                                                            þinni
                                                                            í
                                                                            næsta
                                                                            mánuði.
                                                                        </p>
                                                                    </>
                                                                }
                                                                title="Skilaboð til gjafaþega"
                                                            >
                                                                <QuestionCircleTwoTone
                                                                    twoToneColor="#afafaf"
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </Popover>
                                                            <span
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    left: 10,
                                                                }}
                                                            >
                                                                Skilaboð til
                                                                gjafaþega
                                                            </span>
                                                        </>
                                                    }
                                                    name="gift_certificate_activated_message"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Þú verður að útvega skilaboð til gjafaþega.',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows="5"></TextArea>
                                                </Form.Item>
                                            </>
                                        )}

                                        {form?.getFieldValue('type') !==
                                            'GIFT_CERTIFICATE' && (
                                            <Form.Item
                                                label="Verð"
                                                name="price"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Þú verður að gefa vörunni verð.',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    size="large"
                                                    autoComplete="off"
                                                />
                                            </Form.Item>
                                        )}

                                        {personalInfoList && (
                                            <>
                                                <Form.Item
                                                    label="Hvaða upplýsingar vilt þú frá viðskiptavinum?"
                                                    name="basic_info_to_collect"
                                                    initialValue={[
                                                        personalNameId,
                                                        personalEmailId,
                                                    ]}
                                                >
                                                    <Checkbox.Group
                                                        disabled={true}
                                                        options={[
                                                            {
                                                                label: 'Nafn',
                                                                value: personalNameId,
                                                            },
                                                            {
                                                                label: 'Netfang',
                                                                value: personalEmailId,
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="info_to_collect"
                                                    initialValue={
                                                        product?.info_to_collect
                                                    }
                                                >
                                                    <Checkbox.Group
                                                        options={personalInfoList
                                                            .filter(
                                                                (option) => {
                                                                    const isName =
                                                                        option.slug ===
                                                                        'name';
                                                                    const isEmail =
                                                                        option.slug ===
                                                                        'email';

                                                                    return isName ||
                                                                        isEmail
                                                                        ? 0
                                                                        : -1;
                                                                }
                                                            )
                                                            .map((option) => {
                                                                const required =
                                                                    option.name ===
                                                                        'name' ||
                                                                    option.name ===
                                                                        'email';

                                                                let obj = {
                                                                    label: option.name,
                                                                    value: option.id,
                                                                };

                                                                if (required)
                                                                    obj = {
                                                                        checked:
                                                                            true,
                                                                        disabled:
                                                                            true,
                                                                        ...obj,
                                                                    };

                                                                return obj;
                                                            })}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}

                                        <Form.Item>
                                            <Button
                                                className="btn-success"
                                                block
                                                size="large"
                                                loading={loading}
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Vista
                                            </Button>
                                        </Form.Item>
                                    </Card>
                                </Space>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}
