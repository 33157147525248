import { useEffect, useState } from 'react';
import { Content, ContentAsset } from '../../../interfaces/content';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { Tabs } from 'antd';
import AssetStats from './AssetStats';

type Props = {
    content: Content;
    shopUUID: string;
};

type OverallStats = {
    averageWatchTime: string;
    totalViewers: string;
    viewersPeak: string;
    viewersPeakDate: string;
    totalWatchTime: string;
};

export default function ContentStats({ content, shopUUID }: Props) {
    const [overallStats, setOverallStats] = useState<OverallStats>();
    const [loading, setLoading] = useState(true);
    const [repeatMediaAssets, setRepeatMediaAssets] =
        useState<ContentAsset[]>();

    useEffect(() => {
        setRepeatMediaAssets(
            content.assets.filter((a) => a.type === 'REPEAT_MEDIA')
        );
        const firstAsset = content.assets.find(
            (a) => a.type === 'REPEAT_MEDIA'
        );

        axios
            .post('/efni/get_repeat_media_video_stats/', {
                video_id: firstAsset?.repeat_media_asset?.asset_id || '',
                shop_uuid: shopUUID,
            })
            .then((res) => {
                setOverallStats(res.data[0]);
                setLoading(false);
            });

        return () => {
            setOverallStats(undefined);
            setLoading(true);
        };
    }, [content]);

    return (
        <div>
            <Tabs defaultActiveKey="1">
                {repeatMediaAssets?.map((asset, index) => (
                    <Tabs.TabPane
                        tab={asset.title}
                        key={(index + 1).toString()}
                    >
                        <AssetStats
                            asset={asset}
                            shopUUID={shopUUID}
                        ></AssetStats>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
}
