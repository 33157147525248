import { useEffect, useState } from 'react';
import { message, Card, Table, Badge, Button, Dropdown, Menu } from 'antd';
import Axios from 'axios';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';

import Shop from '../../../interfaces/shop';
import Order, { OrderProduct } from '../../../interfaces/order';

export default function OrderBox(props: { shop: Shop }) {
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Axios.get(
            `/verslun/api/orders/?shop__uuid=${props.shop.uuid}&page_size=10&ordering=-created&is_fulfilled=false`
        ).then((rsp) => {
            setOrders(rsp.data.results);

            setLoading(false);
        });
    }, []);

    const markOrderAsFulfilled = (order: Order, bool: boolean) => {
        Axios.patch(`/verslun/api/order/${order.uuid}/`, {
            is_fulfilled: bool,
        })
            .then((rsp) => {
                setOrders([
                    ...orders.filter((o) => o.uuid !== order.uuid),
                    { ...order, is_fulfilled: bool },
                ]);
                message.success('Tókst!');
            })
            .catch((err) => {
                message.error('Úps, eitthvað fór úrskeiðis :/');
            });
    };

    return (
        <Card
            className="statistics_container"
            title={
                <>
                    <img
                        style={{ height: 45 }}
                        src="/static/images/svg/shopping-cart.svg"
                        alt=""
                    />{' '}
                    Pantanir
                </>
            }
            extra={
                <Button type="primary">
                    <a href={'/pantanir/' + props.shop.uuid + '/'}>
                        Stjórnborð
                    </a>
                </Button>
            }
        >
            {orders.length > 0 && loading === false ? (
                <Table
                    className="white_table"
                    style={{ width: '100%' }}
                    loading={loading}
                    pagination={false}
                    locale={{
                        emptyText: 'Engar pantanir fundust',
                    }}
                    size="small"
                    showHeader={false}
                    columns={[
                        {
                            render: (order) => {
                                return (
                                    <div>
                                        <a
                                            className="capitalize"
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.65)',
                                            }}
                                            href={`/vidskiptavinur/${order.customer.uuid}`}
                                        >
                                            {order.customer.name}
                                        </a>
                                        <div
                                            style={{
                                                color: '#b1b1b1',
                                                fontSize: 11,
                                                marginBottom: 5,
                                            }}
                                        >
                                            {moment(order.created).format(
                                                'lll'
                                            )}
                                        </div>

                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: '#b1b1b1',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: 150,
                                            }}
                                        >
                                            {order.products
                                                .map(
                                                    (product: OrderProduct) => {
                                                        return product.product
                                                            .title;
                                                    }
                                                )
                                                .join(', ')}
                                        </div>
                                    </div>
                                );
                            },
                        },

                        {
                            align: 'right',
                            render: (order) => {
                                return (
                                    <div style={{ display: 'flex' }}>
                                        <Dropdown
                                            menu={{
                                                items: [
                                                    {
                                                        icon: (
                                                            <Badge status="success" />
                                                        ),
                                                        label: 'Merkja sem uppfyllt',
                                                        key: 'fulfilled',
                                                        onClick: () => {
                                                            markOrderAsFulfilled(
                                                                order,
                                                                true
                                                            );
                                                        },
                                                    },
                                                    {
                                                        onClick: () => {
                                                            markOrderAsFulfilled(
                                                                order,
                                                                false
                                                            );
                                                        },
                                                        icon: (
                                                            <Badge status="error" />
                                                        ),
                                                        label: 'Merkja sem óuppfyllt',
                                                        key: 'unfulfilled',
                                                    },
                                                ],
                                            }}
                                        >
                                            <Button size="small">
                                                <Badge
                                                    status={
                                                        order.is_fulfilled
                                                            ? 'success'
                                                            : 'error'
                                                    }
                                                />{' '}
                                                <span
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    {order.is_fulfilled
                                                        ? ' Uppfyllt'
                                                        : ' Óuppfyllt'}{' '}
                                                </span>
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                );
                            },
                        },
                    ]}
                    rowKey={(order) => order.uuid}
                    dataSource={orders.sort((a, b) => {
                        return a.created < b.created ? 0 : -1;
                    })}
                />
            ) : (
                <div>
                    <img
                        style={{ maxWidth: '70%' }}
                        src="/static/images/svg/task_done.svg"
                        alt="Allt komið"
                    />
                    <br />
                    <div>Allar pantanir eru afgreiddar</div>
                </div>
            )}
        </Card>
    );
}
