export default {
    lang: {
        locale: 'is_IS',
        placeholder: 'Veldu dags',
        rangePlaceholder: ['Upphafsdagur', 'Lokadagur'],
        today: 'Í dag',
        now: 'Núna',
        backToToday: 'Velja daginn í dag',
        ok: 'Staðfesta',
        clear: 'Tæma',
        month: 'Mánuður',
        year: 'Ár',
        timeSelect: 'Veldu tíma',
        dateSelect: 'Veldu dagsetningu',
        monthSelect: 'Veldu mánuð',
        yearSelect: 'Veldu ár',
        decadeSelect: 'Veldu áratug',
        yearFormat: 'YYYY',
        dateFormat: 'D/M/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: 'Fyrri mánuður',
        nextMonth: 'Næsti mánuður',
        previousYear: 'Síðasta ár',
        nextYear: 'Næsta ár',
        previousDecade: 'Síðasti áratugur',
        nextDecade: 'Næsti áratugur',
        previousCentury: 'Síðasta öld',
        nextCentury: 'Næsta öld',
    },
    timePickerLocale: {
        placeholder: 'Veldu tíma',
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
};
