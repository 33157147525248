import { useRef } from 'react';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Layout, Card, Button, Space, Badge, Descriptions, Tabs } from 'antd';

//Components
import FakeDashboard from '../../components/Dashboard/FakeDashboard';

// Libraries
import 'moment/locale/is';
import SVG from 'react-inlinesvg';

// Less
import './Frontpage.less';
import brandingConfig from '../../utils/brandingConfig';
import RepeatFlow from './RepeatFlow/RepeatFlow';
import Header from '../../components/Header/Header';
import TabPane from 'antd/lib/tabs/TabPane';

interface IWindow extends Window {
    me?: string;
}

const w: IWindow = window;

export default function Frontpage() {
    return (
        <ParallaxProvider>
            <div id="frontpageSection">
                <Header></Header>
                <div id="frontpage_hero_section">
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                margin: 50,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <SVG
                                    id="hero_logo"
                                    baseURL="/"
                                    cacheRequests={true}
                                    description="Logo"
                                    src={'/static/images/logo/logo.svg'}
                                    title="Logo"
                                    key="hero_logo"
                                    uniqueHash="hero_logo"
                                    uniquifyIDs={false}
                                    fill={brandingConfig.primaryColor}
                                />
                                <div>
                                    <h1
                                        style={{
                                            color: 'white',
                                            fontSize: 20,
                                            marginTop: 5,
                                        }}
                                    >
                                        Tæknilausnir fyrir endurteknar greiðslur
                                    </h1>
                                </div>

                                <div className="text-center">
                                    <Space>
                                        {!w.me ? (
                                            <Button
                                                className="btn-primary"
                                                size="large"
                                                type="primary"
                                                style={{
                                                    padding: '30px 40px',
                                                    lineHeight: 0,
                                                    fontSize: '20px',
                                                    backgroundColor:
                                                        'rgba(120,120,120, 0.37)',
                                                    marginTop: 20,
                                                }}
                                            >
                                                <a
                                                    style={{
                                                        color: 'rgb(195 194 194)',
                                                    }}
                                                    href="/nyskra/"
                                                >
                                                    Stofna aðgang
                                                </a>
                                            </Button>
                                        ) : (
                                            <Button
                                                className="btn-primary"
                                                size="large"
                                                type="primary"
                                                style={{
                                                    padding: '30px 40px',
                                                    lineHeight: 0,
                                                    fontSize: '20px',
                                                    backgroundColor:
                                                        'rgba(120,120,120, 0.37)',
                                                    marginTop: 20,
                                                }}
                                            >
                                                <a
                                                    style={{
                                                        color: 'rgb(195 194 194)',
                                                    }}
                                                    href="/stjornbord/"
                                                >
                                                    Fara á stjórnborð
                                                </a>
                                            </Button>
                                        )}
                                    </Space>
                                </div>
                            </div>

                            <Parallax
                                style={{
                                    position: 'relative',
                                }}
                                speed={85}
                            >
                                <div className="text-center">
                                    <img
                                        alt="Dashboard screenshot"
                                        style={{
                                            borderRadius: 10,
                                            maxWidth: 1600,
                                            width: '100%',
                                            boxShadow:
                                                'black 0px 0px 80px 10px',
                                        }}
                                        src="/static/images/screenshots/dashboard.jpg"
                                    />
                                </div>
                            </Parallax>
                        </div>

                        {/* <div
                        style={{
                            marginTop: 150,
                            textAlign: 'center',

                            width: '100%',
                            height: '70vh',
                            pointerEvents: 'none',
                            position: 'relative',
                            top: 0,
                            maxWidth: 1200,
                            margin: '0 auto',
                        }}
                    >
                    </div> */}

                        {/* <div className="text-center">
                        <Space>
                            {!w.me ? (
                                <></>
                            ) : (
                                <Button
                                    className="btn-primary"
                                    size="large"
                                    type="primary"
                                    style={{
                                        padding: '30px 40px',
                                        lineHeight: 0,
                                        fontSize: '23px',
                                    }}
                                >
                                    <a
                                        style={{
                                            color: '#fff',
                                            fontFamily: 'Space Grotesk',
                                        }}
                                        href="/stjornbord/"
                                    >
                                        Fara á stjórnborð
                                    </a>
                                </Button>
                            )}
                        </Space>
                    </div> */}
                    </>
                </div>

                <div
                    style={{
                        minHeight: '80vh',
                        display: 'flex',
                        // justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <h2>Komdu í hóp ánægðra viðskiptavina</h2>

                    <h3
                        style={{
                            marginBottom: 100,
                        }}
                    >
                        Þessir aðilar hafa valið Repeat
                    </h3>

                    <Tabs
                        defaultActiveKey="1"
                        centered
                        style={{
                            color: 'white',
                            maxWidth: 1400,
                            margin: '0 auto',
                            width: '90%',
                        }}
                    >
                        <TabPane tab="Vörur" key="1">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/geosilica.png"
                                        alt=""
                                    />

                                    <div>Geo Silica</div>
                                    <div className="customerUrl">
                                        geosilica.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/feel.png"
                                        alt=""
                                    />

                                    <div>Feel Iceland</div>
                                    <div className="customerUrl">
                                        feeliceland.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/cbdrvk.png"
                                        alt=""
                                    />
                                    <div>CBD Reykjavík</div>
                                    <div className="customerUrl">cbdrvk.is</div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/happyhydrate.png"
                                        alt=""
                                    />

                                    <div>Happy Hydrate</div>
                                    <div className="customerUrl">
                                        happyhydrate.is
                                    </div>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Hlaðvörp" key="2">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/tebodid.png"
                                        alt=""
                                    />

                                    <div>Teboðið</div>

                                    <div className="customerUrl">
                                        tebodid.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/illverk.png"
                                        alt=""
                                    />
                                    <div>Illverk</div>

                                    <div className="customerUrl">
                                        illverk.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/solvi.png"
                                        alt=""
                                    />
                                    <div>Podcast með Sölva Tryggva</div>
                                    <div className="customerUrl">
                                        solvitryggva.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/betkastid.png"
                                        alt=""
                                    />

                                    <div>Betkastið</div>

                                    <div className="customerUrl">
                                        betkastid.is
                                    </div>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Netnámskeið" key="11">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/vignir.png"
                                        alt=""
                                    />

                                    <div>Vignir Vatnar</div>

                                    <div className="customerUrl">
                                        vignirvatnar.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/eldabaka.png"
                                        alt=""
                                    />

                                    <div>Eldabaka</div>

                                    <div className="customerUrl">
                                        eldabaka.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/sarapals.png"
                                        alt=""
                                    />
                                    <div>Frelsi frá kvíða</div>

                                    <div className="customerUrl">
                                        frelsifrakvida.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/jorthadild.png"
                                        alt=""
                                    />
                                    <div>Jörth - Aðild</div>
                                    <div className="customerUrl">
                                        jorth.is/adild
                                    </div>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Góðgerðamál" key="3">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/kvennaathvarf.png"
                                        alt=""
                                    />

                                    <div>Kvennaathvarfið</div>

                                    <div className="customerUrl">
                                        kvennaathvarf.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/pieta.png"
                                        alt=""
                                    />

                                    <div>Píeta samtökin</div>

                                    <div className="customerUrl">pieta.is</div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/herinn.png"
                                        alt=""
                                    />
                                    <div>Hjálpræðisherinn</div>

                                    <div className="customerUrl">herinn.is</div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/adhd.png"
                                        alt=""
                                    />
                                    <div>ADHD samtökin</div>
                                    <div className="customerUrl">adhd.is</div>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Þjálfun og heilsa" key="4">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/fjarthjalfun.png"
                                        alt=""
                                    />

                                    <div>Fjarþjálfun.is</div>

                                    <div className="customerUrl">
                                        fjarthjalfun.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/annaeiriks.png"
                                        alt=""
                                    />

                                    <div>Anna Eiríks</div>

                                    <div className="customerUrl">
                                        annaeiriks.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/yogashala.png"
                                        alt=""
                                    />
                                    <div>Yoga Shala</div>

                                    <div className="customerUrl">
                                        yogashala.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/empower.png"
                                        alt=""
                                    />
                                    <div>Empower by dóttir</div>
                                    <div className="customerUrl">
                                        empowerbydottir.com
                                    </div>
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Ýmislegt" key="ymislegt">
                            <div className="repeatCustomerBoxes">
                                <div>
                                    <img
                                        src="/static/images/screenshots/1.png"
                                        alt=""
                                    />

                                    <div>Bókabeitan</div>
                                    <div className="customerUrl">
                                        bokabeitan.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/roasters.png"
                                        alt=""
                                    />

                                    <div>Reykjavík Roasters</div>
                                    <div className="customerUrl">
                                        reykjavikroasters.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/happatreyjur.png"
                                        alt=""
                                    />
                                    <div>Happatreyjur</div>
                                    <div className="customerUrl">
                                        happatreyjur.is
                                    </div>
                                </div>

                                <div>
                                    <img
                                        src="/static/images/screenshots/bilstolaleigan.png"
                                        alt=""
                                    />

                                    <div>Bílstólaleigan</div>
                                    <div className="customerUrl">
                                        bilstolaleigan.is
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>

                    <div
                        style={{
                            marginTop: 30,
                            textAlign: 'center',
                            color: '#808080',
                            fontSize: 20,
                            marginBottom: 150,
                        }}
                    >
                        ... ásamt fjölmörgum öðrum
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        // background: 'purple',
                    }}
                >
                    <div
                        style={{
                            maxWidth: 1400,
                            width: '100%',
                        }}
                    >
                        <h2 className="text-center">Einfalt viðmót</h2>

                        <FakeDashboard></FakeDashboard>

                        <div
                            style={{
                                pointerEvents: 'none',
                                height: '60vh',
                            }}
                        >
                            <RepeatFlow></RepeatFlow>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        minHeight: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',

                        padding: 20,
                    }}
                >
                    <div>
                        <h2>Teymið</h2>
                        <div className="staffContainer">
                            <div className="staffImage">
                                <img
                                    src="/static/images/staff/oli.png"
                                    alt="Óli Tómas"
                                />
                                <div className="employeeName">Óli Tómas</div>
                                <div>Tæknimál / Rekstur</div>
                                <div>olitomas@repeat.is</div>
                            </div>

                            {/* <div className='staffImage'>
                                    <img src='/static/images/staff/fridgeir.png' alt='Friðgeir' />
                                    <div className='employeeName'>Friðgeir</div>
                                    <div>Sölumál</div>
                                    <div>fridgeir@repeat.is</div>
                                    <div>Sími: 790 9775</div>
                                </div> */}

                            <div className="staffImage">
                                <img
                                    src="/static/images/staff/jodis.png"
                                    alt="Jódís"
                                />
                                <div className="employeeName">Jódís</div>
                                <div>Markaðsmál</div>
                                <div>jodis@repeat.is</div>
                            </div>

                            <div className="staffImage">
                                <img
                                    src="/static/images/staff/fridgeir.png"
                                    alt="Fridgeir"
                                />
                                <div className="employeeName">Friðgeir</div>
                                <div>Sölustjóri</div>
                                <div>fridgeir@repeat.is</div>
                            </div>

                            <div className="staffImage">
                                <img
                                    src="/static/images/staff/snorri.png"
                                    alt="Snorri"
                                />
                                <div className="employeeName">Snorri Már</div>
                                <div>Rekstur / Fjármál</div>
                                <div>snorri@repeat.is</div>
                            </div>

                            <div className="staffImage">
                                <img
                                    src="/static/images/staff/teddi.png"
                                    alt="Theódór"
                                />
                                <div className="employeeName">Theódór</div>
                                <div>Rekstur / Tæknimál</div>
                                <div>teddi@repeat.is</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: 20,
                        minHeight: '50vh',
                    }}
                >
                    <div>
                        <h2>Verðskrá</h2>
                        <div className="pricingCards">
                            <Card className="shadowCard pricingCard">
                                <div className="pricingIntro">
                                    <div>Fyrir þá sem eru með undir</div>
                                    <div>250.000 kr á mán í veltu</div>
                                </div>
                                <Descriptions bordered={true} column={1}>
                                    <Descriptions.Item label="Mánaðargjald">
                                        5.900 kr + vsk
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Færslugjald">
                                        3.5%
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>

                            <Card className="shadowCard pricingCard">
                                <div className="pricingIntro">
                                    <div>Fyrir þá sem eru með yfir</div>
                                    <div>580.000 kr á mán í veltu</div>
                                </div>
                                <Descriptions bordered={true} column={1}>
                                    <Descriptions.Item label="Mánaðargjald">
                                        12.900 kr + vsk
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Færslugjald">
                                        2%
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>

                            <Card className="shadowCard pricingCard">
                                <div className="pricingIntro">
                                    <div>Fyrir þá sem eru með yfir</div>
                                    <div>1.700.000 kr á mán í veltu</div>
                                </div>
                                <Descriptions bordered={true} column={1}>
                                    <Descriptions.Item label="Mánaðargjald">
                                        39.900 kr + vsk
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Færslugjald">
                                        1%
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="footer white-color">
                    <div className="footerWrapper">
                        <div className="companyInfo">
                            <div>Repeat ehf</div>
                            <div>Kennitala: 440521-2660</div>
                            <div>Suðurlandsbraut 4A</div>
                            <div>108 Reykjavík</div>
                            <div>Sími: 461 2000</div>
                            <div>repeat@repeat.is</div>
                        </div>

                        <div className="text-center" id="footerLogoContainer">
                            <div>
                                <SVG
                                    id="footer_logo"
                                    style={{
                                        height: '70px',
                                        padding: 10,
                                    }}
                                    baseURL="/"
                                    cacheRequests={true}
                                    description="Logo"
                                    src={'/static/images/logo/logo.svg'}
                                    title="Logo"
                                    key="header_logo"
                                    uniqueHash="header_logo"
                                    uniquifyIDs={false}
                                    fill="#fff"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="payment_providers">
                                <SVG
                                    id="salt_logo"
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="Teya"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /<path/g,
                                            '<path fill="#fff" '
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/svg/teya.svg'}
                                    title="Teya logo"
                                    key={'teya_logo'}
                                    uniqueHash={'teya_logo'}
                                    uniquifyIDs={true}
                                    style={{ marginBottom: 20 }}
                                />

                                <SVG
                                    id="straumur_logo"
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="straumur"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /<path/g,
                                            '<path fill="#fff" '
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/svg/straumur.svg'}
                                    title="straumur logo"
                                    key={'straumur_logo'}
                                    uniqueHash={'straumur_logo'}
                                    uniquifyIDs={true}
                                    style={{ marginBottom: 20 }}
                                />

                                <SVG
                                    id="valitor_logo"
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="Valitor logo"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /<path/g,
                                            '<path fill="#fff" '
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/svg/valitor.svg'}
                                    title="Valitor logo"
                                    key={'valitor_logo'}
                                    uniqueHash={'valitor_logo'}
                                    uniquifyIDs={true}
                                    style={{ marginBottom: 20 }}
                                />

                                <SVG
                                    id="verifone_logo"
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="Verifone logo"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /<path/g,
                                            '<path fill="#fff" '
                                        );

                                        code = code.replace(
                                            /<circle/g,
                                            '<circle fill="#fff" '
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/svg/verifone.svg'}
                                    title="Verfifone logo"
                                    key={'verifone_logo'}
                                    uniqueHash={'verifone_logo'}
                                    uniquifyIDs={true}
                                />

                                <SVG
                                    id="fjarflaedi_logo"
                                    baseURL="/"
                                    cacheRequests={false}
                                    description="Fjárflæði logo"
                                    preProcessor={(code) => {
                                        code = code.replace(
                                            /<path/g,
                                            '<path fill="#fff" '
                                        );
                                        return code;
                                    }}
                                    src={'/static/images/svg/fjarflaedi.svg'}
                                    title="Fjárflæði logo"
                                    key={'fjarflaedi_logo'}
                                    uniqueHash={'fjarflaedi_logo'}
                                    uniquifyIDs={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <ParallaxLayer offset={2.5} speed={0.5}> */}
                {/* <HeroSection>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h2>Einfaldlega virkar!</h2>
                        <h3>Óháð kerfi</h3>

                        <div className="global_logos">
                            <div className="global_logo">
                                <img
                                    src="/static/images/global_logos/wordpress.png"
                                    alt="Wordpress"
                                />
                            </div>

                            <div className="global_logo">
                                <img
                                    src="/static/images/global_logos/shopify.png"
                                    alt="Shopify"
                                />
                            </div>

                            <div className="global_logo">
                                <img
                                    src="/static/images/global_logos/wix.png"
                                    alt="Wix"
                                />
                            </div>

                            <div className="global_logo">
                                <img
                                    src="/static/images/global_logos/squarepace.png"
                                    alt="Squarespace"
                                />
                            </div>
                        </div>
                    </div>
                </HeroSection> */}
                {/* </ParallaxLayer> */}
            </div>
        </ParallaxProvider>
    );
}
