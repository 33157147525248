import React from 'react';
import { ShopifyCartResponse } from './interfaces/repeatCheckoutInterfaces';
import { displayCurrencyPrice } from '../../utils';
import { Typography } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import translate from '../../translate/translate';
const { Paragraph } = Typography;

type Props = {
    line: ShopifyCartResponse;
    language: 'is' | 'en';
};

export default function ShopifyProductCard({ line, language }: Props) {
    const node = line.node;
    let intro = node.merchandise.product.description;

    //Sometimes the description is a html string. If so then we need the first 100 characters
    const isHtmlRegex = /<[a-z][\s\S]*>/i;
    const isHtml = isHtmlRegex.test(intro);

    if (isHtml) {
        try {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(intro, 'text/html');
            intro = htmlDoc.body.textContent || '';

            if (intro.length > 900) {
                intro = intro.slice(0, 900);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div key={node.id} className="productInformation oneTimePurchase">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <img
                    style={{
                        width: 80,
                        marginBottom: 20,
                    }}
                    src={node.merchandise.image.url}
                    alt="Vörumynd"
                />

                <div style={{ color: 'gray', fontSize: 14 }}>
                    <div>
                        <ShoppingCartOutlined />{' '}
                        {translate('one_time_purchase', language)}
                    </div>
                </div>
            </div>

            <div className="mb20">
                <div className="productTitle">
                    {node.merchandise.title.toLowerCase() === 'default title'
                        ? node.merchandise.product.title
                        : node.merchandise.title}
                </div>

                <div className="productPriceDetails">
                    <strong>{node.quantity}</strong> x{' '}
                    {displayCurrencyPrice(
                        parseFloat(node.merchandise.priceV2.amount),
                        node.merchandise.priceV2.currencyCode
                    )}
                </div>

                <div>
                    <Paragraph
                        className="productDescription"
                        ellipsis={{
                            rows: 4,
                            // @ts-ignore
                            // suffix: (
                            //     <span key={line.merchandise.}>
                            //         <Button
                            //             size="small"
                            //             type="link"
                            //             style={{
                            //                 color: 'gray',
                            //             }}
                            //             onClick={() => {
                            //                 setProductDetailsModal(
                            //                     product.details
                            //                 );
                            //             }}
                            //         >
                            //             {translate(
                            //                 'read_more',
                            //                 selectedLanguage
                            //             )}
                            //         </Button>
                            //     </span>
                            // ),
                        }}
                    >
                        {ReactHtmlParser(intro) as React.ReactNode}
                    </Paragraph>
                </div>
            </div>
        </div>
    );
}
