import Shop, { SuperadminShop } from '../interfaces/shop';

type PriceStructure = {
    percentOfTotal: number;
    fixedFee: boolean;
    description: string;
    fixedNegotiated: boolean;
    percentageNegotiated: boolean;
    minimumFee: number;
    fixedAmount?: number;
};

const _getFee = (
    slug:
        | 'FIXED_PRICE'
        | 'BASIC'
        | 'FIXED_PERCENTAGE'
        | 'SMALL'
        | 'MEDIUM'
        | 'BIG'
): PriceStructure => {
    switch (slug) {
        case 'FIXED_PRICE':
            return {
                percentOfTotal: 0,
                fixedFee: true,
                description: 'Fast umsamið verð',
                fixedNegotiated: true,
                percentageNegotiated: false,
                minimumFee: 0,
                fixedAmount: 0,
            };
        case 'BASIC':
            return {
                percentOfTotal: 0.05,
                fixedFee: false,
                description: 'Þeir sem skráðu sig þegar repeat var nýtt',
                fixedNegotiated: false,
                percentageNegotiated: false,
                minimumFee: 11148,
                fixedAmount: 0,
            };
        case 'FIXED_PERCENTAGE':
            return {
                percentOfTotal: 0,
                fixedFee: false,
                fixedNegotiated: false,
                percentageNegotiated: true,
                description: 'Föst prósenta',
                minimumFee: 0,
                fixedAmount: 0,
            };
        case 'SMALL':
            return {
                percentOfTotal: 0.035,
                fixedFee: true,
                fixedNegotiated: false,
                percentageNegotiated: false,
                description: 'Góður',
                minimumFee: 0,
                fixedAmount: 7316,
            };
        case 'MEDIUM':
            return {
                percentOfTotal: 0.02,
                fixedFee: true,
                fixedNegotiated: false,
                percentageNegotiated: false,
                description: 'Betri',
                minimumFee: 0,
                fixedAmount: 15996,
            };
        case 'BIG':
            return {
                percentOfTotal: 0.01,
                fixedFee: true,
                fixedNegotiated: false,
                percentageNegotiated: false,
                description: 'Bestur',
                minimumFee: 0,
                fixedAmount: 49476,
            };
    }
};
export const getFee = _getFee;

const calculatePlanPrice = (
    priceStructure: PriceStructure,
    paymentTotal: number,
    shop: SuperadminShop
) => {
    let percentageFee = 0;
    if (priceStructure.percentOfTotal) {
        if (priceStructure.percentageNegotiated) {
            percentageFee = paymentTotal * (shop.contract_amount || 0);
        } else {
            percentageFee = paymentTotal * priceStructure.percentOfTotal;
        }
    }

    let fixedFee = 0;
    if (priceStructure.fixedFee) {
        if (priceStructure.fixedNegotiated) {
            fixedFee = shop.contract_amount || 0;
        } else {
            fixedFee = priceStructure.fixedAmount || 0;
        }
    }

    let totalFee = percentageFee + fixedFee;

    if (
        priceStructure.minimumFee &&
        totalFee < priceStructure.minimumFee &&
        totalFee > 0
    ) {
        totalFee = priceStructure.minimumFee;
    }

    if (!paymentTotal) {
        totalFee = 0;
    }

    return totalFee;
};

export const getPeriodPriceForShop = (shop: SuperadminShop, amount: number) => {
    if (amount < 10001) {
        return 0;
    } else if (amount < 30000) {
        // 2990 + vsk
        return 3708;
    }

    if (['BIG', 'MEDIUM', 'SMALL', 'BASIC'].includes(shop.contract || '')) {
        const big = _getFee('BIG');
        const medium = _getFee('MEDIUM');
        const small = _getFee('SMALL');

        // Special calculations for public welfare companies
        if (shop.public_welfare_company) {
            big.percentOfTotal = big.percentOfTotal * 0.75;
            if (big.fixedAmount) big.fixedAmount = big.fixedAmount / 2;

            medium.percentOfTotal = medium.percentOfTotal * 0.75;
            if (medium.fixedAmount) medium.fixedAmount = medium.fixedAmount / 2;

            if (small.fixedAmount) small.fixedAmount = small.fixedAmount / 2;
            small.percentOfTotal = small.percentOfTotal * 0.75;
        }

        const prices = [
            calculatePlanPrice(big, amount, shop),
            calculatePlanPrice(medium, amount, shop),
            calculatePlanPrice(small, amount, shop),
        ];

        return Math.min.apply(Math, prices);
    } else if (shop.contract === 'FIXED_PRICE') {
        return shop.contract_amount;
    } else if (shop.contract === 'FIXED_PERCENTAGE') {
        return (shop.contract_amount || 0) * amount;
    }
};
