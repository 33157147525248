import { useState, useEffect } from 'react';
import {
    Input,
    Button,
    Space,
    Form,
    Card,
    Col,
    Row,
    Layout,
    message,
    Select,
} from 'antd';
import axios from 'axios';
import './RedeemGiftCertificate.less';
import { sendPostMessage } from '../../utils';
import Product from '../../interfaces/product';
import CheckoutFormItems from '../RepeatCheckout/CheckoutFormItems';
import { InfoToCollect } from '../RepeatCheckout/interfaces/repeatCheckoutInterfaces';
import { DeliveryConfig } from '../../interfaces/delivery';

interface IWindow extends Window {
    uuid?: string;
}

interface Cert {
    gift_certificate_activated_message: string;
    product: {
        uuid: string;
        title: string;
        gift_certificate_valid_products: Product[];
        info_to_collect: { slug: InfoToCollect }[];
        delivery_config: DeliveryConfig;
    };
    code: string;
    shop: string;
}

const w: IWindow = window;

const { Content } = Layout;

export default function RedeemGiftCertificate() {
    const [form] = Form.useForm();
    const [code, setCode] = useState<string>();
    const [certificate, setCertificate] = useState<Cert>();
    const [loading, setLoading] = useState(false);
    const [successScreen, setSuccessScreen] = useState(false);
    const [orderUUID, setOrderUUID] = useState<string>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();

    useEffect(() => {
        window.addEventListener('load', (e) => {
            if (w.uuid) sendPostMessage(w.uuid);

            setTimeout(() => {
                if (w.uuid) {
                    sendPostMessage(w.uuid);
                }
            }, 200);
        });

        window.addEventListener('resize', (e) => {
            if (w.uuid) sendPostMessage(w.uuid);
        });
    }, []);

    const checkCode = () => {
        if (code) {
            setLoading(true);

            axios
                .get(`/askrift/api/check_certificate_code/${w.uuid}/${code}/`)
                .then((rsp) => {
                    if (rsp.data.certificate) {
                        setCertificate(rsp.data.certificate);

                        if (
                            rsp.data.certificate.product
                                .gift_certificate_valid_products?.length > 0
                        ) {
                            setSelectedProduct(
                                rsp.data.certificate.product
                                    .gift_certificate_valid_products[0]
                            );
                        }
                    }
                    if (!rsp.data.success) message.error(rsp.data.message);
                    if (w.uuid) sendPostMessage(w.uuid);
                    setTimeout(() => {
                        if (w.uuid) sendPostMessage(w.uuid);
                    }, 200);
                })
                .catch((err) => {
                    console.error('!!!!!!!!!!!!!!!!!', err);
                    message.error('Úps, eitthvað fór úrskeiðis :/');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onFinish = (values: any) => {
        setLoading(true);
        axios
            .post(
                `/askrift/api/redeem_gift_certificate/${w.uuid}/${code}/`,
                values
            )
            .then((rsp) => {
                if (!rsp.data.success) {
                    message.error(rsp.data.message);
                }

                if (rsp.data.order_uuid) {
                    window.location.href = `/pontun/${rsp.data.order_uuid}/`;
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {!certificate && (
                <div id="RedeemGiftCertificate">
                    <Space direction="vertical">
                        <Input
                            id="giftCode"
                            value={code}
                            onPressEnter={() => {}}
                            onChange={(e) => {
                                const val = (
                                    e.target.value || ''
                                ).toUpperCase();
                                setCode(val);
                            }}
                            size="large"
                            placeholder="Gjafakóði"
                        ></Input>
                        <Button
                            loading={loading}
                            id="giftCodeSubmit"
                            onClick={checkCode}
                            size="large"
                        >
                            Sækja gjafabréf
                        </Button>
                    </Space>
                </div>
            )}

            {certificate && !successScreen && (
                <Layout>
                    <Content>
                        <Row gutter={[10, 10]} style={{ margin: 10 }}>
                            <div id="certificateFormParent">
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={18}
                                    lg={14}
                                    xl={10}
                                    xxl={8}
                                >
                                    <Card
                                        id="certificateForm"
                                        title={
                                            'Virkja gjafabréf ' +
                                            certificate.code
                                        }
                                        extra={certificate.product.title}
                                    >
                                        <Form
                                            size="large"
                                            form={form}
                                            initialValues={{
                                                recipient_selected_product:
                                                    selectedProduct?.uuid,
                                            }}
                                            onFinish={onFinish}
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                label="Tegund áskriftar"
                                                name="recipient_selected_product"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Þú verður að velja tegund áskriftar.',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Veldu tegund"
                                                    size="large"
                                                    defaultActiveFirstOption
                                                    disabled={
                                                        certificate.product
                                                            .gift_certificate_valid_products
                                                            ?.length === 1
                                                    }
                                                    onChange={(value) => {
                                                        const p =
                                                            certificate.product.gift_certificate_valid_products?.find(
                                                                (product) =>
                                                                    product.uuid ===
                                                                    value
                                                            );

                                                        if (p)
                                                            setSelectedProduct(
                                                                p
                                                            );
                                                    }}
                                                >
                                                    {certificate.product.gift_certificate_valid_products?.map(
                                                        (
                                                            product: Product,
                                                            key: number
                                                        ) => {
                                                            return (
                                                                <Select.Option
                                                                    value={
                                                                        product.uuid
                                                                    }
                                                                    key={key}
                                                                >
                                                                    {
                                                                        product.title
                                                                    }
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                hidden
                                                name="external_delivery_location_id"
                                            ></Form.Item>
                                            <Form.Item
                                                hidden
                                                name="delivery_option"
                                            ></Form.Item>
                                            <Form.Item
                                                hidden
                                                name="shipping_provider"
                                            ></Form.Item>

                                            {selectedProduct && (
                                                <CheckoutFormItems
                                                    selectedLanguage="is"
                                                    form={form}
                                                    loading={loading}
                                                    outOfStock={false}
                                                    deliveryConfig={
                                                        selectedProduct.delivery_config
                                                    }
                                                    infoToCollect={selectedProduct.info_to_collect?.map(
                                                        (r) => {
                                                            // @ts-ignore
                                                            return r.slug;
                                                        }
                                                    )}
                                                    totalProductPrice={0}
                                                    shopUUID={certificate.shop}
                                                    onValuesChange={(
                                                        changedValues
                                                    ) => {
                                                        console.log(
                                                            'changedValues',
                                                            changedValues
                                                        );
                                                        form.setFieldsValue(
                                                            changedValues
                                                        );
                                                    }}
                                                    disableInputs={false}
                                                    freeDelivery={true}
                                                    products={[selectedProduct]}
                                                ></CheckoutFormItems>
                                            )}

                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Button
                                                    className="btn-success"
                                                    block
                                                    size="large"
                                                    loading={loading}
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Staðfesta
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Col>
                            </div>
                        </Row>
                    </Content>
                </Layout>
            )}
        </>
    );
}
