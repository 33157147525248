import { ReactElement, useState, useEffect } from 'react';
import Axios from 'axios';
import SVG from 'react-inlinesvg';
import {
    Form,
    Input,
    Button,
    Card,
    Modal,
    message,
    Space,
    Table,
    ConfigProvider,
    Avatar,
    Tabs,
    Tag,
    Result,
} from 'antd';
// @ts-ignore
import { Kennitala } from '../../kennitala.js';

import {
    UserOutlined,
    CreditCardOutlined,
    ExpandOutlined,
    FieldTimeOutlined,
    EditOutlined,
    PlusCircleOutlined,
    RetweetOutlined,
} from '@ant-design/icons';

/* Interfaces */
import { SimpleCustomer } from '../../interfaces/customer';
import {
    PublicSubscription,
    SubscriptionBundle,
} from '../../interfaces/subscription';
import { getSubscriptionsAsBundles } from '../../utils/subscriptionUtils';
import { displayCurrencyPrice, getDefaultCurrency, kr } from '../../utils';
import Product, { Currency } from '../../interfaces/product.js';
import AddProductToSubscriptionModal, {
    Steps,
} from './AddProductToSubscriptionModal.js';
import SaltPay from '../../components/SaltPay/SaltPay.js';
import SubscriptionBundleCard from './SubscriptionBundleCard.js';
import UpdateCreditCardModal from './UpdateCreditCardModal.js';
import dayjs from 'dayjs';
import PodcastInstructions from '../../components/PodcastInstructions/PodcastInstructions.js';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage.js';
import translate, { LanguageKey } from '../../translate/translate.js';
import ReceiptOverview from './ReceiptOverview.js';
import AddOns from './AddOns.js';
import CreateAppwriteUserAccountModal from '../../components/CreateAppwriteUserAccountModal/CreateAppwriteUserAccountModal.js';

interface IWindow extends Window {
    customer_uuid?: string;
    shop_uuid?: string;
    ssoTokenMissing?: boolean;
    ssoTokenExpired?: boolean;
    ssoTokenInvalid?: boolean;
}

const w: IWindow = window;

ConfigProvider.config({
    theme: {
        primaryColor: '#000',
    },
});

export default function EditCustomerPublic(): ReactElement {
    const urlParams = new URLSearchParams(window.location.search);

    const [customer, setCustomer] = useState<SimpleCustomer | undefined>();
    const [subscriptions, setSubscriptions] = useState<
        PublicSubscription[] | undefined
    >();
    const [editCustomerInfo, setEditCustomerInfo] = useState<
        SimpleCustomer | undefined | null
    >();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [selectedCurrency, setselectedCurrency] =
        useState<Currency['currency_code']>();

    const [subscriptionBundles, setSubscriptionBundles] = useState<
        SubscriptionBundle[]
    >([]);
    const [inactiveSubscriptionBundles, setInactiveSubscriptionBundles] =
        useState<SubscriptionBundle[]>([]);

    const [updateCreditCardModal, setUpdateCreditCardModal] = useState(false);
    const [customerCardInfo, setCustomerCardInfo] = useState<{
        created: string;
        PAN?: string;
        exp_year?: string;
        exp_month?: string;
        enabled: boolean;
        is_blocked: boolean;
        payment_provider?: string;
    }>();
    const [products, setProducts] = useState<Product[]>([]);
    const [addProductModal, setAddProductModal] = useState<
        false | { product: Product; open: boolean; initalStep?: Steps }
    >(false);
    const [thirdPartyChecked, setThirdPartyChecked] = useState(false);
    const [unpaidTransactions, setUnpaidTransactions] = useState<
        {
            date: string;
            products: string[];
        }[]
    >([]);

    const [selectedLanguage, setSelectedLanguage] = useState<'is' | 'en'>(
        (urlParams.get('lang') ||
            urlParams.get('language') ||
            'is') as LanguageKey
    );

    const getSubscriptions = () => {
        setLoading(true);
        Axios.get(
            `/askrift/api/public_subscription_list?customer__uuid=${w.customer_uuid}&ordering=-created`
        ).then((rsp) => {
            setselectedCurrency(
                getDefaultCurrency(undefined, customer, rsp.data.results)
            );

            setSubscriptions(rsp.data.results);
            setLoading(false);
        });
    };

    useEffect(() => {
        /* Get customer subscriptions */
        getSubscriptions();

        Axios.post('/verslun/api/get_public_customer_card_info/', {
            customer_uuid: w.customer_uuid,
        }).then((rsp) => setCustomerCardInfo(rsp.data));

        Axios.get(
            `/askrift/api/get_unpaid_subscription_transactions_public/${w.customer_uuid}`
        ).then((rsp) => {
            setUnpaidTransactions(rsp.data.data);
        });

        /* Get customer */
        Axios.get(`/verslun/api/public_customer/${w.customer_uuid}`).then(
            (rsp) => {
                setCustomer(rsp.data);

                Axios.get(
                    `/verslun/api/public_products/${rsp.data.shop.uuid}/`
                ).then((res) => {
                    setProducts(res.data.results);
                });

                if (rsp.data.shop.onpay) {
                    const onpayCardmask = urlParams.get('onpay_cardmask');
                    const onpayUUID = urlParams.get('onpay_uuid');
                    let onpayError = urlParams.get('onpay_errorcode');

                    if (onpayError === '0') {
                        onpayError = '';
                    }

                    if (onpayCardmask && onpayUUID && !onpayError) {
                        Axios.post('/payment/save_onpay_card_token/', {
                            customer_uuid: rsp.data.uuid,
                            onpay_cardmask: onpayCardmask,
                            onpay_uuid: onpayUUID,
                        })
                            .then((rsp) => {
                                message.success(
                                    'Nýja kortinu þínu hefur verið bætt við'
                                );
                            })
                            .catch((err) => {
                                message.error(
                                    err.response?.data?.message ||
                                        'Villa kom upp við að vista kort'
                                );
                            });
                    } else if (onpayError) {
                        message.error('Villa kom upp við að tengja kort');
                    }
                }

                if (rsp.data?.shop.rapyd) {
                    const rapydCustomerId = urlParams.get('rapyd_customer');
                    const rapydSuccess =
                        urlParams.get('rapyd_success') === 'true';

                    if (rapydSuccess === true) {
                        Axios.post('/payment/save_rapyd_card_token/', {
                            customer_uuid: rsp.data.uuid,
                            rapyd_customer_id: rapydCustomerId,
                        })
                            .then((rsp) => {
                                message.success(
                                    'Nýja kortinu þínu hefur verið bætt við'
                                );
                            })
                            .catch((err) => {
                                message.error(
                                    err.response?.data?.message ||
                                        'Villa kom upp við að vista kort'
                                );
                            });
                    } else if (urlParams.get('rapyd_success') === 'false') {
                        message.error('Villa kom upp við að tengja kort');
                    }
                }
            }
        );
    }, []);

    useEffect(() => {
        if (subscriptions) {
            const activeBundles = getSubscriptionsAsBundles(
                subscriptions.filter((s) => s.active)
            );

            const inactiveBundles = getSubscriptionsAsBundles(
                subscriptions.filter((s) => !s.active)
            );

            setSubscriptionBundles(activeBundles);
            setInactiveSubscriptionBundles(inactiveBundles);
        }
    }, [subscriptions]);

    useEffect(() => {
        if (customer) {
            form.setFieldsValue({
                ...customer,
            });
        }
    }, [customer]);

    return (
        <>
            <div
                id="editCustomerWrapper"
                style={{
                    width: '100%',
                    background: '#f8f8f8',
                    minHeight: '100%',
                }}
            >
                {w.ssoTokenExpired && (
                    <Result
                        status="warning"
                        title={translate('token_expired', selectedLanguage)}
                    />
                )}

                {w.ssoTokenInvalid && (
                    <Result
                        status="error"
                        title={translate('token_invalid', selectedLanguage)}
                    />
                )}

                {w.ssoTokenMissing && (
                    <Result
                        status="error"
                        title={translate('token_not_found', selectedLanguage)}
                    />
                )}
                {w.ssoTokenExpired !== true &&
                    w.ssoTokenInvalid !== true &&
                    w.ssoTokenMissing !== true && (
                        <div id="editCustomerInner">
                            <header className="customerPublicHeader">
                                <div>
                                    <img
                                        style={{ maxWidth: 90, maxHeight: 65 }}
                                        src={customer?.shop.logo.image}
                                        alt="Logo"
                                    />{' '}
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: 20 }}>
                                        {translate(
                                            'my_subscription_settings',
                                            selectedLanguage
                                        )}
                                    </div>
                                    <SelectLanguage
                                        defaultValue={
                                            selectedLanguage as 'is' | 'en'
                                        }
                                        onChange={(val) =>
                                            setSelectedLanguage(val)
                                        }
                                    ></SelectLanguage>
                                </div>
                            </header>

                            <main className="main">
                                {/* <div className="announcementArea">asdf</div> */}

                                <div className="introBlocks">
                                    {subscriptions && products && (
                                        <AddOns
                                            products={products}
                                            subscriptions={subscriptions}
                                            language={selectedLanguage}
                                        ></AddOns>
                                    )}
                                    <Card
                                        title={
                                            <>
                                                <UserOutlined></UserOutlined>{' '}
                                                {translate(
                                                    'customer',
                                                    selectedLanguage
                                                )}
                                            </>
                                        }
                                        extra={
                                            customer?.shop
                                                .customers_can_edit_profile ? (
                                                <Button
                                                    onClick={() => {
                                                        setEditCustomerInfo(
                                                            customer
                                                        );
                                                    }}
                                                    size="small"
                                                >
                                                    <EditOutlined />
                                                    {translate(
                                                        'change',
                                                        selectedLanguage
                                                    )}
                                                </Button>
                                            ) : (
                                                false
                                            )
                                        }
                                    >
                                        <div className="customerDetails">
                                            <div>
                                                <label>
                                                    {translate(
                                                        'name',
                                                        selectedLanguage
                                                    )}
                                                </label>
                                                <h2>{customer?.name}</h2>
                                            </div>

                                            <div className="customerDetailsInner">
                                                <div>
                                                    <div>
                                                        <label>
                                                            {translate(
                                                                'email',
                                                                selectedLanguage
                                                            )}
                                                        </label>
                                                    </div>
                                                    <span>
                                                        {customer?.email}
                                                    </span>
                                                </div>

                                                {customer?.phone && (
                                                    <div>
                                                        <label>
                                                            {translate(
                                                                'phone',
                                                                selectedLanguage
                                                            )}
                                                        </label>
                                                        <div>
                                                            <span>
                                                                {
                                                                    customer?.phone
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                                {customer?.ssid && (
                                                    <div>
                                                        <label>
                                                            {translate(
                                                                'ssid',
                                                                selectedLanguage
                                                            )}
                                                        </label>
                                                        <div>
                                                            <span>
                                                                {customer?.ssid}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                                {customer?.street && (
                                                    <div>
                                                        <label>
                                                            {translate(
                                                                'address',
                                                                selectedLanguage
                                                            )}
                                                        </label>
                                                        <div>
                                                            <span>
                                                                {
                                                                    customer?.street
                                                                }

                                                                {customer.postal_code &&
                                                                    customer.town && (
                                                                        <>
                                                                            ,{' '}
                                                                            <span>
                                                                                {
                                                                                    customer?.postal_code
                                                                                }{' '}
                                                                                {
                                                                                    customer?.town
                                                                                }
                                                                            </span>
                                                                        </>
                                                                    )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card>

                                    {unpaidTransactions.length > 0 && (
                                        <Card
                                            title={
                                                <>
                                                    <FieldTimeOutlined />{' '}
                                                    {translate(
                                                        'unpaid',
                                                        selectedLanguage
                                                    )}
                                                </>
                                            }
                                            bodyStyle={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                height: 'calc(100% - 58px)',
                                            }}
                                        >
                                            {unpaidTransactions.map(
                                                (obj, i) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                padding: 7,
                                                            }}
                                                            key={i}
                                                        >
                                                            <div>
                                                                <Tag
                                                                    key={i}
                                                                    style={{
                                                                        border: 'none',
                                                                        borderRadius: 20,
                                                                        background:
                                                                            '#ff5a67',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    {dayjs(
                                                                        obj.date
                                                                    ).format(
                                                                        'D. MMM YYYY'
                                                                    )}
                                                                </Tag>
                                                            </div>

                                                            <div>
                                                                {obj.products.map(
                                                                    (
                                                                        title,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <span
                                                                                key={
                                                                                    key
                                                                                }
                                                                            >
                                                                                <small
                                                                                    style={{
                                                                                        marginRight: 5,
                                                                                        color: 'gray',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        title
                                                                                    }
                                                                                    {key ===
                                                                                    obj
                                                                                        .products
                                                                                        .length -
                                                                                        1
                                                                                        ? ''
                                                                                        : ','}
                                                                                </small>
                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Card>
                                    )}

                                    {subscriptions?.some(
                                        (s) =>
                                            s.active &&
                                            (s.product.repeat_rss_feed ||
                                                s.product.is_podcast)
                                    ) && (
                                        <Card>
                                            <PodcastInstructions
                                                language={selectedLanguage}
                                                subscriptions={subscriptions.filter(
                                                    (s) =>
                                                        s.active &&
                                                        (s.product
                                                            .repeat_rss_feed ||
                                                            s.product
                                                                .is_podcast)
                                                )}
                                            ></PodcastInstructions>
                                        </Card>
                                    )}

                                    {((subscriptions?.filter((s) => s.active)
                                        .length || 0) > 0 ||
                                        unpaidTransactions.length > 0) && (
                                        <Card
                                            title={
                                                <>
                                                    <CreditCardOutlined />{' '}
                                                    {translate(
                                                        'card_information',
                                                        selectedLanguage
                                                    )}
                                                </>
                                            }
                                            extra={
                                                customer &&
                                                (customer.shop.rapyd ||
                                                    customer.shop.salt_pay ||
                                                    customer.shop.valitor ||
                                                    customer.shop.straumur ||
                                                    customer.shop.onpay) && (
                                                    <Button
                                                        size="small"
                                                        onClick={() =>
                                                            setUpdateCreditCardModal(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        {!!!customerCardInfo ? (
                                                            <>
                                                                <PlusCircleOutlined />{' '}
                                                                {translate(
                                                                    'add',
                                                                    selectedLanguage
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <EditOutlined />{' '}
                                                                {translate(
                                                                    'change',
                                                                    selectedLanguage
                                                                )}
                                                            </>
                                                        )}
                                                    </Button>
                                                )
                                            }
                                            bodyStyle={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 'calc(100% - 58px)',
                                            }}
                                        >
                                            <div className="text-center">
                                                <div>
                                                    <img
                                                        src="/static/images/svg/card.svg"
                                                        alt="Card"
                                                        style={{
                                                            maxWidth: 90,
                                                        }}
                                                    />
                                                </div>
                                                <br />

                                                {!!!customerCardInfo && (
                                                    <div>
                                                        {translate(
                                                            'no_card_on_file',
                                                            selectedLanguage
                                                        )}
                                                    </div>
                                                )}

                                                {!!customerCardInfo && (
                                                    <div>
                                                        {customerCardInfo.PAN && (
                                                            <div>
                                                                {
                                                                    customerCardInfo.PAN
                                                                }
                                                            </div>
                                                        )}

                                                        {!!!customerCardInfo.PAN && (
                                                            <div>
                                                                {translate(
                                                                    'card_on_file',
                                                                    selectedLanguage
                                                                )}
                                                            </div>
                                                        )}

                                                        {customerCardInfo.exp_year &&
                                                            customerCardInfo.exp_month && (
                                                                <div>
                                                                    {translate(
                                                                        'expires',
                                                                        selectedLanguage
                                                                    )}
                                                                    :{' '}
                                                                    <strong>
                                                                        {
                                                                            customerCardInfo.exp_month
                                                                        }
                                                                        /
                                                                        {
                                                                            customerCardInfo.exp_year
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        </Card>
                                    )}

                                    {/* <Card
                                title={
                                    <>
                                        <CalendarOutlined></CalendarOutlined>{' '}
                                        Næst
                                    </>
                                }
                            >
                                <div className="text-center">
                                    <div>
                                        <CheckCircleOutlined
                                            style={{
                                                color: '#32c79f',
                                                fontSize: 30,
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <strong>Þriðjudagur 12. október</strong>
                                    </div>
                                </div>
                            </Card> */}
                                </div>

                                <div className="main-cards">
                                    <Card
                                        title=""
                                        bodyStyle={{ paddingTop: 12 }}
                                        loading={loading}
                                    >
                                        <Tabs
                                            items={[
                                                {
                                                    key: 'virkar',
                                                    label: translate(
                                                        'active_subscriptions',
                                                        selectedLanguage
                                                    ),
                                                    children: (
                                                        <div
                                                            style={{
                                                                marginTop: 20,
                                                            }}
                                                        >
                                                            {!!(
                                                                subscriptionBundles.length >
                                                                0
                                                            ) ? (
                                                                subscriptionBundles.map(
                                                                    (
                                                                        bundle,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <SubscriptionBundleCard
                                                                                    language={
                                                                                        selectedLanguage
                                                                                    }
                                                                                    bundle={
                                                                                        bundle
                                                                                    }
                                                                                    disabled={
                                                                                        false
                                                                                    }
                                                                                    onBundleChange={() => {
                                                                                        getSubscriptions();
                                                                                    }}
                                                                                ></SubscriptionBundleCard>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            margin: 50,
                                                                            opacity: 0.5,
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <ExpandOutlined
                                                                            style={{
                                                                                fontSize: 50,
                                                                                marginBottom: 20,
                                                                            }}
                                                                        />
                                                                        <h3>
                                                                            {translate(
                                                                                'no_active_subscriptions',
                                                                                selectedLanguage
                                                                            )}
                                                                        </h3>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: 'ovirkar',
                                                    label: translate(
                                                        'inactive_subscriptions',
                                                        selectedLanguage
                                                    ),
                                                    children: (
                                                        <div
                                                            style={{
                                                                marginTop: 20,
                                                            }}
                                                        >
                                                            {!!(
                                                                inactiveSubscriptionBundles.length >
                                                                0
                                                            ) ? (
                                                                inactiveSubscriptionBundles.map(
                                                                    (
                                                                        bundle,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <SubscriptionBundleCard
                                                                                    language={
                                                                                        selectedLanguage
                                                                                    }
                                                                                    bundle={
                                                                                        bundle
                                                                                    }
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                ></SubscriptionBundleCard>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            margin: 50,
                                                                            opacity: 0.5,
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <ExpandOutlined
                                                                            style={{
                                                                                fontSize: 50,
                                                                                marginBottom: 20,
                                                                            }}
                                                                        />
                                                                        <h3>
                                                                            {translate(
                                                                                'you_dont_have_inactive_subscriptions',
                                                                                selectedLanguage
                                                                            )}
                                                                        </h3>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: 'receipts',
                                                    label: translate(
                                                        'receipts',
                                                        selectedLanguage
                                                    ),
                                                    children: (
                                                        <ReceiptOverview
                                                            language={
                                                                selectedLanguage
                                                            }
                                                            customer={customer}
                                                            url={`/payment/api/payment_requests_public/?ordering=-created&customer=${w.customer_uuid}&shop_uuid=${w.shop_uuid}&request_type=PAYMENT&success=true`}
                                                        />
                                                    ),
                                                },
                                                {
                                                    key: 'faerslur_og_greidslur',
                                                    label: translate(
                                                        'payments',
                                                        selectedLanguage
                                                    ),
                                                    children: (
                                                        <SaltPay
                                                            hideShowMore={true}
                                                            isPublic={true}
                                                            columns={[
                                                                'date',
                                                                'type',
                                                            ]}
                                                            url={`/payment/api/payment_requests_public/?ordering=-created&customer=${w.customer_uuid}&shop_uuid=${w.shop_uuid}`}
                                                            language={
                                                                selectedLanguage
                                                            }
                                                        ></SaltPay>
                                                    ),
                                                },
                                            ]}
                                        ></Tabs>
                                    </Card>

                                    <Card
                                        title={
                                            <>
                                                <RetweetOutlined />{' '}
                                                {translate(
                                                    'other_subscriptions_available',
                                                    selectedLanguage
                                                )}
                                            </>
                                        }
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Table
                                                size="small"
                                                className="white_table"
                                                style={{ width: '100%' }}
                                                rowKey={(record) => record.uuid}
                                                dataSource={products.filter(
                                                    (p) => {
                                                        const isAlreadySubscribed =
                                                            subscriptions?.find(
                                                                (s) =>
                                                                    s.product
                                                                        .uuid ===
                                                                        p.uuid &&
                                                                    s.active
                                                            );

                                                        return (
                                                            p.type ===
                                                                'SUBSCRIPTION' &&
                                                            !isAlreadySubscribed
                                                        );
                                                    }
                                                )}
                                                locale={{
                                                    emptyText: (
                                                        <div
                                                            style={{
                                                                margin: 50,
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            <ExpandOutlined
                                                                style={{
                                                                    fontSize: 50,
                                                                    marginBottom: 20,
                                                                }}
                                                            />
                                                            <h3>
                                                                {translate(
                                                                    'no_other_subscriptions_available',
                                                                    selectedLanguage
                                                                )}
                                                            </h3>
                                                        </div>
                                                    ),
                                                }}
                                                columns={[
                                                    {
                                                        render: (
                                                            product: Product
                                                        ) => (
                                                            <div>
                                                                <Avatar
                                                                    size={
                                                                        'large'
                                                                    }
                                                                    // @ts-ignore
                                                                    src={
                                                                        product.main_image
                                                                    }
                                                                ></Avatar>
                                                            </div>
                                                        ),
                                                        title: '',
                                                    },
                                                    {
                                                        render: (
                                                            product: Product
                                                        ) => product.title,
                                                        title: translate(
                                                            'title',
                                                            selectedLanguage
                                                        ),
                                                    },
                                                    {
                                                        title: translate(
                                                            'price',
                                                            selectedLanguage
                                                        ),
                                                        align: 'right',
                                                        render: (
                                                            product: Product
                                                        ) =>
                                                            selectedCurrency
                                                                ? displayCurrencyPrice(
                                                                      false,
                                                                      selectedCurrency,
                                                                      product
                                                                  )
                                                                : '',
                                                    },
                                                    {
                                                        title: '',

                                                        render: (
                                                            product: Product
                                                        ) => (
                                                            <div className="text-center">
                                                                <Space>
                                                                    <Button
                                                                        type="dashed"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setAddProductModal(
                                                                                {
                                                                                    product,
                                                                                    open: true,
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        {translate(
                                                                            'details',
                                                                            selectedLanguage
                                                                        )}
                                                                    </Button>
                                                                    <Button
                                                                        type="dashed"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setAddProductModal(
                                                                                {
                                                                                    product,
                                                                                    open: true,
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </Space>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                                pagination={false}
                                            ></Table>
                                        </div>
                                    </Card>
                                </div>
                            </main>

                            <footer className="footer">
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <a href="https://repeat.is" target="blank">
                                        <SVG
                                            style={{
                                                width: '150px',
                                                boxSizing: 'initial',
                                                paddingBottom: '0px',
                                            }}
                                            id="hero_logo"
                                            baseURL="/"
                                            cacheRequests={true}
                                            description="Logo"
                                            src={'/static/images/logo/logo.svg'}
                                            title="Logo"
                                            key="hero_logo"
                                            uniqueHash="hero_logo"
                                            uniquifyIDs={false}
                                            fill={'lightgray'}
                                        />
                                        <div
                                            style={{
                                                paddingBottom: 35,
                                                color: 'lightgray',
                                            }}
                                        >
                                            {translate(
                                                'powered_by_repeat',
                                                selectedLanguage
                                            )}
                                        </div>
                                    </a>
                                </div>
                            </footer>
                        </div>
                    )}
            </div>

            <Modal
                className="editCustomerPublicModal"
                footer={null}
                getContainer={false}
                open={!!editCustomerInfo}
                title={translate('edit_basic_info', selectedLanguage)}
                onCancel={() => {
                    setEditCustomerInfo(null);
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);
                        Axios.post(
                            `/verslun/api/update_customer_public/${w.customer_uuid}/`,
                            form.getFieldsValue()
                        ).then((rsp) => {
                            setCustomer({
                                ...customer,
                                ...form.getFieldsValue(),
                            });
                            message.success(
                                'Viðskiptavinur hefur verið uppfærður 🥳'
                            );
                            setLoading(false);
                            setEditCustomerInfo(null);
                        });
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Nafn"
                        rules={[
                            {
                                required: true,
                                message: 'Þú verður að gefa upp nafn',
                            },
                        ]}
                    >
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Netfang"
                        rules={[
                            {
                                required: true,
                                message: 'Þú verður að gefa upp netfang',
                            },
                        ]}
                    >
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: false,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value) {
                                        const kt = new Kennitala(value);
                                        // @ts-ignore
                                        if (!kt.valid) {
                                            return Promise.reject(
                                                new Error('Ógild kennitala!')
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                        name="ssid"
                        label="Kennitala"
                    >
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item name="street" label="Heimilisfang">
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item name="town" label="Bæjarfélag">
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item name="postal_code" label="Póstnúmer">
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item name="phone" label="Símanúmer">
                        <Input size="large" autoComplete="off"></Input>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            loading={loading}
                            block
                            type="primary"
                            size="large"
                        >
                            Vista breytingar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {addProductModal && customer && (
                <AddProductToSubscriptionModal
                    open={!!addProductModal.open}
                    onClose={() => setAddProductModal(false)}
                    onSuccess={() => getSubscriptions()}
                    currentBundles={subscriptionBundles}
                    product={addProductModal.product}
                    customer={customer}
                    initalStep={addProductModal.initalStep}
                    language={selectedLanguage}
                    currencyCode={selectedCurrency || 'ISK'}
                ></AddProductToSubscriptionModal>
            )}

            {updateCreditCardModal && customer && (
                <UpdateCreditCardModal
                    open={true}
                    onCancel={() => setUpdateCreditCardModal(false)}
                    customer={customer}
                    className="editCustomerPublicModal"
                    currencyCode={selectedCurrency || 'ISK'}
                    language={selectedLanguage}
                ></UpdateCreditCardModal>
            )}

            {customer &&
                customer.shop.appwrite_project_id &&
                subscriptions?.find(
                    (s) => s.active && s.product.appwrite_user_profiles
                ) && (
                    <CreateAppwriteUserAccountModal
                        language={selectedLanguage}
                        open={customer.appwrite_user_id ? false : true}
                        customer={customer}
                        onClose={() => {
                            setCustomer({
                                ...customer,
                                appwrite_user_id: customer.uuid,
                            });
                        }}
                    ></CreateAppwriteUserAccountModal>
                )}
        </>
    );
}
