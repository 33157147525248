// @ts-nocheck
import React, { ReactElement, useState, useEffect } from 'react';
import {
    Form,
    Select,
    Switch,
    Input,
    Button,
    Alert,
    DatePicker,
    Card,
    Space,
    message,
} from 'antd';
import Axios from 'axios';
import moment from 'moment';
moment.locale('is');
import 'moment/locale/is';
import locale from '../../../antd-datepicker-locale-is';

/* Interfaces */
import Product from '../../../interfaces/product';

interface Props {
    product: Product;
}

export default function PaymentSubscriptionSettings(
    props: Props
): ReactElement {
    const [form] = Form.useForm();
    const [reRender, setReRender] = useState(true);

    const {
        interval,
        interval_type,
        base_date_is_signup_date,
        day_of_month,
        day_of_week,
        specific_days_of_year,
        base_date,
        skip_if_unpaid_count_gt,
        skip_if_newest_transaction_lt_days,
    } = props.product.cron_transaction || {};
    const useCron =
        !!props.product.cron_transaction &&
        props.product.cron_transaction.is_active;

    useEffect(() => {
        setReRender(!reRender);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                is_active: useCron,
                interval: interval || 1,
                interval_type: interval_type || 'MONTH',
                day_of_month: day_of_month || 1,
                day_of_week: day_of_week || 1,
                specific_days_of_year,
                base_date_is_signup_date:
                    base_date_is_signup_date === undefined
                        ? true
                        : base_date_is_signup_date,
                base_date: base_date ? moment(base_date) : null,
                skip_if_unpaid_count_gt: skip_if_unpaid_count_gt || 2,
                skip_if_newest_transaction_lt_days:
                    skip_if_newest_transaction_lt_days === 0 ||
                    skip_if_newest_transaction_lt_days
                        ? skip_if_newest_transaction_lt_days
                        : 15,
            }}
            onValuesChange={() => {
                setReRender(!reRender);
            }}
            onFinish={(values) => {
                const isEdit = !!props.product.cron_transaction;

                if (isEdit) {
                    Axios.patch(
                        `/verslun/api/transaction_cron/${props.product.cron_transaction.uuid}/`,
                        values
                    ).then((rsp) => {
                        message.success('Tókst');
                    });
                } else {
                    Axios.post(
                        `/verslun/api/create_transaction_cron/`,
                        values
                    ).then((rsp) => {
                        Axios.patch(
                            `/verslun/api/basic_product/${props.product.uuid}/`,
                            { cron_transaction: rsp.data.uuid }
                        ).then((res) => {
                            message.success('Tókst');
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        });
                    });
                }
            }}
        >
            <Space direction="vertical">
                <Form.Item
                    name="is_active"
                    label="Vilt þú að kerfið stofni reglulegar færslur (sendingar og/eða rukkun) sjálfkrafa (t.d mánaðarlega)?"
                    valuePropName="checked"
                >
                    <Switch checkedChildren="JÁ " unCheckedChildren="NEI" />
                </Form.Item>

                {form.getFieldValue('is_active') && (
                    <>
                        <div>Hversu reglubundið?</div>

                        <div style={{ display: 'flex' }}>
                            <Form.Item name="interval" label="Fjöldi">
                                <Input></Input>
                            </Form.Item>

                            <Form.Item name="interval_type" label="Tegund">
                                <Select name="cron_transactions_interval_type">
                                    <Select.Option value="MONTH">
                                        mánaða
                                    </Select.Option>
                                    <Select.Option disabled value="WEEK">
                                        vikna
                                    </Select.Option>
                                    <Select.Option value="YEAR">
                                        {form.getFieldValue('interval') > 1
                                            ? 'ára'
                                            : 'árs'}
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-label">
                                    <label> </label>
                                </div>
                                <div
                                    className="ant-col ant-form-item-control"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        paddingLeft: 10,
                                    }}
                                >
                                    fresti
                                </div>
                            </div>
                        </div>

                        <Alert
                            title="Greitt / Uppfyllt"
                            message={`Færslur verða sjálfkrafa rukkaðar og verða merktar sem ${
                                props.product.auto_mark_as_fullfilled
                                    ? '"Uppfylltar"'
                                    : '"Óuppfylltar"'
                            } (miðast við stillingar undir "Grunnupplýsingar")`}
                            type="info"
                        />
                        <br />

                        <Form.Item
                            name="base_date_is_signup_date"
                            label="Hvenær?"
                        >
                            <Select>
                                <Select.Option value={true}>
                                    Daginn sem viðkomandi skráði sig
                                </Select.Option>

                                <Select.Option value={false}>
                                    Alltaf sama dag{' '}
                                    {form.getFieldValue('interval_type') ===
                                    'MONTH'
                                        ? 'mánaðar'
                                        : 'árs'}
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        {form.getFieldValue('interval_type') === 'MONTH' &&
                            !form.getFieldValue('base_date_is_signup_date') && (
                                <Form.Item name="base_date" label="Hvenær">
                                    <DatePicker
                                        locale="is_IS"
                                        popupClassName="dayPicker"
                                        disabledDate={(date) => {
                                            return date.date() > 28
                                                ? true
                                                : false;
                                        }}
                                        format="D. [hvers mánaðar]"
                                    />
                                </Form.Item>
                            )}

                        {form.getFieldValue('interval_type') === 'YEAR' &&
                            !form.getFieldValue('base_date_is_signup_date') && (
                                <Form.Item name="base_date" label="Hvenær">
                                    <DatePicker
                                        locale={{
                                            locale: locale,
                                        }}
                                        format={`D. MMMM [á ${form.getFieldValue(
                                            'interval'
                                        )} ${
                                            form.getFieldValue('interval') > 1
                                                ? 'ára'
                                                : 'árs'
                                        } fresti]`}
                                    />
                                </Form.Item>
                            )}

                        <Form.Item
                            name="skip_if_unpaid_count_gt"
                            label="Sleppa ef ógreiddar færslur eru fleiri en"
                            extra={`Hætt verður við ef fleiri en ${form.getFieldValue(
                                'skip_if_unpaid_count_gt'
                            )} ógreiddar færslur finnast í kerfinu (af þessari tegund).`}
                        >
                            <Input></Input>
                        </Form.Item>

                        <Form.Item
                            name="skip_if_newest_transaction_lt_days"
                            label={`Sleppa ef það finnst færsla sem er minna en X daga gömul.`}
                            extra={`Hætt verður við ef það finnst færlsa (af þessari tegund) í kerfinu sem er minna en ${form.getFieldValue(
                                'skip_if_newest_transaction_lt_days'
                            )} daga gömul.`}
                        >
                            <Input></Input>
                        </Form.Item>
                    </>
                )}

                <Form.Item>
                    <Button type="primary" size="large" htmlType="submit">
                        Vista
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
}
