import { useEffect, useState } from 'react';
import Axios from 'axios';
import dayjs from 'dayjs';
import { Button, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import SVG from 'react-inlinesvg';

import './SubscriptionTransactionReceipt.scss';

import { displayCurrencyPrice, kr } from '../../utils';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import translate, { LanguageKey } from '../../translate/translate';

interface IWindow extends Window {
    uuid?: string;
    id?: number;
}

const w: IWindow = window;

interface T {
    amount: number;
    created: string;
    currency_code: string;
    customer_name: string;
    customer_ssid: string;
    is_free: boolean;
    is_fulfilled: boolean;
    is_paid: boolean;
    is_refunded: boolean;
    payment_method: string;
    perform_payment_time?: string;
    product_title: string;
    shop_logo: string;
    shop_name: string;
    uuid: string;
}

interface Customer {
    name: string;
    email: string;
    phone: string;
    uuid: string;
    ssid: null;
    street: null;
    town: null;
    postal_code: null;
    shop: {
        name: string;
        default_currency: string;
        customers_can_cancel_subscription: true;
        customers_can_edit_profile: true;
        id: 1;
        ssid: string;
        website: string;
        email: string;
        uuid: string;
        manage_permission_based_content: true;
        banking: null;
        appwrite_project_id: string;
        logo: {
            id: 54;
            updated: string;
            created: string;
            uuid: string;
            image: string;
        };
        salt_pay: true;
        valitor: false;
        rapyd: false;
        straumur: false;
        onpay: false;
        terms: string;
        terms_url: string;
        use_three_d_secure: false;
    };
}

export default function SubscriptionTransactionReceipt() {
    const [transaction, setTransaciton] = useState<T>();
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageKey>('is');
    const [customer, setCustomer] = useState<Customer>();

    useEffect(() => {
        Axios.get(`/askrift/api/subscription_transaction_public/${w.id}/`).then(
            (rsp) => {
                setTransaciton({
                    ...rsp.data,
                });
            }
        );

        Axios.get(`/verslun/api/public_customer/${w.uuid}/`).then((res) => {
            setCustomer(res.data);
        });
    }, []);

    return (
        <div id="subscriptionTransactionReceipt">
            <div className="toolbar">
                <Space>
                    <SelectLanguage
                        defaultValue="is"
                        onChange={(val) => setSelectedLanguage(val)}
                    ></SelectLanguage>

                    <Button
                        onClick={() => {
                            window.print();
                        }}
                        size="small"
                    >
                        <PrinterOutlined />{' '}
                        {translate('print', selectedLanguage)}
                    </Button>
                </Space>
            </div>
            {transaction && customer && (
                <div id="receiptPaper">
                    <header>
                        <div className="leftSide">
                            <div>
                                <img
                                    style={{ maxWidth: 90 }}
                                    src={customer.shop.logo?.image}
                                    alt="Shop Logo"
                                />
                            </div>

                            <h1>{customer.shop.name}</h1>
                            {customer.shop.ssid && (
                                <div>{customer.shop.ssid}</div>
                            )}
                            {customer.shop.website && (
                                <div>{customer.shop.website}</div>
                            )}
                            {customer.shop.email && (
                                <div>{customer.shop.email}</div>
                            )}

                            <div style={{ marginTop: 40 }}>
                                <div>
                                    <div>
                                        <strong>{customer.name}</strong>
                                    </div>
                                    <div>
                                        {customer.ssid && <>{customer.ssid}</>}
                                    </div>
                                </div>

                                {customer.street && (
                                    <>
                                        <div>
                                            {customer.street},{' '}
                                            {customer.postal_code}{' '}
                                            {customer.town}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="rightSide">
                            <h1>{translate('receipt', selectedLanguage)}</h1>

                            <h3>
                                {translate('date', selectedLanguage)}:{' '}
                                {dayjs(transaction.created).format(
                                    'D. MMM YYYY'
                                )}
                            </h3>

                            {transaction.is_paid &&
                                !transaction.is_refunded && (
                                    <h2>
                                        {translate('paid', selectedLanguage)}
                                    </h2>
                                )}

                            {transaction.is_refunded && (
                                <h2>
                                    {translate('refunded', selectedLanguage)}
                                </h2>
                            )}

                            {!transaction.is_refunded &&
                                !transaction.is_paid && (
                                    <h2>
                                        {translate('unpaid', selectedLanguage)}
                                    </h2>
                                )}
                        </div>
                    </header>

                    <main>
                        <div
                            style={{
                                marginTop: 40,
                                backgroundColor: '#f5f5f5',
                                padding: '10px 20px',
                            }}
                        >
                            <div>
                                {translate('description', selectedLanguage)}
                            </div>
                        </div>

                        {
                            <div
                                style={{
                                    marginTop: 20,
                                    padding: '10px 20px',
                                }}
                            >
                                <div>{transaction.product_title}</div>
                            </div>
                        }

                        <hr />

                        <div
                            style={{
                                textAlign: 'right',
                                marginTop: 40,
                            }}
                        >
                            <div>{translate('amount', selectedLanguage)}</div>
                            <h1>
                                {displayCurrencyPrice(
                                    transaction.amount,
                                    transaction.currency_code
                                )}
                            </h1>
                        </div>
                    </main>

                    <footer className="footer">
                        <div
                            style={{
                                textAlign: 'center',
                                paddingTop: 40,
                            }}
                        >
                            <a href="https://repeat.is" target="blank">
                                <SVG
                                    style={{
                                        width: '120px',
                                        boxSizing: 'initial',
                                        paddingBottom: '0px',
                                    }}
                                    id="hero_logo"
                                    baseURL="/"
                                    cacheRequests={true}
                                    description="Logo"
                                    src={'/static/images/logo/logo.svg'}
                                    title="Logo"
                                    key="hero_logo"
                                    uniqueHash="hero_logo"
                                    uniquifyIDs={false}
                                    fill={'lightgray'}
                                />
                                <div
                                    style={{
                                        color: 'lightgray',
                                        fontSize: 12,
                                    }}
                                >
                                    {translate(
                                        'powered_by_repeat',
                                        selectedLanguage
                                    )}
                                </div>
                            </a>
                        </div>
                    </footer>
                </div>
            )}
        </div>
    );

    // <Card
    //     className="shadowCard"
    //     loading={!!!subscriptionTransaction}
    //     title={
    //         subscriptionTransaction ? subscriptionTransaction.shop_name : ''
    //     }
    //     style={{
    //         width: '90%',
    //         maxWidth: 400,
    //         margin: 'auto',
    //         marginTop: 25,
    //     }}
    //     extra="Kvittun"
    // >
    //     {receipt && (
    //         <div style={{ textAlign: 'center' }}>
    //             <Space direction="vertical" style={{ display: 'flex' }}>
    //                 <h1>Kvittun</h1>

    //                 <div className="shopLogo">
    //                     <img
    //                         style={{ maxWidth: 70 }}
    //                         src={receipt.shop_logo}
    //                         alt="Shop Logo"
    //                     />
    //                 </div>

    //                 <h2>{receipt.product_title}</h2>

    //                 <Statistic
    //                     title="Verð"
    //                     value={displayCurrencyPrice(
    //                         receipt.amount,
    //                         receipt.currency_code
    //                     )}
    //                 />

    //                 {receipt.customer_ssid && (
    //                     <Statistic
    //                         title="Kennitala"
    //                         value={
    //                             receipt.customer_ssid.substring(0, 6) +
    //                             '-' +
    //                             receipt.customer_ssid.substring(6, 10)
    //                         }
    //                     />
    //                 )}
    //                 <Statistic
    //                     title="Viðskiptavinur"
    //                     value={receipt.customer_name}
    //                 />
    //                 <Statistic
    //                     title="Dagsetning"
    //                     value={dayjs(receipt?.created).format(
    //                         'DD. MMM YYYY'
    //                     )}
    //                 />

    //                 <Tag
    //                     color={receipt.is_paid ? '#87d068' : '#f44336'}
    //                     style={{
    //                         fontSize: 16,
    //                         padding: '5px 10px',
    //                         width: '100%',
    //                     }}
    //                 >
    //                     {receipt.is_paid ? 'Greitt' : 'Ógreitt'}
    //                 </Tag>
    //             </Space>
    //         </div>
    //     )}
    // </Card>
}
