import React, { useEffect, useState } from 'react';
import Product, { IntervalCombination } from '../../../interfaces/product';
import axios from 'axios';
import { Button, Divider, Form, Select, Switch, message } from 'antd';

type Props = {
    product: Product;
};

export default function ProductFrequencySettings({ product }: Props) {
    const [form] = Form.useForm();

    const [intervalCombinations, setIntervalCombinations] =
        useState<IntervalCombination[]>();
    const [loading, setLoading] = useState(true);
    const [forceRerender, setForceRerender] = useState(false);

    useEffect(() => {
        axios
            .get(
                `/verslun/api/admin_product_interval_combinations/${product.uuid}/`
            )
            .then((res: { data: IntervalCombination[] }) => {
                form.setFieldsValue({
                    WEEK: res.data.some((ic) => ic.type === 'WEEK'),
                    MONTH: res.data.some((ic) => ic.type === 'MONTH'),
                    YEAR: res.data.some((ic) => ic.type === 'YEAR'),
                    week_count_options:
                        res.data.find((ic) => ic.type === 'WEEK')
                            ?.count_options || [],
                    month_count_options:
                        res.data.find((ic) => ic.type === 'MONTH')
                            ?.count_options || [],
                    year_count_options:
                        res.data.find((ic) => ic.type === 'YEAR')
                            ?.count_options || [],
                });

                setIntervalCombinations(res.data || []);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {intervalCombinations && (
                <Form
                    onValuesChange={() => setForceRerender(!forceRerender)}
                    form={form}
                    initialValues={{
                        WEEK: intervalCombinations.some(
                            (ic) => ic.type === 'WEEK'
                        ),
                        MONTH: intervalCombinations.some(
                            (ic) => ic.type === 'MONTH'
                        ),
                        YEAR: intervalCombinations.some(
                            (ic) => ic.type === 'YEAR'
                        ),
                        week_count_options:
                            intervalCombinations.find(
                                (ic) => ic.type === 'WEEK'
                            )?.count_options || [],
                        month_count_options:
                            intervalCombinations.find(
                                (ic) => ic.type === 'MONTH'
                            )?.count_options || [],
                        year_count_options:
                            intervalCombinations.find(
                                (ic) => ic.type === 'YEAR'
                            )?.count_options || [],
                    }}
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);

                        axios
                            .post(
                                `/verslun/api/admin_save_product_interval_combinations/${product.uuid}/`,
                                values
                            )
                            .then((res) => {
                                message.success(
                                    'Tókst, breytingar hafa verið vistaðar'
                                );
                                setLoading(false);
                            });
                    }}
                >
                    <Form.Item
                        label="Vikur"
                        name="WEEK"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="JÁ " unCheckedChildren="NEI" />
                    </Form.Item>

                    {form.getFieldValue('WEEK') && (
                        <>
                            <Form.Item
                                label="Leyfileg vikutíðni"
                                extra="Ef þú skilur eftir tómt þá er öll vikutíðni leyfileg"
                                name="week_count_options"
                            >
                                <Select
                                    options={[
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 },
                                        { label: '5', value: 5 },
                                        { label: '6', value: 6 },
                                        { label: '7', value: 7 },
                                        { label: '8', value: 8 },
                                        { label: '9', value: 9 },
                                        { label: '10', value: 10 },
                                        { label: '11', value: 11 },
                                        { label: '12', value: 12 },
                                    ]}
                                    mode="multiple"
                                    placeholder="Veldu vikutíðni"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Divider />

                    <Form.Item
                        label="Mánuðir"
                        name="MONTH"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="JÁ " unCheckedChildren="NEI" />
                    </Form.Item>

                    {form.getFieldValue('MONTH') && (
                        <>
                            <Form.Item
                                label="Leyfileg mánaðartíðni"
                                extra="Ef þú skilur eftir tómt þá er öll mánaðartíðni leyfileg"
                                name="month_count_options"
                            >
                                <Select
                                    options={[
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 },
                                        { label: '5', value: 5 },
                                        { label: '6', value: 6 },
                                        { label: '7', value: 7 },
                                        { label: '8', value: 8 },
                                        { label: '9', value: 9 },
                                        { label: '10', value: 10 },
                                        { label: '11', value: 11 },
                                        { label: '12', value: 12 },
                                    ]}
                                    mode="multiple"
                                    placeholder="Veldu mánaðartíðni"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Divider />

                    <Form.Item label="Ár" name="YEAR" valuePropName="checked">
                        <Switch checkedChildren="JÁ " unCheckedChildren="NEI" />
                    </Form.Item>

                    {form.getFieldValue('YEAR') && (
                        <>
                            <Form.Item
                                label="Leyfileg árstíðni"
                                extra="Ef þú skilur eftir tómt þá er öll árstíðni leyfileg"
                                name="year_count_options"
                            >
                                <Select
                                    options={[
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 },
                                        { label: '5', value: 5 },
                                        { label: '6', value: 6 },
                                        { label: '7', value: 7 },
                                        { label: '8', value: 8 },
                                        { label: '9', value: 9 },
                                        { label: '10', value: 10 },
                                        { label: '11', value: 11 },
                                        { label: '12', value: 12 },
                                    ]}
                                    mode="multiple"
                                    placeholder="Veldu árstíðni"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Vista
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
}
