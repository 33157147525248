import { useEffect, useState } from 'react';
import Shop from '../../../interfaces/shop';
import { Button, Table, Tag } from 'antd';
import { BuildOutlined } from '@ant-design/icons';
import axios from 'axios';
import { OverviewProduct } from '../../../interfaces/product';
import ActionFormModal from './ActionFormModal';

type Props = {
    shop: Shop;
};

type TriggerAction = {
    id: number;
    uuid: string;
    slug: string;
    new_price?: number;
    products: OverviewProduct[];
};

type Trigger = {
    id: number;
    uuid: string;
    title: string;
    description: string;
    trigger_event: string;
    trigger_value: number;
    trigger_operator: string;
    trigger_check: string;
    is_active: boolean;
    shop: number;
    actions: TriggerAction[];
    products: OverviewProduct[];
};

const getTriggerActionLabel = (triggerAction: string) => {
    switch (triggerAction) {
        case 'CHANGE_PRICE':
            return 'Breyta verði';
        case 'SEND_EMAIL':
            return 'Senda tölvupóst';
        case 'SEND_SMS':
            return 'Senda SMS';
        case 'CHANGE_PRODUCT':
            return 'Breyta vörutegund:';
        default:
            return triggerAction;
    }
};

const getTriggerEventLabel = (triggerEvent: string) => {
    switch (triggerEvent) {
        case 'SUBSCRIPTION_TRANSACTION_CREATED':
            return 'Áskriftarfærsla stofnuð (fjöldi)';
        case 'SUBSCRIPTION_TRANSACTION_FAILED':
            return 'Áskriftarfærsla mistókst';
        case 'SUBSCRIPTION_TRANSACTION_SUCCEEDED':
            return 'Áskriftarfærsla tókst';

        default:
            return triggerEvent;
    }
};

const getTriggerOperatorLabel = (triggerOperator: string) => {
    switch (triggerOperator) {
        case 'LESS_THAN':
            return 'Minna en';
        case 'GREATER_THAN':
            return 'Stærra en';
        case 'EQUALS':
            return 'Jafnt og';
        default:
            return triggerOperator;
    }
};

export default function ShopActions({ shop }: Props) {
    const [triggers, setTriggers] = useState<Trigger[]>([]);
    const [actionFormModal, setActionFormModal] = useState<
        | {
              uuid?: string;
              create: boolean;
          }
        | false
    >();

    const getTriggers = async () => {
        return axios.get(`/action/triggers/${shop.uuid}/`).then((rsp) => {
            setTriggers(rsp.data);
        });
    };

    useEffect(() => {
        getTriggers();
    }, [shop.uuid]);

    return (
        <div>
            <div>
                <h1>
                    <BuildOutlined /> Reglur
                </h1>
                <p>
                    Hægt er að sjálvirknivæða aðgerðir þegar ákveðnir atburðir
                    eiga sér stað.
                </p>
                <p>
                    Sem dæmi er hægt að breyta um áskriftarvöru viðskiptavina
                    eftir x mörg skipti.
                </p>
                <p>Hér eru nokkur dæmi um hvernig hægt er að nota reglur:</p>

                <ul>
                    <li>
                        Fyrsta sending inniheldur glas eða krukku, en eftir að
                        fyrsta sending fer af stað breytist áskriftin yfir í
                        aðra vöru sem er poki/umslag.
                    </li>
                </ul>
                <Button
                    onClick={() => {
                        setActionFormModal({
                            create: true,
                        });
                    }}
                >
                    + Bæta við reglu
                </Button>
            </div>
            <Table
                size="large"
                style={{ marginTop: '20px', fontSize: '12px' }}
                pagination={false}
                dataSource={triggers}
                columns={[
                    {
                        title: 'Vörur',
                        render: (trigger: Trigger) =>
                            trigger.products.map((product) => {
                                return (
                                    <Tag key={product.uuid}>
                                        {product.title}
                                    </Tag>
                                );
                            }),
                    },
                    {
                        title: 'Atburður',
                        render: (trigger: Trigger) =>
                            getTriggerEventLabel(trigger.trigger_event),
                    },
                    {
                        title: 'Viðmiðunargildi',
                        render: (trigger: Trigger) => trigger.trigger_value,
                    },
                    {
                        title: 'Skilirði',
                        render: (trigger: Trigger) =>
                            getTriggerOperatorLabel(trigger.trigger_operator),
                    },
                    {
                        title: 'Aðgerðir',
                        render: (trigger: Trigger) => {
                            return (
                                <>
                                    <div>
                                        {trigger.actions.map((action) => {
                                            return (
                                                <div key={action.uuid}>
                                                    <div>
                                                        {' '}
                                                        {getTriggerActionLabel(
                                                            action.slug
                                                        )}
                                                    </div>

                                                    {(action.new_price ||
                                                        action.new_price ===
                                                            0) && (
                                                        <div>
                                                            {' '}
                                                            Nýtt verð:{' '}
                                                            {
                                                                action.new_price
                                                            }{' '}
                                                            kr.
                                                        </div>
                                                    )}

                                                    {action.products.length >
                                                        0 && (
                                                        <div>
                                                            {action.products.map(
                                                                (product) => {
                                                                    return (
                                                                        <Tag
                                                                            key={
                                                                                product.uuid
                                                                            }
                                                                        >
                                                                            {
                                                                                product.title
                                                                            }
                                                                        </Tag>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        },
                    },

                    {
                        title: '',
                        render: (trigger: Trigger) => (
                            <Button
                                onClick={() =>
                                    setActionFormModal({
                                        uuid: trigger.uuid,
                                        create: false,
                                    })
                                }
                                size="small"
                            >
                                Breyta
                            </Button>
                        ),
                    },
                ]}
            ></Table>

            <ActionFormModal
                onCancel={() => setActionFormModal(false)}
                onSuccess={() => {
                    getTriggers();
                }}
                shop={shop}
                open={!!actionFormModal}
                uuid={actionFormModal ? actionFormModal.uuid : undefined}
            ></ActionFormModal>
        </div>
    );
}
