import { Button, Checkbox, Input, Modal, Space, message } from 'antd';
import { useState } from 'react';
import Shop from '../../../interfaces/shop';
import { useSearchParams } from 'react-router-dom';

type Props = { open: boolean; shop: Shop; onCancel: () => void };

export default function PublicWelfareModal({ shop, open, onCancel }: Props) {
    const [webKey, setWebKey] = useState('');
    const [cardTransactions, setCardTransactions] = useState(true);
    const [claims, setClaims] = useState(true);

    const lastYear = new Date().getFullYear() - 1;

    return (
        <Modal
            footer={false}
            title="Gagnaskil til RSK"
            open={open}
            onCancel={() => onCancel()}
        >
            <p>
                Almannaheillafélög þurfa að skila inn upplýsingum, rafrænt eigi
                síðar en 20. janúar.
            </p>

            <p>
                Skila þarf í gegnum gagnaskil á skattur.is. Tvær leiðir eru í
                boði, handskráning einstakra styrktaraðila eða með því að senda
                inn XML skrá með upplýsingum um styrktaraðila.
            </p>

            <p>
                Þú getur sótt XML skrána með því að smella á "Sækja skrá" hér að
                neðan. Athugaðu að til þess að skráin sé gild þarf að fylla út
                RSK veflykil.
            </p>

            <p>
                Þegar skráin hefur verið sótt er hægt að fara inn á skattur.is
                og hlaða henni upp.
            </p>

            <Space
                style={{ display: 'flex', width: '100%' }}
                direction="vertical"
                size={'large'}
            >
                <div>
                    <label>Veflykill</label>
                    <Input
                        value={webKey}
                        onChange={(e) => setWebKey(e.target.value)}
                        size="large"
                    ></Input>
                </div>

                <div>
                    <label>
                        <Checkbox
                            checked={cardTransactions}
                            onChange={(e) =>
                                setCardTransactions(e.target.checked)
                            }
                        ></Checkbox>{' '}
                        Kortafærslur
                    </label>
                </div>

                <div>
                    <label>
                        <Checkbox
                            checked={claims}
                            onChange={(e) => setClaims(e.target.checked)}
                        ></Checkbox>{' '}
                        Kröfur
                    </label>
                </div>

                <Button
                    onClick={() => {
                        if (!claims && !cardTransactions) {
                            message.error(
                                'Þú þarft að velja að minnsta kosti eina tegund af færslum'
                            );
                            return;
                        }
                        window.open(
                            `/public_welfare_report/?webKey=${webKey}&cardTransactions=${cardTransactions}&claims=${claims}&shop_uuid=${shop.uuid}`,
                            '_blank'
                        );
                    }}
                    size="large"
                    type="primary"
                    block
                >
                    Sækja skrá vegna {lastYear}
                </Button>
            </Space>
        </Modal>
    );
}
