import React from 'react';
import EmailOverview from '../../components/EmailOverview/EmailOverview';
import {Layout, Card, Row, Col} from 'antd';

const {Content} = Layout;

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function EmailOverviewPage() {
    return (
        <Layout id='layout_wrapper'>
            <Content>
                <Row gutter={[10, 10]} className='main_row'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={16}>
                        <div className='text-center' style={{fontSize: 32, margin: 20}}>
                            <div>Sendir tölvupóstar</div>
                        </div>

                        <Card>
                            <EmailOverview url={`/tolvupostur/api/message/?shop=&shop__uuid=${w.uuid}&page_size=25&ordering=-created`} shopUUID={w.uuid || ''} pagination={true} />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
