import { Button, Input, Tabs, message } from 'antd';
import { OrderProduct } from '../../interfaces/order';
import TabPane from 'antd/lib/tabs/TabPane';
import Subscription, {
    PublicSubscription,
} from '../../interfaces/subscription';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import translate, { LanguageKey } from '../../translate/translate';

type Props = {
    onChange?: () => void;
    subscriptions?: Subscription[] | PublicSubscription[];
    orderProducts?: OrderProduct[];
    language: LanguageKey;
};

export default function PodcastInstructions({
    onChange,
    subscriptions,
    orderProducts,
    language,
}: Props) {
    return (
        <div>
            <p>
                {(orderProducts || subscriptions) && (
                    <>
                        {(orderProducts || subscriptions || []).length > 1
                            ? translate('your_rss_links', language)
                            : translate('your_rss_link', language)}
                    </>
                )}
                :
            </p>
            {orderProducts &&
                orderProducts.map((orderProduct) => {
                    return (
                        <div key={orderProduct.id}>
                            <h4>{orderProduct.product.title}</h4>
                            <Input
                                size="large"
                                value={
                                    orderProduct.product.repeat_rss_feed
                                        ? `https://repeat.is/efni/rss/subscription/${orderProduct.subscription_uuid}`
                                        : `${window.location.origin}/podcast/${orderProduct.subscription_uuid}`
                                }
                            ></Input>
                            {/* @ts-ignore */}
                            <CopyToClipboard
                                text={
                                    orderProduct.product.repeat_rss_feed
                                        ? `https://repeat.is/efni/rss/subscription/${orderProduct.subscription_uuid}`
                                        : `${window.location.origin}/podcast/${orderProduct.subscription_uuid}`
                                }
                                onCopy={() =>
                                    message.success(
                                        'Tókst, hlekkurinn hefur verið afritaður!'
                                    )
                                }
                            >
                                <Button
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                    block
                                >
                                    {translate('copy_link', language)}
                                </Button>
                            </CopyToClipboard>
                        </div>
                    );
                })}
            {subscriptions &&
                subscriptions.map((subscription) => {
                    return (
                        <div key={subscription.uuid}>
                            <h4>{subscription.product.title}</h4>
                            <Input
                                size="small"
                                value={
                                    subscription.product.repeat_rss_feed
                                        ? `https://repeat.is/efni/rss/subscription/${subscription.uuid}`
                                        : `${window.location.origin}/podcast/${subscription.uuid}`
                                }
                            ></Input>
                            {/* @ts-ignore */}
                            <CopyToClipboard
                                text={
                                    subscription.product.repeat_rss_feed
                                        ? `https://repeat.is/efni/rss/subscription/${subscription.uuid}`
                                        : `${window.location.origin}/podcast/${subscription.uuid}`
                                }
                                onCopy={() =>
                                    message.success(
                                        'Tókst, hlekkurinn hefur verið afritaður!'
                                    )
                                }
                            >
                                <Button
                                    size="small"
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        backgroundColor: '#424242',
                                        color: 'white',
                                        border: 'none',
                                    }}
                                    block
                                >
                                    {translate('copy_link', language)}
                                </Button>
                                {/* @ts-ignore */}
                            </CopyToClipboard>
                        </div>
                    );
                })}
            <br></br>
            <h3>{translate('how_to_add_podcast', language)}</h3>

            <Tabs
                defaultActiveKey="1"
                onChange={() => {
                    if (onChange) onChange();
                }}
                style={{
                    textAlign: 'left',
                }}
            >
                <TabPane tab="iOS" key="ios">
                    <h3>iPhone / ipad</h3>

                    {translate('ios_podcast_instructions', language)}
                </TabPane>
                <TabPane tab="Android" key="android">
                    <h3>Android</h3>
                    <h4>{translate('get_podcast_addict', language)}</h4>

                    {translate('android_podcast_instructions', language)}
                </TabPane>
            </Tabs>
        </div>
    );
}
