import Shop from '../../interfaces/shop';
import Axios from 'axios';
import { Button, Form, FormInstance, Input, Switch, Tabs, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

type Props = {
    shop: Shop;
};

export default function NotificationSettings({ shop }: Props) {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();

    const NotificationForm = (
        activeText: string,
        activeName: string,
        titleName: string,
        bodyName: string,
        form: FormInstance
    ) => {
        return (
            <Form
                initialValues={shop}
                form={form}
                layout="vertical"
                size="large"
                onFinish={(values) => {
                    Axios.patch('/verslun/api/shop/' + shop.uuid + '/', {
                        ...values,
                    })
                        .then(() => {
                            message.success('Breytingar hafa verið vistaðar');
                        })
                        .catch(() => {
                            message.error('Eitthvað fór úrskeiðis');
                        });
                }}
            >
                <Form.Item
                    valuePropName="checked"
                    name={activeName}
                    label={activeText}
                >
                    <Switch
                        checkedChildren="JÁ"
                        unCheckedChildren="NEI"
                    ></Switch>
                </Form.Item>

                <Form.Item name={titleName} label="Titill">
                    <Input />
                </Form.Item>

                <Form.Item
                    name={bodyName}
                    label="Texti"
                    help="Ath, það má nota HTML (þarf ekki)"
                >
                    <TextArea rows={15} />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" size="large" type="primary">
                        Staðfesta
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    return (
        <Tabs defaultActiveKey="1" tabPosition="left">
            <Tabs.TabPane tab="Pöntun staðfesting" key="1">
                {NotificationForm(
                    'Viltu senda staðfestingarpóst á viðskiptavini þegar pöntun er gerð?',
                    'send_order_confirmation_email',
                    'order_confirmation_subject',
                    'order_confirmation_message',
                    form1
                )}
            </Tabs.TabPane>

            <Tabs.TabPane tab="Áskrift afvirkjuð" key="2">
                {NotificationForm(
                    'Viltu senda tölvupóst á viðskiptavini þegar áskrift óvirkjast?',
                    'send_cancel_subscription_email',
                    'cancel_subscription_email_subject',
                    'cancel_subscription_message',
                    form2
                )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Greiðsla tókst ekki" key="3">
                {NotificationForm(
                    'Viltu senda póst á viðskiptavini þegar ekki tekst að rukka kort?',
                    'send_payment_update_reminders',
                    'payment_update_reminder_email_subject',
                    'payment_update_reminder_email_body',
                    form3
                )}
            </Tabs.TabPane>
        </Tabs>
    );
}
