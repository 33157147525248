import { useEffect, useState } from 'react';
import {
    RequestLogDetail,
    RequestLogOverview,
} from '../../interfaces/requestLog';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Axios from 'axios';
import { Spin, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
    log: RequestLogOverview;
};

export default function LogDetails(props: Props) {
    const [log, setLog] = useState<RequestLogDetail>();

    useEffect(() => {
        Axios.get(
            '/api/external_request_log_details/' + props.log.id + '/'
        ).then((rsp) => setLog(rsp.data));

        return () => {
            setLog(undefined);
        };
    }, [props.log]);

    return log ? (
        <Tabs
            defaultActiveKey="1"
            type="card"
            size={'large'}
            items={[
                {
                    label: 'Payload',
                    key: 'payload',
                    children: [
                        <div
                            style={{
                                fontSize: '18px',
                                marginBottom: '10px',
                            }}
                        >
                            Method: {log.method}
                        </div>,
                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: false,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(log.url, null, 2),
                        }),
                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: true,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(log.payload, null, 2),
                        }),
                    ],
                },
                {
                    label: 'Response',
                    key: 'response',
                    children: [
                        <div
                            style={{
                                fontSize: '18px',
                                marginBottom: '10px',
                            }}
                        >
                            Status code: {log.status_code}
                        </div>,

                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: true,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(log.response, null, 2),
                        }),
                    ],
                },
            ]}
        />
    ) : (
        <div>
            <h2>Sæki gögn</h2>
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
        </div>
    );
}
