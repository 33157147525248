import { useEffect, useState } from 'react';
import Customer from '../../../interfaces/customer';
import axios from 'axios';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Input,
    Modal,
    Select,
    message,
} from 'antd';

import { EditOutlined, LockOutlined } from '@ant-design/icons';

type Props = {
    customer: Customer;
};

interface Membership {
    id: number;
    customer_id: number;
    customer_data: any[];
    plan_id: number;
    plan_name: string;
    status: string;
    order_id: number;
    product_id: number;
    subscription_id?: number;
    date_created: string;
    date_created_gmt: string;
    start_date: string;
    start_date_gmt: string;
    end_date: string;
    end_date_gmt: string;
    paused_date?: string;
    paused_date_gmt?: string;
    cancelled_date: string;
    cancelled_date_gmt: string;
    view_url: string;
    profile_fields: any[];
    meta_data: any[];
    _links?: {
        self?: {
            href: string;
        }[];
        collection?: {
            href: string;
        }[];
        customer?: {
            href: string;
        }[];
        order?: {
            href: string;
        }[];
        product?: {
            href: string;
        }[];
    };
}

export default function CustomerWooMembershipDetails({ customer }: Props) {
    const [createUserModal, setCreateUserModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [changeLoginEmailModal, setChangeLoginEmailModal] = useState(false);
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [changeMembershipStatusModal, setChangeMembershipStatusModal] =
        useState<Membership | false>(false);

    useEffect(() => {
        axios
            .get('/efni/get_woo_user_from_customer_uuid/' + customer.uuid)
            .then((res) => {
                setMemberships(res.data.memberships);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const isMembershipActive = (membership: Membership[]) => {
        return membership.filter((m) => m.status === 'active').length > 0;
    };

    const isActive = isMembershipActive(memberships);

    return (
        <>
            <Card
                title={
                    <div>
                        <img
                            style={{
                                width: 50,

                                marginRight: 3,
                            }}
                            src="/static/images/svg/woocommerce.svg"
                            alt="Woo"
                        />{' '}
                    </div>
                }
                headStyle={{
                    textAlign: 'center',
                }}
                bodyStyle={{
                    padding: 20,
                    paddingTop: 0,
                }}
            >
                <h4 className="text-center">
                    <div>
                        <Badge
                            status={
                                customer.external_user_id ? 'success' : 'error'
                            }
                        />{' '}
                        {customer.external_user_id
                            ? 'Customer ID: ' + customer.external_user_id
                            : 'Notandi ekki til'}
                    </div>

                    <div className="mt10">
                        {customer.external_user_id ? (
                            <>
                                <Button
                                    size="small"
                                    type="text"
                                    onClick={() =>
                                        setChangeLoginEmailModal(true)
                                    }
                                >
                                    <EditOutlined /> Breyta netfangi
                                </Button>{' '}
                            </>
                        ) : (
                            <Button onClick={() => setCreateUserModal(true)}>
                                Búa til notanda og lykilorð
                            </Button>
                        )}
                    </div>
                </h4>

                {customer.external_user_id && (
                    <div>
                        <div className="mt10 text-center">
                            <Button
                                size="small"
                                onClick={() => setChangePasswordModal(true)}
                                type="text"
                            >
                                <LockOutlined /> Breyta lykilorði
                            </Button>
                        </div>
                    </div>
                )}

                <h5 className="text-center mt10">Memberships:</h5>

                {memberships.map((membership) => (
                    <div
                        style={{
                            marginBottom: 10,
                            padding: 10,
                            borderRadius: 5,
                            background: 'rgb(228 228 228)',
                            textAlign: 'center',
                            display: 'flex',
                            gap: 10,
                            flexDirection: 'column',
                        }}
                    >
                        <strong>{membership.plan_name}</strong>
                        <div
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setChangeMembershipStatusModal(membership);
                            }}
                        >
                            {membership.status === 'active' ? (
                                <Badge status="success" text="Virkt" />
                            ) : (
                                <Badge status="error" text="Óvirkt" />
                            )}{' '}
                            <EditOutlined />
                        </div>
                        <div>
                            <small>Membership ID: {membership.id} </small>
                        </div>
                    </div>
                ))}
            </Card>

            <Modal
                open={!!changeMembershipStatusModal}
                onCancel={() => setChangeMembershipStatusModal(false)}
                title="Breyta stöðu á membership"
                footer={false}
            >
                {changeMembershipStatusModal && (
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            axios
                                .post(
                                    '/efni/change_woo_membership_status/' +
                                        customer.uuid +
                                        '/',
                                    {
                                        status: values.status,
                                        plan_id:
                                            changeMembershipStatusModal.plan_id,
                                        membership_id:
                                            changeMembershipStatusModal.id,
                                    }
                                )
                                .then((res) => {
                                    message.success('Tókst, stöðu breytt!');
                                    setChangeMembershipStatusModal(false);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item
                            initialValue={
                                changeMembershipStatusModal.status === 'active'
                                    ? 'cancelled'
                                    : 'active'
                            }
                            label="Breyta í"
                            name="status"
                        >
                            <Select
                                options={[
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="success"
                                                    text="Virk"
                                                />
                                            </>
                                        ),
                                        value: 'active',
                                    },
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="error"
                                                    text="Óvirk"
                                                />
                                            </>
                                        ),
                                        value: 'cancelled',
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                )}
            </Modal>

            {isActive && (
                <Modal
                    title="Breyta lykilorði notanda"
                    open={changePasswordModal}
                    onCancel={() => setChangePasswordModal(false)}
                    footer={false}
                >
                    <Alert description='Athugaðu að þú getur einnig bent viðkomandi á að breyta lykilorðinu sjálf/ur með því að smella á "Gleymt lykilorð" á vefsíðunni þinni.'></Alert>
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            axios
                                .post(
                                    '/efni/change_woo_customer_password/' +
                                        customer.uuid +
                                        '/',
                                    {
                                        password: values.password,
                                    }
                                )
                                .then((res) => {
                                    message.success('Tókst, lykilorði breytt!');
                                    setChangePasswordModal(false);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item label="Nýtt lykilorð" name="password">
                            <Input type="password" />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                </Modal>
            )}

            {isActive && (
                <Modal
                    title="Breyta innskráninganetfangi notanda"
                    open={changeLoginEmailModal}
                    onCancel={() => setChangeLoginEmailModal(false)}
                    footer={false}
                >
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            axios
                                .post(
                                    '/efni/change_woo_customer_email/' +
                                        customer.uuid +
                                        '/',
                                    {
                                        email: values.email,
                                    }
                                )
                                .then((res) => {
                                    message.success('Tókst, netfangi breytt!');
                                    setChangeLoginEmailModal(false);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item label="Nýtt netfang" name="email">
                            <Input type="text" />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                </Modal>
            )}

            <Modal
                title="Búa til notanda og lykilorð"
                open={createUserModal}
                footer={false}
                onCancel={() => setCreateUserModal(false)}
            >
                <Form
                    size="large"
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);
                        axios
                            .post(
                                '/efni/create_appwrite_user/' +
                                    customer.uuid +
                                    '/',
                                {
                                    password: values.password,
                                    email: values.email,
                                }
                            )
                            .then((res) => {
                                message.success(
                                    'Tókst, notandi hefur verið stofnaður!'
                                );
                                setCreateUserModal(false);

                                // axios
                                //     .get(
                                //         '/efni/get_appwrite_user_from_customer_uuid/' +
                                //             customer.uuid
                                //     )
                                //     .then((res) => {
                                //         setAppwriteCustomer(res.data.user);
                                //     })
                                //     .catch((err) => {
                                //         console.log(err);
                                //     });
                            })
                            .catch((err) => {
                                message.error(err.response.data.error);
                                setLoading(false);
                            });
                    }}
                >
                    <Form.Item
                        name="email"
                        label="Netfang"
                        initialValue={customer.email}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item label="Veldu lykilorð" name="password">
                        <Input type="password" />
                    </Form.Item>

                    <Button
                        disabled={loading}
                        type="primary"
                        htmlType="submit"
                        block
                    >
                        Staðfesta
                    </Button>
                </Form>
            </Modal>
        </>
    );
}
