import { useState, useEffect, useContext } from 'react';
import { Form, message, Button, Switch, Popover, Input } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import AuthContext from '../../../authContext';
import axios from 'axios';
import Shop from '../../../interfaces/shop';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function ShopExtraSettings() {
    const { shops, selectedShop } = useContext(AuthContext);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [shop, setShop] = useState<Shop>();
    const [reRender, setReRender] = useState(false);

    useEffect(() => {
        if (shops && selectedShop) {
            const _shop = shops.find((s) => s.uuid === selectedShop.uuid);

            if (!_shop) {
                return;
            }
            form.setFieldsValue(_shop);
            setShop(_shop);
        }
    }, [shops]);

    return (
        <Form
            onValuesChange={() => {
                setReRender(!reRender);
            }}
            form={form}
            layout="vertical"
            name="basic"
            onFinish={(values) => {
                setLoading(true);

                if ((shop && !shop.admins) || !shop) {
                    values.admins = [w.me];
                }

                if (selectedShop?.uuid) {
                    axios
                        .patch(
                            '/verslun/api/update_shop/' +
                                selectedShop.uuid +
                                '/',
                            values
                        )
                        .then((rsp) => {
                            message.success('Verslunin hefur verið uppfærð!');
                        })
                        .catch((err) => {
                            message.error(
                                'Úps, eitthvað fór úrskeiðis 🤔 Farðu aftur yfir alla reiti.'
                            );
                            setLoading(false);
                        });
                }
            }}
        >
            <Form.Item
                label={'Viltu selja gjafabréf'}
                name="sell_gift_certificates"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren="JÁ "
                    unCheckedChildren="NEI"
                    onChange={(checked) => {
                        form.setFieldsValue({
                            sell_gift_certificates: checked,
                        });
                    }}
                />
            </Form.Item>

            <Form.Item
                name="manage_permission_based_content"
                valuePropName="checked"
                label={
                    <>
                        <Popover
                            content={
                                <>
                                    <p>
                                        Við mælum með að þú hafir samband við
                                        starfsfólk Repeat til að fá hjálp með
                                        uppsetningu ef þú vilt selja aðgang að
                                        læstu efni
                                    </p>
                                </>
                            }
                        >
                            <QuestionCircleTwoTone
                                twoToneColor="#afafaf"
                                style={{
                                    position: 'absolute',
                                    fontSize: 16,
                                }}
                            />
                        </Popover>
                        <span
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Viltu selja aðgang að læstu efni?
                        </span>
                    </>
                }
            >
                <Switch
                    checkedChildren="JÁ "
                    unCheckedChildren="NEI"
                    onChange={(checked) => {
                        form.setFieldsValue({
                            manage_permission_based_content: checked,
                        });
                    }}
                />
            </Form.Item>

            <Form.Item label="Hlekkur á skilmála verslunar" name="terms_url">
                <Input></Input>
            </Form.Item>

            {!form.getFieldValue('terms_url') && (
                <Form.Item
                    name="terms"
                    label="Skilmálar verslunar (bara notað ef hlekkurinn hér að ofan er tómur)"
                    help="Má vera HTML, má einnig nota einfaldan texta"
                >
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        setOptions={{
                            useWorker: false,
                        }}
                        showPrintMargin={false}
                        width="100%"
                    ></AceEditor>
                </Form.Item>
            )}

            <Form.Item>
                <Button
                    className="btn-success"
                    block
                    size="large"
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
