import dayjs from 'dayjs';
import axios from 'axios';
import Product, { Currency, RepeatCheckoutProduct } from './interfaces/product';
import translate, { LanguageKey } from './translate/translate';
import { Popover, Badge } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import { SimpleCustomer } from './interfaces/customer';
import Shop from './interfaces/shop';
import { PublicSubscription } from './interfaces/subscription';
import { ShippingProviders } from './interfaces/delivery';
import { OrderProductAttribute } from './interfaces/order';
import { format } from 'prettier/standalone';
import parserHtml from 'prettier/plugins/html';

export const getAvailablePaymentMethods = (type?: 'cards' | 'claims') => {
    const cardTypes = [
        'SALT_PAY',
        'VALITOR',
        'STRAUMUR',
        'RAPYD',
        'ONPAY',
        'TEYA',
        'ONPAY',
    ];

    if (!!!type) {
        return [...cardTypes, 'BANK_CLAIM'];
    } else if (type === 'cards') {
        return cardTypes;
    } else if (type === 'claims') {
        return ['BANK_CLAIM'];
    } else {
        return [];
    }
};

export const kr = (x: any, suffix?: string) => {
    x = x || 0;
    const round = Math.round(x);
    let parts = round.toString().split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return parts.join('.') + (suffix === undefined ? ' kr' : suffix);
};

export const displayCurrencyPrice = (
    amount: number | false,
    currency: Currency['currency_code'],
    product?: Product
) => {
    let _amount = amount || 0;

    if (amount === false && product) {
        const currencyAmount = product.prices.find(
            (p) => p.currency.currency_code === currency
        )?.amount;

        if (currencyAmount) {
            _amount = currencyAmount;
        }
    }

    if (currency === 'ISK') {
        return kr(_amount);
    }

    const userLocale = navigator.language || 'us-US';

    const formatted = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currency: currency,
    }).format(_amount);

    return formatted;
};

export const subscriptionProductSuffix = (
    product: Product,
    lang: LanguageKey = 'is'
) => {
    if (product.custom_suffix) {
        return ' ' + product.custom_suffix.trim();
    } else if (product.type === 'SUBSCRIPTION') {
        return ` / ${translate('each_time', lang)}`;
    } else {
        return '';
    }
};

export const getUrlParameter = (sParam: string) => {
    let sPageURL = window.location.search.substring(1);
    let sURLVariables = sPageURL.split('&');
    let sParameterName;
    let i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined
                ? ''
                : decodeURIComponent(sParameterName[1]);
        }
    }

    return '';
};

export const sendPostMessage = (uuid: string) => {
    setTimeout(() => {
        try {
            const element_heights = [];
            const modals = document.getElementsByClassName('ant-modal');

            if ([...modals].length) {
                const firstModal = modals[0] as HTMLElement;
                element_heights.push(firstModal.offsetHeight);
            }

            const parent =
                document.getElementById('product_container') ||
                document.getElementById('certificateFormParent');

            const offsetHeight = parent?.offsetHeight || 0;

            const current_height = Math.max(
                ...element_heights,
                0,
                offsetHeight
            );

            window.parent.postMessage(
                {
                    uuid,
                    frameHeight: current_height + 40,
                },
                '*'
            );
        } catch (error) {
            console.log(error);
        }
    }, 20);
};

const _postalCodes = [
    { code: '101', name: 'Reykjavík' },
    { code: '102', name: 'Reykjavík' },
    { code: '103', name: 'Reykjavík' },
    { code: '104', name: 'Reykjavík' },
    { code: '105', name: 'Reykjavík' },
    { code: '107', name: 'Reykjavík' },
    { code: '108', name: 'Reykjavík' },
    { code: '109', name: 'Reykjavík' },
    { code: '110', name: 'Reykjavík' },
    { code: '111', name: 'Reykjavík' },
    { code: '112', name: 'Reykjavík' },
    { code: '113', name: 'Reykjavík' },
    { code: '116', name: 'Reykjavík' },
    { code: '121', name: 'Reykjavík' },
    { code: '123', name: 'Reykjavík' },
    { code: '124', name: 'Reykjavík' },
    { code: '125', name: 'Reykjavík' },
    { code: '127', name: 'Reykjavík' },
    { code: '128', name: 'Reykjavík' },
    { code: '129', name: 'Reykjavík' },
    { code: '130', name: 'Reykjavík' },
    { code: '132', name: 'Reykjavík' },
    { code: '150', name: 'Reykjavík' },
    { code: '155', name: 'Reykjavík' },
    { code: '161', name: 'Reykjavík' },
    { code: '162', name: 'Reykjavík' },
    { code: '170', name: 'Seltjarnarnes' },
    { code: '172', name: 'Seltjarnarnes' },
    { code: '200', name: 'Kópavogur' },
    { code: '201', name: 'Kópavogur' },
    { code: '202', name: 'Kópavogur' },
    { code: '203', name: 'Kópavogur' },
    { code: '206', name: 'Kópavogur' },
    { code: '210', name: 'Garðabær' },
    { code: '212', name: 'Garðabær' },
    { code: '220', name: 'Hafnarfjörður' },
    { code: '221', name: 'Hafnarfjörður' },
    { code: '222', name: 'Hafnarfjörður' },
    { code: '225', name: 'Garðabær' },
    { code: '270', name: 'Mosfellsbær' },
    { code: '271', name: 'Mosfellsbær' },
    { code: '276', name: 'Mosfellsbær' },
    { code: '190', name: 'Vogar' },
    { code: '191', name: 'Vogar' },
    { code: '230', name: 'Reykjanesbær' },
    { code: '232', name: 'Reykjanesbær' },
    { code: '233', name: 'Reykjanesbær' },
    { code: '235', name: 'Reykjanesbær' },
    { code: '240', name: 'Grindavík' },
    { code: '241', name: 'Grindavík' },
    { code: '245', name: 'Suðurnesjabær' },
    { code: '246', name: 'Suðurnesjabær' },
    { code: '250', name: 'Suðurnesjabær' },
    { code: '251', name: 'Suðurnesjabær' },
    { code: '260', name: 'Reykjanesbær' },
    { code: '262', name: 'Reykjanesbær' },
    { code: '300', name: 'Akranes' },
    { code: '301', name: 'Akranes' },
    { code: '302', name: 'Akranes' },
    { code: '310', name: 'Borgarnes' },
    { code: '311', name: 'Borgarnes' },
    { code: '320', name: 'Reykholt' },
    { code: '340', name: 'Stykkishólmur' },
    { code: '341', name: 'Stykkishólmur' },
    { code: '342', name: 'Stykkishólmur' },
    { code: '345', name: 'Flatey' },
    { code: '350', name: 'Grundarfjörður' },
    { code: '351', name: 'Grundarfjörður' },
    { code: '355', name: 'Ólafsvík' },
    { code: '356', name: 'Snæfellsbær' },
    { code: '360', name: 'Hellissandur' },
    { code: '370', name: 'Búðardalur' },
    { code: '371', name: 'Búðardalur' },
    { code: '380', name: 'Reykhólahreppur' },
    { code: '381', name: 'Reykhólahreppur' },
    { code: '400', name: 'Ísafjörður' },
    { code: '401', name: 'Ísafjörður' },
    { code: '410', name: 'Hnífsdalur' },
    { code: '415', name: 'Bolungarvík' },
    { code: '416', name: 'Bolungarvík' },
    { code: '420', name: 'Súðavík' },
    { code: '421', name: 'Súðavík' },
    { code: '425', name: 'Flateyri' },
    { code: '426', name: 'Flateyri' },
    { code: '430', name: 'Suðureyri' },
    { code: '431', name: 'Suðureyri' },
    { code: '450', name: 'Patreksfjörður' },
    { code: '451', name: 'Patreksfjörður' },
    { code: '460', name: 'Tálknafjörður' },
    { code: '461', name: 'Tálknafjörður' },
    { code: '465', name: 'Bíldudalur' },
    { code: '466', name: 'Bíldudalur' },
    { code: '470', name: 'Þingeyri' },
    { code: '471', name: 'Þingeyri' },
    { code: '500', name: 'Staður' },
    { code: '510', name: 'Hólmavík' },
    { code: '511', name: 'Hólmavík' },
    { code: '512', name: 'Hólmavík' },
    { code: '520', name: 'Drangsnes' },
    { code: '524', name: 'Árneshreppur' },
    { code: '530', name: 'Hvammstangi' },
    { code: '531', name: 'Hvammstangi' },
    { code: '540', name: 'Blönduós' },
    { code: '541', name: 'Blönduós' },
    { code: '545', name: 'Skagaströnd' },
    { code: '546', name: 'Skagaströnd' },
    { code: '550', name: 'Sauðárkrókur' },
    { code: '551', name: 'Sauðárkrókur' },
    { code: '560', name: 'Varmahlíð' },
    { code: '561', name: 'Varmahlíð' },
    { code: '565', name: 'Hofsós' },
    { code: '566', name: 'Hofsós' },
    { code: '570', name: 'Fljótum' },
    { code: '580', name: 'Siglufjörður' },
    { code: '581', name: 'Siglufjörður' },
    { code: '600', name: 'Akureyri' },
    { code: '601', name: 'Akureyri' },
    { code: '602', name: 'Akureyri' },
    { code: '603', name: 'Akureyri' },
    { code: '604', name: 'Akureyri' },
    { code: '605', name: 'Akureyri' },
    { code: '606', name: 'Akureyri' },
    { code: '607', name: 'Akureyri' },
    { code: '610', name: 'Grenivík' },
    { code: '611', name: 'Grímsey' },
    { code: '616', name: 'Grenivík' },
    { code: '620', name: 'Dalvík' },
    { code: '621', name: 'Dalvík' },
    { code: '625', name: 'Ólafsfjörður' },
    { code: '626', name: 'Ólafsfjörður' },
    { code: '630', name: 'Hrísey' },
    { code: '640', name: 'Húsavík' },
    { code: '641', name: 'Húsavík' },
    { code: '645', name: 'Fosshóll' },
    { code: '650', name: 'Laugar' },
    { code: '660', name: 'Mývatn' },
    { code: '670', name: 'Kópasker' },
    { code: '671', name: 'Kópasker' },
    { code: '675', name: 'Raufarhöfn' },
    { code: '676', name: 'Raufarhöfn' },
    { code: '680', name: 'Þórshöfn' },
    { code: '681', name: 'Þórshöfn' },
    { code: '685', name: 'Bakkafjörður' },
    { code: '686', name: 'Bakkafjörður' },
    { code: '690', name: 'Vopnafjörður' },
    { code: '691', name: 'Vopnafjörður' },
    { code: '700', name: 'Egilsstaðir' },
    { code: '701', name: 'Egilsstaðir' },
    { code: '710', name: 'Seyðisfjörður' },
    { code: '711', name: 'Seyðisfjörður' },
    { code: '715', name: 'Mjóafjörður' },
    { code: '720', name: 'Borgarfjörður' },
    { code: '721', name: 'Borgarfjörður' },
    { code: '730', name: 'Reyðarfjörður' },
    { code: '731', name: 'Reyðarfjörður' },
    { code: '735', name: 'Eskififjörður' },
    { code: '736', name: 'Eskififjörður' },
    { code: '740', name: 'Neskaupstaður' },
    { code: '741', name: 'Neskaupstaður' },
    { code: '750', name: 'Fáskrúðsfjörður' },
    { code: '751', name: 'Fáskrúðsfjörður' },
    { code: '755', name: 'Stöðvarfjörður' },
    { code: '756', name: 'Stöðvarfjörður' },
    { code: '760', name: 'Breiðdalsvík' },
    { code: '761', name: 'Breiðdalsvík' },
    { code: '765', name: 'Djúpavogur' },
    { code: '766', name: 'Djúpavogur' },
    { code: '780', name: 'Höfn' },
    { code: '781', name: 'Höfn' },
    { code: '785', name: 'Öræfi' },
    { code: '800', name: 'Selfoss' },
    { code: '801', name: 'Selfoss' },
    { code: '802', name: 'Selfoss' },
    { code: '803', name: 'Selfoss' },
    { code: '804', name: 'Selfoss' },
    { code: '805', name: 'Selfoss' },
    { code: '806', name: 'Selfoss' },
    { code: '810', name: 'Hveragerði' },
    { code: '815', name: 'Þorlákshöfn' },
    { code: '816', name: 'Ölfus' },
    { code: '820', name: 'Eyrarbakki' },
    { code: '825', name: 'Stokkseyri' },
    { code: '840', name: 'Laugarvatn' },
    { code: '845', name: 'Flúðir' },
    { code: '846', name: 'Flúðir' },
    { code: '850', name: 'Hella' },
    { code: '851', name: 'Hella' },
    { code: '860', name: 'Hvolsvöllur' },
    { code: '861', name: 'Hvolsvöllur' },
    { code: '870', name: 'Vík' },
    { code: '871', name: 'Vík' },
    { code: '880', name: 'Kirkjubæjarklaustur' },
    { code: '881', name: 'Kirkjubæjarklaustur' },
    { code: '900', name: 'Vestmannaeyjar' },
    { code: '902', name: 'Vestmannaeyjar' },
];

export const postalCodes = _postalCodes;

export const getPostalName = (postalCode: string) => {
    const codes = _postalCodes;

    if (postalCode) {
        const postalCodeObject = codes.find((obj) => obj.code === postalCode);
        if (postalCodeObject) return postalCodeObject.name;
    }
    return '';
};

export const getCronLabel = (
    interval: number,
    intervalType?: 'MONTH' | 'YEAR' | 'DAY' | 'WEEK',
    baseProduct?: Product,
    baseDate?: string
) => {
    if (!interval || !intervalType) return 'Ekki virkt';

    const plural = interval > 1;

    let label;

    if (intervalType === 'MONTH') label = 'mán';
    if (intervalType === 'DAY') label = 'daga';
    if (intervalType === 'WEEK') label = plural ? 'vikna' : 'viku';
    if (intervalType === 'YEAR') label = plural ? 'ára' : 'árs';

    const cronDisabled =
        baseProduct && !baseProduct.cron_transaction?.is_active;

    return (
        <>
            {' '}
            {cronDisabled ? (
                <Popover content="Ath, þessi vara fyrir þessa áskrift er með slökkt á sjálfkrafa færslum.">
                    <Badge status="error" />
                </Popover>
            ) : (
                ''
            )}{' '}
            {`${interval} ${label} fresti ${
                baseDate
                    ? 'frá (' + dayjs(baseDate).format('D. MMM YYYY') + ')'
                    : ''
            }`}
        </>
    );
};

export const getCronLabelSimple = (
    interval?: number,
    intervalType?: 'MONTH' | 'YEAR' | 'DAY' | 'WEEK',
    baseProduct?: Product | null,
    baseDate?: string | null,
    lang: LanguageKey = 'is'
) => {
    if (!interval || !intervalType) return '';

    const cronDisabled =
        baseProduct && !baseProduct.cron_transaction?.is_active;

    if (cronDisabled) return '';

    if (lang === 'is') {
        let label;
        const plural = interval > 1;

        if (intervalType === 'MONTH') label = 'mán';
        if (intervalType === 'DAY') label = 'daga';
        if (intervalType === 'WEEK') label = plural ? 'vikna' : 'viku';
        if (intervalType === 'YEAR') label = plural ? 'ára' : 'árs';

        return `${interval} ${label} fresti ${
            baseDate
                ? 'frá (' + dayjs(baseDate).format('D. MMM YYYY') + ')'
                : ''
        }`;
    } else if (lang === 'en') {
        let label;
        const plural = interval > 1;

        if (intervalType === 'MONTH') label = plural ? 'months' : 'month';
        if (intervalType === 'DAY') label = plural ? 'days' : 'day';
        if (intervalType === 'WEEK') label = plural ? 'weeks' : 'week';
        if (intervalType === 'YEAR') label = plural ? 'years' : 'year';

        return `${interval} ${label} apart ${
            baseDate
                ? 'from (' + dayjs(baseDate).format('D. MMM YYYY') + ')'
                : ''
        }`;
    }
};

export const getProductTranslation = (product: Product, lang: LanguageKey) => {
    const { translations } = product;

    const translation = translations.find((t) => t.language.code === lang);

    return {
        title: translation?.title || product.title,
        description: translation?.description || product.description,
        ...(translation || {}),
    };
};

export const getDefaultCurrency = (
    shop?: Shop,
    customer?: SimpleCustomer,
    subscriptions?: PublicSubscription[]
) => {
    let defaultCurrency = shop?.default_currency;

    if (subscriptions && subscriptions.length) {
        defaultCurrency = subscriptions[0].subscription_product.currency_code;
    } else if (customer) {
        defaultCurrency = customer.shop.default_currency;
    }

    return defaultCurrency || 'ISK';
};

export const orderCreationLabel = (slug?: string) => {
    if (slug === 'REPEAT_CHECKOUT') return 'Repeat greiðslusíða';
    if (slug === 'OLD_CHECKOUT') return 'Gamla greiðslusíðan (iFrame)';
    if (slug === 'API') return 'API';
    if (slug === 'MY_SUBSCRIPTION_PAGE') return 'Mínar síður';
    if (slug === 'GIFT_CERTIFICATE') return 'Gjafabréf';
    if (slug === 'EXCEL_IMPORT') return 'Excel import';
    if (slug === 'SHOP_OWNER') return 'Verslun bakendi';
    return '';
};

export const getDeliveryIcon = (
    shippingProvider: ShippingProviders,
    maxWidth?: number
) => {
    return (
        <>
            {/* DROPP */}
            {['DROPP_PICKUP', 'DROPP_HOMESENT'].includes(shippingProvider) && (
                <img
                    src="/static/images/global_logos/dropp.svg"
                    alt="Dropp logo"
                    style={{ maxWidth: maxWidth || 100 }}
                />
            )}

            {/* PÓSTURINN */}
            {[
                'POSTURINN_DPH',
                'POSTURINN_DPO',
                'POSTURINN_DPP',
                'POSTURINN_DPT',
            ].includes(shippingProvider) && (
                <img
                    src="/static/images/svg/posturinn.svg"
                    alt="Posturinn logo"
                    style={{ maxWidth: maxWidth || 100 }}
                />
            )}

            {/* PIKKOLO */}
            {shippingProvider === 'PIKKOLO' && (
                <img
                    src="/static/images/partner_logos/pikkolo.webp"
                    alt="Pikkolo logo"
                    style={{
                        maxWidth: maxWidth && maxWidth < 50 ? maxWidth : 50,
                    }}
                />
            )}

            {/* CUSTOM */}
            {shippingProvider == 'CUSTOM' && <ExpandOutlined />}
        </>
    );
};
export const getSelectedAttributeInfo = (
    orderAttribute: OrderProductAttribute,
    selectedCurrency: Currency['currency_code']
) => {
    const attribute = orderAttribute.attribute;
    const selectedOptions = orderAttribute.option_answers?.map(
        (optionAnswer) => {
            const option = attribute.options.find(
                (o) => o.uuid === optionAnswer.uuid
            );

            if (!option) return null;

            return {
                ...option,
                prices: optionAnswer.prices,
            };
        }
    );

    const extraCost =
        selectedOptions?.reduce((acc, option) => {
            return (
                acc +
                (option?.prices.find(
                    (p) => p.currency.currency_code === selectedCurrency
                )?.amount || 0)
            );
        }, 0) || 0;

    return {
        label: attribute?.label,
        type: attribute?.type,
        allow_multiple: attribute?.allow_multiple,
        selectedOptions,
        extraCost,
    };
};

export const uploadFileToDigitalOcean = (
    file: File,
    shopUUID: string,
    onSuccess: ({
        url,
        assetType,
    }: {
        url: string;
        assetType: 'IMAGE' | 'AUDIO' | 'PDF' | 'UNKNOWN';
        fileExtension?: string;
    }) => void,
    onError: (error: any) => void,
    onUploadProgress?: (progress: number) => void
) => {
    let uniqueId =
        Date.now().toString(36) +
        Math.random().toString(36).substring(2).toString();

    if (file.name.includes('.')) {
        uniqueId += file.name.substring(file.name.lastIndexOf('.'));
    }

    const temporaryTokenPayload = {
        file_name: uniqueId,
    } as {
        file_name: string;
        mimetype?: string;
    };

    // const removeSvgHeightWidthAndFixViewport = async (file: File) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             const svg = e.target?.result as string;
    //             const parser = new DOMParser();
    //             const doc = parser.parseFromString(svg, 'image/svg+xml');
    //             const svgElement = doc.querySelector('svg');

    //             if (svgElement) {
    //                 svgElement.removeAttribute('width');
    //                 svgElement.removeAttribute('height');
    //                 svgElement.setAttribute('viewBox', '0 0 100 100');
    //             }

    //             const blob = new Blob([doc.documentElement.outerHTML], {
    //                 type: 'image/svg+xml',
    //             });

    //             const newFile = new File([blob], file.name, {
    //                 type: 'image/svg+xml',
    //             });

    //             resolve(newFile);
    //         };

    //         reader.readAsText(file);
    //     });

    // }

    if (uniqueId.includes('.svg')) {
        temporaryTokenPayload.mimetype = 'image/svg+xml';
    }

    axios
        .post(
            `/efni/api/get_temporary_do_token/${shopUUID}/`,
            temporaryTokenPayload
        )
        .then((rsp) => {
            const token = rsp.data.token;
            // Construct a FormData object with the required fields
            const formData = new FormData();
            formData.append('key', token.fields.key);
            formData.append(
                'x-amz-credential',
                token.fields['x-amz-credential']
            );

            formData.append('x-amz-date', token.fields['x-amz-date']);

            formData.append('ACL', 'public-read');

            formData.append('x-amz-algorithm', token.fields['x-amz-algorithm']);
            formData.append('policy', token.fields.policy);
            formData.append('x-amz-signature', token.fields['x-amz-signature']);
            formData.append('x-amz-acl', token.fields['x-amz-acl']);

            if (uniqueId.includes('.svg')) {
                formData.append('Content-Type', 'image/svg+xml');
            }
            formData.append('file', file);

            // Make a POST request to the DigitalOcean Space URL
            const response = axios
                .post('https://repeat.ams3.digitaloceanspaces.com/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = Math.round(
                            (progressEvent.loaded * 100) /
                                (progressEvent.total || 1)
                        );

                        if (onUploadProgress)
                            onUploadProgress(percentCompleted);
                    },
                })
                .then((rsp) => {
                    const url = token.url + token.fields.key;
                    //Checking if the file is a sound file
                    const audioRegex = /^audio\//;
                    const pdfRegex = /^application\/pdf/;
                    const imageRegex = /^image\//;

                    // Check if it's an audio file
                    const isAudio = audioRegex.test(file.type);

                    // Check if it's a PDF
                    const isPDF = pdfRegex.test(file.type);

                    // Check if it's an image
                    const isImage = imageRegex.test(file.type);

                    let assetType: 'IMAGE' | 'AUDIO' | 'PDF' | 'UNKNOWN';

                    if (isImage) {
                        assetType = 'IMAGE';
                    } else if (isAudio) {
                        assetType = 'AUDIO';
                    } else if (isPDF) {
                        assetType = 'PDF';
                    } else {
                        assetType = 'UNKNOWN';
                    }

                    onSuccess({
                        url,
                        assetType,
                        fileExtension: file.name.split('.').pop(),
                    });
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                    onError(error);
                });
        });
};

export const formatHtml = (value: string) => {
    if (!value) return '';

    try {
        return format(value, {
            parser: 'html',
            plugins: [parserHtml],
            printWidth: 80,
            tabWidth: 4,
            htmlWhitespaceSensitivity: 'ignore',
        });
    } catch (error) {
        console.error('HTML formatting failed:', error);
        return value;
    }
};
