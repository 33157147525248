import { useEffect, useState } from 'react';
import Shop from '../../interfaces/shop';

import axios from 'axios';
import { Alert, Button, Form, Input, message, Space, Switch } from 'antd';
import AuthContext from '../../authContext';

type Props = {
    shop: Shop;
};

export default function PaydaySettings({ shop }: Props) {
    const [form] = Form.useForm();
    const [active, setActive] = useState(shop?.payday?.is_active || false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue(shop.payday);

        return () => {
            form.resetFields();
        };
    }, [shop]);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                client_id: shop?.payday?.client_id || '',
                client_secret: shop?.payday?.client_secret || '',
                send_email: shop?.payday?.send_email || false,
            }}
            onFinish={(values) => {
                setLoading(true);
                axios
                    .post(
                        '/verslun/api/create_or_update_store_payday_settings/',
                        {
                            ...values,
                            is_active: active,
                            shop_uuid: shop.uuid,
                        }
                    )
                    .then(() => {
                        message.success('Tókst! 🥳');
                    })
                    .catch(() => {
                        message.error('Það kom upp villa :/');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        >
            <Space
                direction="vertical"
                style={{ display: 'flex' }}
                size="large"
            >
                <Alert
                    message="Stofna þarf API lykil undir Stillingar -> Fyrirtæki -> Samþættingar -> Bæta við"
                    description={
                        <>
                            <p>
                                Valið er "PaydayAPI" og gott er að gefa lyklinum
                                nafnið "Repeat.is"
                            </p>

                            <p>Mikilvægt er að muna að smella á "Staðfesta"</p>
                        </>
                    }
                    type="info"
                />

                <div>
                    <div>Virkja Payday tengingu?</div>
                    <Switch
                        onClick={() => setActive(!active)}
                        checked={active}
                        checkedChildren="JÁ "
                        unCheckedChildren="NEI"
                    ></Switch>
                </div>

                {active && (
                    <>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn API lykilinn',
                                },
                            ]}
                            label="ClientId"
                            name="client_id"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn "ClientSecret"',
                                },
                            ]}
                            label="ClientSecret"
                            name="client_secret"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="Viltu að Payday sendi viðskiptavinum þegar reikningur er stofnaður?"
                            name="send_email"
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="JÁ"
                                unCheckedChildren="NEI"
                            />
                        </Form.Item>
                    </>
                )}
            </Space>

            <Form.Item>
                <Button
                    loading={loading}
                    block
                    className="btn-success mt20"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
