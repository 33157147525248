import React, { useState, useEffect } from 'react';
import { Card, Layout, Row, Col } from 'antd';
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Shop from '../../interfaces/shop';
import Product from '../../interfaces/product';
import { getUrlParameter } from '../../utils';

const { Content } = Layout;

interface IWindow extends Window {
    uuid?: string;
    shop_uuid?: string;
}

const w: IWindow = window;

export default function Terms() {
    const [loading, setLoading] = useState(true);
    const [shop, setShop] = useState<Shop>();
    const [product, setProduct] = useState<Product>();
    const [isProduct, setIsProduct] = useState<boolean>();
    const [content, setContent] = useState('');

    useEffect(() => {
        const productUUID = getUrlParameter('product');
        if (productUUID) {
            Axios.get('/verslun/api/product/' + productUUID).then((rsp) => {
                setProduct(rsp.data);
            });
        }

        setIsProduct(!!productUUID);

        Axios.get('/verslun/api/shop_details/' + w.uuid + '/').then((rsp) => {
            setShop(rsp.data);
        });
    }, []);

    useEffect(() => {
        if ((isProduct && product && shop) || (!isProduct && shop)) {
            let text = (product ? product.terms : shop.terms) || '';
            text = text.replaceAll(
                '__company_name__',
                '<strong>' + shop?.name + '</strong>'
            );
            text = text.replaceAll(
                '__company_ssid__',
                '<strong>' + shop?.ssid + '</strong>'
            );
            text = text.replaceAll(
                '__company_website__',
                '<strong>' + shop?.website + '</strong>'
            );
            text = text.replaceAll(
                '__company_notice__',
                '<strong>' + shop?.notice + '</strong>'
            );
            setContent(text);
            setLoading(false);
        }
    }, [isProduct, product, shop]);

    return (
        <Layout>
            <Content
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Col xs={24} sm={18} md={16} lg={14} xl={10} xxl={8}>
                        <Card
                            loading={loading}
                            title="Skilmálar"
                            extra={shop?.name || ''}
                        >
                            {shop && (
                                <div
                                    style={{
                                        whiteSpace: 'pre-line',
                                        display: 'block',
                                    }}
                                >
                                    {
                                        ReactHtmlParser(
                                            content
                                        ) as React.ReactNode
                                    }
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
