import React, { useState } from 'react';
import { Button, Form, Modal, message } from 'antd';
import Axios from 'axios';
import { SubscriptionTransactionFilterRequest } from '../../../interfaces/transaction';

interface Props {
    selection: SubscriptionTransactionFilterRequest;
    open: boolean;
    onCancel: () => void;
    onSuccess: Function;
}

export default function RefundCardsModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState<React.ReactNode>();

    return (
        <Modal
            title={
                <>
                    Endurgreiða {props.selection.selectedCount} sending
                    {props.selection.selectedCount > 1 ? 'ar' : 'u'}?
                </>
            }
            open={props.open}
            onCancel={props.onCancel}
            footer={null}
        >
            {successMessage || (
                <Form>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                Axios.post(
                                    '/askrift/refund_subscription_transactions/',
                                    {
                                        selection: props.selection,
                                    }
                                )
                                    .then((rsp) => {
                                        props.onSuccess(rsp.data);
                                    })
                                    .catch((err) => {
                                        message.error(
                                            'Úps, eitthvað fór úrskeiðis :/'
                                        );
                                    });
                            }}
                            className="btn-success"
                            block
                            size="large"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Staðfesta
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
}
