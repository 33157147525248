import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import Shop from '../../interfaces/shop';
import Axios from 'axios';

type Props = {
    onCancel: () => void;
    shop: Shop;
};

export default function CreateRepeatMediaAccount({ onCancel, shop }: Props) {
    const [loading, setLoading] = useState(false);

    return (
        <Form
            size="large"
            layout="vertical"
            onFinish={(values) => {
                setLoading(true);

                Axios.post('/efni/create_repeat_media_account/', {
                    ...values,
                    shop: shop.uuid,
                })
                    .then((rsp) => {
                        setLoading(false);
                        onCancel();
                        message.success({
                            content: 'Nýr aðgangur búinn til',
                            duration: 5,
                        });
                        window.location.reload();
                    })
                    .catch((err) => {
                        message.error({
                            content: err.response.data.errors,
                            duration: 5,
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        >
            <Form.Item
                name="username"
                label="Notendanafn"
                help="Engin bil né hástafir"
                rules={[
                    {
                        required: true,
                        message: 'Verður að fylla út notendanafn',
                    },
                ]}
            >
                <Input placeholder="Notendanafn" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Netfang"
                rules={[
                    {
                        type: 'email',
                        message: 'Verður að vera gilt netfang',
                    },
                ]}
            >
                <Input placeholder="Netfang" />
            </Form.Item>

            <Form.Item
                name="channel_name"
                label="Channel name"
                help="Engin bil né hástafir, má ekki vera sama og username"
                rules={[
                    {
                        required: true,
                        message: 'Verður að fylla út notendanafn',
                    },
                ]}
            >
                <Input placeholder="Channel name" />
            </Form.Item>

            <br />

            <Button type="primary" htmlType="submit" block>
                Staðfesta
            </Button>
        </Form>
    );
}
