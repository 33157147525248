import React from 'react';
import Subscription from '../../interfaces/subscription';
import { Alert, Space } from 'antd';
import dayjs from 'dayjs';
import { getSubscriptionCommitmentPeriod } from '../../utils/subscriptionUtils';

type Props = {
    subscription: Subscription;
};

const translateType = (type: string) => {
    switch (type) {
        case 'MONTH':
            return 'mánuðir';
        case 'WEEK':
            return 'vikur';
        case 'YEAR':
            return 'ár';
        default:
            return type;
    }
};

export default function BeforeCancelInfo({ subscription }: Props) {
    const {
        commitmentHasEnded,

        cancelNoticeEndDate,
        cancelNoticeHasEnded,

        freeToCancel,
    } = getSubscriptionCommitmentPeriod(subscription);

    return !freeToCancel ? (
        <Alert
            style={{ marginBottom: 20 }}
            type="error"
            description={
                <Space direction="vertical">
                    {subscription.product.commitment_period &&
                    subscription.product.commitment_period_type &&
                    !commitmentHasEnded ? (
                        <div>
                            <div>
                                <b>Binditími ætti að vera:</b>
                            </div>
                            {subscription.product.commitment_period}{' '}
                            {translateType(
                                subscription.product.commitment_period_type
                            )}{' '}
                            (
                            {dayjs(subscription.created)
                                .add(
                                    subscription.product.commitment_period,
                                    subscription.product.commitment_period_type.toLocaleLowerCase() as
                                        | 'day'
                                        | 'month'
                                        | 'year'
                                        | 'week'
                                )
                                .format('D. MMM YYYY')}
                            )
                        </div>
                    ) : (
                        <></>
                    )}

                    {!cancelNoticeHasEnded ? (
                        <div>
                            <div>
                                <b>Uppsagnafrestur ætti að vera:</b>
                            </div>
                            {subscription.product.cancel_notice} skipti (
                            {cancelNoticeEndDate.format('D. MMM YYYY')})
                        </div>
                    ) : (
                        <></>
                    )}
                </Space>
            }
        ></Alert>
    ) : (
        <></>
    );
}
