import { useState, useCallback, useContext } from 'react';
import { Input, AutoComplete, Checkbox, Dropdown, Button } from 'antd';
import {
    UserOutlined,
    PhoneOutlined,
    HomeOutlined,
    SearchOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { debounce } from 'lodash';
import Customer from '../../interfaces/customer';
import AuthContext from '../../authContext';

/* Interfaces */
interface IWindow extends Window {
    uuid?: string;
    me?: number;
}
const w: IWindow = window;

interface Props {
    onClick?: Function;
    href?: string;
    style?: object;
    placeholder?: string;
    notFoundContent?: React.ReactElement;
    onCreateNewClick?: Function;
}

interface SearchResult {
    label: React.ReactElement;
    options: number[];
}

export default function CustomerSearch(props: Props) {
    const { selectedShop, shops } = useContext(AuthContext);
    const [searchEveryone, setSearchEveryone] = useState(false);
    const [searchAllShops, setSearchAllShops] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);

    const searchHeader = (
        <>
            <span>
                <span>Leitarniðurstaða:</span>

                {!!props.onCreateNewClick ? (
                    <></>
                ) : (
                    <>
                        {/* <a href='' style={{float: 'right', cursor: 'pointer'}}>
                        Stofna nýjan
                    </a> */}
                    </>
                )}
            </span>
        </>
    );

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const searchDatabase = (
        term: string,
        shopUUID: string,
        allShops: boolean,
        everyone: boolean
    ) => {
        let url = '/verslun/api/customers/?search=' + encodeURIComponent(term);
        if (shopUUID && !allShops) {
            url = `${url}&shop__uuid=${shopUUID}`;
        }

        if (everyone) {
            url = `${url}&search_everyone=1`;
        }

        setSearchIsLoading(true);

        axios
            .get(url)
            .then((rsp) => {
                setSearchIsLoading(false);
                const results = rsp.data.results.map(
                    (customer: Customer, key: number) => {
                        const linkProps: { href?: string; onClick?: Function } =
                            {};

                        if (props.onClick) {
                            linkProps.onClick = () => {
                                if (props.onClick) props.onClick(customer);
                            };
                        } else {
                            linkProps.href = `/vidskiptavinur/${customer.uuid}/`;
                        }

                        return {
                            key,
                            label: (
                                // @ts-ignore
                                <a
                                    className="customerSearchResult"
                                    {...linkProps}
                                    key={key}
                                >
                                    <div>
                                        {w.me === 1 && (
                                            <div>
                                                <strong>
                                                    {customer.shop.name}
                                                </strong>
                                            </div>
                                        )}
                                        <UserOutlined></UserOutlined>{' '}
                                        <span className="capitalize">
                                            {customer.name}
                                        </span>{' '}
                                        {customer.email && (
                                            <span className="customerSearchEmail">
                                                ({customer.email})
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className="customerExtraInfo"
                                    >
                                        {customer.street && (
                                            <div>
                                                <HomeOutlined />{' '}
                                                {customer.street}{' '}
                                                {customer.postal_code
                                                    ? ', ' +
                                                      customer.postal_code +
                                                      ' '
                                                    : ''}
                                                {customer.town
                                                    ? '' + customer.town
                                                    : ''}
                                            </div>
                                        )}

                                        <div>
                                            {customer.phone && (
                                                <span>
                                                    <PhoneOutlined></PhoneOutlined>{' '}
                                                    {customer.phone}{' '}
                                                </span>
                                            )}

                                            {customer.ssid &&
                                                customer.phone &&
                                                ' - '}

                                            {customer.ssid && (
                                                <span>{customer.ssid}</span>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            ),
                            value: customer.name,
                        };
                    }
                );

                const setRes = results.length
                    ? [
                          {
                              label: searchHeader,
                              options: results,
                          },
                      ]
                    : [];

                setSearchResults(setRes);
            })
            .catch((err) => {
                setSearchIsLoading(false);
                console.error(err);
            });
    };

    const handler = useCallback(debounce(searchDatabase, 400), []);

    const menuItems = [
        {
            label: (
                <Button className="text-left" block type="text" size="small">
                    <Checkbox
                        style={{
                            marginRight: 7,
                        }}
                        onChange={(e) => setSearchEveryone(e.target.checked)}
                    />{' '}
                    Leita óháð kaupum
                </Button>
            ),
            key: 'search_everyone',
        },
    ];

    if (shops && shops.length > 1) {
        menuItems.push({
            label: (
                <Button className="text-left" block type="text" size="small">
                    <Checkbox
                        style={{
                            marginRight: 7,
                        }}
                        onChange={(e) => {
                            setSearchAllShops(e.target.checked);
                        }}
                    />{' '}
                    Leita í öllum verslunum
                </Button>
            ),

            key: 'search_all_shops',
        });
    }

    return (
        <>
            {w.me && (
                <div className="searchBox">
                    <AutoComplete
                        notFoundContent={props.notFoundContent}
                        style={{
                            width: '100%',
                            ...(props.style || {}),
                        }}
                        popupClassName="certain-category-search-dropdown"
                        dropdownMatchSelectWidth={500}
                        options={searchResults}
                    >
                        <Input.Search
                            spellCheck={false}
                            onChange={(e) => {
                                const term = e.currentTarget.value;

                                if (term && term.length > 1) {
                                    handler(
                                        term,
                                        selectedShop?.uuid || '',
                                        searchAllShops,
                                        searchEveryone
                                    );
                                } else {
                                    setSearchResults([]);
                                }
                            }}
                            size="large"
                            placeholder={
                                props.placeholder || 'Finna viðskiptavin ...'
                            }
                            enterButton={
                                <Dropdown.Button
                                    style={{ padding: 0, height: '100%' }}
                                    icon={
                                        searchIsLoading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    size="small"
                                    menu={{
                                        items: menuItems,
                                    }}
                                ></Dropdown.Button>
                            }
                        />
                    </AutoComplete>
                </div>
            )}
        </>
    );
}
