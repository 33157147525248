import { Form, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import translate, { LanguageKey } from '../../translate/translate';

type Props = {
    language: LanguageKey;
    onStationSelected: (station: PikkoloStation) => void;
    email?: string;
    phoneNumber?: string;
    city?: string;
    street?: string;
    postalCode?: string;
    nrOfRefrigeratedItems?: number;
    nrOfFrozenItems?: number;
};

interface CartContents {
    nrOfRefrigeratedItems: number;
    nrOfFrozenItems: number;
}

interface StationParameters {
    cartContents: CartContents;
    countryCode: string;
    city: string;
    postalCode: string;
    street?: string;
    phoneNumberHash?: string;
    emailHash?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface PikkoloStation {
    id: string;
    name: string;
    address: {
        city: string;
        postalCode: string;
        street: string;
    };
}

interface PikkoloResponse {
    data: {
        bestAvailableStation?: PikkoloStation;
        stations: PikkoloStation[];
    };
}

interface IWindow extends Window {
    uuid?: string;
    pikkolo_vendor_id?: string;
}

const w: IWindow = window;

const hashCode = (s: string): string => {
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32-bit integer
    }
    return btoa(hash.toString(16));
};

const hashPhoneNumber = (phoneNumber: string): string => {
    return hashCode(phoneNumber.replace(/ /g, ''));
};

const hashEmail = (email: string): string => {
    return hashCode(email.replace(/ /g, ''));
};

async function getAvailableStations(
    params: StationParameters,
    vendorId: string,
    environment: 'production' | 'staging' | 'dev' = 'production'
): Promise<PikkoloResponse['data']> {
    const apiUrl = `https://api${
        environment !== 'production' ? `-${environment}` : ''
    }.pikkolo.is/api/public/v1`;

    const queryParams = new URLSearchParams({
        nrOfRefrigeratedItems:
            params.cartContents.nrOfRefrigeratedItems.toString(),
        nrOfFrozenItems: params.cartContents.nrOfFrozenItems.toString(),
        countryCode: params.countryCode,
        city: params.city,
        postalCode: params.postalCode,
        ...(params.street && { street: params.street }),
        ...(params.phoneNumberHash && {
            phoneNumberHash: params.phoneNumberHash,
        }),
        ...(params.emailHash && { emailHash: params.emailHash }),
        ...(params.startDate && {
            startDate: params.startDate.toISOString().split('T')[0],
        }),
        ...(params.endDate && {
            endDate: params.endDate.toISOString().split('T')[0],
        }),
    });

    const url = `${apiUrl}/stations/available?${queryParams}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-vendor-id': vendorId,
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result: PikkoloResponse = await response.json();
    return result.data;
}

// Usage example
async function fetchStations(
    email: string,
    phoneNumber: string,
    city: string,
    street: string,
    postalCode: string,
    nrOfRefrigeratedItems: number,
    nrOfFrozenItems: number,
    vendorId: string = 'c5060040-7186-4213-8fd4-9929848c2ba7'
) {
    try {
        const params: StationParameters = {
            cartContents: {
                nrOfRefrigeratedItems,
                nrOfFrozenItems,
            },
            countryCode: 'IS',
            city,
            postalCode,
            phoneNumberHash: hashPhoneNumber(phoneNumber),
            emailHash: hashEmail(email),
            street,
        };

        return await getAvailableStations(
            params,
            vendorId,
            w.top?.location?.hostname?.includes('repeat')
                ? 'production'
                : 'staging'
        );
    } catch (error) {
        console.error('Error fetching stations:', error);
    }
}

export default function PikkoloSelector({
    onStationSelected,
    language,
    email = 'myemail@netfangidmitt.is',
    phoneNumber = '+3545812345',
    city = 'Reykjavík',
    street = 'Laugavegur 77',
    postalCode = '101',
    nrOfRefrigeratedItems = 1,
    nrOfFrozenItems = 0,
}: Props) {
    const [allStations, setAllStations] = useState<PikkoloStation[]>([]);

    useEffect(() => {
        fetchStations(
            email,
            phoneNumber,
            city,
            street,
            postalCode,
            nrOfRefrigeratedItems,
            nrOfFrozenItems,
            w.pikkolo_vendor_id
        ).then((rsp) => {
            setAllStations(rsp?.stations || []);
        });

        return () => {};
    }, []);

    return (
        <>
            <Form.Item
                name="stationId"
                label={translate('where_to_pickup', language)}
                rules={[
                    {
                        required: true,
                        message: translate('field_required', language),
                    },
                ]}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder={translate('where_to_pickup', language)}
                    loading={!allStations.length}
                    notFoundContent={<LoadingOutlined />}
                    onChange={(stationId) => {
                        const selectedStation = allStations.find(
                            (station) => station.id === stationId
                        );
                        if (selectedStation) {
                            onStationSelected(selectedStation);
                        }
                    }}
                >
                    {allStations.map((station) => (
                        <Select.Option key={station.id} value={station.id}>
                            <div>{station.name}</div>
                            <div>
                                <small>
                                    {station.address.street},{' '}
                                    {station.address.postalCode}{' '}
                                    {station.address.city}
                                </small>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </>
    );
}
