export const SALT_PAY_CODES = {
    '000': 'Tókst',
    '100': 'Útgefandi kortsins leyfir ekki þessa aðgerð',
    '101': 'Gildistími þessa korts er liðinn',
    '102': 'Korti þessu er ekki treystandi',
    '103': 'Merchant call acquirer 01, 66, 08',
    '104': 'Þetta kort hefur verið takmarkað af útgefanda',
    '106': 'Hámark PIN innsetninga hefur verið náð',
    '109': 'Sölustaður svarar ekki, vinsamlegast hafðu samband við SaltPay eða reyndu aftur síðar.',
    '110': 'Upphæð ólögleg, vinsamlegast hafðu samband við SaltPay eða reyndu aftur síðar.',
    '111': 'Kortanúmer er ekki gilt',
    '112': 'Vantar PIN númer',
    '116': 'Það fékkst ekki heimild fyrir þessari færslu',
    '117': 'PIN númer ekki rétt',
    '118': 'Óþekkt kort.',
    '119': 'Kortahafi hefur lokað fyrir þessa færslu.',
    '120': 'Féhirðir hefur lokað fyrir þessa færslu, vinsamlegast hafðu samband við SaltPay eða reyndu aftur síðar.',
    '121': 'Of há upphæð 61, 65',
    '125': 'Greiðslukort ekki gilt',
    '126': 'False PIN block ',
    '129': 'Suspected fraud ',
    '130': 'Invalid Track2 ',
    '131': 'Invalid Expiration Date ',
    '161': 'DCC transaction not allowed to cardholder ',
    '162': 'DCC cardholder currency not supported ',
    '163': 'DCC exceeds time limit for withdrawal ',
    '164': 'DCC transaction not allowed to terminal ',
    '165': 'DCC not allowed to merchant ',
    '166': 'DCC unknown error ',
    '200': 'No not honor 04',
    '201': 'Card not valid 33',
    '202': 'Korti þessu er ekki treystandi',
    '203': 'Merchant contact acquirer 37',
    '204': 'Limited card 36',
    '205': 'Merchant contact police ',
    '206': 'Allowed PIN-retries exceeded 38',
    '207': 'Special occasion ',
    '208': 'Týnt kort 41',
    '209': 'Stolið kort 43',
    '210': 'Suspected fraud ',
    '902': 'False transaction 89, 06',
    '904': 'Form error 30',
    '907': 'Issuer not responding ',
    '908': "'Message' can not be routed 15, 80, 81,",
    '909': 'System error 83, 96',
    '910': 'Issuer did not respond 68',
    '911': 'Issuer timed out 82',
    '912': 'Issuer not reachable 91',
    '913': 'Double transaction transmission 94',
    '914': 'Original transaction can not be traced ',
    '916': 'Merchant not found ',
    '950': 'No financial record found for detail data ',
    '951': 'Batch already closed ',
    '952': 'Invalid batch number ',
    '953': 'Host timeout ',
    '954': 'Batch not closed ',
    '955': 'Merchant not active ',
    '956': 'Transaction number not unique ',
    '997': 'Action code exclusive to RPG and indicates a network communication problem.',
    '998': 'Action code exclusive to RPG and indicates a form error (See ActionCode 904)',
    '999': 'Action code exclusive to RPG and indicates a system error.',
};
