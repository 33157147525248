import React from 'react';
import { Layout, Row, Col, Card } from 'antd';

const { Content } = Layout;

interface IWindow extends Window {
    uuid?: string;
}
export const w: IWindow = window;

export default function AppwriteConfig() {
    return (
        <Layout id="layout_wrapper">
            <Content>
                <Row gutter={[10, 10]} className="main_row">
                    <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={16}>
                        <div
                            className="text-center"
                            style={{ fontSize: 32, margin: 20 }}
                        >
                            Appwrite config
                        </div>

                        <Card>yo</Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
