import React, { useEffect, useState } from 'react';
import { Button, Alert, Space, Card, Switch, Form, Input, message } from 'antd';
import Axios from 'axios';
import Shop from '../../interfaces/shop';

interface Props {
    shop: Shop;
}

export default function WoocommerceSettings({ shop }: Props) {
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    return (
        <Form
            layout="vertical"
            initialValues={{
                api_key: shop?.woocommerce?.api_key || '',
                secret_key: shop?.woocommerce?.secret_key || '',
                api_base_url: shop?.woocommerce?.api_base_url || '',
            }}
            onFinish={(values) => {
                setLoading(true);
                Axios.post(
                    '/verslun/api/create_or_update_store_woocommerce_settings/',
                    {
                        shop_uuid: shop.uuid,
                        api_key: values.api_key,
                        secret_key: values.secret_key,
                        api_base_url: values.api_base_url,
                    }
                )
                    .then(() => {
                        message.success('Tókst! 🥳');
                    })
                    .catch(() => {
                        message.error('Það kom upp villa :/');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        >
            <Space
                direction="vertical"
                style={{ display: 'flex' }}
                size="large"
            >
                <Alert
                    message="Stofna þarf API lykil undir Settings -> Advanced í Woocommerce"
                    description={
                        <>
                            <p>
                                Nánar um API lykla hér:{' '}
                                <a
                                    href="https://woocommerce.com/document/woocommerce-rest-api/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://woocommerce.com/document/woocommerce-rest-api/
                                </a>
                            </p>
                        </>
                    }
                    type="info"
                />

                <div>
                    <div>Virkja Woocommerce tengingu?</div>
                    <Switch
                        onClick={() => setActive(!active)}
                        checked={active}
                        checkedChildren="JÁ "
                        unCheckedChildren="NEI"
                    ></Switch>
                </div>

                {active && (
                    <>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn API lykilinn',
                                },
                            ]}
                            label="API lykill"
                            name="api_key"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn "Secret key"',
                                },
                            ]}
                            label="Secret key"
                            name="secret_key"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn grunn api lénið',
                                },
                            ]}
                            label="API grunn lén"
                            name="api_base_url"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>
                    </>
                )}
            </Space>

            <Form.Item>
                <Button
                    loading={loading}
                    block
                    className="btn-success mt20"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
