import { Button, Modal, Table, message } from 'antd';
import { useState, useEffect, Key } from 'react';
import CreateTransactionsModal from '../../../SubscriptionOverview/CreateTransactionsModal/CreateTransactionsModal';

import axios from 'axios';
import Subscription from '../../../../interfaces/subscription';

type Props = {
    customer_uuid: string;
    shop_uuid: string;
    onClose: () => void;
};

export default function CreateCustomerTransactions({
    customer_uuid,
    shop_uuid,
    onClose,
}: Props) {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [createTransactionsModal, setCreateTransactionsModal] =
        useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    useEffect(() => {
        axios
            .get(
                `/askrift/api/subscription/?customer__uuid=${customer_uuid}&active=true`
            )
            .then((rsp) => {
                console.log(rsp.data);
                setSubscriptions(rsp.data.results);
            });

        return () => {
            setSubscriptions([]);
        };
    }, []);

    return (
        <div>
            <Modal
                open={true}
                title="Veldu þær áskriftir sem þú vilt stofna sendingu fyrir"
                footer={null}
                onCancel={() => {
                    onClose();
                }}
            >
                <Table
                    size="large"
                    pagination={false}
                    dataSource={subscriptions}
                    rowKey={'uuid'}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: (selectedRowKeys) => {
                            setSelectedRowKeys(selectedRowKeys);
                        },
                        hideSelectAll: true,
                    }}
                    columns={[
                        {
                            title: 'Tegund áskriftar',
                            render: (subscription: Subscription) => (
                                <div>{subscription.product.title}</div>
                            ),
                        },
                    ]}
                ></Table>
                <br />
                <Button
                    block
                    type="primary"
                    size="large"
                    onClick={() => {
                        if (selectedRowKeys.length === 0) {
                            message.error(
                                'Vinsamlegast veldu að minnsta kosti eina áskrift'
                            );
                            return;
                        }
                        setCreateTransactionsModal(true);
                    }}
                >
                    Áfram
                </Button>
            </Modal>
            <CreateTransactionsModal
                shop_uuid={shop_uuid}
                filters={{
                    active: '',
                    ordering: '',
                    product__in: '',
                    payment_method__in: '',
                    frequencies: [],
                }}
                selectionList={selectedRowKeys as string[]}
                allSelected={false}
                onCancel={() => {
                    setCreateTransactionsModal(false);
                    onClose();
                }}
                open={createTransactionsModal}
                selectedCount={selectedRowKeys.length}
                title={<>Saset</>}
            ></CreateTransactionsModal>
        </div>
    );
}
