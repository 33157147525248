import { ReactElement, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Select, Space, Tabs } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import ApexCharts from 'react-apexcharts';
import { Form, Empty, Radio, DatePicker } from 'antd';
import Axios from 'axios';
import './ShopStats.less';
import { displayCurrencyPrice, kr } from '../../utils';
import brandingConfig from '../../utils/brandingConfig';
import CancelStats from '../../components/CancelStats/CancelStats';
import { Currency } from '../../interfaces/product';
import AuthContext from '../../authContext';

const { TabPane } = Tabs;

interface IWindow extends Window {
    uuid?: string;
}

type SignupObj = {
    created: string;
    count: number;
    title: string;
    external_ref?: string;
};

interface Signup {
    refs?: string[];
    dates: string[];
    categories: string[];
    subscriptions_cancelled: number;
    data: SignupObj[];
}

interface TransactionStatObj {
    sum: number;
    card_sum: number;
    claim_sum: number;
    payment_received_date: string;
    payment_received_date__day: number;
}

interface OnetimeStatObj {
    count: number;
    created: string;
    created__day: number;
    product_id: number;
    sum: number;
    title: string;
}

interface GiftCertificatteStatObj {
    count: number;
    created: string;
    created__day: number;
    sum: number;
}

const w: IWindow = window;

const now = moment();

const dates = {
    currentMonth: {
        from: moment(now).startOf('month'),
        to: moment(now).endOf('month'),
        name: moment(now).format('MMM'),
    },
    lastMonth: {
        from: moment(now).subtract(1, 'month').startOf('month'),
        to: moment(now).subtract(1, 'month').endOf('month'),
        name: moment(now).subtract(1, 'month').format('MMM'),
    },
    twoMonthsAgo: {
        from: moment(now).subtract(2, 'month').startOf('month'),
        to: moment(now).subtract(2, 'month').endOf('month'),
        name: moment(now).subtract(2, 'month').format('MMM'),
    },
    threeMonthsAgo: {
        from: moment(now).subtract(3, 'month').startOf('month'),
        to: moment(now).subtract(3, 'month').endOf('month'),
        name: moment(now).subtract(3, 'month').format('MMM'),
    },
};

const chartColors = [
    brandingConfig.primaryColor,
    '#878e88',
    '#96C0B7',
    '#D4DFC7',
    '#FEF6C9',
    '#2D2D2A',
    '#F7B1AB',
];

export default function ShopStats(props: { uuid?: string }): ReactElement {
    const { selectedShop } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [render, forceRender] = useState(true);
    const [loading, setLoading] = useState(true);
    const [signups, setSignups] = useState<Signup>();
    const [transactions, setTransactions] = useState<TransactionStatObj[]>([]);
    const [oneTimePurchases, setOneTimePurchases] = useState<OnetimeStatObj[]>(
        []
    );
    const [allOneTimePurchases, setAllOneTimePurchases] = useState<{
        [key: Currency['currency_code']]: {
            data: OnetimeStatObj[];
            total: number;
        };
    }>({});
    const [selectedCurrency, setSelectedCurrency] =
        useState<Currency['currency_code']>('ISK');
    const [allTransactions, setAllTransactions] = useState<{
        [key: Currency['currency_code']]: {
            data: TransactionStatObj[];
            total: number;
        };
    }>({});
    const [allGiftCertificates, setAllGiftCertificates] = useState<{
        [key: Currency['currency_code']]: {
            data: GiftCertificatteStatObj[];
            total: number;
        };
    }>({});
    // const [combinedTransactions, setCombinedTransactions] = useState([]);
    // const [payments, setPayments] = useState([]);
    const [giftCertificates, setGiftCertificates] = useState<
        GiftCertificatteStatObj[]
    >([]);
    const [totalIncome, setTotalIncome] = useState(0);
    const [hasExternalRefs, setHasExternalRefs] = useState<boolean>(false);
    const [selectedFrom, setSelectedFrom] = useState(
        moment().startOf('month').toISOString()
    );
    const [selectedTo, setSelectedTo] = useState(
        moment().endOf('month').toISOString()
    );

    useEffect(() => {
        const transactionIncome = transactions.reduce(
            (acc: number, item: { sum: number }) => {
                return acc + item.sum;
            },
            0
        );

        const certificateIncome = giftCertificates.reduce(
            (acc: number, item: { sum: number }) => {
                return acc + item.sum;
            },
            0
        );

        const oneTimeIncome = oneTimePurchases.reduce(
            (acc: number, item: { sum: number }) => {
                return acc + item.sum;
            },
            0
        );

        setTotalIncome(transactionIncome + certificateIncome + oneTimeIncome);
    }, [transactions, giftCertificates, oneTimePurchases]);

    useEffect(() => {
        if (selectedCurrency) {
            setTransactions(allTransactions[selectedCurrency]?.data || []);

            setOneTimePurchases(
                allOneTimePurchases[selectedCurrency]?.data || []
            );

            setGiftCertificates(
                allGiftCertificates[selectedCurrency]?.data || []
            );
        }
    }, [selectedCurrency, allTransactions, allOneTimePurchases]);

    useEffect(() => {
        if (selectedShop) {
            getStatsFromPeriod(
                moment().startOf('month').toISOString(),
                moment().endOf('month').toISOString()
            );
        }
    }, [selectedShop]);

    // useEffect(() => {
    //     const map = [...oneTimePurchases, ...transactions].reduce((acc, curr: TransactionStatObj) => {
    //         return {
    //             ...acc,
    //             [curr.product_id?.toString()]: {
    //                 ...curr,
    //             },
    //         };
    //     }, {} as {[key: string]: TransactionStatObj});

    //     const combined = Object.keys(map).reduce((acc, curr) => {
    //         // @ts-ignore
    //         acc.push(map[curr]);
    //         return acc;
    //     }, []);

    //     console.log('Combined', combined);

    //     setCombinedTransactions(combined);
    // }, [oneTimePurchases, transactions]);

    function getStatsFromPeriod(from: string, to: string) {
        setSelectedFrom(from);
        setSelectedTo(to);

        if (!selectedShop) return;

        Axios.post('/verslun/api/get_stat_period/', {
            shop_uuid: selectedShop.uuid,
            from,
            to,
        }).then((rsp) => {
            const categories = rsp.data.subscriptions_created.data.reduce(
                (a: string[], b: { title: string }) => {
                    if (a.includes(b.title)) return a;
                    return [...a, b.title];
                },
                []
            );

            const refs = rsp.data.subscriptions_created.data
                .reduce((a: string[], b: { external_ref: string }) => {
                    if (a.includes(b.external_ref)) return a;
                    return [...a, b.external_ref];
                }, [])
                .filter((ref: string) => ref);

            const dates: string[] = rsp.data.subscriptions_created.data.reduce(
                (a: string[], b: { created: string }) => {
                    if (
                        a.some((date: string) =>
                            moment(date).isSame(moment(b.created), 'day')
                        )
                    ) {
                        return a;
                    }
                    return [...a, b.created];
                },
                []
            );

            setSignups({
                refs: [...(refs || []), 'Ekkert auðkenni'],
                dates,
                categories,
                data: rsp.data.subscriptions_created.data,
                subscriptions_cancelled:
                    rsp.data.subscriptions_created.total_cancelled,
            });

            setHasExternalRefs(
                rsp.data.subscriptions_created.data.some(
                    (obj: SignupObj) => obj.external_ref
                )
            );

            const _transactions = rsp.data.transactions;
            setAllTransactions(_transactions);

            const _oneTimePurchases = rsp.data.one_time_purchases;
            setAllOneTimePurchases(_oneTimePurchases);

            setTransactions(
                _transactions ? _transactions[selectedCurrency]?.data || [] : []
            );
            setOneTimePurchases(
                _oneTimePurchases
                    ? _oneTimePurchases[selectedCurrency]?.data || []
                    : []
            );

            const _giftCertificates = rsp.data.gift_certificates;
            setAllGiftCertificates(_giftCertificates);

            setGiftCertificates(
                _giftCertificates
                    ? _giftCertificates[selectedCurrency]?.data || []
                    : []
            );

            setLoading(false);
        });

        setLoading(false);
    }

    return (
        <>
            {selectedShop && (
                <Card
                    id="shopStats"
                    className="basePageCard"
                    title={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <BarChartOutlined
                                style={{
                                    fontSize: 35,
                                    marginRight: 10,
                                }}
                            />{' '}
                            Tölfræði
                        </div>
                    }
                    extra={
                        <Space>
                            <div className="text-center mb20 ">
                                <Select
                                    bordered={false}
                                    size="large"
                                    onChange={(
                                        val: Currency['currency_code']
                                    ) => {
                                        setSelectedCurrency(val);
                                    }}
                                    defaultValue={'ISK'}
                                >
                                    <Select.Option value="ISK">
                                        ISK
                                    </Select.Option>
                                    <Select.Option value="USD">
                                        USD
                                    </Select.Option>
                                    <Select.Option value="EUR">
                                        EUR
                                    </Select.Option>
                                </Select>
                            </div>
                            <Form
                                layout="inline"
                                className="monthSelect"
                                onValuesChange={(values) => {
                                    if (
                                        !values.range &&
                                        values.period !== 'custom'
                                    ) {
                                        getStatsFromPeriod(
                                            // @ts-ignore
                                            dates[
                                                values.period
                                            ].from.toISOString(),
                                            // @ts-ignore
                                            dates[
                                                values.period
                                            ].to.toISOString()
                                        );
                                    }
                                    forceRender(!render);
                                }}
                                form={form}
                                style={{
                                    margin: '0px auto 20px',
                                    justifyContent: 'center',
                                }}
                            >
                                <Form.Item
                                    initialValue="currentMonth"
                                    name="period"
                                >
                                    <Radio.Group
                                        buttonStyle="solid"
                                        size="large"
                                        onChange={() => {
                                            setLoading(true);
                                        }}
                                    >
                                        <Radio.Button
                                            className="capitalize"
                                            value="currentMonth"
                                        >
                                            {dates.currentMonth.name}
                                        </Radio.Button>
                                        <Radio.Button
                                            className="capitalize"
                                            value="lastMonth"
                                        >
                                            {dates.lastMonth.name}
                                        </Radio.Button>
                                        <Radio.Button
                                            className="capitalize"
                                            value="twoMonthsAgo"
                                        >
                                            {dates.twoMonthsAgo.name}
                                        </Radio.Button>
                                        <Radio.Button
                                            className="capitalize"
                                            value="threeMonthsAgo"
                                        >
                                            {dates.threeMonthsAgo.name}
                                        </Radio.Button>
                                        <Radio.Button
                                            className="capitalize"
                                            value="custom"
                                        >
                                            Velja tímabil
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                {form.getFieldValue('period') === 'custom' && (
                                    <>
                                        <Form.Item className="mt5" name="range">
                                            <DatePicker.RangePicker
                                                onCalendarChange={(values) => {
                                                    console.log(values);
                                                    if (
                                                        values &&
                                                        values[0] &&
                                                        values[1]
                                                    ) {
                                                        getStatsFromPeriod(
                                                            values[0]
                                                                .startOf('day')
                                                                .toISOString(),
                                                            values[1]
                                                                .endOf('day')
                                                                .toISOString()
                                                        );
                                                    }
                                                }}
                                            ></DatePicker.RangePicker>
                                        </Form.Item>
                                    </>
                                )}
                            </Form>
                        </Space>
                    }
                >
                    <Tabs
                        defaultActiveKey="1"
                        tabBarExtraContent={`Alls innkoma á tímabili: ${displayCurrencyPrice(
                            totalIncome || 0,
                            selectedCurrency
                        )}`}
                    >
                        <TabPane tab="Innkoma" key="1">
                            <div className="geckoboard">
                                {transactions.length < 1 &&
                                    giftCertificates.length < 1 &&
                                    oneTimePurchases.length < 1 && (
                                        <Empty></Empty>
                                    )}

                                {transactions.length > 0 && (
                                    <>
                                        <Card
                                            loading={loading}
                                            className="geckoCard"
                                            title="Áskriftafærslur"
                                        >
                                            <>
                                                <div className="text-center">
                                                    <h1>
                                                        {displayCurrencyPrice(
                                                            transactions.reduce(
                                                                (
                                                                    acc: number,
                                                                    item: {
                                                                        sum: number;
                                                                    }
                                                                ) => {
                                                                    return (
                                                                        acc +
                                                                        item.sum
                                                                    );
                                                                },
                                                                0
                                                            ),
                                                            selectedCurrency
                                                        )}
                                                    </h1>
                                                </div>

                                                <ApexCharts
                                                    options={{
                                                        colors: chartColors,
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                            stacked: true,
                                                            toolbar: {
                                                                show: true,
                                                            },
                                                            zoom: {
                                                                enabled: true,
                                                            },
                                                        },
                                                        responsive: [
                                                            {
                                                                breakpoint: 480,
                                                                options: {
                                                                    legend: {
                                                                        position:
                                                                            'bottom',
                                                                        offsetX:
                                                                            -10,
                                                                        offsetY: 0,
                                                                    },
                                                                },
                                                            },
                                                        ],
                                                        yaxis: {
                                                            labels: {
                                                                formatter: (
                                                                    val
                                                                ) => {
                                                                    return displayCurrencyPrice(
                                                                        val,
                                                                        selectedCurrency
                                                                    );
                                                                },
                                                            },
                                                        },
                                                        xaxis: {
                                                            type: 'datetime',
                                                            categories:
                                                                transactions.map(
                                                                    (day: {
                                                                        payment_received_date: string;
                                                                    }) =>
                                                                        day.payment_received_date
                                                                ),
                                                            labels: {
                                                                formatter: (
                                                                    val: string
                                                                ) =>
                                                                    moment(
                                                                        val
                                                                    ).format(
                                                                        'll'
                                                                    ),
                                                            },
                                                        },
                                                        legend: {
                                                            position: 'top',
                                                        },
                                                        fill: {
                                                            opacity: 1,
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            formatter:
                                                                function (val) {
                                                                    // format the value here
                                                                    // for example, add a dollar sign and commas
                                                                    return displayCurrencyPrice(
                                                                        val as number,
                                                                        selectedCurrency
                                                                    );
                                                                },
                                                        },
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'Bankakröfur',
                                                            data: transactions.map(
                                                                (transaction: {
                                                                    claim_sum: number;
                                                                }) =>
                                                                    transaction.claim_sum
                                                            ),
                                                        },
                                                        {
                                                            name: 'Kortagreiðslur',
                                                            data: transactions.map(
                                                                (transaction: {
                                                                    card_sum: number;
                                                                }) =>
                                                                    transaction.card_sum
                                                            ),
                                                        },
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </>
                                        </Card>

                                        {transactions.reduce(
                                            (
                                                prev,
                                                current: {
                                                    claim_sum: number;
                                                }
                                            ) => prev + current.claim_sum,
                                            0
                                        ) > 0 &&
                                            transactions.reduce(
                                                (
                                                    prev,
                                                    current: {
                                                        card_sum: number;
                                                    }
                                                ) => prev + current.card_sum,
                                                0
                                            ) > 0 && (
                                                <Card
                                                    loading={loading}
                                                    className="geckoCard"
                                                    title="Skipting greiðsluleiða"
                                                >
                                                    <>
                                                        <ApexCharts
                                                            options={{
                                                                plotOptions: {
                                                                    pie: {
                                                                        donut: {
                                                                            size: '50%',
                                                                        },
                                                                    },
                                                                },
                                                                chart: {
                                                                    type: 'donut',
                                                                    height: 350,
                                                                },
                                                                colors: chartColors,
                                                                labels: [
                                                                    'Bankakröfur',
                                                                    'Kortagreiðslur',
                                                                ],
                                                                tooltip: {
                                                                    y: {
                                                                        formatter:
                                                                            function (
                                                                                value
                                                                            ) {
                                                                                return displayCurrencyPrice(
                                                                                    value,
                                                                                    selectedCurrency
                                                                                );
                                                                            },
                                                                    },
                                                                },
                                                            }}
                                                            series={[
                                                                transactions.reduce(
                                                                    (
                                                                        prev,
                                                                        current: {
                                                                            claim_sum: number;
                                                                        }
                                                                    ) =>
                                                                        prev +
                                                                        current.claim_sum,
                                                                    0
                                                                ),
                                                                transactions.reduce(
                                                                    (
                                                                        prev,
                                                                        current: {
                                                                            card_sum: number;
                                                                        }
                                                                    ) =>
                                                                        prev +
                                                                        current.card_sum,
                                                                    0
                                                                ),
                                                            ]}
                                                            type="donut"
                                                            height={350}
                                                        />
                                                    </>
                                                </Card>
                                            )}
                                    </>
                                )}

                                {giftCertificates.length > 0 && (
                                    <Card
                                        loading={loading}
                                        className="geckoCard"
                                        title="Seld gjafabréf"
                                    >
                                        <>
                                            <div className="text-center">
                                                <h1>
                                                    {displayCurrencyPrice(
                                                        giftCertificates.reduce(
                                                            (
                                                                acc: number,
                                                                item: {
                                                                    sum: number;
                                                                }
                                                            ) => {
                                                                return (
                                                                    acc +
                                                                    item.sum
                                                                );
                                                            },
                                                            0
                                                        ),
                                                        selectedCurrency
                                                    )}
                                                </h1>
                                            </div>

                                            <ApexCharts
                                                options={{
                                                    colors: chartColors,
                                                    chart: {
                                                        type: 'bar',
                                                        height: 350,
                                                        stacked: true,
                                                        toolbar: {
                                                            show: true,
                                                        },
                                                        zoom: {
                                                            enabled: true,
                                                        },
                                                    },
                                                    responsive: [
                                                        {
                                                            breakpoint: 480,
                                                            options: {
                                                                legend: {
                                                                    position:
                                                                        'bottom',
                                                                    offsetX:
                                                                        -10,
                                                                    offsetY: 0,
                                                                },
                                                            },
                                                        },
                                                    ],
                                                    yaxis: {
                                                        labels: {
                                                            formatter: (
                                                                val
                                                            ) => {
                                                                return displayCurrencyPrice(
                                                                    val,
                                                                    selectedCurrency
                                                                );
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        type: 'datetime',
                                                        // @ts-ignore
                                                        categories:
                                                            giftCertificates.map(
                                                                (day: {
                                                                    created: string;
                                                                }) =>
                                                                    day.created
                                                            ),
                                                        labels: {
                                                            formatter: (
                                                                val: string
                                                            ) =>
                                                                moment(
                                                                    val
                                                                ).format('ll'),
                                                        },
                                                    },
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                }}
                                                // @ts-ignore
                                                series={[
                                                    {
                                                        name: 'Innkoma',
                                                        data: giftCertificates.map(
                                                            (transaction: {
                                                                sum: number;
                                                            }) =>
                                                                transaction.sum
                                                        ),
                                                    },
                                                ]}
                                                type="bar"
                                                height={350}
                                            />
                                        </>
                                    </Card>
                                )}

                                {oneTimePurchases.length > 0 && (
                                    <Card
                                        loading={loading}
                                        className="geckoCard"
                                        title="Stakar greiðslur (ekki áskrift)"
                                    >
                                        <>
                                            <div className="text-center">
                                                <h1>
                                                    {displayCurrencyPrice(
                                                        oneTimePurchases.reduce(
                                                            (
                                                                acc: number,
                                                                item: {
                                                                    sum: number;
                                                                }
                                                            ) => {
                                                                return (
                                                                    acc +
                                                                    item.sum
                                                                );
                                                            },
                                                            0
                                                        ),
                                                        selectedCurrency
                                                    )}
                                                </h1>
                                            </div>

                                            <ApexCharts
                                                options={{
                                                    colors: chartColors,
                                                    chart: {
                                                        type: 'bar',
                                                        height: 350,
                                                        stacked: true,
                                                        toolbar: {
                                                            show: true,
                                                        },
                                                        zoom: {
                                                            enabled: true,
                                                        },
                                                    },
                                                    responsive: [
                                                        {
                                                            breakpoint: 480,
                                                            options: {
                                                                legend: {
                                                                    position:
                                                                        'bottom',
                                                                    offsetX:
                                                                        -10,
                                                                    offsetY: 0,
                                                                },
                                                            },
                                                        },
                                                    ],
                                                    yaxis: {
                                                        labels: {
                                                            formatter: (
                                                                val
                                                            ) => {
                                                                return displayCurrencyPrice(
                                                                    val,
                                                                    selectedCurrency
                                                                );
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        type: 'datetime',
                                                        // @ts-ignore
                                                        categories:
                                                            oneTimePurchases.map(
                                                                (day: {
                                                                    created: string;
                                                                }) =>
                                                                    day.created
                                                            ),
                                                        labels: {
                                                            formatter: (
                                                                val: string
                                                            ) =>
                                                                moment(
                                                                    val
                                                                ).format('ll'),
                                                        },
                                                    },
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                }}
                                                // @ts-ignore
                                                series={[
                                                    {
                                                        name: 'Innkoma',
                                                        data: oneTimePurchases.map(
                                                            (transaction: {
                                                                sum: number;
                                                            }) =>
                                                                transaction.sum
                                                        ),
                                                    },
                                                ]}
                                                type="bar"
                                                height={350}
                                            />
                                        </>
                                    </Card>
                                )}
                            </div>
                        </TabPane>
                        <TabPane tab="Nýskráningar" key="2">
                            <div className="geckoboard">
                                {!signups ||
                                    (!!!signups.data?.length && (
                                        <Empty></Empty>
                                    ))}

                                {signups && !!signups.data.length && (
                                    <Card
                                        loading={loading}
                                        className="geckoCard"
                                        title="Nýskráningar"
                                    >
                                        <>
                                            <div className="text-center">
                                                <div>
                                                    Nýskráningar / afskráningar
                                                </div>
                                                <h2>
                                                    <span
                                                        style={{
                                                            color: '#32c79f',
                                                        }}
                                                    >
                                                        {signups.data.reduce(
                                                            (
                                                                acc: number,
                                                                item: {
                                                                    count: number;
                                                                }
                                                            ) =>
                                                                acc +
                                                                item.count,
                                                            0
                                                        )}
                                                    </span>

                                                    <span> / </span>

                                                    <span>
                                                        {
                                                            signups.subscriptions_cancelled
                                                        }
                                                    </span>
                                                </h2>
                                            </div>
                                            <ApexCharts
                                                type="bar"
                                                height={350}
                                                options={{
                                                    chart: {
                                                        type: 'bar',
                                                        height: 350,
                                                        stacked: true,
                                                        toolbar: {
                                                            show: true,
                                                        },
                                                        zoom: {
                                                            enabled: true,
                                                        },
                                                    },
                                                    colors: chartColors,
                                                    responsive: [
                                                        {
                                                            breakpoint: 480,
                                                            options: {
                                                                legend: {
                                                                    position:
                                                                        'bottom',
                                                                    offsetX:
                                                                        -10,
                                                                    offsetY: 0,
                                                                },
                                                            },
                                                        },
                                                    ],

                                                    xaxis: {
                                                        type: 'datetime',
                                                        categories:
                                                            signups.dates,
                                                        labels: {
                                                            formatter: (
                                                                val: string
                                                            ) =>
                                                                moment(
                                                                    val
                                                                ).format('ll'),
                                                        },
                                                    },
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                }}
                                                series={signups.categories.map(
                                                    (category) => {
                                                        return {
                                                            name: category,
                                                            data: signups.dates.map(
                                                                (date) => {
                                                                    return signups.data.reduce(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) => {
                                                                            if (
                                                                                b.title ===
                                                                                    category &&
                                                                                moment(
                                                                                    b.created
                                                                                ).isSame(
                                                                                    moment(
                                                                                        date
                                                                                    ),
                                                                                    'day'
                                                                                )
                                                                            ) {
                                                                                return (a +=
                                                                                    b.count);
                                                                            }
                                                                            return a;
                                                                        },
                                                                        0
                                                                    );
                                                                }
                                                            ),
                                                        };
                                                    }
                                                )}
                                            />
                                            {/* <Select allowClear defaultValue={signups.categories} style={{width: '100%'}} mode='multiple' optionFilterProp='children'>
                                {signups.categories.map((category, key) => {
                                    return (
                                        <Select.Option key={key} value={category}>
                                            {category}
                                        </Select.Option>
                                    );
                                })}
                            </Select> */}
                                        </>
                                    </Card>
                                )}

                                {signups && signups.categories.length > 1 && (
                                    <Card
                                        loading={loading}
                                        className="geckoCard"
                                        title="Skipting nýskráninga eftir vörum"
                                    >
                                        <>
                                            <ApexCharts
                                                options={{
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '50%',
                                                            },
                                                        },
                                                    },
                                                    chart: {
                                                        type: 'donut',
                                                        height: 350,
                                                    },
                                                    colors: chartColors,
                                                    labels: signups.categories,
                                                }}
                                                series={signups.categories.map(
                                                    (category) => {
                                                        return signups.data.reduce(
                                                            (a, b) => {
                                                                if (
                                                                    b.title ===
                                                                    category
                                                                ) {
                                                                    return (a +=
                                                                        b.count);
                                                                }
                                                                return a;
                                                            },
                                                            0
                                                        );
                                                    }
                                                )}
                                                type="donut"
                                                height={350}
                                            />
                                        </>
                                    </Card>
                                )}

                                {hasExternalRefs &&
                                    (signups?.data?.reduce(
                                        (acc: number, item: SignupObj) =>
                                            item.external_ref
                                                ? acc + item.count
                                                : acc,
                                        0
                                    ) || 0) > 1 && (
                                        <Card
                                            loading={loading}
                                            className="geckoCard"
                                            title="Skipting söluauðkenna"
                                        >
                                            <div className="text-center">
                                                <h3>Alls sölur með auðkenni</h3>
                                                <h1>
                                                    {signups?.data.reduce(
                                                        (
                                                            acc: number,
                                                            item: SignupObj
                                                        ) =>
                                                            item.external_ref
                                                                ? acc +
                                                                  item.count
                                                                : acc,
                                                        0
                                                    )}
                                                </h1>
                                            </div>
                                            <ApexCharts
                                                options={{
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '50%',
                                                            },
                                                        },
                                                    },
                                                    chart: {
                                                        type: 'donut',
                                                        height: 350,
                                                    },
                                                    colors: chartColors,
                                                    labels: signups?.refs,
                                                }}
                                                series={signups?.refs?.map(
                                                    (ref) => {
                                                        return signups.data.reduce(
                                                            (a, b) => {
                                                                const x =
                                                                    b.external_ref ||
                                                                    'Ekkert auðkenni';
                                                                if (x === ref) {
                                                                    return (a +=
                                                                        b?.count);
                                                                }
                                                                return a;
                                                            },
                                                            0
                                                        );
                                                    }
                                                )}
                                                type="donut"
                                                height={350}
                                            />
                                        </Card>
                                    )}

                                {hasExternalRefs && (
                                    <Card
                                        loading={loading}
                                        className="geckoCard"
                                        title="Söluauðkenni - Sala per dag"
                                    >
                                        <>
                                            <div className="text-center">
                                                <h3>Alls sölur með auðkenni</h3>
                                                <h1>
                                                    {signups?.data.reduce(
                                                        (
                                                            acc: number,
                                                            item: SignupObj
                                                        ) =>
                                                            item.external_ref
                                                                ? acc +
                                                                  item.count
                                                                : acc,
                                                        0
                                                    )}
                                                </h1>
                                            </div>
                                            <ApexCharts
                                                type="bar"
                                                height={350}
                                                options={{
                                                    chart: {
                                                        type: 'bar',
                                                        height: 350,
                                                        stacked: true,
                                                        toolbar: {
                                                            show: true,
                                                        },
                                                        zoom: {
                                                            enabled: true,
                                                        },
                                                    },
                                                    colors: chartColors,
                                                    responsive: [
                                                        {
                                                            breakpoint: 480,
                                                            options: {
                                                                legend: {
                                                                    position:
                                                                        'bottom',
                                                                    offsetX:
                                                                        -10,
                                                                    offsetY: 0,
                                                                },
                                                            },
                                                        },
                                                    ],

                                                    xaxis: {
                                                        type: 'datetime',
                                                        categories:
                                                            signups?.dates,
                                                        labels: {
                                                            formatter: (
                                                                val: string
                                                            ) =>
                                                                moment(
                                                                    val
                                                                ).format('ll'),
                                                        },
                                                    },
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                }}
                                                series={signups?.refs?.map(
                                                    (category) => {
                                                        return {
                                                            name: category,
                                                            data: signups.dates.map(
                                                                (date) => {
                                                                    return signups.data.reduce(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) => {
                                                                            const x =
                                                                                b.external_ref ||
                                                                                'Ekkert auðkenni';

                                                                            if (
                                                                                x ===
                                                                                    category &&
                                                                                moment(
                                                                                    b.created
                                                                                ).isSame(
                                                                                    moment(
                                                                                        date
                                                                                    ),
                                                                                    'day'
                                                                                )
                                                                            ) {
                                                                                return (a +=
                                                                                    b.count);
                                                                            }
                                                                            return a;
                                                                        },
                                                                        0
                                                                    );
                                                                }
                                                            ),
                                                        };
                                                    }
                                                )}
                                            />
                                        </>
                                    </Card>
                                )}
                            </div>
                        </TabPane>

                        <TabPane tab="Uppsagnir">
                            <div className="geckoboard">
                                <Card
                                    loading={loading}
                                    className="geckoCard"
                                    title="Uppsagnir"
                                >
                                    <>
                                        <h2 className="text-center">
                                            Alls uppsagnir fyrir valið tímabil:{' '}
                                            {signups?.subscriptions_cancelled}
                                        </h2>
                                        <br />
                                        <div className="text-center">
                                            <CancelStats
                                                shopUUID={selectedShop.uuid}
                                                _from={selectedFrom}
                                                _to={selectedTo}
                                            ></CancelStats>
                                        </div>
                                    </>
                                </Card>
                            </div>
                        </TabPane>
                    </Tabs>
                </Card>
            )}
        </>
    );
}
