import { useEffect, useState } from 'react';
import { Form, Input, Switch, Button, message, Space } from 'antd';
import Axios from 'axios';
import Product, { InventoryInfo } from '../../../interfaces/product';

interface Props {
    product: Product;
}

export default function Inventory({ product }: Props) {
    const [form] = Form.useForm();
    const [trackInventory, setTrackInventory] = useState<boolean>();
    const [decreaseInventoryOnFullfill, setDecreaseInventoryOnFullfill] =
        useState<boolean>();
    const [madeChanges, setMadeChanges] = useState(false);
    const [inventoryObject, setInventoryObject] = useState<InventoryInfo>();

    useEffect(() => {
        if (product.inventory) {
            // @ts-ignore
            setInventoryObject(product.inventory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inventoryObject) {
            form.setFieldsValue({ ...inventoryObject, track_inventory: true });
            setTrackInventory(true);
            setDecreaseInventoryOnFullfill(
                inventoryObject.decrease_inventory_on_fullfill
            );
        } else {
            setTrackInventory(false);
            setDecreaseInventoryOnFullfill(true);
            form.setFieldsValue({ decrease_inventory_on_fullfill: true });
        }
    }, [inventoryObject]);

    const createNewInventory = (values: any) => {
        values.shop = product.shop;
        return Axios.post(`/verslun/api/create_inventory/`, values).then(
            (rsp) => {
                Axios.patch(
                    '/verslun/api/basic_product/' + product.uuid + '/',
                    { inventory: rsp.data.id }
                ).then((rsp) => {
                    message.success('Tókst!');
                });
            }
        );
    };

    const updateInventory = (values: any) => {
        if (!values.track_inventory) {
            Axios.patch('/verslun/api/basic_product/' + product.uuid + '/', {
                inventory: null,
            }).then((rsp) => {
                message.success('Tókst!');
            });
        } else {
            return Axios.patch(
                // @ts-ignore
                `/verslun/api/update_inventory/${inventoryObject.id}/`,
                values
            ).then((rsp) => {
                message.success('Tókst!');
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFieldsChange={() => {
                setMadeChanges(!madeChanges);
            }}
            onFinish={(values) => {
                if (
                    (values.quantity === null ||
                        values.quantity === undefined ||
                        values.quantity === '') &&
                    values.track_inventory
                ) {
                    message.error(
                        'Þú verður að tilgreina birgðastöðu ef þú vilt halda utan um birgðir (þú mátt setja 0).'
                    );
                } else {
                    values.decrease_inventory_on_fullfill =
                        values.decrease_inventory_on_fullfill || false;
                    values.decrease_inventory_on_signup =
                        values.decrease_inventory_on_signup || false;
                    values.increase_inventory_on_cancel =
                        values.increase_inventory_on_cancel || false;

                    if (!inventoryObject) {
                        createNewInventory(values);
                    } else {
                        updateInventory(values);
                    }

                    console.log(values);

                    if (
                        values.out_of_stock_waiting_list === false ||
                        values.out_of_stock_waiting_list
                    ) {
                        Axios.patch(
                            '/verslun/api/basic_product/' + product.uuid + '/',
                            {
                                out_of_stock_waiting_list:
                                    values.out_of_stock_waiting_list,
                            }
                        );
                    }
                }
            }}
        >
            <Space direction="vertical">
                <Form.Item
                    label="Halda utan um birgðarstöðu?"
                    name="track_inventory"
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren="JÁ "
                        unCheckedChildren="NEI"
                        onChange={(checked) => {
                            setTrackInventory(checked);
                        }}
                    />
                </Form.Item>

                {trackInventory && (
                    <>
                        <Form.Item name="quantity" label={'Birgðarstaða'}>
                            <Input size="large" placeholder="Birgðarstaða" />
                        </Form.Item>

                        <Form.Item
                            name="sell_when_out_of_stock"
                            label={
                                'Halda áfram að selja þegar birgðir eru tómar.'
                            }
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="JÁ "
                                unCheckedChildren="NEI"
                            />
                        </Form.Item>

                        {!form.getFieldValue('sell_when_out_of_stock') && (
                            <Form.Item
                                label="Biðlisti"
                                name="out_of_stock_waiting_list"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="JÁ"
                                    unCheckedChildren="NEI"
                                ></Switch>
                            </Form.Item>
                        )}

                        {product.type === 'SUBSCRIPTION' && (
                            <Form.Item
                                name="decrease_inventory_on_fullfill"
                                label={
                                    'Lækka birgðastöðu sjálfkrafa þegar sendingar er afgreiddar.'
                                }
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="JÁ "
                                    unCheckedChildren="NEI"
                                    onChange={(checked) => {
                                        setDecreaseInventoryOnFullfill(checked);
                                    }}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="decrease_inventory_on_signup"
                            label={'Lækka birgðastöðu við skráningu.'}
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="JÁ "
                                unCheckedChildren="NEI"
                            />
                        </Form.Item>

                        {!decreaseInventoryOnFullfill &&
                            product.type === 'SUBSCRIPTION' && (
                                <>
                                    <Form.Item
                                        name="increase_inventory_on_cancel"
                                        label={
                                            'Hækka birgðastöðu við þegar áskrift er sagt upp.'
                                        }
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checkedChildren="JÁ "
                                            unCheckedChildren="NEI"
                                        />
                                    </Form.Item>
                                </>
                            )}
                    </>
                )}

                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit">
                        Vista
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
}
