import { useEffect, useState } from 'react';
import Axios from 'axios';
import {
    Card,
    Col,
    Row,
    Layout,
    Form,
    Input,
    Button,
    message,
    UploadFile,
} from 'antd';
import UploadImage from '../../components/UploadImage/UploadImage';
import { UploadChangeParam } from 'antd/lib/upload';
import './Profile.less';

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

const { Content } = Layout;

export default function Profile() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();
    const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>();
    const [form] = Form.useForm();
    const [initImageCheck, setInitImageCheck] = useState(false);

    useEffect(() => {
        Axios('/account/api/user/' + w.me + '/').then((rsp) => {
            setImageFileList(
                rsp.data.image
                    ? [
                          {
                              url: rsp.data.image.image,
                              status: 'done',
                              name: 'image.png',
                              uid: rsp.data.image.id,
                          },
                      ]
                    : []
            );

            setLoading(false);
            setUser(rsp.data);

            form.setFieldsValue(rsp.data);
        });
    }, []);

    return (
        <Layout id="profile">
            <Content>
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
                        <Card title="Stillingar" loading={loading}>
                            <Form
                                form={form}
                                layout="vertical"
                                name="basic"
                                onFinish={(data) => {
                                    data.image = data.image?.id || data.image;
                                    if (data.phone && data.phone.length === 7)
                                        data.phone = '+354' + data.phone;
                                    Axios.patch(
                                        '/account/api/user/' +
                                            w.me +
                                            '/update/',
                                        data
                                    )
                                        .then((rsp) => {
                                            message.success(
                                                'Tókst! Prófíllinn þinn hefur verið uppfærður'
                                            );
                                        })
                                        .catch((err) => {
                                            if (err.response?.data) {
                                                const key = Object.keys(
                                                    err.response.data
                                                )[0];

                                                message.error(
                                                    key +
                                                        ': ' +
                                                        err.response.data[key]
                                                );
                                            } else {
                                                message.error(
                                                    'Úps, eitthvað fór úrskeiðis!'
                                                );
                                            }
                                        });
                                }}
                                onFinishFailed={() => {
                                    message.error(
                                        'Úps, lagaðu formið áður en þú uppfærir :)'
                                    );
                                }}
                            >
                                <Form.Item
                                    label="Netfang"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Þú verður að hafa netfang!',
                                        },
                                    ]}
                                >
                                    <Input autoComplete="new-password" />
                                </Form.Item>

                                <Form.Item label="Símanúmer" name="phone">
                                    <Input autoComplete="new-password" />
                                </Form.Item>

                                <Form.Item label="Nafn" name="signature_name">
                                    <Input autoComplete="new-password" />
                                </Form.Item>

                                <Form.Item
                                    label="Prófílmynd"
                                    name="image"
                                    valuePropName="fileList"
                                >
                                    <UploadImage
                                        defaultFileList={imageFileList}
                                        onSuccess={(
                                            imageId: string,
                                            resObj: UploadChangeParam<
                                                UploadFile<any>
                                            >
                                        ) => {
                                            form.setFieldsValue({
                                                image: parseInt(imageId),
                                            });
                                        }}
                                        helpText="Hlaða inn mynd"
                                    ></UploadImage>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        className="btn-success"
                                        block
                                        size="large"
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Vista
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
