import Axios from 'axios';
import { useState, useEffect } from 'react';
import { Form, Select, Input } from 'antd';
import { DeliveryOption } from '../../interfaces/delivery.js';
import { kr } from '../../utils';
import useDropp from '../../customHooks/Dropp/Dropp.js';
import translate, { LanguageKey } from '../../translate/translate.js';

import Product from '../../interfaces/product.js';

interface PostboxOption {
    postboxId: string;
    name: string;
    address: string;
    postcode: string;
    town: string;
    latitude: string;
    longitude: string;
}

export interface SelectedDeliveryOption {
    external_delivery_location_id?: string | undefined;
    delivery_option: number;
    delivery_postal_code?: string;
    posturinn_location_name?: string;
    price?: number;
    shipping_provider?: string | undefined;
    title?: string;
}

export default function DeliveryChoicesForNewSubscription(props: {
    product: Product;
    onSuccess: (res: SelectedDeliveryOption | undefined) => void;
    size?: 'small' | 'large';
    language: LanguageKey;
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [renderChange, setRenderChange] = useState(true);

    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption | null>();
    const [postboxOptions, setPostboxOptions] = useState<PostboxOption[]>([]);
    const [parcelpointOptions, setParcelpointOptions] = useState<
        PostboxOption[]
    >([]);
    const [selectedDPOPostalCode, setSelectedDPOPostalCode] = useState<
        string | null
    >();

    const [selectedDPTPostalCode, setSelectedDPTPostalCode] = useState<
        string | null
    >();

    const [isChanged, setIsChanged] = useState(false);
    const { product, language } = props;
    const deliveryConfig = product?.delivery_config;
    const {
        droppSelection,
        clearDroppSelection,
        openDroppModal,
        setDroppLocationId,
    } = useDropp(deliveryConfig?.dropp?.store_id);

    const shopUUID = product.shop_uuid;

    useEffect(() => {
        if (
            !selectedDPOPostalCode ||
            selectedDPOPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpo_name: undefined,
            });
        } else if (selectedDPOPostalCode.toString().length === 3) {
            Axios.post('/shipping/api/posturinn_postboxes', {
                shop_uuid: shopUUID,
                postal_code: selectedDPOPostalCode,
            }).then((rsp) => {
                setPostboxOptions(rsp.data || []);
            });
        }
    }, [selectedDPOPostalCode]);

    useEffect(() => {
        if (
            !selectedDPTPostalCode ||
            selectedDPTPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            form.setFieldsValue({
                posturinn_dpt_name: undefined,
            });
        } else if (selectedDPTPostalCode?.toString().length === 3) {
            Axios.post('/shipping/api/posturinn_parcelpoints', {
                shop_uuid: shopUUID,
                postal_code: selectedDPTPostalCode,
            }).then((rsp) => {
                setParcelpointOptions(rsp.data || []);
            });
        }
    }, [selectedDPTPostalCode]);

    useEffect(() => {
        if (selectedDeliveryOption) {
            if (
                selectedDeliveryOption.shipping_provider === 'DROPP_PICKUP' &&
                !droppSelection
            ) {
                props.onSuccess(undefined);
            } else if (
                selectedDeliveryOption.shipping_provider === 'POSTURINN_DPT' &&
                (!form.getFieldValue('posturinn_dpt_name') ||
                    !form.getFieldValue('posturinn_dpt_postal_code'))
            ) {
                props.onSuccess(undefined);
            } else if (
                selectedDeliveryOption.shipping_provider === 'POSTURINN_DPO' &&
                (!form.getFieldValue('posturinn_dpo_name') ||
                    !form.getFieldValue('posturinn_dpo_postal_code'))
            ) {
                props.onSuccess(undefined);
            } else {
                form.submit();
            }
        }
    }, [
        selectedDeliveryOption,
        droppSelection,
        renderChange,
        selectedDPOPostalCode,
        selectedDPTPostalCode,
    ]);

    return (
        <div>
            <Form
                form={form}
                size={props.size || 'large'}
                layout="vertical"
                onFinish={(values) => {
                    const payload = {
                        external_delivery_location_id: droppSelection?.id,
                        delivery_option: values.delivery_option,
                        delivery_postal_code:
                            values.posturinn_dpo_postal_code ||
                            values.posturinn_dpo_postal_code,
                        posturinn_location_name:
                            values.posturinn_dpt_name ||
                            values.posturinn_dpo_name,
                        price: selectedDeliveryOption?.price,
                        shipping_provider:
                            selectedDeliveryOption?.shipping_provider,
                        title: selectedDeliveryOption?.title,
                    };

                    props.onSuccess(payload);
                }}
            >
                {product?.delivery_config && (
                    <Form.Item
                        name="delivery_option"
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'delivery_method_required',
                                    language
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={translate(
                                'select_delivery_option',
                                language
                            )}
                            size={props.size || 'large'}
                            onSelect={(id: number) => {
                                const option =
                                    product.delivery_config?.options.find(
                                        (o) => o.id === id
                                    );

                                if (option) {
                                    if (option.shipping_provider) {
                                        setSelectedDeliveryOption(option);

                                        if (
                                            option.shipping_provider ===
                                            'DROPP_PICKUP'
                                        ) {
                                            openDroppModal();
                                        } else {
                                            clearDroppSelection();
                                        }
                                    }
                                }
                            }}
                            children={product.delivery_config.options.map(
                                (option, i) => {
                                    return (
                                        <Select.Option
                                            value={option.id}
                                            key={option.id}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>{option.title}</div>

                                                <small>
                                                    {kr(option.price)}
                                                </small>
                                            </div>
                                        </Select.Option>
                                    );
                                }
                            )}
                        ></Select>
                    </Form.Item>
                )}
                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPO' && (
                    // Póstbox
                    <>
                        <Form.Item
                            name="posturinn_dpo_postal_code"
                            label={translate(
                                'posturinn_dpo_postal_code',
                                language
                            )}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        language
                                    ),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedDPOPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpo_postal_code',
                                    language
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpo_name"
                            label={translate('posturinn_dpo', language)}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        language
                                    ),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate(
                                    'posturinn_dpo',
                                    language
                                )}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    language
                                )}
                                onChange={() => setRenderChange(!renderChange)}
                                children={postboxOptions.map((option, i) => {
                                    return (
                                        <Select.Option
                                            value={option.name}
                                            key={option.postboxId}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>{option.name}</div>
                                            </div>
                                        </Select.Option>
                                    );
                                })}
                            ></Select>
                        </Form.Item>
                    </>
                )}
                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPT' && (
                    // Pósturinn pakkaport sækja
                    <>
                        <Form.Item
                            name="posturinn_dpt_postal_code"
                            label={translate(
                                'posturinn_dpt_postal_code',
                                language
                            )}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        language
                                    ),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedDPTPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpt_postal_code',
                                    language
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpt_name"
                            label={translate('posturinn_dpt', language)}
                            rules={[
                                {
                                    required: true,
                                    message: translate(
                                        'field_required',
                                        language
                                    ),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate(
                                    'posturinn_dpt',
                                    language
                                )}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    language
                                )}
                                onChange={() => setRenderChange(!renderChange)}
                                children={parcelpointOptions.map(
                                    (option, i) => {
                                        return (
                                            <Select.Option
                                                value={option.name}
                                                key={option.postboxId}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>{option.name}</div>
                                                </div>
                                            </Select.Option>
                                        );
                                    }
                                )}
                            ></Select>
                        </Form.Item>
                    </>
                )}
                {droppSelection && (
                    <div
                        style={{
                            background: 'rgb(239 239 239)',
                            fontSize: 14,
                            padding: 5,
                            borderRadius: 20,
                            display: 'flex',
                            marginBottom: 20,
                            marginTop: -10,
                            color: '#3d3d3d',
                        }}
                    >
                        <img
                            src="/static/images/global_logos/dropp.svg"
                            alt="Dropp logo"
                            style={{ width: 40, marginRight: 5 }}
                        />
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                {droppSelection.name}
                            </div>
                            <div>{droppSelection.address}</div>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}
