import { useEffect, useState } from 'react';
import { Button, DatePicker, Space, Table, message } from 'antd';
import Axios from 'axios';
import { kr } from '../../utils';
import dayjs from 'dayjs';
import moment from 'moment';

type Props = {
    customerUUID: string;
};

type BankClaim = {
    // 0: Unpaid,
    // 1: Paid,
    // 2: Cancelled
    // 3: Invalid
    // 4: Unknown
    Status: 0 | 1 | 2 | 3 | 4;
    State: number;
    CategoryCode?: any;
    TotalAmountDue: number;
    NoticeChargeAmount: number;
    DefaultChargeAmount: number;
    OtherCostsAmount: number;
    OtherDefaultCostsAmount: number;
    DefaultInterestAmount: number;
    DiscountAmount: number;
    SecondaryCollectionIdentifier: string;
    LegalCollectionIdentifier: string;
    IsPartiallyPaid: boolean;
    Key: {
        Claimant: string;
        Account: string;
        DueDate: string;
    };
    PayorID: string;
    PayorName: string;
    ClaimantName: string;
    CancellationDate: string;
    Identifier: string;
    Amount: number;
    Reference: string;
    FinalDueDate: string;
    BillNumber: string;
    CustomerNumber: string;
    NoticeAndPaymentFee?: object;
    DefaultCharge?: object;
    OtherCosts: number;
    OtherDefaultCosts: number;
    DefaultInterest?: {
        Rule: number;
        Percentage4: number;
        SpecialCode: string;
    };
    CurrencyInformation?: string;
    PermitOutOfSequencePayment: boolean;
    Discount?: object;
    IsPartialPaymentAllowed: boolean;
    BillPresentmentSystem?: any;
    Printing?: object;
};

export default function CustomerClaimsOverview(props: Props) {
    const [claims, setClaims] = useState<BankClaim[]>([]);
    const [loading, setLoading] = useState(true);
    const [dates, setDates] = useState({
        from: moment().subtract(5, 'years'),
        to: moment().add(2, 'months'),
    });

    useEffect(() => {
        getClaimData();
    }, []);

    const getClaimData = () => {
        Axios.post('/payment/api/get_customer_claims/', {
            customer_uuid: props.customerUUID,
            from: dates.from,
            to: dates.to,
        }).then((rsp) => {
            setClaims(rsp.data);
            setLoading(false);
        });
    };

    return (
        <Space direction="vertical" style={{ display: 'flex' }}>
            <Space>
                <label>Frá: </label>
                <DatePicker
                    defaultValue={dates.from}
                    onChange={(val) => val && setDates({ ...dates, from: val })}
                ></DatePicker>

                <label>Til: </label>
                <DatePicker
                    defaultValue={dates.to}
                    onChange={(val) => val && setDates({ ...dates, to: val })}
                ></DatePicker>

                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                        if (!dates.from || !dates.to) {
                            message.error('Þú verður að velja dagsetningar');
                            return;
                        }

                        getClaimData();
                        console.log(
                            dates.to.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
                            dates.from.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                        );
                    }}
                >
                    Leita
                </Button>
            </Space>

            <Table
                loading={loading}
                pagination={false}
                dataSource={claims}
                columns={[
                    {
                        title: 'Upphæð',
                        render: (claim: BankClaim) => {
                            return <div>{kr(claim.Amount)}</div>;
                        },
                    },
                    {
                        title: 'Eindagi',
                        render: (claim: BankClaim) => {
                            return (
                                <div>
                                    {dayjs(claim.FinalDueDate).format(
                                        'D. MMM YYYY'
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Staða',
                        render: (claim: BankClaim) => {
                            return (
                                <div>
                                    {claim.Status === 0 && <div>Ógreitt</div>}
                                    {claim.Status === 1 && <div>Greitt</div>}
                                    {claim.Status === 2 && (
                                        <div>Niðurfelld</div>
                                    )}
                                    {claim.Status === 3 && <div>Villa</div>}
                                    {claim.Status === 4 && <div>Óvitað</div>}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Kröfunúmer',
                        render: (claim: BankClaim) => {
                            return <div>{claim.Key.Account}</div>;
                        },
                    },
                ]}
            ></Table>
        </Space>
    );
}
