import { Select } from 'antd';

type Props = {
    defaultValue?: 'is' | 'en';
    onChange: (val: 'is' | 'en') => void;
};

const supported = ['is', 'en'];

export default function SelectLanguage(props: Props) {
    let def = props.defaultValue || 'is';
    if (!supported.includes(def.toLocaleLowerCase())) def = 'is';

    return (
        <Select
            className="noBorderSelect"
            size="small"
            onChange={(val: 'is' | 'en') => props.onChange(val)}
            style={{
                width: 60,
                textAlign: 'center',
                border: 'none',
            }}
            defaultValue={def}
            disabled={false}
        >
            <Select.Option value="is">
                <img
                    alt="IS flag"
                    src={'/static/images/svg/languages/is.svg'}
                    style={{
                        width: 20,
                        display: 'inline-block',
                    }}
                />
            </Select.Option>
            <Select.Option value="en">
                <img
                    alt="UK flag"
                    src={'/static/images/svg/languages/en.svg'}
                    style={{
                        width: 20,
                        display: 'inline-block',
                    }}
                />
            </Select.Option>
        </Select>
    );
}
