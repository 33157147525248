import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import Axios from 'axios';
import ReactWordcloud, { OptionsProp } from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import Shop from '../../interfaces/shop';
import dayjs from 'dayjs';

type Props = {
    shopUUID: string;
    _from: string;
    _to: string;
};

function removeEmails(text: string) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    return text.replace(emailRegex, '');
}

function removeWordsFromArray(text: string, wordsToRemove: string[]) {
    const regex = new RegExp(wordsToRemove.join('|'), 'gi');
    return text.replace(regex, '');
}

function getTop50Words(text: string) {
    const words = text.split(/\P{L}+/gu).filter((word) => word);
    const longWords = words.filter((word) => word.length > 3);
    const wordCounts = longWords.reduce((acc, word) => {
        const lowerCaseWord = word.toLowerCase();
        acc[lowerCaseWord] = (acc[lowerCaseWord] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);
    const wordCountPairs = Object.entries(wordCounts);

    wordCountPairs.sort((a, b) => b[1] - a[1]);
    const top50WordsWithWeights = wordCountPairs
        .slice(0, 150)
        .map(([text, value]) => ({
            text,
            value,
        }));

    return top50WordsWithWeights;
}

export default function CancelStats({ shopUUID, _from, _to }: Props) {
    const [words, setWords] = useState<{ text: string; value: number }[]>();
    const [cancelledSubscriptions, setCancelledSubscriptions] = useState<
        {
            uuid: string;
            updated: string;
            cancel_reason: string;
            resign_date?: string;
            customer: {
                uuid: string;
                updated: string;
                created: string;
                name: string;
            };
        }[]
    >([]);

    useEffect(() => {
        if (shopUUID) {
            Axios.get(
                '/askrift/api/cancelled_subscriptions/?active=false&ordering=-updated&product__shop__uuid=' +
                    shopUUID +
                    '&updated__gte=' +
                    _from +
                    '&updated__lte=' +
                    _to
            )
                .then((res) => {
                    setCancelledSubscriptions(res.data.results);
                    const reasons = res.data.results.map(
                        (result: any) => result.cancel_reason
                    );

                    const all = reasons.join(' ').toLowerCase();

                    let cleanedText = removeEmails(all);

                    const wordsToRemove = [
                        'áskrift',
                        'áskriftin',
                        'hefur',
                        'verið',
                        'óvirkjuð',
                        'stofnuð',
                        'framkvæmd',
                        'önnur',
                        'aðgerð',
                        'staðin',
                        'janúar',
                        'febrúar',
                        'mars',
                        'apríl',
                        'maí',
                        'júní',
                        'júlí',
                        'ágúst',
                        'september',
                        'október',
                        'nóvember',
                        'desember',
                    ];

                    const withRemoved = removeWordsFromArray(
                        cleanedText,
                        wordsToRemove
                    );

                    const top50Words = getTop50Words(withRemoved);

                    setWords(top50Words);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [shopUUID, _from, _to]);

    return (
        <>
            <div style={{ display: 'flex' }}>
                {words && (
                    <ReactWordcloud
                        options={{
                            rotations: 20,
                            rotationAngles: [-20, 20],
                            fontSizes: [10, 50],
                            colors: ['gray'],
                        }}
                        size={[600, 400]}
                        words={words}
                    />
                )}

                <Table
                    dataSource={cancelledSubscriptions}
                    pagination={false}
                    columns={[
                        {
                            title: 'Nafn',
                            render: (record) => record.customer.name,
                        },
                        {
                            title: 'Hætti',
                            render: (record) =>
                                dayjs(record.updated).format('D. MMM YYYY'),
                        },

                        {
                            title: 'Ástæða',
                            dataIndex: 'cancel_reason',
                            key: 'cancel_reason',
                        },
                    ]}
                ></Table>
            </div>
        </>
    );
}
