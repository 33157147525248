import {
    Alert,
    Button,
    Form,
    Input,
    Modal,
    Select,
    Space,
    Tabs,
    message,
} from 'antd';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Product from '../../../interfaces/product';
import { SubscriptionFilterPayload } from '../../../interfaces/subscription';
const { TabPane } = Tabs;

type Props = {
    open: boolean;
    onCancel: () => void;
    shopUUID: string;
    subscriptionFilterPayload: SubscriptionFilterPayload;
    selectedCount: number;
};

export default function ChangeSubscriptionsModal({
    open,
    onCancel,
    shopUUID,
    selectedCount,
    subscriptionFilterPayload,
}: Props) {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Axios.get('/verslun/api/product/?shop__uuid=' + shopUUID).then(
            (res) => {
                setProducts(res.data.results);
            }
        );
    }, []);

    return (
        <Modal onCancel={onCancel} footer={null} open={open}>
            <h3>Hverju viltu breyta?</h3>

            <Alert
                type="error"
                message={'Mikilvægt!'}
                description={`Þú ert að breyta ${selectedCount} áskriftum, þeim verður ÖLLUM breytt.`}
            ></Alert>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Verðinu" key="1">
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Form
                            onFinish={(values) => {
                                console.log(values);

                                Axios.post(
                                    '/askrift/change_multiple_subscriptions_price/',
                                    {
                                        ...subscriptionFilterPayload,
                                        new_price: values.new_price,
                                    }
                                )
                                    .then((rsp) => {
                                        message.success(
                                            'Áskriftum hefur verið breytt'
                                        );
                                        onCancel();
                                    })
                                    .catch(() =>
                                        message.error('Eitthvað fór úrskeiðis')
                                    );
                            }}
                            size="large"
                            layout="vertical"
                        >
                            <Form.Item label="Nýtt verð" name="new_price">
                                <Input />
                            </Form.Item>

                            <Button
                                loading={loading}
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                Breyta verði
                            </Button>
                        </Form>
                    </Space>
                </TabPane>
                <TabPane tab="Tegund vöru" key="2">
                    <Form
                        onFinish={(values) => {
                            setLoading(true);

                            const payload = {
                                ...subscriptionFilterPayload,
                                new_product_uuid: values.new_product,
                            };

                            Axios.post(
                                '/askrift/change_multiple_subscriptions_product/',
                                payload
                            )
                                .then((rsp) => {
                                    message.success(
                                        'Áskriftum hefur verið breytt'
                                    );

                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 2000);
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis');
                                });
                        }}
                        layout="vertical"
                        size="large"
                    >
                        <Form.Item label="Ný tegund" name="new_product">
                            <Select>
                                {products.map((product) => {
                                    return (
                                        <Select.Option
                                            value={product.uuid}
                                            key={product.uuid}
                                        >
                                            {product.title}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <p>
                            Núverandi áskrift verður gerð óvirk og ný áskrift
                            stofnuð með nýrri vöru en sömu stillingum að öðru
                            leyti (verð, tíðni, sendingarmáti, greiðslumáti
                            o.s.frv)
                        </p>

                        <p>
                            Heyrðu í starfsfólki Repeat og fáðu ráðgjöf ef þú
                            ert óviss um hvernig þessi fítus virkar.
                        </p>

                        <Button
                            loading={loading}
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            Breyta tegund vöru
                        </Button>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
}
