import { useState, useEffect, useCallback } from 'react';
import { Card, List, Button, Space, Input } from 'antd';
import Shop from '../../interfaces/shop';
import Axios from 'axios';
import Product from '../../interfaces/product';
import AdminProductPreview from '../AdminProductPreview/AdminProductPreview';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import './productOverview.less';

type Props = {
    shop: Shop;
};

export default function ProductOverview(props: Props) {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>();
    const [searchTerm, setSearchTerm] = useState('');
    const [waitingForHandler, setwaitingForHandler] = useState(false);
    const [pagination, setPagination] = useState<{
        count: number;
        next: string;
        next_page_number: number;
        previous: string;
        previous_page_number: number;
        total_pages: number;
        results: Product[];
    }>();
    const [totalProducts, setTotalProducts] = useState<number>();

    useEffect(() => {
        getProducts();
    }, [searchTerm, props.shop]);

    const searchProducts = (term: string) => {
        setSearchTerm(term);
    };

    const getProducts = (page?: number) => {
        let url =
            '/verslun/api/admin_product/?shop__uuid=' +
            props.shop.uuid +
            '&ordering=-created';
        let pageSize = 5;

        if (page) {
            url += '&page=' + page;
        }

        if (searchTerm) {
            url += '&search=' + searchTerm;
            pageSize = 100;
        }

        url += '&page_size=' + pageSize;
        Axios.get(url).then((rsp) => {
            if (!totalProducts) {
                setTotalProducts(rsp.data.count);
            }

            setProducts(rsp.data.results);
            setLoading(false);
            setPagination(rsp.data);
            setwaitingForHandler(false);
        });
    };

    const handler = useCallback(debounce(searchProducts, 400), []);

    return (
        <div id="adminProductOverviewContainer">
            <Card
                loading={loading}
                title={
                    <>
                        <img
                            style={{ height: 45 }}
                            src="/static/images/svg/tag.svg"
                            alt=""
                        />{' '}
                        Vörur
                    </>
                }
                extra={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 10,
                        }}
                    >
                        {(totalProducts || 0) > 5 && (
                            <Input
                                style={{
                                    width: '100%',
                                    borderRadius: 10,
                                }}
                                placeholder="Leita að vöru"
                                prefix={
                                    waitingForHandler ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <SearchOutlined />
                                    )
                                }
                                onChange={(e) => {
                                    const term = e.currentTarget.value;
                                    setwaitingForHandler(true);

                                    if (term && term.length > 1) {
                                        handler(term);
                                    } else {
                                        setProducts(pagination?.results || []);
                                        setSearchTerm('');
                                        setTimeout(() => {
                                            setwaitingForHandler(false);
                                        }, 500);
                                    }
                                }}
                            />
                        )}
                        <Button
                            aria-label="Stofna nýja vöru"
                            href={'/ny_vara/' + props.shop.uuid}
                            type="dashed"
                        >
                            +
                        </Button>
                    </div>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={products || []}
                    locale={{
                        emptyText: (
                            <Space direction="vertical">
                                {!searchTerm && !waitingForHandler && (
                                    <div>
                                        <h2>
                                            Stofnaðu fyrstu vöruna þína
                                            <span role="img" aria-label="emoji">
                                                🚀
                                            </span>
                                        </h2>
                                        <Button
                                            type="primary"
                                            size="large"
                                            block
                                        >
                                            <a
                                                href={
                                                    '/ny_vara/' +
                                                    props.shop.uuid
                                                }
                                            >
                                                + Stofna vöru
                                            </a>
                                        </Button>
                                    </div>
                                )}

                                {(searchTerm || waitingForHandler) && (
                                    <div>
                                        <h3>
                                            Engar vörur fundust með
                                            leitarorðinu:
                                        </h3>
                                        <div>
                                            <h3>
                                                <b>{searchTerm}</b>
                                            </h3>
                                        </div>
                                    </div>
                                )}
                            </Space>
                        ),
                    }}
                    renderItem={(product) => (
                        <AdminProductPreview
                            product={product}
                            shop={props.shop}
                            lastItem={
                                products?.indexOf(product) ===
                                (products?.length || 0) - 1
                            }
                        />
                    )}
                    pagination={
                        pagination && !searchTerm && pagination.count > 5
                            ? {
                                  pageSize: 5,
                                  total: pagination.count,
                                  showSizeChanger: false,
                                  size: 'small',
                                  onChange: (page) => {
                                      getProducts(page);
                                  },
                              }
                            : false
                    }
                />
            </Card>
        </div>
    );
}
