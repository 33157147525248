import { ReactElement, useState } from 'react';
import { Space, Modal, Button, message } from 'antd';
import CardToken from '../../interfaces/cardToken';
import SaveSaltPayInfo from '../../components/SavePaymentInfo/SaltPay/SaveSaltPayInfo';
import Axios from 'axios';
import { SimpleCustomer } from '../../interfaces/customer';
import Valitor3DSecure from './Valitor/Valitor3DSecure';
import { Currency } from '../../interfaces/product';
import OnPay from './OnPay/OnPay';
import translate, { LanguageKey } from '../../translate/translate';

interface Props {
    open: boolean;
    onCancel: Function;
    customer: SimpleCustomer;
    className?: string;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}

export default function UpdateCreditCardModal({
    customer,
    onCancel,
    open,
    className,
    currencyCode,
    language,
}: Props): ReactElement {
    const [loading, setLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState<
        CardToken | undefined | null
    >();

    return (
        <Modal
            title={translate('connected_card', language)}
            footer={false}
            open={open}
            onCancel={() => {
                onCancel();
            }}
            className={className || ''}
        >
            <h4 className="capitalize">{customer.name}</h4>

            {(customer.shop.valitor || customer.shop.straumur) && (
                <Valitor3DSecure
                    onSuccess={() => {
                        onCancel();
                    }}
                    customer={customer}
                    currencyCode={currencyCode}
                    language={language}
                ></Valitor3DSecure>
            )}

            {customer.shop.salt_pay && (
                <SaveSaltPayInfo
                    useTermsCheckbox={true}
                    customer_uuid={customer.uuid}
                    onSuccess={() => {
                        onCancel();
                        message.success(translate('card_saved', language));
                    }}
                    pay_unpaid_subscription_transactions={true}
                    shop={customer.shop}
                    currencyCode={currencyCode}
                    language={language}
                ></SaveSaltPayInfo>
            )}

            {customer.shop.onpay && (
                <OnPay customer={customer} language={language}></OnPay>
            )}

            {customer.shop.rapyd && (
                <Space
                    style={{ display: 'flex' }}
                    direction="vertical"
                    size="large"
                >
                    <div>
                        {translate('go_to_issuer_to_save_card', language)}
                    </div>

                    <Button
                        loading={loading}
                        block
                        type="primary"
                        size="large"
                        onClick={() => {
                            setLoading(true);
                            Axios.get(
                                `/payment/get_rapyd_collect_card_url?shop_uuid=${customer.shop.uuid}&name=${customer.name}&email=${customer.email}`
                            )
                                .then((response) => {
                                    window.location.href = response.data;
                                })
                                .catch(() => {
                                    message.error('Villa kom upp');
                                    setLoading(false);
                                });
                        }}
                    >
                        {translate('confirm', language)}
                    </Button>
                </Space>
            )}
        </Modal>
    );
}
