import { useState } from 'react';
import Subscription from '../../interfaces/subscription';
import { Button, Form, Select, message } from 'antd';
import Axios from 'axios';

type Props = {
    subscription: Subscription;
    onSuccess: () => void;
};

export default function SubscriptionAttributeOptions({
    subscription,
    onSuccess,
}: Props) {
    const [loading, setLoading] = useState(false);

    const { product, subscription_product } = subscription;
    const subscriptionProduct = subscription_product;

    const initalValues = subscriptionProduct?.attributes?.reduce(
        (acc, attribute) => {
            if (attribute.attribute.type === 'SELECT') {
                return {
                    ...acc,
                    [attribute.attribute.uuid]: attribute.option_answers?.map(
                        (oa) => oa.uuid
                    ),
                };
            }
            return acc;
        },
        {}
    );

    console.log(initalValues);

    return (
        <Form
            size="large"
            layout="vertical"
            initialValues={initalValues}
            onFinish={(attributes) => {
                setLoading(true);

                Axios.post('/askrift/api/update_subscription_attributes/', {
                    subscription_uuid: subscription.uuid,
                    attributes,
                })
                    .then((rsp) => {
                        setLoading(false);
                        onSuccess();
                        message.success('Breytingar hafa verið vistaðar');
                    })
                    .catch((err) => {
                        setLoading(false);
                        message.error('Villa kom upp við að vista breytingar');
                        console.error(err);
                    });
            }}
        >
            {product.attributes?.map((attribute) => {
                if (attribute.type === 'SELECT') {
                    return (
                        <Form.Item
                            label={attribute.label}
                            key={attribute.uuid}
                            name={attribute.uuid}
                            rules={
                                attribute.required
                                    ? [
                                          {
                                              required: true,
                                              message: `Vinsamlegast veldu ${attribute.label}`,
                                          },
                                      ]
                                    : []
                            }
                        >
                            <Select>
                                {attribute.options?.map((option) => {
                                    return (
                                        <Select.Option
                                            key={option.uuid}
                                            value={option.uuid}
                                        >
                                            {option.title}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    );
                }
            })}

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
