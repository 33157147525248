// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';

//Importing world-map.json file from the currrent folder as geoUrl
import geoUrl from './world-countries.json';

// @ts-ignore
const colorScale = scaleLinear().domain([0, 100]).range(['#fff0f1', '#ff5967']);

type Props = {
    data: {
        country: string;
        value: number;
    }[];
};

export default function WorldMap({ data }: Props) {
    return (
        <ComposableMap>
            <Geographies geography={geoUrl}>
                {({ geographies }: { geographies: any[] }) =>
                    geographies.map((geo) => {
                        console.log(geo.properties.name);
                        const d = data.find(
                            (s) => s.country === geo.properties.name
                        );
                        return (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                fill={
                                    d
                                        ? colorScale(d.value).toString()
                                        : '#F5F4F6'
                                }
                                style={{
                                    default: {
                                        outline: 'none',
                                    },
                                    hover: {
                                        outline: 'none',
                                    },
                                    pressed: {
                                        outline: 'none',
                                    },
                                }}
                            />
                        );
                    })
                }
            </Geographies>
        </ComposableMap>
    );
}
