// @ts-nocheck
import React from 'react';
import { Modal, Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { SalesPageUrlParams } from '../../interfaces/shop';

interface Props {
    title: string;
    onCancel: () => void;
    open: boolean;
    content: string;
    onChange: (obj: SalesPageUrlParams) => void;
    justContents: boolean;
}

export default function CopyToClipboardModal(props: Props) {
    const getBody = () => {
        return (
            <>
                <SyntaxHighlighter
                    language="markup"
                    style={dracula}
                    showLineNumbers={true}
                >
                    {props.content}
                </SyntaxHighlighter>

                <CopyToClipboard
                    text={props.content.toString()}
                    onCopy={() =>
                        message.success('Tókst, kóðinn hefur verið afritaður!')
                    }
                >
                    <Button size="large" block type="primary">
                        Afrita kóða
                    </Button>
                </CopyToClipboard>
            </>
        );
    };

    return props.justContents ? (
        getBody()
    ) : (
        <Modal
            title={props.title}
            onCancel={props.onCancel}
            open={props.open}
            footer={null}
        >
            {getBody()}
        </Modal>
    );
}
