import { useState, useEffect } from 'react';
import { Form, Button, message, InputNumber, Space } from 'antd';
import Axios from 'axios';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

// Typescript
import Product from '../../../interfaces/product';

type Props = {
    product: Product;
};

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function CustomProductDataSettings(props: Props) {
    const [loading, setLoading] = useState(false);
    const [classScheduleId, setClassScheduleId] = useState();
    const [form] = Form.useForm();

    const { product } = props;
    document.documentElement.setAttribute('data-color-mode', 'dark');

    useEffect(() => {
        if (classScheduleId) {
            form.setFieldsValue({
                custom_data: JSON.stringify(
                    {
                        ...JSON.parse(
                            form.getFieldValue('custom_data') || '{}'
                        ),
                        mindbody: {
                            class_schedule_id: classScheduleId,
                        },
                    },
                    null,
                    4
                ),
            });
        }
    }, [classScheduleId]);

    return (
        <Form
            form={form}
            initialValues={{
                ['custom_data']: product.custom_data
                    ? JSON.stringify(product.custom_data, null, 4)
                    : '{}',
            }}
            layout="vertical"
            onFinish={(values) => {
                // checking if custom_data is valid JSON
                try {
                    JSON.parse(values.custom_data);
                } catch (e) {
                    message.error('Ekki löglegt JSON');
                    return;
                }

                Axios.patch(
                    '/verslun/api/basic_product/' + product.uuid + '/',
                    {
                        custom_data: JSON.parse(values.custom_data),
                    }
                ).then((rsp) => {
                    message.success('Tókst!');
                });
            }}
            // initialValues={{
            //     product_id: woocommerce?.product_id,
            //     create_woocommerce_order:
            //         woocommerce?.create_woocommerce_order || false,
            // }}
        >
            <p>
                Þessi gögn verða send áfram með vefkrókum (webhooks) og API
                köllum. Þetta getur komið sér vel til að koma upplýsingum í ytri
                kerfi. Frjálst er að geyma upplýsingar á hvaða formi sem er svo
                lengi sem það er löglegt JSON.
            </p>

            <br />

            {[
                '7b507ddd-bf3d-4f7f-9130-4a2097d9e119',
                '10900089-7cbb-4fb8-946f-f6560728ad1f',
            ].includes(product.shop_uuid || '') && (
                <>
                    <p>
                        <h3>Mindbody class ID</h3>
                        <Space>
                            <InputNumber
                                onChange={(value) => {
                                    if (value) {
                                        // @ts-ignore
                                        setClassScheduleId(value);
                                    }
                                }}
                                placeholder="Class ID"
                            ></InputNumber>
                        </Space>
                    </p>

                    <br />
                </>
            )}

            <Form.Item name="custom_data" label="Sérsniðin gögn (JSON)">
                <AceEditor
                    mode="json"
                    theme="monokai"
                    showPrintMargin={false}
                    width="100%"
                    setOptions={{
                        useWorker: false,
                    }}
                ></AceEditor>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={loading}
                    type="primary"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
//
