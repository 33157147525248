import { useEffect, useState } from 'react';
import Axios from 'axios';
import FontPicker from 'font-picker-react';
import {
    Button,
    Collapse,
    Form,
    Input,
    Popover,
    Select,
    Switch,
    message,
} from 'antd';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

import Product from '../../../interfaces/product';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import Shop from '../../../interfaces/shop';

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

const getFontString = (val: string | undefined) => {
    try {
        const split = (val || '').split('font-family: ');
        const result = split[1].split(';')[0];
        return result;
    } catch (err) {
        return 'Open Sans';
    }
};

export default function AdvancedSettings(props: {
    product: Product;
    shop: Shop;
}) {
    const { product, shop } = props;
    const [activeFontFamily, setActiveFontFamily] = useState('Open Sans');
    const [form] = Form.useForm();
    document.documentElement.setAttribute('data-color-mode', 'dark');

    useEffect(() => {
        form.setFieldsValue({
            ...product,
        });
    }, [product]);

    return (
        <Form
            size="large"
            onFinish={(values) => {
                //Checking if first_payment_day_buffer is a number
                // if not then remove it from values
                if (
                    values.first_payment_day_buffer === '' ||
                    isNaN(values.first_payment_day_buffer)
                ) {
                    values.first_payment_day_buffer = null;
                } else {
                    values.first_payment_day_buffer = parseInt(
                        values.first_payment_day_buffer
                    );
                }

                if (values.price_before_discount === '')
                    values.price_before_discount = null;

                if (values.cancel_notice === '') values.cancel_notice = 0;
                if (values.commitment_period === '')
                    values.commitment_period = null;
                if (values.transaction_count_limit === '')
                    values.transaction_count_limit = null;

                Axios.patch(
                    '/verslun/api/basic_product/' + product.uuid + '/',
                    values
                ).then(() => {
                    message.success('Varan hefur verið uppfærð!');
                });
            }}
            form={form}
            layout="vertical"
        >
            <Form.Item label="Uppsagnafrestur (skipti)" name="cancel_notice">
                <Input
                    value={form.getFieldValue('cancel_notice')}
                    type="number"
                ></Input>
            </Form.Item>

            <div style={{ display: 'flex', gap: 10 }}>
                <Form.Item
                    style={{ flex: 1 }}
                    name="commitment_period"
                    label={
                        <Popover
                            content={
                                <>
                                    <p>
                                        ATH - Binditími er ekki það sama og
                                        uppsagnafrestur.
                                    </p>
                                    <p>
                                        Binditími segir til um það hversu lengi
                                        fólk er bundið eftir kaup.
                                    </p>

                                    <p>
                                        Ef binditími er t.d 3 mánuðir þá getur
                                        viðskiptavinur sem kaupir áskrift 1.jan
                                        ekki hætt áskriftinni fyrr en 1. apríl
                                        eða síðar.
                                    </p>

                                    <p>
                                        En ef viðskiptavinurinn ætlar að hætta
                                        t.d 3. ágúst þá getur hann hætt strax
                                        því binditíminn er liðinn.
                                    </p>
                                </>
                            }
                        >
                            <QuestionCircleTwoTone
                                twoToneColor="#afafaf"
                                style={{
                                    position: 'absolute',
                                    fontSize: 16,
                                }}
                            />
                            <div
                                style={{
                                    position: 'relative',
                                    left: 20,
                                }}
                            >
                                Binditími{' '}
                            </div>
                        </Popover>
                    }
                >
                    <Input
                        value={form.getFieldValue('commitment_period')}
                        type="number"
                    ></Input>
                </Form.Item>

                <Form.Item
                    name="commitment_period_type"
                    style={{ flex: 1 }}
                    label="Tegund binditíma"
                >
                    <Select>
                        <Select.Option value="DAY">Dagar</Select.Option>
                        <Select.Option value="WEEK">Vikur</Select.Option>
                        <Select.Option value="MONTH">Mánuðir</Select.Option>
                        <Select.Option value="YEAR">Ár</Select.Option>
                    </Select>
                </Form.Item>
            </div>

            <Form.Item
                label={
                    <Popover
                        content={
                            <>
                                <p>
                                    Líftími vöru segir til um það hversu margar
                                    sendingar eiga sér stað áður en það slökknar
                                    sjálfkrafa á áskriftinni. Hafðu þennan reit
                                    tómann nema um tímabundna áskrift sé að
                                    ræða.
                                </p>
                            </>
                        }
                    >
                        <QuestionCircleTwoTone
                            twoToneColor="#afafaf"
                            style={{
                                position: 'absolute',
                                fontSize: 16,
                            }}
                        />
                        <div
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Líftími vöru (skipti)
                        </div>
                    </Popover>
                }
                name="transaction_count_limit"
            >
                <Input
                    value={form.getFieldValue('transaction_count_limit')}
                    type="number"
                ></Input>
            </Form.Item>

            <Form.Item
                name="first_payment_day_buffer"
                label={
                    <Popover
                        content={
                            <>
                                <p>
                                    Þessi tala segir til um hversu margir dagar
                                    líða áður en fyrsta greiðsla er innheimt.
                                </p>

                                <p>
                                    Frá þeirri dagsetningu er síðan innheimt
                                    miðað við tíðni (t.d í hverjum mánuði).
                                </p>

                                <p>
                                    Þetta er t.d notað ef boðið er upp á fría
                                    viku.
                                </p>
                            </>
                        }
                    >
                        <QuestionCircleTwoTone
                            twoToneColor="#afafaf"
                            style={{
                                position: 'absolute',
                                fontSize: 16,
                            }}
                        />
                        <div
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Frestun fyrstu greiðslu í dögum.
                        </div>
                    </Popover>
                }
            >
                <Input
                    value={form.getFieldValue('first_payment_day_buffer')}
                    type="number"
                ></Input>
            </Form.Item>

            <Form.Item
                name="cancel_policy"
                label={
                    <Popover
                        content={
                            <>
                                <p>
                                    Hér er ekki átt við binditíma eða
                                    uppsagnarfrest.
                                </p>

                                <p>
                                    Hér er einungis verið að ákveða hvort
                                    áskriftin slökknar strax eða rétt fyrir
                                    næstu greiðslu (þegar það kemur að því að
                                    slökkva á henni).
                                </p>

                                <ul>
                                    <li>
                                        Ef þú ert að selja aðgang að efni á
                                        netinu eins og netnámskeið, fjarþjálfun
                                        eða hlaðvarp þá er líklegt að þú viljir
                                        að áskriftin sé í gildi þangað til næsta
                                        greiðsla hefði átt að fara fram (þ.e út
                                        mánuðinn sem dæmi).
                                    </li>

                                    <li>
                                        Ef þú ert að selja vörur þá er líklegt
                                        að þú viljir að áskriftin slökkni strax.
                                    </li>
                                </ul>
                            </>
                        }
                    >
                        <QuestionCircleTwoTone
                            twoToneColor="#afafaf"
                            style={{
                                position: 'absolute',
                                fontSize: 16,
                            }}
                        />
                        <div
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Hversu lengi er áskriftin í gildi eftir að henni er
                            sagt upp?
                        </div>
                    </Popover>
                }
            >
                <Select
                    options={[
                        {
                            label: 'Slökkva strax',
                            value: 'IMMEDIATELY',
                        },
                        {
                            value: 'JUST_BEFORE_NEXT_PAYMENT',
                            label: 'Slökkva rétt áður en næsta greiðsla hefði verið innheimt',
                        },
                    ]}
                ></Select>
            </Form.Item>

            <Form.Item
                name="price_before_discount"
                label={
                    <>
                        <Popover
                            content={
                                <div>
                                    <p>
                                        Notað til að sýna hefðbundið verð (ef
                                        verðið er t.d ódýrara í áskrift)
                                    </p>

                                    <p>Dæmi:</p>

                                    <h3
                                        style={{
                                            textDecoration: 'line-through',
                                        }}
                                    >
                                        6.990 kr
                                    </h3>

                                    <h3>5.990 kr</h3>
                                </div>
                            }
                        >
                            <QuestionCircleTwoTone
                                twoToneColor="#afafaf"
                                style={{
                                    position: 'absolute',
                                    fontSize: 16,
                                }}
                            />

                            <span
                                style={{
                                    position: 'relative',
                                    left: 20,
                                }}
                            >
                                Hefðbundið verð (án afsláttar)
                            </span>
                        </Popover>
                    </>
                }
            >
                <Input
                    size="large"
                    placeholder="Hefðbundið verð (án afsláttar)"
                    value={form.getFieldValue('price_before_discount')}
                    type="number"
                ></Input>
            </Form.Item>

            <Form.Item
                name="appwrite_user_profiles"
                label={
                    <>
                        <Popover
                            content={
                                <>
                                    <p>
                                        Viðskiptavinir þínir velja sér lykilorð
                                        og geta skráð sig inn á sitt svæði.
                                    </p>
                                </>
                            }
                        >
                            <QuestionCircleTwoTone
                                twoToneColor="#afafaf"
                                style={{
                                    position: 'absolute',
                                    fontSize: 16,
                                }}
                            />
                        </Popover>
                        <span
                            style={{
                                position: 'relative',
                                left: 20,
                            }}
                        >
                            Vilt þú að viðskiptavinir þínir geti skráð sig inn
                            og fengið aðgang að læstu efni?
                        </span>
                    </>
                }
                valuePropName="checked"
            >
                <Switch
                    checkedChildren="JÁ "
                    unCheckedChildren="NEI"
                    onChange={(checked) => {
                        form.setFieldsValue({
                            appwrite_user_profiles: checked,
                        });
                    }}
                />
            </Form.Item>

            {form?.getFieldValue('type') === 'SUBSCRIPTION' && (
                <>
                    <Form.Item
                        name="is_podcast"
                        label={
                            <>
                                <Popover
                                    content={
                                        <>
                                            <p>
                                                Settu hlekkinn á RSS strauminn
                                                hér. Athugaðu að þú getur notað
                                                hvaða straum sem er, en passaðu
                                                að halda þessum hlekk sem
                                                leyndarmáli.
                                            </p>

                                            <p>
                                                Repeat.is býr til sér straum
                                                fyrir hvern og einn áskrifanda
                                                (sá hlekkur er búinn til útfrá
                                                þessum straum).
                                            </p>

                                            <p>
                                                Þegar áskrifendur hætta í
                                                áskrift verður straumurinn
                                                þeirra sjálfkrafa óvirkur.
                                            </p>
                                        </>
                                    }
                                >
                                    <QuestionCircleTwoTone
                                        twoToneColor="#afafaf"
                                        style={{
                                            position: 'absolute',
                                            fontSize: 16,
                                        }}
                                    />
                                </Popover>
                                <span
                                    style={{
                                        position: 'relative',
                                        left: 20,
                                    }}
                                >
                                    Ertu að selja áskrift að podcast straumi?
                                </span>
                            </>
                        }
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    is_podcast: checked,
                                });
                            }}
                        />
                    </Form.Item>

                    {form?.getFieldValue('is_podcast') && (
                        <Form.Item
                            name="podcast_rss_feed"
                            label="Podcast RSS straumur"
                        >
                            <Input size="large"></Input>
                        </Form.Item>
                    )}
                </>
            )}

            <Collapse
                children={[
                    <Collapse.Panel
                        header='Sérsniðið CSS fyrir "iframe checkout"'
                        key="1"
                    >
                        <Form.Item
                            name="custom_font_css"
                            label="Sérsniðið CSS fyrir leturgerð"
                        >
                            <AceEditor
                                mode="css"
                                theme="monokai"
                                showPrintMargin={false}
                                setOptions={{
                                    useWorker: false,
                                }}
                                width="100%"
                            ></AceEditor>
                        </Form.Item>
                        <Form.Item name="custom_css" label="Sérsniðið CSS">
                            <AceEditor
                                mode="css"
                                theme="monokai"
                                showPrintMargin={false}
                                width="100%"
                                setOptions={{
                                    useWorker: false,
                                }}
                            ></AceEditor>
                        </Form.Item>

                        <Form.Item label="Hvaða leturgerð vilt þú nota á sölusíðunni?">
                            {/* @ts-ignore */}
                            <FontPicker
                                activeFontFamily={
                                    product?.custom_font_css
                                        ? getFontString(product.custom_font_css)
                                        : activeFontFamily
                                }
                                apiKey="AIzaSyAbWHtj-CySx7txdP76pI6wiW2H6BJ47sY"
                                onChange={(nextFont) => {
                                    const _customFontCss = `
@font-face {
font-family: ${nextFont.family};
src: url(${nextFont?.files?.regular.replace('http://', 'https://')});
}

#sales_page {
font-family: ${nextFont.family}!important;
}
`;

                                    form.setFieldsValue({
                                        custom_font_css: _customFontCss,
                                    });
                                    setActiveFontFamily(nextFont.family);
                                }}
                                limit={300}
                            />
                        </Form.Item>
                    </Collapse.Panel>,
                ]}
            ></Collapse>

            <Button className="mt20" type="primary" htmlType="submit">
                Vista
            </Button>
        </Form>
    );
}
