import { useEffect, useState } from 'react';
import { Card, Button, Table, Space, Modal, Avatar, Tag } from 'antd';
import Axios from 'axios';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import Shop from '../../interfaces/shop';
import './ContentOverview.scss';
import CreateRepeatMediaAccount from '../CreateRepeatMediaAccount/CreateRepeatMediaAccount';

type Props = {
    shop: Shop;
};

export default function ContentOverview(props: Props) {
    const [contents, setContents] = useState([]);
    const [showGetMoreButton, setShowGetMoreButton] = useState(true);
    const [createRepeatMediaAccountModal, setCreateRepeatMediaAccountModal] =
        useState(false);

    useEffect(() => {
        Axios.get(
            '/efni/api/contents/' +
                props.shop.uuid +
                '/?page_size=5&ordering=-date'
        ).then((res) => {
            setContents(res.data.results);
        });
    }, [props.shop]);

    return (
        <Card
            id="contentContainer"
            loading={!props.shop}
            className="statistics_container topAligned"
            title={
                <>
                    <img
                        style={{ height: 45, marginRight: 5 }}
                        src="/static/images/svg/media.svg"
                        alt=""
                    />{' '}
                    Færslur
                </>
            }
            extra={
                <Space>
                    <Button
                        type="primary"
                        href={`/efnisyfirlit/${props.shop.uuid}/`}
                    >
                        Stjórnborð
                    </Button>
                    <Button
                        type="dashed"
                        href={'/nytt_efni/' + props.shop.uuid + '/'}
                    >
                        +
                    </Button>
                </Space>
            }
        >
            {!!!props.shop.repeat_media_account && (
                <div>
                    <div>
                        Það er ekki til media.repeat.is aðgangur. Viltu búa hann
                        til?
                    </div>

                    <br />

                    <Button
                        onClick={() => setCreateRepeatMediaAccountModal(true)}
                    >
                        Búa til Repeat media aðgang
                    </Button>
                </div>
            )}
            <Table
                dataSource={contents}
                rowKey="uuid"
                className="white_table"
                size="small"
                style={{
                    padding: '20px',
                }}
                pagination={false}
                columns={[
                    {
                        render: (item) => (
                            <Avatar src={item.main_image}></Avatar>
                        ),
                    },
                    {
                        title: 'Titill',
                        render: (item) => {
                            return (
                                <div>
                                    <div>{item.title}</div>

                                    <div>
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {item.type === 'PUBLIC' ? (
                                                <>
                                                    <UnlockOutlined /> Opið
                                                    öllum
                                                </>
                                            ) : item.type ===
                                              'ALL_SUBSCRIPTIONS' ? (
                                                <>
                                                    <LockOutlined /> Allar
                                                    áskriftir
                                                </>
                                            ) : (
                                                <>
                                                    <LockOutlined /> Sumar
                                                    áskriftir
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        },
                        className: 'contentTitle',
                    },
                    {
                        title: 'Staða',
                        render: (item) => {
                            return (
                                <Tag
                                    style={{ borderRadius: '5px' }}
                                    color={item.is_draft ? 'red' : 'green'}
                                >
                                    {item.is_draft ? 'Drög' : 'Birt'}
                                </Tag>
                            );
                        },
                    },

                    // {
                    //     title: 'Flokkar',
                    //     render: (item) => {
                    //         return item.tags ? item.tags.join(',') : 'Óflokkað';
                    //     },
                    // },
                    {
                        title: 'Breyta',
                        render: (item) => {
                            return (
                                <Button size="small">
                                    <a
                                        href={`/nytt_efni/${props.shop.uuid}/?uuid=${item.uuid}`}
                                    >
                                        Breyta
                                    </a>
                                </Button>
                            );
                        },
                    },
                ]}
            ></Table>

            {
                <Modal
                    title="Búa til Repeat media aðgang"
                    open={createRepeatMediaAccountModal}
                    footer={false}
                >
                    <CreateRepeatMediaAccount
                        onCancel={() => setCreateRepeatMediaAccountModal(false)}
                        shop={props.shop}
                    ></CreateRepeatMediaAccount>
                </Modal>
            }
        </Card>
    );
}
