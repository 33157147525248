import React from 'react';
import { Card, Col, Row, Button, List, Space, Statistic, Table } from 'antd';
import {
    CreditCardTwoTone,
    DollarTwoTone,
    UserOutlined,
} from '@ant-design/icons';
import { kr } from '../../utils';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import brandingConfig from '../../utils/brandingConfig';
dayjs.locale('is'); // use locale

export default function Dashboard() {
    return (
        <>
            <Row
                className="logged_in_section"
                style={{
                    justifyContent: 'center',
                    textAlign: 'left',
                    margin: 20,
                }}
            >
                <Col xs={24} sm={24}>
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Card
                            style={{ padding: 0, background: 'transparent' }}
                            className="frontpage_card"
                            headStyle={{ borderBottom: 'none' }}
                            extra={
                                <div
                                    style={{
                                        color: 'lightgray',
                                    }}
                                >
                                    ATH - Þetta er sýnidæmi og öll virkni hefur
                                    verið fjarlægð
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <div className="frontpage_main_section">
                                <Card
                                    style={{ overflow: 'auto' }}
                                    className="statistics_container"
                                    title={
                                        <>
                                            <img
                                                style={{ height: 45 }}
                                                src="/static/images/svg/group.svg"
                                                alt=""
                                            />{' '}
                                            Áskrifendur
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            Stjórnborð
                                        </Button>
                                    }
                                >
                                    <Statistic
                                        title="Virkar áskriftir"
                                        value={785}
                                        prefix={<UserOutlined />}
                                    />
                                    <Statistic
                                        valueStyle={{
                                            color: brandingConfig.successColor,
                                        }}
                                        title="Í þessum mánuði"
                                        value={'+ ' + 22}
                                    />
                                    <Statistic
                                        valueStyle={{
                                            color: brandingConfig.successColor,
                                        }}
                                        title="Í síðasta mánuði"
                                        value={'+ ' + 33}
                                    />
                                </Card>
                                <Card
                                    style={{ overflow: 'auto' }}
                                    className="statistics_container"
                                    title={
                                        <>
                                            <img
                                                style={{ height: 45 }}
                                                src="/static/images/svg/box.svg"
                                                alt=""
                                            />{' '}
                                            Sendingar
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            Stjórnborð
                                        </Button>
                                    }
                                >
                                    <Statistic
                                        title="Óuppfylltar og/eða ógreiddar sendingar"
                                        value={700}
                                    />
                                </Card>
                                <Card
                                    style={{ overflow: 'auto' }}
                                    className="statistics_container"
                                    title={
                                        <>
                                            <img
                                                style={{ height: 45 }}
                                                src="/static/images/svg/surprise.svg"
                                                alt=""
                                            />{' '}
                                            Gjafabréf
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            Stjórnborð
                                        </Button>
                                    }
                                >
                                    <Statistic
                                        title="Ókláruð gjafabréf"
                                        value={57}
                                    />
                                    <Statistic
                                        title="Fullnýtt gjafabréf"
                                        value={312}
                                    />
                                </Card>

                                <Card
                                    style={{ overflow: 'auto' }}
                                    title={
                                        <>
                                            <img
                                                style={{
                                                    height: 45,
                                                    padding: 8,
                                                }}
                                                src="/static/images/svg/teya.svg"
                                                alt=""
                                            />{' '}
                                            Nýjustu samskiptin
                                        </>
                                    }
                                >
                                    <Table
                                        className="white_table"
                                        pagination={false}
                                        size="small"
                                        locale={{
                                            emptyText: (
                                                <>
                                                    Engin samskipti virðast hafa
                                                    átt sér stað
                                                </>
                                            ),
                                        }}
                                        columns={[
                                            {
                                                title: 'Nafn',
                                                render: (transaction) => {
                                                    return (
                                                        <>
                                                            {transaction.order
                                                                ? transaction
                                                                      .order
                                                                      .customer
                                                                      ?.name
                                                                : transaction
                                                                      .subscription
                                                                      ?.customer
                                                                      ?.name}
                                                        </>
                                                    );
                                                },
                                            },
                                            {
                                                title: 'Dagsetning',
                                                render: (transaction) => {
                                                    return (
                                                        <div>
                                                            {dayjs(
                                                                transaction.created
                                                            ).format(
                                                                'D. MMM YYYY - HH:mm'
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                title: 'Aðgerð',
                                                render: (transaction) => {
                                                    return (
                                                        <>
                                                            {/* Successfull card token creation */}
                                                            {transaction.endpoint.includes(
                                                                '/token/multi'
                                                            ) &&
                                                                transaction
                                                                    .response
                                                                    .VerifyCardResult
                                                                    ?.ActionCode ===
                                                                    '000' && (
                                                                    <>
                                                                        <CreditCardTwoTone
                                                                            twoToneColor={
                                                                                brandingConfig.successColor
                                                                            }
                                                                        />{' '}
                                                                        Kort
                                                                        vistað
                                                                    </>
                                                                )}

                                                            {/* Unuccessfull card token creation */}
                                                            {transaction.endpoint.includes(
                                                                '/token/multi'
                                                            ) &&
                                                                transaction
                                                                    .response
                                                                    .VerifyCardResult
                                                                    ?.ActionCode !==
                                                                    '000' && (
                                                                    <>
                                                                        <CreditCardTwoTone
                                                                            style={{
                                                                                fontSize: 20,
                                                                            }}
                                                                            twoToneColor={
                                                                                brandingConfig.dangerColor
                                                                            }
                                                                        />{' '}
                                                                        Kort
                                                                        vistað
                                                                        <div>
                                                                            {
                                                                                transaction
                                                                                    .response
                                                                                    .Message
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )}

                                                            {/* Successfull card payment */}
                                                            {transaction.endpoint.includes(
                                                                'rpg/api/payment'
                                                            ) &&
                                                                transaction
                                                                    .response
                                                                    .ActionCode ===
                                                                    '000' && (
                                                                    <>
                                                                        <DollarTwoTone
                                                                            style={{
                                                                                fontSize: 20,
                                                                            }}
                                                                            twoToneColor={
                                                                                brandingConfig.successColor
                                                                            }
                                                                        />{' '}
                                                                        Kort
                                                                        rukkað (
                                                                        {kr(
                                                                            transaction
                                                                                .response
                                                                                .Amount
                                                                        )}
                                                                        )
                                                                    </>
                                                                )}

                                                            {/* Unsuccessfull card payment */}
                                                            {transaction.endpoint.includes(
                                                                'rpg/api/payment'
                                                            ) &&
                                                                transaction
                                                                    .response
                                                                    .ActionCode !==
                                                                    '000' && (
                                                                    <>
                                                                        <DollarTwoTone
                                                                            style={{
                                                                                fontSize: 20,
                                                                            }}
                                                                            twoToneColor={
                                                                                brandingConfig.dangerColor
                                                                            }
                                                                        />{' '}
                                                                        {'Aðgerð: Kort rukkað (' +
                                                                            kr(
                                                                                transaction
                                                                                    .response
                                                                                    .Amount ||
                                                                                    0
                                                                            ) +
                                                                            ')'}
                                                                    </>
                                                                )}
                                                        </>
                                                    );
                                                },
                                            },
                                        ]}
                                        dataSource={[
                                            {
                                                order: {
                                                    customer: {
                                                        name: 'Díana Dagný',
                                                    },
                                                },
                                                key: 1,
                                                endpoint: 'rpg/api/payment',
                                                response: {
                                                    Amount: 2990,
                                                    ActionCode: '000',
                                                    VerifyCardResult: {
                                                        ActionCode: '000',
                                                    },
                                                },
                                            },
                                            {
                                                order: {
                                                    customer: {
                                                        name: 'Díana Dagný',
                                                    },
                                                },
                                                key: 2,
                                                endpoint: '/token/multi',
                                                response: {
                                                    Amount: 2990,
                                                    VerifyCardResult: {
                                                        ActionCode: '000',
                                                    },
                                                },
                                            },

                                            {
                                                order: {
                                                    customer: {
                                                        name: 'Vilhjálmur Viggóson',
                                                    },
                                                },
                                                key: 3,
                                                endpoint: 'rpg/api/payment',
                                                response: {
                                                    Amount: 2990,
                                                    ActionCode: '000',
                                                    VerifyCardResult: {
                                                        ActionCode: '000',
                                                    },
                                                },
                                            },
                                            {
                                                order: {
                                                    customer: {
                                                        name: 'Vilhjálmur Viggóson',
                                                    },
                                                },
                                                key: 4,
                                                endpoint: '/token/multi',
                                                response: {
                                                    Amount: 2990,
                                                    VerifyCardResult: {
                                                        ActionCode: '000',
                                                    },
                                                },
                                            },
                                            {
                                                order: {
                                                    customer: {
                                                        name: 'Fanney Fátæka',
                                                    },
                                                },
                                                key: 5,
                                                endpoint: 'rpg/api/payment',
                                                response: {
                                                    Amount: 2990,
                                                    ActionCode: 'x',
                                                    VerifyCardResult: {
                                                        ActionCode: 'x',
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </Card>

                                <Card
                                    style={{ overflow: 'auto' }}
                                    title={
                                        <>
                                            <img
                                                style={{ height: 45 }}
                                                src="/static/images/svg/tag.svg"
                                                alt=""
                                            />{' '}
                                            Vörur
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            + Stofna nýja vöru
                                        </Button>
                                    }
                                >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={[
                                            {
                                                price: 2990,
                                                image: '/static/images/makeup.jpg',
                                                title: 'Snyrtibox mánaðarins',
                                            },
                                            {
                                                price: 1290,
                                                image: '/static/images/lipstick.jpg',
                                                title: 'Varalitur mánaðarins',
                                            },
                                        ]}
                                        renderItem={(product) => (
                                            <List.Item
                                                extra={
                                                    <>
                                                        <div>
                                                            {kr(product.price)}
                                                        </div>
                                                    </>
                                                }
                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <img
                                                            className="product_image"
                                                            src={product.image}
                                                            alt="Vörumynd"
                                                        />
                                                    }
                                                    title={
                                                        <>
                                                            <div>
                                                                {product.title}
                                                            </div>
                                                        </>
                                                    }
                                                    description={
                                                        <div>
                                                            <span
                                                                style={{
                                                                    color: brandingConfig.primaryColor,
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Breyta
                                                            </span>{' '}
                                                            |{' '}
                                                            <span
                                                                style={{
                                                                    color: brandingConfig.primaryColor,
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Sölusíða
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>

                                <Card
                                    style={{ overflow: 'auto' }}
                                    title={
                                        <>
                                            <img
                                                style={{ height: 45 }}
                                                src="/static/images/svg/email.svg"
                                                alt=""
                                            />{' '}
                                            Sendir tölvupóstar
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">Yfirlit</Button>
                                    }
                                >
                                    <Table
                                        className="white_table"
                                        pagination={false}
                                        size="small"
                                        columns={[
                                            {
                                                title: 'Fyrirsögn',
                                                dataIndex: 'subject',
                                            },
                                            {
                                                title: 'Sent á',
                                                render: (item) => {
                                                    return item.to.length > 1
                                                        ? item.to.length +
                                                              ' aðila'
                                                        : item.to.join('');
                                                },
                                            },
                                            {
                                                title: 'Dagsetning',
                                                render: (item) => {
                                                    return (
                                                        <div>
                                                            {dayjs(
                                                                item.created
                                                            ).format(
                                                                'D. MMM YYYY - HH:mm'
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            },
                                        ]}
                                        dataSource={[
                                            {
                                                subject:
                                                    'Pöntunin þín - Snyrtiverslunin.is',
                                                to: [
                                                    'diana_dasamlega@gmail.com',
                                                ],
                                                created: new Date(),
                                                key: 1,
                                            },
                                            {
                                                subject:
                                                    'Pöntunin þín - Snyrtiverslunin.is',
                                                to: [
                                                    'villi_wonderful@hotmail.com',
                                                ],
                                                created: new Date(),
                                                key: 2,
                                            },
                                            {
                                                subject:
                                                    'Varalitur mánaðrins kemur frá Nars',
                                                to: new Array(785).fill('x'),
                                                created: new Date(),
                                                key: 3,
                                            },
                                            {
                                                subject:
                                                    'Pöntunin þín - Snyrtiverslunin.is',
                                                to: [
                                                    'samuel_svavarsson@yahoo.com',
                                                ],
                                                created: new Date(),
                                                key: 4,
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                        </Card>
                    </Space>
                </Col>
            </Row>
        </>
    );
}
