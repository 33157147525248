import React from 'react';
import SVG from 'react-inlinesvg';
import { SimpleCustomer } from '../../interfaces/customer';
import Shop from '../../interfaces/shop';

type PaymentProvider = number | false | true | { active: boolean } | null;

type Props = {
    shop: Shop | SimpleCustomer['shop'];
    maxWidth?: string | number;
    margin: string | number;
    opacity?: number;
};

const isProviderActive = (provider: PaymentProvider): boolean => {
    if (!provider) return false;
    if (typeof provider === 'boolean') return provider;
    if (typeof provider === 'number') return true;
    return provider.active;
};

export default function PaymentProviderLogo({
    shop,
    maxWidth,
    margin,
    opacity,
}: Props) {
    const renderLogo = (
        providerKey: string,
        provider: PaymentProvider,
        title: string,
        extraProcessor?: (code: string) => string
    ) => {
        if (!isProviderActive(provider)) return null;

        return (
            <SVG
                style={{ width: '100%' }}
                className="paymentProviderLogo"
                baseURL="/"
                cacheRequests={false}
                description={title}
                preProcessor={(code) => {
                    code = code.replace(
                        /<path/g,
                        `<path fill="rgba(0,0,0,${opacity ? opacity.toString() : '0.15'})" `
                    );
                    if (extraProcessor) {
                        code = extraProcessor(code);
                    }
                    return code;
                }}
                src={`/static/images/svg/${providerKey}.svg`}
                title={`${title} logo`}
                key={`${providerKey}_logo`}
                uniqueHash={`${providerKey}_logo`}
                uniquifyIDs={true}
            />
        );
    };

    return (
        <div style={{ maxWidth: maxWidth, margin }}>
            {renderLogo('teya', shop?.salt_pay, 'Teya')}
            {renderLogo('verifone', shop?.verifone, 'Verifone', (code) =>
                code.replace(/<circle/g, '<circle fill="rgba(0,0,0,0.15)" ')
            )}
            {renderLogo('onpay', shop?.onpay, 'OnPay')}
            {renderLogo('rapyd', shop?.rapyd, 'Rapyd')}
            {renderLogo('valitor', shop?.valitor, 'Valitor')}
            {renderLogo('straumur', shop?.straumur, 'Straumur')}
        </div>
    );
}
