import { useEffect, useRef, useState } from 'react';
import {
    Form,
    Button,
    Space,
    Input,
    Card,
    Modal,
    Select,
    message,
    UploadFile,
    Switch,
    Calendar,
    Popover,
} from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { getUrlParameter } from '../../utils';
import DebounceSelect from '../../components/DebounceSelect/DebounceSelect';
import axios from 'axios';
import UploadContentAsset from '../../components/UploadContentAsset/UploadContentAsset';
import QuillWrapper from '../../components/QuillWrapper/QuillWrapper';
import AddTagModal from './AddTagModal';
import './CreateContent.less';
import { ContentAsset, Content, ContentTag } from '../../interfaces/content';
import ReactPlayer from 'react-player';
import AudioPlayerOrig from 'react-audio-player';

import Shop from '../../interfaces/shop';
import UploadImage from '../../components/UploadImage/UploadImage';
import moment from 'moment';
import VideoJS from '../../components/VideoJS/VideoJS';
import TiptapWrapper from '../../components/TipTapWrapper/TipTapWrapper';
import { Editor } from '@tiptap/react';

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

// const isLocalhost = w.top?.location.hostname !== 'repeat.is';
// Needs to be false in production to avoid CORS issues

// Usage of DebounceSelect
interface UserValue {
    label: string;
    value: string;
}

// async function fetchUserList(username: string): Promise<UserValue[]> {
//     return fetch('https://randomuser.me/api/?results=5')
//         .then((response) => response.json())
//         .then((body) =>
//             body.results.map((user: {name: {first: string; last: string}; login: {username: string}}) => ({
//                 label: `${user.name.first} ${user.name.last}`,
//                 value: user.login.username,
//             }))
//         );
// }

export default function CreateContent() {
    const [uuid, setUuid] = useState<string | undefined>();
    const [addContentModal, setAddContentModal] = useState(false);
    const [forceRerender, setForceRerender] = useState(true);
    const [loading, setloading] = useState(true);
    const [forceRender, setForceRender] = useState(false);
    const [shop, setShop] = useState<Shop>();
    const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>();
    const [addTagModalVisible, setAddTagModalVisible] = useState(false);
    const [availableAtDate, setAvailableAtDate] = useState(false);
    const [lockedForDays, setLockedForDays] = useState(false);
    const [form] = Form.useForm();
    const [initalValue, setInitalValue] = useState<string>();
    const editorRef = useRef<Editor | null>(null);

    useEffect(() => {
        const uuid = getUrlParameter('uuid');

        if (uuid && typeof uuid === 'string') {
            setUuid(uuid);

            axios.get('/efni/api/content/' + uuid + '/').then((res) => {
                const content = res.data as Content;

                setInitalValue(content.body || '');

                if (content.locked_for_days) {
                    setLockedForDays(true);
                }

                form.setFieldsValue({
                    ...content,
                    is_draft: !content.is_draft,

                    permitted_subscriptions: (
                        content.permitted_subscriptions || []
                    ).map((p) => {
                        return {
                            ...p,
                            label: p.title,
                            value: p.id,
                        };
                    }),
                    tags: content.tags.map((tag) => {
                        return {
                            ...tag,
                            label: tag.title,
                            value: tag.id,
                        };
                    }),
                    body: content.body || '',

                    main_image: content.main_image?.id,
                });

                if (content.becomes_available_date) {
                    form.setFieldsValue({
                        becomes_available_date: moment(
                            content.becomes_available_date
                        ),
                    });
                    setAvailableAtDate(true);
                }

                if (content.main_image) {
                    setImageFileList([
                        {
                            uid: content.main_image.id.toString(),
                            url: content.main_image.image,
                            name: 'main_image',
                            status: 'done',
                        },
                    ]);
                }

                setloading(false);
            });
        } else {
            setloading(false);
        }

        axios.get('/verslun/api/shop/' + w.uuid).then((rsp) => {
            setShop(rsp.data);
        });
    }, []);

    return (
        <>
            <Card
                id="createContent"
                title={uuid ? 'Breyta efni' : 'Búa til efni'}
                className="basePageCard"
                extra={
                    <Button
                        hidden={!uuid}
                        danger
                        onClick={() => {
                            Modal.confirm({
                                title: 'Eyða færslu',
                                content:
                                    'Ertu viss um að þú viljir eyða færslunni?',
                                okText: 'Já, eyða',
                                cancelText: 'Nei, hætta við',
                                onOk: () => {
                                    axios
                                        .patch(`/efni/api/content/${uuid}/`, {
                                            is_deleted: true,
                                        })
                                        .then((rsp) => {
                                            message.success(
                                                'Tókst, efnið hefur verið eytt!'
                                            );
                                            setTimeout(() => {
                                                if (w.top?.location.href) {
                                                    w.top.location.href =
                                                        '/stjornbord/' + w.uuid;
                                                }
                                            }, 500);
                                        });
                                },
                            });
                        }}
                    >
                        Eyða færslu
                    </Button>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={(values) => {
                        setloading(true);

                        //Checking if is_podcast is true and that at least one asset has type: AUDIO

                        values.is_draft = !values.is_draft;

                        if (values.is_podcast) {
                            if (
                                !values.assets.some(
                                    (asset: ContentAsset) =>
                                        asset.type === 'AUDIO'
                                )
                            ) {
                                message.error(
                                    'Hlaðvarpsþættir verða að hafa eina hljóðskrá'
                                );
                                setloading(false);
                                return;
                            }
                        }

                        values.assets = (values.assets || []).map(
                            (asset: { id: number }) => asset.id
                        );

                        values.tags = (values.tags || []).map(
                            (tag: { value: number }) => tag.value
                        );

                        values.permitted_subscriptions = (
                            values.permitted_subscriptions || []
                        ).map(
                            (subscription: { value: number }) =>
                                subscription.value
                        );

                        if (uuid) {
                            axios
                                .patch(`/efni/api/content/${uuid}/`, values)
                                .then((rsp) => {
                                    message.success(
                                        'Tókst, efnið hefur verið vistað!'
                                    );
                                    setTimeout(() => {
                                        if (w.top?.location.href) {
                                            w.top.location.href =
                                                '/stjornbord/' + w.uuid;
                                        }
                                    }, 500);
                                });
                        } else {
                            values.shop = shop?.id;

                            axios
                                .post(`/efni/api/content/`, values)
                                .then((rsp) => {
                                    message.success(
                                        'Tókst, efnið hefur verið vistað!'
                                    );
                                    setTimeout(() => {
                                        if (w.top?.location.href) {
                                            w.top.location.href =
                                                '/stjornbord/' + w.uuid;
                                        }
                                    }, 500);
                                });
                        }
                    }}
                    onValuesChange={() => {
                        setForceRerender(!forceRerender);
                    }}
                >
                    <Space direction="vertical" style={{ display: 'flex' }}>
                        <Card loading={loading}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Form.Item
                                    style={{
                                        display: 'block',
                                        width: 75,
                                        textAlign: 'right',
                                        marginBottom: 0,
                                    }}
                                    name="is_draft"
                                    label={
                                        <div>
                                            <Popover
                                                content={
                                                    <>
                                                        <div>
                                                            Færslan birtist ekki
                                                            á vefsíðunni þinni
                                                            nema þú veljir "Já"
                                                        </div>
                                                    </>
                                                }
                                            >
                                                <QuestionCircleTwoTone
                                                    twoToneColor="#afafaf"
                                                    style={{
                                                        position: 'absolute',
                                                        fontSize: 16,
                                                    }}
                                                />
                                            </Popover>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    left: 20,
                                                    top: -5,
                                                }}
                                            >
                                                Sýnilegt
                                            </div>
                                        </div>
                                    }
                                    valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren="JÁ "
                                        unCheckedChildren="NEI"
                                        onChange={(checked) => {
                                            form.setFieldsValue({
                                                is_draft: checked,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item
                                name="title"
                                label="Titill"
                                required={true}
                            >
                                <Input size="large"></Input>
                            </Form.Item>
                            <Form.Item
                                name="tags"
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <div>Flokkar (má sleppa)</div>
                                    </div>
                                }
                                extra={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: 10,
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setAddTagModalVisible(true);
                                            }}
                                            type="link"
                                            size="small"
                                            style={{ color: '#6c6c6c' }}
                                        >
                                            + Bæta við / breyta flokkum
                                        </Button>
                                    </div>
                                }
                            >
                                <DebounceSelect
                                    //Allowing only unique i.e no duplicate tags
                                    mode="tags"
                                    fetchOptions={() => {
                                        return axios
                                            .get(
                                                `/efni/api/tags/${shop?.uuid}/`
                                            )
                                            .then((rsp) => {
                                                return rsp.data.map(
                                                    (item: ContentTag) => {
                                                        return {
                                                            ...item,
                                                            label: (
                                                                <>
                                                                    <span>
                                                                        {
                                                                            item.title
                                                                        }{' '}
                                                                        <small>
                                                                            (
                                                                            {
                                                                                item.slug
                                                                            }
                                                                            )
                                                                        </small>
                                                                    </span>
                                                                </>
                                                            ),

                                                            value: item.id,
                                                        };
                                                    }
                                                );
                                            });
                                    }}
                                    size="large"
                                    showAction={['focus', 'click']}
                                    onChange={(newValue) => {
                                        form.setFieldsValue({
                                            tags: newValue,
                                        });
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item name="main_image" label="Forsíðumynd">
                                <UploadImage
                                    defaultFileList={imageFileList}
                                    onRemove={() =>
                                        form.setFieldsValue({
                                            main_image: null,
                                        })
                                    }
                                    onSuccess={(ImageId: string) => {
                                        form.setFieldsValue({
                                            main_image: parseInt(ImageId),
                                        });
                                    }}
                                ></UploadImage>
                            </Form.Item>

                            <Form.Item name="body" label="Efni">
                                {!loading && (
                                    <TiptapWrapper
                                        onEditorReady={(editor) =>
                                            (editorRef.current = editor)
                                        }
                                        onChange={(value) =>
                                            form.setFieldsValue({
                                                content: value,
                                            })
                                        }
                                        initialValue={initalValue || ''}
                                        shopUUID={w.uuid || ''}
                                    ></TiptapWrapper>
                                )}
                            </Form.Item>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Form.Item
                                    required={true}
                                    label="Hverjir hafa aðgang?"
                                    name="type"
                                    initialValue={'ALL_SUBSCRIPTIONS'}
                                >
                                    <Select size="large" style={{ width: 220 }}>
                                        <Select.Option value="ALL_SUBSCRIPTIONS">
                                            Allar áskriftir
                                        </Select.Option>
                                        <Select.Option value="SOME_SUBSCRIPTIONS">
                                            Ákveðnar áskriftir / vörur
                                        </Select.Option>
                                        <Select.Option value="PUBLIC">
                                            Allir (opið almenningi)
                                        </Select.Option>
                                        <Select.Option value="AUTH_REPEAT_LOGGED_IN">
                                            Allir sem eiga aðgang (óháð stöðu
                                            áskriftar)
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="is_podcast"
                                    valuePropName="checked"
                                    label="Hlaðvarpsþáttur?"
                                >
                                    <Switch
                                        checkedChildren="JÁ"
                                        unCheckedChildren="NEI"
                                    ></Switch>
                                </Form.Item>
                            </div>

                            {form.getFieldValue('type') ===
                                'SOME_SUBSCRIPTIONS' && (
                                <Form.Item
                                    label="Hvaða áskriftir / vörur?"
                                    name="permitted_subscriptions"
                                >
                                    <DebounceSelect
                                        mode="multiple"
                                        fetchOptions={() => {
                                            return axios
                                                .get(
                                                    `/verslun/api/product/?shop__uuid=${w.uuid}&type__in=SUBSCRIPTION,ONE_TIME&page_size=9999`
                                                )
                                                .then((rsp) => {
                                                    return rsp.data?.results.map(
                                                        (item: {
                                                            title: string;
                                                            id: string;
                                                        }) => {
                                                            return {
                                                                label: item.title,
                                                                value: item.id,
                                                            };
                                                        }
                                                    );
                                                });
                                        }}
                                        size="large"
                                        showAction={['focus', 'click']}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )}

                            <div>
                                <Form.Item label="Opnast á dagsetningu?">
                                    <Switch
                                        checked={availableAtDate}
                                        onChange={(checked) => {
                                            if (!checked) {
                                                form.setFieldValue(
                                                    'becomes_available_date',
                                                    null
                                                );
                                            }
                                            setAvailableAtDate(checked);
                                        }}
                                        checkedChildren="JÁ"
                                        unCheckedChildren="NEI"
                                    ></Switch>
                                </Form.Item>

                                <Form.Item
                                    hidden={!availableAtDate}
                                    name="becomes_available_date"
                                    label="Hvenær?"
                                >
                                    <Calendar
                                        style={{ maxWidth: 300 }}
                                        fullscreen={false}
                                    ></Calendar>
                                </Form.Item>
                            </div>

                            <div>
                                <Form.Item label="Læst ákveðinn tíma eftir að áskrift er stofnuð?">
                                    <Switch
                                        checked={lockedForDays}
                                        onChange={(checked) => {
                                            if (!checked) {
                                                form.setFieldValue(
                                                    'locked_for_days',
                                                    null
                                                );
                                            }
                                            setLockedForDays(checked);
                                        }}
                                        checkedChildren="JÁ"
                                        unCheckedChildren="NEI"
                                    ></Switch>
                                </Form.Item>

                                {lockedForDays && (
                                    <Form.Item
                                        name="locked_for_days"
                                        label="Í hversu marga daga á efnið að vera læst eftir að áskrift er stofnuð?"
                                    >
                                        <Input type="number"></Input>
                                    </Form.Item>
                                )}
                            </div>
                        </Card>

                        <Card
                            title="Fylgiskjöl"
                            loading={loading}
                            extra={
                                <Button
                                    onClick={() => setAddContentModal(true)}
                                    type="primary"
                                >
                                    + Bæta við
                                </Button>
                            }
                        >
                            <Form.Item name="assets">
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {form.getFieldValue('assets') &&
                                        form.getFieldValue('assets').length >
                                            0 &&
                                        form
                                            .getFieldValue('assets')
                                            .map(
                                                (
                                                    item: ContentAsset,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            style={{
                                                                border: '1px solid #d9d9d9',
                                                                borderRadius: 4,
                                                                padding: 10,
                                                                minWidth: 280,
                                                                maxWidth: 350,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                }}
                                                            >
                                                                {/* <span>
                                                            <Button size='small'>Eyða</Button>
                                                        </span> */}
                                                                {item.type ===
                                                                    'IMAGE' && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        Mynd
                                                                    </span>
                                                                )}
                                                                {item.type ===
                                                                    'VIMEO' && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        Vimeo
                                                                        myndband
                                                                    </span>
                                                                )}
                                                                {item.type ===
                                                                    'CUSTOM_YOUTUBE' && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        Youtube
                                                                        myndband
                                                                    </span>
                                                                )}
                                                                {item.type ===
                                                                    'CUSTOM_VIMEO' && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        Vimeo
                                                                        myndband
                                                                    </span>
                                                                )}
                                                                {item.type ===
                                                                    'AUDIO' && (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        Hljóðskrá
                                                                    </span>
                                                                )}

                                                                <div
                                                                    onClick={() => {
                                                                        axios.patch(
                                                                            '/efni/api/asset/' +
                                                                                item.id +
                                                                                '/',
                                                                            {
                                                                                featured:
                                                                                    !!!item.featured,
                                                                            }
                                                                        );

                                                                        form.setFieldsValue(
                                                                            {
                                                                                assets: form
                                                                                    .getFieldValue(
                                                                                        'assets'
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            asset: ContentAsset
                                                                                        ) => {
                                                                                            if (
                                                                                                asset.id ===
                                                                                                item.id
                                                                                            ) {
                                                                                                asset.featured =
                                                                                                    !!!asset.featured;
                                                                                            }

                                                                                            return asset;
                                                                                        }
                                                                                    ),
                                                                            }
                                                                        );

                                                                        setForceRender(
                                                                            !forceRender
                                                                        );
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                'inline-block',
                                                                            border: '1px solid gray',
                                                                            height: 15,
                                                                            width: 15,
                                                                            borderRadius: 5,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {item.featured && (
                                                                            <div
                                                                                style={{
                                                                                    background:
                                                                                        '#f87276',
                                                                                    height: 9,
                                                                                    width: 9,
                                                                                    margin: 2,
                                                                                    borderRadius: 3,
                                                                                }}
                                                                            ></div>
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 11,
                                                                            textAlign:
                                                                                'right',
                                                                            display:
                                                                                'inline-block',
                                                                            height: 15,
                                                                            top: -4,
                                                                            position:
                                                                                'relative',
                                                                            marginLeft: 3,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        Birta
                                                                        stórt
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {item.title}
                                                            </div>
                                                            <hr />

                                                            {item.type ===
                                                                'VIMEO' && (
                                                                <div
                                                                    className="player-wrapper"
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        paddingTop:
                                                                            '56.25%' /* Player ratio: 100 / (1280 / 720) */,
                                                                    }}
                                                                >
                                                                    {/* @ts-ignore */}
                                                                    <ReactPlayer
                                                                        vimeo={{
                                                                            title: item.title,
                                                                        }}
                                                                        className="vimeoPlayer"
                                                                        controls={
                                                                            true
                                                                        }
                                                                        url={`https://player.vimeo.com/video/${
                                                                            (
                                                                                item.vimeo_uri ||
                                                                                ''
                                                                            ).split(
                                                                                '/'
                                                                            )[2]
                                                                        }`}
                                                                    />
                                                                </div>
                                                            )}

                                                            {(item.type ===
                                                                'CUSTOM_YOUTUBE' ||
                                                                item.type ===
                                                                    'CUSTOM_VIMEO') && (
                                                                <div
                                                                    className="player-wrapper"
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        paddingTop:
                                                                            '56.25%' /* Player ratio: 100 / (1280 / 720) */,
                                                                    }}
                                                                >
                                                                    {/* @ts-ignore */}
                                                                    <ReactPlayer
                                                                        className="vimeoPlayer"
                                                                        controls={
                                                                            true
                                                                        }
                                                                        url={
                                                                            item.youtube_uri ||
                                                                            item.vimeo_uri
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            {item.type ===
                                                                'IMAGE' && (
                                                                <img
                                                                    alt="Mynd"
                                                                    src={
                                                                        item.file_uri
                                                                    }
                                                                    style={{
                                                                        maxWidth:
                                                                            '100%',
                                                                    }}
                                                                />
                                                            )}
                                                            {item.type ===
                                                                'AUDIO' && (
                                                                <AudioPlayerOrig
                                                                    src={
                                                                        item.file_uri ||
                                                                        ''
                                                                    }
                                                                    controls
                                                                />
                                                            )}

                                                            {item.type ===
                                                                'REPEAT_MEDIA' && (
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {item
                                                                        .repeat_media_asset
                                                                        ?.status ===
                                                                    'TRANSCODING' ? (
                                                                        <div>
                                                                            Myndbandið
                                                                            er í
                                                                            vinnslu.
                                                                            Athugið
                                                                            að
                                                                            það
                                                                            getur
                                                                            tekið
                                                                            allt
                                                                            að
                                                                            klukkutíma
                                                                            að
                                                                            vinna
                                                                            myndbandið
                                                                            ef
                                                                            það
                                                                            er
                                                                            langt.
                                                                        </div>
                                                                    ) : (
                                                                        <VideoJS
                                                                            contentAsset={
                                                                                item
                                                                            }
                                                                            options={{
                                                                                fluid: true,
                                                                                html5: {
                                                                                    hls: {
                                                                                        overrideNative:
                                                                                            true,
                                                                                        withCredentials:
                                                                                            false,
                                                                                    },
                                                                                },
                                                                                controls:
                                                                                    true,
                                                                                bigPlayButton:
                                                                                    false,
                                                                                controlBar:
                                                                                    {
                                                                                        currentTimeDisplay:
                                                                                            true,
                                                                                        remainingTimeDisplay:
                                                                                            true,
                                                                                    },

                                                                                sources:
                                                                                    [
                                                                                        {
                                                                                            src:
                                                                                                'https://media.repeat.is/hls/private/' +
                                                                                                item
                                                                                                    .repeat_media_asset
                                                                                                    ?.uuid +
                                                                                                '/' +
                                                                                                item
                                                                                                    .repeat_media_asset
                                                                                                    ?.playlist_id +
                                                                                                '-master.m3u8',
                                                                                            type: 'application/x-mpegURL',
                                                                                        },
                                                                                    ],
                                                                                resizeTo:
                                                                                    'container',
                                                                            }}
                                                                        ></VideoJS>
                                                                    )}
                                                                </div>
                                                            )}

                                                            <Button
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                                block
                                                                onClick={() => {
                                                                    form.setFieldsValue(
                                                                        {
                                                                            assets: [
                                                                                ...(form
                                                                                    .getFieldValue(
                                                                                        'assets'
                                                                                    )
                                                                                    .filter(
                                                                                        (
                                                                                            a: ContentAsset
                                                                                        ) =>
                                                                                            a.id !==
                                                                                            item.id
                                                                                    ) ||
                                                                                    []),
                                                                            ],
                                                                        }
                                                                    );
                                                                    setForceRerender(
                                                                        !forceRerender
                                                                    );
                                                                    message.success(
                                                                        'Tókst, mundu að vista breytingarnar þínar'
                                                                    );
                                                                }}
                                                            >
                                                                Eyða
                                                            </Button>
                                                        </div>
                                                    );
                                                }
                                            )}
                                </div>
                            </Form.Item>
                        </Card>

                        <Button
                            block
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            Vista
                        </Button>
                    </Space>
                </Form>
            </Card>

            <Modal
                title="Bæta við fylgiskjali"
                open={addContentModal}
                footer={null}
                onCancel={() => {
                    setAddContentModal(false);
                }}
            >
                <UploadContentAsset
                    shopUUID={w.uuid || ''}
                    shopID={shop?.id || 0}
                    onError={(err: string) => {
                        console.log(err);
                    }}
                    success={(rsp: { data: ContentAsset }) => {
                        setAddContentModal(false);

                        form.setFieldsValue({
                            assets: [
                                ...(form.getFieldValue('assets') || []),
                                rsp.data,
                            ],
                        });
                        setForceRender(!forceRender);
                    }}
                ></UploadContentAsset>
            </Modal>

            {shop && addTagModalVisible && (
                <AddTagModal
                    shop={shop}
                    open={addTagModalVisible}
                    onClose={() => {
                        setAddTagModalVisible(false);
                    }}
                ></AddTagModal>
            )}
        </>
    );
}
