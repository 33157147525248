import axios from 'axios';
import React, { useState } from 'react';

import { Button, Form, Input, message, Modal } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';

import translate, { LanguageKey } from '../../translate/translate';
import Customer from '../../interfaces/customer';

type Props = {
    open: boolean;
    language: LanguageKey;
    customer: Customer;
    onClose: () => void;
};

export default function CreateCustomUserAccountModal({
    open,
    language,
    customer,
    onClose,
}: Props) {
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            title={translate('select_password', language)}
            footer={null}
            maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            open={open}
        >
            <p>{translate('select_password_message', language)}</p>

            {customer && (
                <Form
                    layout="vertical"
                    initialValues={{ email: customer.email || '' }}
                >
                    <Form.Item
                        name="email"
                        label={translate('email', language)}
                    >
                        <Input size="large" disabled />
                    </Form.Item>

                    <Form.Item
                        label={translate('password', language)}
                        name="password"
                    >
                        <Input
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            size="large"
                            type="password"
                            autoComplete="new-password"
                        />
                    </Form.Item>

                    {/* @ts-ignore */}
                    <PasswordStrengthBar
                        minLength={8}
                        scoreWords={[
                            translate('bad', language),
                            translate('barely_passes', language),
                            translate('just_ok', language),
                            translate('good', language),
                            translate('strong', language),
                        ]}
                        shortScoreWord={translate('too_short', language)}
                        password={password}
                    />

                    <Button
                        loading={loading}
                        disabled={!!!((password || '').length > 7)}
                        onClick={() => {
                            setLoading(true);
                            axios
                                .post(
                                    `/verslun/api/create_password_for_new_user/${customer.uuid}/`,
                                    {
                                        password,
                                    }
                                )
                                .then((rsp) => {
                                    setLoading(false);
                                    onClose();
                                    message.success(
                                        translate(
                                            'account_created_message',
                                            language
                                        )
                                    );
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    message.error(
                                        translate('error_came_up', language)
                                    );
                                });
                        }}
                        type="primary"
                        block
                        size="large"
                    >
                        {translate('create_account', language)}
                    </Button>
                </Form>
            )}
        </Modal>
    );
}
