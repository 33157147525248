import { useEffect, useState } from 'react';
import { Content, ContentAsset } from '../../../interfaces/content';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import WorldMap from './WorldMap';
import { Card, Table } from 'antd';

type Props = {
    asset: ContentAsset;
    shopUUID: string;
};

type OverallStats = {
    averageWatchTime: string;
    totalViewers: string;
    viewersPeak: string;
    viewersPeakDate: string;
    totalWatchTime: string;
    countries: {
        isoCode: string;
        viewers: number;
    }[];
};

export default function AssetStats({ asset, shopUUID }: Props) {
    const [overallStats, setOverallStats] = useState<OverallStats>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!overallStats) {
            axios
                .post('/efni/get_repeat_media_video_stats/', {
                    video_id: asset?.repeat_media_asset?.asset_id || '',
                    shop_uuid: shopUUID,
                })
                .then((res) => {
                    setOverallStats(res.data.overall_stats);
                    setLoading(false);
                });
        }

        return () => {
            setOverallStats(undefined);
        };
    }, [asset]);

    return (
        <div>
            {loading && (
                <div className="text-center">
                    {' '}
                    <LoadingOutlined />
                </div>
            )}

            {overallStats && (
                <div>
                    <div className="overallStatBoxContainer">
                        <div className="overallStatBox">
                            <h4>Meðal áhorfstími</h4>
                            <h2>{overallStats.averageWatchTime}</h2>
                        </div>

                        <div className="overallStatBox">
                            <h4>Áhorfendur</h4>
                            <h2>{overallStats.totalViewers}</h2>
                        </div>

                        <div className="overallStatBox">
                            <h4>Mest í einu</h4>
                            <div>
                                <h2>{overallStats.viewersPeak}</h2>
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '12px',
                                        width: '100%',
                                        textAlign: 'center',
                                        left: 0,
                                        fontSize: '11px',
                                        color: 'gray',
                                    }}
                                >
                                    {overallStats.viewersPeakDate
                                        ? dayjs(
                                              overallStats.viewersPeakDate
                                          ).format('D.MMM YYYY HH:mm')
                                        : ''}
                                </div>
                            </div>
                        </div>

                        <div className="overallStatBox">
                            <h4>Heildar áhorfstími</h4>
                            <h2>{overallStats.totalWatchTime}</h2>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            padding: '20px',
                        }}
                    >
                        <div
                            style={{
                                flex: 3,
                            }}
                        >
                            <WorldMap
                                data={overallStats.countries.map((country) => {
                                    return {
                                        country: country.isoCode,
                                        value: country.viewers,
                                    };
                                })}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Card>
                                <div></div>
                                <Table
                                    size="large"
                                    pagination={false}
                                    columns={[
                                        {
                                            title: 'Land',
                                            dataIndex: 'country',
                                            key: 'country',
                                        },
                                        {
                                            title: 'Áhorf',
                                            dataIndex: 'viewers',
                                            key: 'viewers',
                                        },
                                    ]}
                                    dataSource={overallStats.countries.map(
                                        (country) => {
                                            return {
                                                key: country.isoCode,
                                                country:
                                                    country.isoCode == 'Iceland'
                                                        ? 'Ísland'
                                                        : country.isoCode,
                                                viewers: country.viewers,
                                            };
                                        }
                                    )}
                                ></Table>
                            </Card>
                        </div>
                    </div>

                    <div className="text-center" style={{ color: 'gray' }}>
                        Þessar tölur uppfærast einu sinni á klukkutíma
                    </div>
                </div>
            )}
        </div>
    );
}
