import { ReactElement, useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Form, Radio, DatePicker, Button, Space, Table, Card } from 'antd';
import { kr, orderCreationLabel } from '../../utils';
import { getFee, getPeriodPriceForShop } from '../../utils/feeStructure';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import Shop, { SuperadminShop } from '../../interfaces/shop';
import Order from '../../interfaces/order';
import User from '../../interfaces/user';
import LogOverview from '../../components/LogOverview/LogOverview';
import brandingConfig from '../../utils/brandingConfig';
import SalesOverviewChart from './SalesOverveiwChart/SalesOverviewChart';
dayjs.locale('is'); // use locale

interface Payment {
    created: string;
    sum: number;
}

type StaffOrder = Omit<Order, 'shop'> & {
    shop: { name: string; uuid: string };
};

interface Transaction {
    created: string;
    sum: number;
    count: number;
    title: string;
}

interface StatResponse {
    payments: Payment[];
    transactions: Transaction[];
    one_time_purchases: Transaction[];
    subscriptions_created: {
        count: number;
        created: string;
        title: string;
    }[];
}

interface StaffShop extends SuperadminShop {
    shop: Shop;
    paymentTotal?: number;
    price?: number;
    stats: StatResponse;
    uuid: string;
    contract_amount: number;
    contract:
        | 'FIXED_PRICE'
        | 'BASIC'
        | 'FIXED_PERCENTAGE'
        | 'SMALL'
        | 'MEDIUM'
        | 'BIG';
}

type rangeKeys =
    | 'currentMonth'
    | 'lastMonth'
    | 'twoMonthAgo'
    | 'threeMonthsAgo'
    | 'custom';

interface Results {
    results: StaffShop[];
}

const now = moment();

const dates: {
    [key: string]: {
        from: moment.Moment;
        to: moment.Moment;
        name: string;
    };
} = {
    currentMonth: {
        from: moment(now).startOf('month'),
        to: moment(now).endOf('month'),
        name: moment(now).format('MMM'),
    },
    lastMonth: {
        from: moment(now).subtract(1, 'month').startOf('month'),
        to: moment(now).subtract(1, 'month').endOf('month'),
        name: moment(now).subtract(1, 'month').format('MMM'),
    },
    twoMonthsAgo: {
        from: moment(now).subtract(2, 'month').startOf('month'),
        to: moment(now).subtract(2, 'month').endOf('month'),
        name: moment(now).subtract(2, 'month').format('MMM'),
    },
    threeMonthsAgo: {
        from: moment(now).subtract(3, 'month').startOf('month'),
        to: moment(now).subtract(3, 'month').endOf('month'),
        name: moment(now).subtract(3, 'month').format('MMM'),
    },
};

export default function Staff(): ReactElement {
    const [shopStats, setShopStats] = useState<StaffShop[]>([]);
    const [lastMonthShopStats, setLastMonthShopStats] = useState<StaffShop[]>(
        []
    );
    const [render, forceRender] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [form] = Form.useForm();
    const [orderBy, setOrderBy] = useState('income');
    const [users, setUsers] = useState<User[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [totalShopsWithSales, setTotalShopsWithSales] = useState(0);

    useEffect(() => {
        getStatsFromPeriod(
            moment().startOf('month').toISOString(),
            moment().endOf('month').toISOString()
        );

        getLastMonth();

        axios
            .get('/account/api/users/?page_size=10&ordering=-created')
            .then((rsp) => {
                setUsers(rsp.data.results);
            });

        axios
            .get(
                '/verslun/api/orders/?page_size=25&ordering=-created&page_size=5'
            )
            .then((rsp) => {
                setOrders(rsp.data.results);
            });
    }, []);

    const getLastMonth = () => {
        const from = moment()
            .subtract(1, 'month')
            .startOf('month')
            .toISOString();
        const to = moment().subtract(1, 'month').endOf('day').toISOString();

        axios
            .get('/verslun/api/shops/?get_all_shops=true&page_size=500')
            .then((rsp: AxiosResponse<Results>) => {
                const promises = rsp.data?.results?.map((shop: StaffShop) => {
                    return axios.post('/verslun/api/get_stat_period/', {
                        shop_uuid: shop.uuid,
                        from: from,
                        to: to,
                    });
                });

                Promise.all(promises).then((res) => {
                    let shopCounter = 0;
                    const stats = res.map((shopStat: any, i: number) => {
                        const shop = rsp.data.results[i] as StaffShop;
                        const data = shopStat.data;
                        const paymentTotal = data.total;

                        shop.price = getPeriodPriceForShop(shop, paymentTotal);
                        shop.paymentTotal = paymentTotal;

                        if (shop.price) shopCounter = shopCounter + 1;

                        return shop;
                    });

                    setLastMonthShopStats(stats);
                });
            });
    };

    const getStatsFromPeriod = (
        from: string,
        to: string,
        isPageLoad: boolean = false
    ) => {
        if (!isPageLoad) {
            setLastMonthShopStats([]);
        }

        setLoading(true);

        axios
            .get('/verslun/api/shops/?get_all_shops=true&page_size=500')
            .then((rsp: AxiosResponse<Results>) => {
                const promises = rsp.data?.results?.map((shop: StaffShop) => {
                    return axios.post('/verslun/api/get_stat_period/', {
                        shop_uuid: shop.uuid,
                        from: from,
                        to: to,
                    });
                });

                Promise.all(promises).then((res) => {
                    let shopCounter = 0;
                    const stats = res.map((shopStat: any, i: number) => {
                        const shop = rsp.data.results[i] as StaffShop;
                        const data = shopStat.data;
                        const paymentTotal = data.total;

                        shop.price = getPeriodPriceForShop(shop, paymentTotal);
                        shop.paymentTotal = paymentTotal;

                        if (shop.price) shopCounter = shopCounter + 1;

                        return shop;
                    });

                    setTotalShopsWithSales(shopCounter);

                    setShopStats(stats);
                    setLoading(false);
                });
            });
    };

    // const getStatsFromPeriod => (from: string, to: string) {
    //     axios.post('/verslun/api/get_stat_period/', {shop_uuid: shopUUID, from, to}).then((rsp) => {
    //         const categories = rsp.data.subscriptions_created.reduce((a: string[], b: {title: string}) => {
    //             if (a.includes(b.title)) return a;
    //             return [...a, b.title];
    //         }, []);

    //         const dates: string[] = rsp.data.subscriptions_created.reduce((a: string[], b: {created: string}) => {
    //             if (a.some((date: string) => moment(date).isSame(moment(b.created), 'day'))) {
    //                 return a;
    //             }
    //             return [...a, b.created];
    //         }, []);

    //         setSignups({
    //             dates,
    //             categories,
    //             data: rsp.data.subscriptions_created,
    //         });
    //         setPayments(rsp.data.payments);
    //         setLoading(false);
    //     });

    //     setLoading(false);
    // }

    const totalIncome = shopStats.reduce((acc, stat: StaffShop) => {
        return (stat.price || 0) + acc;
    }, 0);

    const totalIncomeWithoutVat = totalIncome / 1.24;

    return (
        <>
            <Card
                className="basePageCard"
                title={
                    <>
                        <h3>Alls innkoma: {kr(totalIncome)}</h3>
                        <div>
                            <small>Án vsk: {kr(totalIncomeWithoutVat)}</small>
                        </div>
                        <small>
                            Alls rukkað í gegnum repeat.is:{' '}
                            {kr(
                                shopStats.reduce((acc, stat: StaffShop) => {
                                    return (stat.paymentTotal || 0) + acc;
                                }, 0)
                            )}
                        </small>
                    </>
                }
                extra={
                    <>
                        <Form
                            className="monthSelect"
                            layout="inline"
                            onValuesChange={(values: {
                                period: rangeKeys;
                                range: {};
                            }) => {
                                if (
                                    !values.range &&
                                    values.period !== 'custom'
                                ) {
                                    getStatsFromPeriod(
                                        dates[values.period].from.toISOString(),
                                        dates[values.period].to.toISOString()
                                    );
                                }
                                forceRender(!render);
                            }}
                            form={form}
                            style={{
                                marginTop: '20px',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Item
                                initialValue="currentMonth"
                                name="period"
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                    size="large"
                                    onChange={() => {
                                        setLoading(true);
                                    }}
                                >
                                    <Radio.Button
                                        className="capitalize"
                                        value="currentMonth"
                                    >
                                        {dates.currentMonth.name}
                                    </Radio.Button>
                                    <Radio.Button
                                        className="capitalize"
                                        value="lastMonth"
                                    >
                                        {dates.lastMonth.name}
                                    </Radio.Button>
                                    <Radio.Button
                                        className="capitalize"
                                        value="twoMonthsAgo"
                                    >
                                        {dates.twoMonthsAgo.name}
                                    </Radio.Button>
                                    <Radio.Button
                                        className="capitalize"
                                        value="threeMonthsAgo"
                                    >
                                        {dates.threeMonthsAgo.name}
                                    </Radio.Button>
                                    <Radio.Button
                                        className="capitalize"
                                        value="custom"
                                    >
                                        Velja tímabil
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {form.getFieldValue('period') === 'custom' && (
                                <>
                                    <Form.Item className="mt5" name="range">
                                        <DatePicker.RangePicker
                                            onCalendarChange={(values) => {
                                                if (
                                                    values &&
                                                    values[0] &&
                                                    values[1]
                                                ) {
                                                    getStatsFromPeriod(
                                                        values[0].toISOString(),
                                                        values[1].toISOString()
                                                    );
                                                }
                                            }}
                                        ></DatePicker.RangePicker>
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                    </>
                }
            >
                <div id="staffPageGrid">
                    <Card title="Nýjustu hreyfingarnar">
                        {lastMonthShopStats.length > 0 && (
                            <SalesOverviewChart
                                shopData={shopStats.map((shop) => {
                                    const lastMonthShop =
                                        lastMonthShopStats.find(
                                            (lastMonthShop) =>
                                                lastMonthShop.uuid === shop.uuid
                                        );
                                    return {
                                        shopName: shop.name,
                                        thisMonth: shop.paymentTotal || 0,
                                        lastMonth:
                                            lastMonthShop?.paymentTotal || 0,
                                    };
                                })}
                            />
                        )}

                        <h3>Pantanir</h3>
                        <Table
                            showHeader={false}
                            className="white_table"
                            pagination={false}
                            dataSource={orders.sort((a, b) => {
                                return a.created < b.created ? 1 : -1;
                            })}
                            columns={[
                                {
                                    title: 'Nafn ',
                                    render: (order: Order) => {
                                        return (
                                            <div>
                                                {''}
                                                <Button
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                    size="small"
                                                    type={'text'}
                                                    href={
                                                        '/vidskiptavinur/' +
                                                        order.customer.uuid
                                                    }
                                                >
                                                    {order.customer.name}
                                                </Button>

                                                <div>
                                                    <small>
                                                        {orderCreationLabel(
                                                            order.creation_method
                                                        )}{' '}
                                                        {order.created_by &&
                                                            '(' +
                                                                order.created_by +
                                                                ')'}
                                                    </small>
                                                </div>
                                            </div>
                                        );
                                    },
                                },

                                {
                                    title: 'Verslun ',
                                    render: (order: StaffOrder) => {
                                        return (
                                            <>
                                                <div>
                                                    <Button
                                                        style={{ padding: 0 }}
                                                        type={'text'}
                                                        size="small"
                                                        href={
                                                            '/stjornbord/' +
                                                            order.shop.uuid
                                                        }
                                                    >
                                                        {order.shop.name}
                                                    </Button>
                                                </div>

                                                <small>
                                                    {dayjs(
                                                        order.created
                                                    ).format('D. MMM - HH:mm')}
                                                </small>
                                            </>
                                        );
                                    },
                                },
                            ]}
                            rowKey={(order: Order) => order.uuid}
                        />

                        <br />

                        <h3>Notendur</h3>
                        <Table
                            className="white_table"
                            pagination={false}
                            dataSource={users.sort((a, b) => {
                                return a.created < b.created ? 1 : -1;
                            })}
                            columns={[
                                {
                                    render: (user) => {
                                        return (
                                            <div>
                                                <div>{user.email}</div>
                                                <div>{user.phone}</div>
                                                <small
                                                    style={{ color: 'gray' }}
                                                >
                                                    {dayjs(user.created).format(
                                                        'D. MMM - HH:mm'
                                                    )}
                                                </small>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            rowKey={(user) => user.id}
                        />
                    </Card>

                    <Card
                        title="Verslanir"
                        extra={
                            <div>
                                Með sölu á tímabilinu: {totalShopsWithSales}
                            </div>
                        }
                    >
                        <Table
                            className="white_table"
                            pagination={false}
                            dataSource={shopStats.sort((a, b) => {
                                if (orderBy === 'income')
                                    return (a.price || 0) < (b.price || 0)
                                        ? 0
                                        : -1;
                                if (orderBy === 'collected')
                                    return (a.paymentTotal || 0) <
                                        (b.paymentTotal || 0)
                                        ? 0
                                        : -1;
                                return 0;
                            })}
                            columns={[
                                {
                                    render: (shop) => {
                                        return (
                                            <div>
                                                <h3>{shop.name}</h3>
                                                <h5>{shop.ssid}</h5>
                                                <div
                                                    style={{
                                                        color: brandingConfig.successColor,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {kr(shop.price)}
                                                </div>

                                                <div
                                                    style={{
                                                        color: 'gray',
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    Sala:{' '}
                                                    {kr(shop.paymentTotal)}
                                                </div>
                                            </div>
                                        );
                                    },
                                },

                                // {
                                //     title: 'Tegund áskriftar',
                                //     render: (stat) => {
                                //         return stat.contract;
                                //     },
                                // },
                                {
                                    title: 'Stjórnborð',
                                    render: (shop) => {
                                        return (
                                            <Space>
                                                <Button size="small">
                                                    <a
                                                        href={`/stjornbord/${shop.uuid}/`}
                                                    >
                                                        Stjórnborð
                                                    </a>
                                                </Button>
                                            </Space>
                                        );
                                    },
                                },
                            ]}
                            rowKey={(shop) => {
                                return shop.uuid;
                            }}
                        ></Table>
                    </Card>

                    <Card title="Logs">
                        <LogOverview admin={true}></LogOverview>
                    </Card>
                </div>
            </Card>
        </>
    );
}
