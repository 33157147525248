import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
    Shop as CheckoutShop,
    Customer,
} from '../interfaces/repeatCheckoutInterfaces';
import Shop from '../../../interfaces/shop';
import { SimpleCustomer } from '../../../interfaces/customer';
import translate from '../../../translate/translate';
import { Currency } from '../../../interfaces/product';

declare global {
    interface Window {
        teyaCardToken: string;
        Cardinal: any;
    }
}

const Three3DSecureModal = (props: {
    cardNumber: string;
    cvc: string;
    month: string;
    year: string;
    shop: SimpleCustomer['shop'] | Shop | CheckoutShop;
    onSuccess: ({}) => void;
    onCancel: () => void;
    currencyCode: Currency['currency_code'];
    customer?: Customer;
}) => {
    const { cardNumber, shop, month, year, onSuccess } = props;
    const [iframeSrc, setIframeSrc] = useState<string | undefined | null>();

    useEffect(() => {
        if (shop.straumur || shop.valitor) {
            initializeValitor();
        } else if (shop.salt_pay) {
            initializeSaltPay();
        } else if (shop.verifone) {
            initializeVerifone();
        }

        const handlePostMessage = (e: MessageEvent) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setIframeSrc(null);
                onSuccess(e.data.valitorMessage);
            }

            if (e.data.hasOwnProperty('teyaMessage')) {
                setIframeSrc(null);
                onSuccess({
                    ...e.data.teyaMessage,
                    teyaCardToken: window.teyaCardToken,
                });
            }
        };

        window.addEventListener('message', handlePostMessage);

        return () => {
            window.removeEventListener('message', handlePostMessage);

            if (shop.verifone) {
                // @ts-ignore
                Cardinal.off('payments.validated');
                // @ts-ignore
                Cardinal.off('payments.setupComplete');
            }
        };
    }, []);

    const initializeSaltPay = () => {
        axios
            .post(`/payment/api/teya_three_d_secure/`, {
                cardNumber: cardNumber,
                expMonth: month,
                expYear: '20' + year,
                cvc: props.cvc,
                shop_uuid: shop.uuid,
                currency_code: props.currencyCode,
            })
            .then((rsp) => {
                const teyaCardToken = rsp.data.cardToken;
                // Making card token available to the event listener
                window.teyaCardToken = teyaCardToken;

                if (['1', '2', '3', '4'].includes(rsp.data.res.MdStatus)) {
                    setIframeSrc(null);
                    onSuccess({
                        ...rsp.data.res,
                        teyaCardToken: teyaCardToken,
                    });
                } else if (!rsp.data.res.RedirectToACSForm) {
                    setIframeSrc(null);
                    props.onCancel();
                    message.error(
                        translate('error_came_up') +
                            ': ' +
                            rsp.data.res.MdErrorMessage
                    );
                }

                setIframeSrc(rsp.data.res.RedirectToACSForm);
            })
            .catch(() => {
                props.onCancel();
                setIframeSrc(null);
            });
    };

    const initializeValitor = () => {
        axios
            .post(`/payment/api/valitor_three_d_secure/`, {
                cardNumber: cardNumber,
                expMonth: month,
                expYear: '20' + year,
                shop_uuid: shop.uuid,
            })
            .then((rsp) => {
                setIframeSrc(rsp.data.res.cardVerificationRawResponse);
            });
    };

    const initializeVerifone = () => {
        axios
            .post(`/payment/api/get_verifone_three_d_secure_jwt/`, {
                shop_uuid: shop.uuid,
            })
            .then((rsp) => {
                const jwt = rsp.data.jwt;

                // @ts-ignore
                Cardinal.setup('init', { jwt });

                // @ts-ignore
                Cardinal.on(
                    'payments.setupComplete',
                    function (setupCompleteData: any) {
                        // pass setupCompleteData.sessionId server side to make the lookup API call
                        console.log('setupCompleteData', setupCompleteData);

                        // @ts-ignore
                        Cardinal.trigger('bin.process', cardNumber)

                            .then(function (results: any) {
                                console.log('xxxxx, results', results);
                                if (results.Status) {
                                    // Bin profiling was successful. Some merchants may want to only move forward with authentication 	if profiling was successful
                                } else {
                                    // Bin profiling failed
                                }

                                // Bin profiling, if this is the card the end user is paying with you may start the CCA flow at this point or 	send the lookup request

                                // Cardinal.start('cca', myOrderObject);

                                const card = {
                                    cardNumber: cardNumber,
                                    expiryMonth: month,
                                    expiryYear: year,
                                    cvv: props.cvc,
                                };

                                console.log(
                                    'shop.verifone_encryption_key',
                                    shop.verifone_encryption_key
                                );

                                // @ts-ignore
                                verifone
                                    .getEncryptedCardDetails(
                                        card,
                                        shop.verifone_encryption_key
                                    )
                                    .then((data: any) => {
                                        const encryptedCard =
                                            data.encryptedCard;

                                        console.log(
                                            'encryptedCard',
                                            encryptedCard
                                        );

                                        const customer = props.customer;

                                        axios
                                            .post(
                                                '/payment/api/make_three_d_s_lookup/',
                                                {
                                                    shop_uuid: shop.uuid,
                                                    name: customer?.name,
                                                    email: customer?.email,
                                                    address: customer?.street,
                                                    town: customer?.town,
                                                    encryptedCard:
                                                        encryptedCard,
                                                    currency_code:
                                                        props.currencyCode,
                                                    device_info_id:
                                                        setupCompleteData.sessionId,

                                                    merchant_reference:
                                                        setupCompleteData.sessionId,
                                                }
                                            )
                                            .then((rsp) => {
                                                setIframeSrc(null);

                                                // @ts-ignore
                                                Cardinal.continue(
                                                    'cca',
                                                    {
                                                        AcsUrl: rsp.data.error
                                                            .acs_url,
                                                        Payload:
                                                            rsp.data.error
                                                                .payload,
                                                    },
                                                    {
                                                        OrderDetails: {
                                                            TransactionId:
                                                                rsp.data.error
                                                                    .transaction_id,
                                                        },
                                                    }
                                                );
                                            });

                                        // @ts-ignore
                                        Cardinal.on(
                                            'payments.validated',
                                            // @ts-ignore
                                            function (data, jwt) {
                                                console.log(
                                                    'payments.validateed!!!!!',
                                                    data
                                                );
                                                switch (data.ActionCode) {
                                                    case 'SUCCESS':
                                                        // Handle successful transaction, send JWT to backend to verify
                                                        onSuccess({
                                                            verifonePaymentData:
                                                                data.Payment
                                                                    .ExtendedData,

                                                            verifoneEncryptedCard:
                                                                encryptedCard,
                                                        });
                                                        break;

                                                    case 'NOACTION':
                                                        // Handle no actionable outcome
                                                        props.onCancel();
                                                        break;

                                                    case 'FAILURE':
                                                        // Handle failed transaction attempt
                                                        props.onCancel();

                                                        message.error(
                                                            translate(
                                                                'error_came_up'
                                                            ) +
                                                                ': ' +
                                                                data.ErrorNumber
                                                        );
                                                        break;

                                                    case 'ERROR':
                                                        props.onCancel();

                                                        message.error(
                                                            translate(
                                                                'error_came_up'
                                                            ) +
                                                                ': ' +
                                                                data.ErrorNumber
                                                        );

                                                        // Handle service level error
                                                        break;
                                                }
                                            }
                                        );
                                    });
                            })

                            .catch(function (error: any) {
                                console.log('error', error);
                                // An error occurred during profiling
                            });
                    }
                );
            });
    };

    return (
        <Modal open={true} footer={false} closable={false}>
            {!iframeSrc && <LoadingOutlined />}

            {iframeSrc && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={iframeSrc}
                />
            )}
        </Modal>
    );
};

export default Three3DSecureModal;
