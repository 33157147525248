import { useState, useEffect } from 'react';
import { SubscriptionTransactionHistory } from '../../interfaces/transaction';
import dayjs from 'dayjs';
import Axios from 'axios';
import { Timeline } from 'antd';

type Props = {
    uuid: string;
};

interface Change {
    history_date: string;
    history_user?: string;
    field: string;
    new?: boolean | string | object;
    old?: boolean | string | object;
}

const translateField = (key: string) => {
    if (key === 'is_refunded') {
        return 'Endurgreitt';
    } else if (key === 'is_paid') {
        return 'Greitt';
    } else if (key === 'payment_received_date') {
        return 'Dagsetning greiðslu';
    } else if (key === 'is_fulfilled') {
        return 'Afgreitt';
    } else {
        return key;
    }
};

const translateValue = (
    value: string | object | boolean | null | undefined,
    key: string
) => {
    if (['is_refunded', 'is_paid', 'is_fulfilled', 'is_free'].includes(key)) {
        return value === true ? 'Já' : value === false ? 'Nei' : 'Tómt';
    } else if (
        ['payment_received_date', 'perform_payment_time'].includes(key)
    ) {
        return value
            ? dayjs(value as string).format('DD. MMM YYYY - HH:mm')
            : 'Tómt';
    } else if (value === null || value === undefined) {
        return 'Tómt';
    }

    if (typeof value === 'object') {
        return JSON.stringify(value);
    }

    return value;
};

export default function TransactionHistory(props: Props) {
    const [changes, setChanges] = useState<Change[]>([]);

    useEffect(() => {
        Axios.get(`/askrift/get_subscription_history/${props.uuid}/`).then(
            (rsp) => {
                setChanges(rsp.data);
            }
        );

        return () => {
            setChanges([]);
        };
    }, []);

    return (
        <div>
            {!!changes.length && (
                <Timeline mode="left">
                    {changes.map((change, key) => {
                        return (
                            <Timeline.Item
                                style={{
                                    margin: 20,
                                }}
                                children={
                                    <div>
                                        <div>
                                            <div>
                                                {translateField(change.field)}:{' '}
                                                <div>
                                                    <strong>
                                                        {translateValue(
                                                            change.new,
                                                            change.field
                                                        )}{' '}
                                                    </strong>
                                                    <div>
                                                        (
                                                        <span
                                                            style={{
                                                                fontSize: 11,
                                                            }}
                                                        >
                                                            áður:{' '}
                                                            {translateValue(
                                                                change.old,
                                                                change.field
                                                            )}
                                                        </span>
                                                        )
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                label={
                                    <>
                                        <div>
                                            {dayjs(change.history_date).format(
                                                'DD. MMM YYYY - HH:mm'
                                            )}
                                        </div>
                                        <div>{change.history_user}</div>
                                    </>
                                }
                            ></Timeline.Item>
                        );
                    })}
                </Timeline>
            )}
        </div>
    );
}
