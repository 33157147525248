import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    message,
} from 'antd';
import {
    DeliveryOption,
    ShopOwnerDeliveryConfig,
} from '../../../interfaces/delivery';
import { getDeliveryIcon, postalCodes } from '../../../utils';

type Props = {
    option?: DeliveryOption;
    config: ShopOwnerDeliveryConfig;
    onSuccess: (option: DeliveryOption) => void;
};

export default function DeliveryOptionForm({
    option,
    config,
    onSuccess,
}: Props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (option) {
            form.setFieldsValue(option);
        } else {
            form.resetFields();
        }
    }, [option]);

    return (
        <Form
            size="large"
            layout="vertical"
            form={form}
            onFinish={(values) => {
                if (option) {
                    Axios.patch(
                        `/shipping/api/update_delivery_option/${option.uuid}/`,
                        values
                    )
                        .then((rsp) => {
                            message.success('Sendingarmöguleiki uppfærður');
                            onSuccess(rsp.data);
                        })
                        .catch((err) => {
                            message.error(
                                'Eitthvað fór úrskeiðis, ekki tókst að uppfæra sendingarmöguleika'
                            );
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    Axios.post('/shipping/api/create_delivery_option/', {
                        ...values,
                        applicable_postal_codes:
                            values.applicable_postal_codes || [],
                    }).then((rsp) => {
                        Axios.patch(
                            `/shipping/api/delivery_config_update/${config.uuid}/`,
                            {
                                options: [
                                    ...config.options.map((o) => o.id),
                                    rsp.data.id,
                                ],
                            }
                        )
                            .then(() => {
                                message.success('Breytingar vistaðar');
                            })
                            .catch(() => {
                                message.error(
                                    'Eitthvað fór úrskeiðis, ekki tókst að vista breytingar'
                                );
                            })
                            .finally(() => {
                                setLoading(false);
                                onSuccess(rsp.data);
                                form.resetFields();
                            });
                    });
                }

                return;
            }}
        >
            <Form.Item
                label="Titill"
                name="title"
                rules={[
                    { required: true, message: 'Þessi reitur er nauðsynlegur' },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Tegund"
                name="shipping_provider"
                rules={[
                    { required: true, message: 'Þessi reitur er nauðsynlegur' },
                ]}
            >
                <Select>
                    <Select.Option value="CUSTOM">
                        {getDeliveryIcon('CUSTOM', 20)} Sérsniðið
                    </Select.Option>

                    <Select.Option value="DROPP_PICKUP">
                        {getDeliveryIcon('DROPP_PICKUP', 20)} Dropp - Sækja
                        nálægt mér
                    </Select.Option>
                    <Select.Option value="DROPP_HOMESENT">
                        {getDeliveryIcon('DROPP_HOMESENT', 20)} Dropp -
                        Heimsending
                    </Select.Option>

                    <Select.Option value="POSTURINN_DPH">
                        {getDeliveryIcon('POSTURINN_DPH', 20)} Pósturinn -
                        Heimkeyrsla
                    </Select.Option>

                    <Select.Option value="POSTURINN_DPO">
                        {getDeliveryIcon('POSTURINN_DPO', 20)} Pósturinn - Pakki
                        í póstbox
                    </Select.Option>

                    <Select.Option value="POSTURINN_DPP">
                        {getDeliveryIcon('POSTURINN_DPP', 20)} Pósturinn - Pakki
                        á pósthús
                    </Select.Option>

                    <Select.Option value="POSTURINN_DPT">
                        {getDeliveryIcon('POSTURINN_DPT', 20)} Pósturinn -
                        Pakkaport sækja
                    </Select.Option>

                    <Select.Option value="PIKKOLO">
                        {getDeliveryIcon('PIKKOLO', 20)} Pikkoló
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Sendingarkostnaður"
                name="price"
                rules={[
                    { required: true, message: 'Þessi reitur er nauðsynlegur' },
                ]}
            >
                <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                label="Frítt sending ef vöruverð er yfir"
                name="free_delivery_threshold"
            >
                <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                label="Ákveðin póstnúmer? (Sleppa ef öll póstnúmer eru í boði)"
                name="applicable_postal_codes"
            >
                <Select
                    mode="tags"
                    placeholder="Póstnúmer"
                    options={postalCodes.map((code) => ({
                        label: code.code,
                        value: code.code,
                    }))}
                />
            </Form.Item>

            <Form.Item
                name="hidden_from_public"
                label={
                    <>
                        Falið? Ef já, þá birtist möguleikinn ekki við kaup.
                        Einungis starfsfólk getur honum við áskriftir.
                    </>
                }
                valuePropName="checked"
            >
                <Switch checkedChildren="Já" unCheckedChildren="Nei"></Switch>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
