import { useEffect, useState } from 'react';
import Customer from '../../../interfaces/customer';
import axios from 'axios';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Input,
    Modal,
    Select,
    message,
} from 'antd';

import { EditOutlined, LockOutlined } from '@ant-design/icons';

type Props = {
    customer: Customer;
};

interface PlayerLink {
    apple_podcasts: string;
    youtube_music: string;
    spotify: string;
    castro: string;
    downcast: string;
    overcast: string;
    pocket_casts: string;
    podcast_addict: string;
    podcast_republic: string;
    podkicker: string;
    rss_radio: string;
}

interface SuperCastUser {
    created_at: string;
    email: string;
    feed_url?: string;
    first_name?: string;
    id: number;
    ignore_usage_alerts: boolean;
    last_name?: string;
    plan_id?: number;
    plan_interval?: number;
    plan_name?: string;
    plan_price_cents?: number;
    plan_price_currency?: string;
    player_links?: PlayerLink[];
    state: string;
    subscriptions: {
        id: number;
        feed_url: string;
        player_links: PlayerLink;
        title: string;
        subdomain: string;
        channel_id: number;
        key: string;
        state: string;
        url: string;
        first_connected_at?: string;
        last_feed_download_at?: string;
        last_episode_download_at?: string;
        user_id: number;
    }[];
    uuid: number;
}

const getMembershipStatus = (customer: Customer) => {
    return axios.get(
        '/efni/get_supercast_user_from_customer_uuid/' + customer.uuid
    );
};

export default function SupercastDetails({ customer }: Props) {
    const [createUserModal, setCreateUserModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [changeLoginEmailModal, setChangeLoginEmailModal] = useState(false);
    const [changeMembershipStatusModal, setChangeMembershipStatusModal] =
        useState<false | { customer: Customer; status: string }>(false);
    const [supercastUser, setSupercastUser] = useState<SuperCastUser>();

    useEffect(() => {
        getMembershipStatus(customer)
            .then((rsp) => {
                setSupercastUser(rsp.data);
                setLoading(false);
            })
            .catch((err) => {
                message.error('Eitthvað fór úrskeiðis :/');
                setLoading(false);
            });
    }, []);

    const isActive = (supercastUser?.subscriptions?.length || 0) > 0;

    return (
        <>
            <Card
                loading={loading}
                title={
                    <div>
                        <img
                            style={{
                                width: 100,

                                marginRight: 3,
                            }}
                            src="/static/images/global_logos/supercast.png"
                            alt="Supercast"
                        />{' '}
                    </div>
                }
                headStyle={{
                    textAlign: 'center',
                }}
                bodyStyle={{
                    padding: 20,
                    paddingTop: 0,
                }}
            >
                <h4 className="text-center">
                    <div>
                        <Badge status={isActive ? 'success' : 'error'} />{' '}
                        Áskrift {isActive ? '' : 'ó'}
                        virk{' '}
                        <Button
                            size="small"
                            type="text"
                            onClick={() => {
                                setChangeMembershipStatusModal({
                                    customer,
                                    status: isActive ? 'cancelled' : 'active',
                                });
                            }}
                        >
                            <EditOutlined></EditOutlined>
                        </Button>
                    </div>
                </h4>
            </Card>

            <Modal
                open={!!changeMembershipStatusModal}
                onCancel={() => setChangeMembershipStatusModal(false)}
                title="Breyta stöðu Supercast áskriftar"
                footer={false}
            >
                {changeMembershipStatusModal && (
                    <Form
                        size="large"
                        layout="vertical"
                        onFinish={(values) => {
                            setLoading(true);
                            axios
                                .post(
                                    '/efni/change_supercast_status/' +
                                        customer.uuid +
                                        '/',
                                    {
                                        status: values.status,
                                    }
                                )
                                .then((res) => {
                                    message.success('Tókst, stöðu breytt!');
                                    setChangeMembershipStatusModal(false);

                                    getMembershipStatus(customer)
                                        .then((rsp) => {
                                            setSupercastUser(rsp.data);
                                            setLoading(false);
                                        })
                                        .catch((err) => {
                                            message.error(
                                                'Eitthvað fór úrskeiðis :/'
                                            );
                                        });
                                })
                                .catch((err) => {
                                    message.error('Eitthvað fór úrskeiðis :/');
                                    setLoading(false);
                                });
                        }}
                    >
                        <Form.Item
                            initialValue={
                                changeMembershipStatusModal.status === 'active'
                                    ? 'cancelled'
                                    : 'active'
                            }
                            label="Breyta í"
                            name="status"
                        >
                            <Select
                                options={[
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="success"
                                                    text="Virk"
                                                />
                                            </>
                                        ),
                                        value: 'active',
                                    },
                                    {
                                        label: (
                                            <>
                                                <Badge
                                                    status="error"
                                                    text="Óvirk"
                                                />
                                            </>
                                        ),
                                        value: 'cancelled',
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Button
                            disabled={loading}
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            Staðfesta
                        </Button>
                    </Form>
                )}
            </Modal>
        </>
    );
}
