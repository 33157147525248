import { useState } from 'react';
import { Button, Modal, message } from 'antd';
import Axios from 'axios';
import Subscription from '../../../interfaces/subscription';

type Props = {
    onCancel: () => void;
    onSuccess: () => void;
    subscription: Subscription;
};

export default function ReactivateSubscription(props: Props) {
    const [loading, setLoading] = useState(false);
    return (
        <Modal
            title="Viltu kveikja aftur á áskriftinni?"
            open={true}
            footer={null}
            onCancel={() => props.onCancel()}
        >
            <Button
                loading={loading}
                block
                type="primary"
                onClick={() => {
                    setLoading(true);

                    Axios.post(
                        `/askrift/api/reactivate_subscription_public/${props.subscription.uuid}/`
                    ).then((rsp) => {
                        setLoading(false);
                        const success = rsp.data.success;

                        if (success) {
                            props.onSuccess();
                            message.success('Tókst, áskrift er virk');
                        } else {
                            message.error('Eitthvað fór úrskeiðis');
                            props.onCancel();
                        }
                    });
                }}
            >
                Já, kveikja aftur á áskrift
            </Button>
        </Modal>
    );
}
