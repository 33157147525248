import { useEffect, useState } from 'react';
import Axios from 'axios';
import {
    Card,
    Space,
    Button,
    Modal,
    Dropdown,
    Badge,
    Alert,
    message,
    Checkbox,
} from 'antd';
import {
    SettingFilled,
    SnippetsOutlined,
    CheckCircleFilled,
    CommentOutlined,
    HistoryOutlined,
    StopOutlined,
    DeleteOutlined,
    MoneyCollectOutlined,
    PlusCircleFilled,
} from '@ant-design/icons';
import {
    SubscriptionTransaction,
    SubscriptionTransactionFilterRequest,
} from '../../../interfaces/transaction';
import { displayCurrencyPrice, kr } from '../../../utils';
import EditTransactionModal from '../../Transactions/EditTransactionsModal/EditTransactionsModal';
import RefundTransaction from '../../../components/RefundTransaction/RefundTransaction';
import DeleteTransactionModal from '../../Transactions/DeleteTransactionsModal/DeleteTransactionsModal';
import { getSubscriptionPaymentString } from '../../../utils/subscriptionUtils';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import TransactionPaymentStatus from '../../../components/TransactionPaymentStatus/TransactionPaymentStatus';
import TransactionDeliveryInfo from '../../../components/TransactionDeliveryInfo/TransactionDeliveryInfo';
import TransactionHistory from '../../../components/TransactionHistory/TransactionHistory';
import SaltPay from '../../../components/SaltPay/SaltPay';
import SendToShopifyModal from './SendToShopifyModal/SendToShopifyModal';
import CreateCustomerTransactions from './CreateCustomerTransactions/CreateCustomerTransactions';
import SendToPaydayModal from './SendToPaydayModal/SendToPaydayModal';
dayjs.locale('is'); // use locale

type Props = {
    customer_uuid: string;
    shop_uuid: string;
};

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

export default function CustomerTransactions(props: Props) {
    const [transactions, setTransactions] = useState<SubscriptionTransaction[]>(
        []
    );
    const [loading, setLoading] = useState(true);
    const [transactionCount, setTransactionCount] = useState<
        number | undefined
    >();
    const [createTransactionsModal, setCreateTransactionsModal] =
        useState<boolean>(false);
    const [editTransactionModal, setEditTransactionModal] = useState<
        | false
        | {
              options: {
                  mark_paid?: boolean;
                  mark_fulfilled?: boolean;
                  comment?: boolean;
              };
              transactions: SubscriptionTransaction[];
          }
    >(false);
    // const [chargeTransactionModal, setChargeTransactionModal] = useState<
    //     false | SubscriptionTransaction
    // >(false);
    const [refundTransactionModal, setRefundTransactionModal] = useState<
        false | SubscriptionTransaction
    >(false);
    const [deleteTransactionModal, setDeleteTransactionModal] = useState<
        false | number[]
    >(false);

    const [sendToShopifyModal, setSendToShopifyModal] = useState<
        false | SubscriptionTransaction
    >(false);

    const [paydayModal, setPaydayModal] = useState<
        false | SubscriptionTransaction
    >(false);

    const [sendToWoocommerceModal, setSendToWoocommerceModal] = useState<
        false | SubscriptionTransaction
    >(false);

    const [transactionHistoryModal, setTransactionHistoryModal] = useState<
        false | string
    >(false);

    const [waitingForWoocommerce, setWaitingForWoocommerce] = useState(false);

    const [showDeleted, setShowDeleted] = useState(false);

    useEffect(() => {
        Axios.get(
            `/askrift/api/subscription_transactions/?page_size=999&ordering=-created&subscription__customer__uuid=${props.customer_uuid}&is_deleted=${showDeleted}`
        ).then((rsp) => {
            setTransactions(rsp.data.results);
            setTransactionCount(rsp.data.count);
            setLoading(false);
        });
    }, [showDeleted]);

    const getSelection = (
        ids: number[]
    ): SubscriptionTransactionFilterRequest => {
        return {
            selectedCount: 1,
            selectionList: ids,
            allSelected: false,
            shop_uuid: props.shop_uuid,
            filters: {
                is_completed: true,
                is_uncompleted: true,
                subscription__active: 'true,false',
                is_free: '',
                ordering: '-created',
                subscription__product__in: '',
                subscription__payment_method__in: 'BANK_CLAIM,CARDS',
                frequencies: '',
                max_per_customer: '',
                min_per_customer: '',
            },
        };
    };

    return (
        <Card
            loading={loading}
            title={
                <div>
                    Sendingar {transactionCount && `(${transactionCount})`}
                </div>
            }
            extra={
                <Button
                    size="small"
                    type="text"
                    onClick={() => setCreateTransactionsModal(true)}
                >
                    <PlusCircleFilled /> Búa til sendingu
                </Button>
            }
        >
            {w.me === 1 && (
                <div className="text-right" style={{ marginBottom: 5 }}>
                    Sýna eyddar sendingar{' '}
                    <Checkbox
                        onChange={(val) => setShowDeleted(val.target.checked)}
                    ></Checkbox>
                </div>
            )}

            <div id="customerShipments">
                <Space
                    size={15}
                    direction="vertical"
                    style={{ display: 'flex' }}
                >
                    {transactions.length === 0 && (
                        <div
                            style={{
                                color: '#ccc',
                            }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    style={{
                                        width: 40,
                                        opacity: 0.2,
                                        marginTop: 10,
                                    }}
                                    src="/static/images/svg/box.svg"
                                    alt="Engar sendingar"
                                />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div
                                    style={{
                                        color: '#ccc',
                                    }}
                                >
                                    Engar sendingar
                                </div>
                            </div>
                        </div>
                    )}

                    {transactions.map(
                        (transaction: SubscriptionTransaction, key: number) => {
                            const customActions = [];

                            if (
                                !!transaction.subscription.product.payday_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/payday_logo.svg"
                                                alt="Payday logo"
                                            />{' '}
                                            Búa til Payday pöntun
                                        </>
                                    ),
                                    key: 'send_to_payday',
                                    onClick: () => {
                                        setPaydayModal(transaction);
                                    },
                                });
                            }

                            if (
                                !!transaction.subscription.product.shopify_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/shopify.svg"
                                                alt="Shopify logo"
                                            />{' '}
                                            Búa til Shopify pöntun
                                        </>
                                    ),
                                    key: 'send_to_shopify',
                                    onClick: () => {
                                        setSendToShopifyModal(transaction);
                                    },
                                });
                            }

                            if (
                                !!transaction.subscription.product
                                    .woocommerce_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/woocommerce.svg"
                                                alt="Woocommerce logo"
                                            />{' '}
                                            Búa til Woocommerce pöntun
                                        </>
                                    ),
                                    key: 'send_to_woocommerce',
                                    onClick: () => {
                                        setSendToWoocommerceModal(transaction);
                                    },
                                });
                            }

                            return (
                                <Card
                                    className="ant-card-gray"
                                    key={key}
                                    title={
                                        <>
                                            <div>
                                                <TransactionPaymentStatus
                                                    transaction={transaction}
                                                />{' '}
                                                <span
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    {transaction.is_fulfilled ? (
                                                        <Badge
                                                            status="success"
                                                            text="Uppfyllt"
                                                        />
                                                    ) : (
                                                        <Badge
                                                            status="error"
                                                            text="Óuppfyllt"
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </>
                                    }
                                    size="small"
                                    extra={
                                        <>
                                            <Space>
                                                <Dropdown
                                                    menu={{
                                                        items: [
                                                            {
                                                                label: 'Endurgreiðsla',
                                                                key: 'refund',
                                                                onClick: () => {
                                                                    setRefundTransactionModal(
                                                                        transaction
                                                                    );
                                                                },
                                                                disabled:
                                                                    (transaction.is_paid ===
                                                                        false &&
                                                                        !!transaction
                                                                            .payment_request_log
                                                                            ?.length) ||
                                                                    transaction.is_free ||
                                                                    transaction.is_refunded ||
                                                                    transaction
                                                                        .subscription
                                                                        ?.payment_method ===
                                                                        'BANK_CLAIM',
                                                                icon: (
                                                                    <HistoryOutlined />
                                                                ),
                                                            },
                                                            {
                                                                key: 'mark_paid_unpaid',
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    mark_paid:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                icon: (
                                                                    <CheckCircleFilled />
                                                                ),
                                                                label: 'Merkja sem greitt/ógreitt',
                                                            },

                                                            {
                                                                key: 'mark_fulfilled_unfulfilled,',
                                                                icon: (
                                                                    <StopOutlined />
                                                                ),
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    mark_fulfilled:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                label: 'Merkja sem uppfyllt/óuppfyllt',
                                                            },

                                                            // {
                                                            //     key: 'charge_card',
                                                            //     onClick: () => {
                                                            //         setChargeTransactionModal(
                                                            //             transaction
                                                            //         );
                                                            //     },
                                                            //     disabled:
                                                            //         transaction.is_paid !==
                                                            //         false,
                                                            //     icon: (
                                                            //         <CreditCardFilled />
                                                            //     ),
                                                            //     label: 'Rukka kort',
                                                            // },

                                                            {
                                                                key: 'add_comment',
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    comment:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                icon: (
                                                                    <CommentOutlined />
                                                                ),

                                                                label: 'Bæta við athugasemd',
                                                            },

                                                            ...customActions,
                                                            {
                                                                key: 'delete',
                                                                onClick: () => {
                                                                    setDeleteTransactionModal(
                                                                        [
                                                                            transaction.id,
                                                                        ]
                                                                    );
                                                                },
                                                                icon: (
                                                                    <DeleteOutlined />
                                                                ),
                                                                danger: true,
                                                                label: 'Eyða',
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <div>
                                                        <Button size="small">
                                                            <SettingFilled></SettingFilled>{' '}
                                                            Aðgerðir
                                                        </Button>
                                                    </div>
                                                </Dropdown>

                                                {w.me === 1 &&
                                                    !!transaction.history &&
                                                    !!transaction.history
                                                        .length && (
                                                        <span>
                                                            <HistoryOutlined
                                                                onClick={() => {
                                                                    if (
                                                                        transaction.history
                                                                    )
                                                                        setTransactionHistoryModal(
                                                                            transaction.uuid
                                                                        );
                                                                }}
                                                            />
                                                        </span>
                                                    )}

                                                <div>
                                                    {w.me === 1 && (
                                                        <>
                                                            {' '}
                                                            <Button
                                                                size="small"
                                                                href={`
                                                    /bakendi/subscription/subscriptiontransaction/${transaction.id}/change/
                                                    `}
                                                            >
                                                                Bakendi
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </Space>
                                        </>
                                    }
                                >
                                    <h4 className="text-center">
                                        {
                                            transaction.subscription?.product
                                                .title
                                        }
                                    </h4>

                                    {transaction.is_refunded && (
                                        <Alert
                                            message="Þessi sending hefur verið
                                            endurgreidd"
                                            type="error"
                                            style={{ textAlign: 'center' }}
                                        />
                                    )}

                                    <div className="mt10 mb10 text-center">
                                        <Space>
                                            {transaction.perform_payment_time && (
                                                <div
                                                    style={{
                                                        color: 'gray',
                                                        fontSize: 11,
                                                    }}
                                                >
                                                    <div>
                                                        Sjálfvirk rukkun
                                                        áformuð:
                                                    </div>

                                                    <div>
                                                        <div>
                                                            {dayjs(
                                                                transaction.perform_payment_time
                                                            ).format('D. MMM')}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Space>
                                    </div>
                                    <h2 className="text-center">
                                        {displayCurrencyPrice(
                                            transaction.amount,
                                            transaction.currency_code
                                        )}
                                    </h2>

                                    <div className="text-center">
                                        <span className="minorBubble">
                                            Stofnað:{' '}
                                            {dayjs(transaction.created).format(
                                                'D. MMM YYYY HH:mm'
                                            )}
                                        </span>

                                        {transaction.payment_received_date &&
                                            transaction.is_paid && (
                                                <span className="minorBubble">
                                                    Greitt:{' '}
                                                    {dayjs(
                                                        transaction.payment_received_date
                                                    ).format(
                                                        'D. MMM YYYY HH:mm'
                                                    )}
                                                </span>
                                            )}
                                    </div>
                                    <div className="text-center mt10">
                                        Greiðsluleið:{' '}
                                        {getSubscriptionPaymentString(
                                            transaction.subscription,
                                            transaction
                                        )}
                                    </div>

                                    <TransactionDeliveryInfo
                                        className="mt10"
                                        isPublic={false}
                                        subscriptionTransaction={transaction}
                                    ></TransactionDeliveryInfo>

                                    {transaction.staff_comments
                                        ?.sort((a, b) =>
                                            b.created > a.created ? 1 : -1
                                        )
                                        .map((comment, key) => {
                                            return (
                                                <Card
                                                    title={dayjs(
                                                        comment.created
                                                    ).format(
                                                        'D. MMM YYYY - HH:mm'
                                                    )}
                                                    key={key}
                                                    size="small"
                                                    extra={comment.user}
                                                    className="customerComment mb10"
                                                >
                                                    {comment.message}
                                                </Card>
                                            );
                                        })}
                                </Card>
                            );
                        }
                    )}
                </Space>
            </div>

            {!!editTransactionModal && (
                <EditTransactionModal
                    selection={getSelection(
                        editTransactionModal?.transactions.map((s) => s.id)
                    )}
                    open={!!editTransactionModal}
                    options={editTransactionModal.options}
                    title="Uppfæra sendingu"
                    onCancel={() => {
                        setEditTransactionModal(false);
                    }}
                    onSuccess={() => {
                        message.success('Tókst');

                        setEditTransactionModal(false);
                    }}
                ></EditTransactionModal>
            )}

            {!!refundTransactionModal && (
                <Modal
                    title="Endurgreiða færslu"
                    onCancel={() => {
                        setRefundTransactionModal(false);
                    }}
                    footer={null}
                    open={!!refundTransactionModal}
                >
                    <RefundTransaction
                        onCancel={(refundSuccessfull) => {
                            setRefundTransactionModal(false);

                            if (refundSuccessfull) {
                                transactions.map((transaction) => {
                                    if (
                                        transaction.id ===
                                        refundTransactionModal?.id
                                    ) {
                                        transaction.is_refunded = true;
                                    }
                                    return transaction;
                                });
                            }
                        }}
                        transaction={refundTransactionModal}
                    ></RefundTransaction>
                </Modal>
            )}

            {/* {!!chargeTransactionModal && (
                <ChargeTransactionsModal
                    selection={{
                        selectedCount: 1,
                        selectionList: [chargeTransactionModal.id],
                        allSelected: false,
                        // filters: SubscriptionTransactionFilter,
                        shop_uuid: shopuu,
                    }}
                    onCancel={() => {
                        setChargeTransactionModal(false);
                    }}
                    open={!!chargeTransactionModal}
                    onSuccess={(res_data: {
                        responses: { id: number; success: boolean }[];
                    }) => {
                        const responses = res_data.responses;
                        const res_ids = responses.map((data) => data.id);

                        setTransactions([
                            ...transactions.filter(
                                (transaction) =>
                                    !res_ids.includes(transaction.id)
                            ),
                            ...transactions
                                .filter((transaction) =>
                                    res_ids.includes(transaction.id)
                                )
                                .map((transaction) => {
                                    return {
                                        ...transaction,
                                        is_paid: responses.filter(
                                            (data) => data.id === transaction.id
                                        )[0].success,
                                    };
                                }),
                        ]);
                    }}
                ></ChargeTransactionsModal>
            )} */}

            {!!deleteTransactionModal && (
                <DeleteTransactionModal
                    selection={getSelection(deleteTransactionModal)}
                    open={!!deleteTransactionModal}
                    onOk={() => {
                        const ids = deleteTransactionModal;
                        setTransactions(
                            transactions.filter((transaction) => {
                                const affected = ids.includes(transaction.id);

                                if (affected) {
                                    return false;
                                }

                                return true;
                            })
                        );

                        setDeleteTransactionModal(false);
                    }}
                    onCancel={() => {
                        setDeleteTransactionModal(false);
                    }}
                ></DeleteTransactionModal>
            )}

            {!!sendToShopifyModal && (
                <SendToShopifyModal
                    subscriptionTransactions={transactions}
                    open={!!sendToShopifyModal}
                    onCancel={() => {
                        setSendToShopifyModal(false);
                    }}
                    preselectedTransactionUuids={[sendToShopifyModal.uuid]}
                ></SendToShopifyModal>
            )}

            {!!paydayModal && (
                <SendToPaydayModal
                    subscriptionTransactions={transactions}
                    open={!!paydayModal}
                    onCancel={() => {
                        setPaydayModal(false);
                    }}
                    preselectedTransactionUuids={[paydayModal.uuid]}
                ></SendToPaydayModal>
            )}

            {!!transactionHistoryModal && (
                <Modal
                    open={!!transactionHistoryModal}
                    onCancel={() => setTransactionHistoryModal(false)}
                >
                    <TransactionHistory
                        uuid={transactionHistoryModal}
                    ></TransactionHistory>
                </Modal>
            )}

            {!!sendToWoocommerceModal && (
                <Modal
                    confirmLoading={waitingForWoocommerce}
                    onCancel={() => setSendToWoocommerceModal(false)}
                    onOk={() => {
                        setWaitingForWoocommerce(true);
                        Axios.post('/askrift/create_custom_woocommerce_order', {
                            transaction_id: sendToWoocommerceModal.id,
                        })
                            .then(() => {
                                message.success('Tókst 🎉');
                                setSendToWoocommerceModal(false);
                            })
                            .finally(() => setWaitingForWoocommerce(false));
                    }}
                    title="Senda aftur í Woocommerce"
                    open={!!sendToWoocommerceModal}
                >
                    <p>
                        Athugaðu að ef Repeat er þegar búið að stofna pöntun í
                        Woocommerce þá verður samt til önnur.
                    </p>
                </Modal>
            )}

            {!!createTransactionsModal && (
                <CreateCustomerTransactions
                    customer_uuid={props.customer_uuid}
                    shop_uuid={props.shop_uuid}
                    onClose={() => {
                        setCreateTransactionsModal(false);
                    }}
                ></CreateCustomerTransactions>
            )}
        </Card>
    );
}
