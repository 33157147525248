import { useState } from 'react';
import Subscription, {
    SubscriptionBundle,
} from '../../interfaces/subscription';
import { Button, Table, message } from 'antd';
import Axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import translate, { LanguageKey } from '../../translate/translate';
import dayjs from 'dayjs';
import { getSubscriptionCommitmentPeriod } from '../../utils/subscriptionUtils';

type Props = {
    subscriptionBundle: SubscriptionBundle;
    selectedSubscriptions: string[];
    onClose: (
        changes: {
            uuid: string;
            resign_date?: string;
            active: boolean;
        }[]
    ) => void;
    language: LanguageKey;
};

export default function CancelSubscriptionsPublic({
    subscriptionBundle,
    selectedSubscriptions,
    onClose,
    language,
}: Props) {
    let defaultSelectedRowKeys = selectedSubscriptions || [];
    if (
        defaultSelectedRowKeys.length === 0 &&
        subscriptionBundle.subscriptions.length === 1
    ) {
        defaultSelectedRowKeys = [subscriptionBundle.subscriptions[0].uuid];
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
        defaultSelectedRowKeys
    );
    const [cancelReason, setCancelReason] = useState<string>('');
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <Table
                pagination={false}
                dataSource={subscriptionBundle.subscriptions}
                rowKey="uuid"
                columns={[
                    {
                        title: translate('subscription', language),
                        render: (subscription: Subscription) => {
                            return subscription.product.title;
                        },
                    },
                    {
                        title: 'Uppsagnafrestur',
                        render: (subscription: Subscription) => {
                            const notice =
                                subscription.product.cancel_notice || 0;

                            let upcomingDates =
                                subscriptionBundle.upcomingDates || [];

                            if (upcomingDates.length > notice) {
                                upcomingDates = upcomingDates.slice(0, notice);
                            }

                            const {
                                commitmentEndDate,
                                commitmentHasEnded,
                                finalNoticeAndCommitmentDate,
                                freeToCancel,
                            } = getSubscriptionCommitmentPeriod(subscription);

                            const {
                                commitment_period,
                                commitment_period_type,
                            } = subscription.product;

                            return (
                                <div>
                                    <div>
                                        {notice > 0 && (
                                            <>
                                                <div>
                                                    <small>
                                                        {translate(
                                                            'cancel_notice',
                                                            language
                                                        )}{' '}
                                                        <strong>
                                                            {' '}
                                                            {notice.toString() +
                                                                ' ' +
                                                                translate(
                                                                    'times',
                                                                    language
                                                                )}
                                                        </strong>
                                                    </small>
                                                </div>
                                            </>
                                        )}

                                        {commitmentEndDate &&
                                            !commitmentHasEnded && (
                                                <div>
                                                    <small>
                                                        {translate(
                                                            'commitment_period',
                                                            language
                                                        )}
                                                        :{' '}
                                                        <strong>
                                                            {commitment_period}{' '}
                                                            {translate(
                                                                commitment_period_type as
                                                                    | 'MONTH'
                                                                    | 'WEEK'
                                                                    | 'DAY'
                                                                    | 'YEAR',
                                                                language
                                                            )}
                                                        </strong>
                                                    </small>
                                                </div>
                                            )}

                                        {!freeToCancel &&
                                            finalNoticeAndCommitmentDate && (
                                                <small>
                                                    {translate(
                                                        'subscription_will_end',
                                                        language
                                                    )}{' '}
                                                    <strong>
                                                        {finalNoticeAndCommitmentDate.format(
                                                            'D. MMM YYYY'
                                                        )}
                                                    </strong>
                                                </small>
                                            )}

                                        {notice === 0 && commitmentHasEnded && (
                                            <>
                                                {translate(
                                                    'no_cancel_notice',
                                                    language
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {/* {notice > 0 &&
                                        subscriptionBundle.upcomingDates && (
                                            <div>
                                                {upcomingDates.map((d, key) => (
                                                    <small
                                                        className="bundleMandatoryDate"
                                                        key={key}
                                                    >
                                                        {d.format(
                                                            'D. MMM YYYY'
                                                        )}
                                                    </small>
                                                ))}
                                            </div>
                                        )} */}
                                </div>
                            );
                        },
                    },
                ]}
                showHeader={false}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        setSelectedRowKeys(selectedRowKeys as string[]);
                    },
                }}
            ></Table>
            <br />

            <label style={{ paddingBottom: 10, display: 'block' }}>
                {translate('cancel_reason', language)}
            </label>
            <TextArea
                placeholder={translate('cancel_reason', language).toString()}
                value={cancelReason}
                onChange={(e) => {
                    setCancelReason(e.target.value);
                }}
                rows={4}
            ></TextArea>
            <br />
            <br />

            <Button
                loading={loading}
                onClick={() => {
                    if (!cancelReason) {
                        message.error(
                            translate('cancel_reason_required', language)
                        );
                        return;
                    }

                    if (selectedRowKeys.length === 0) {
                        message.error(
                            translate('please_select_at_least_one', language)
                        );
                        return;
                    }

                    setLoading(true);
                    Axios.post(`/askrift/api/cancel_subscriptions_public/`, {
                        uuids: selectedRowKeys,
                        cancel_reason: cancelReason,
                    }).then((rsp) => {
                        if (rsp.data.success) {
                            message.success(translate('success', language));

                            // rsp.data.changes = {
                            //     "uuid": subscription.uuid,
                            //     "resign_date": subscription.resign_date,
                            //     "active": subscription.active,
                            // }

                            onClose(rsp.data.changes);
                        }
                    });
                }}
                block
                type="primary"
                size="large"
            >
                {translate('cancel', language)}
            </Button>
        </div>
    );
}
