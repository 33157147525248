import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-sprite-thumbnails';
import { ContentAsset } from '../../interfaces/content';

function calculateThumbnails(
    spriteWidth: number,
    spriteHeight: number,
    totalWidth: number,
    totalHeight: number
) {
    const thumbnailsPerRow = totalWidth / spriteWidth;
    const thumbnailsPerColumn = totalHeight / spriteHeight;
    const totalThumbnails = thumbnailsPerRow * thumbnailsPerColumn;

    return {
        totalColumns: thumbnailsPerRow,
        totalRows: thumbnailsPerColumn,
        totalThumbnails: totalThumbnails,
    };
}

export const VideoJS = (props: {
    options: object;
    onReady?: (player: any) => void;
    contentAsset: ContentAsset;
}) => {
    const videoRef = React.useRef<HTMLDivElement>(null);
    const playerRef = React.useRef<any>(null);
    const { options, onReady, contentAsset } = props;

    const repeatMediaAsset = contentAsset.repeat_media_asset;

    const thumbnails = repeatMediaAsset?.storyboard;

    React.useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current && videoRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js');

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = (playerRef.current = videojs(
                videoElement,
                {
                    ...options,
                    controls: true,
                },
                () => {
                    videojs.log('player is ready');
                    onReady && onReady(player);
                }
            ));

            player.addClass('vjs-has-started');
            player.addClass('vjs-repeat-player');

            if (thumbnails) {
                player.ready(() => {
                    const totalThumbnailCount = calculateThumbnails(
                        thumbnails.spriteWidth,
                        thumbnails.spriteHeight,
                        thumbnails.totalWidth,
                        thumbnails.totalHeight
                    );

                    // @ts-ignore
                    player.spriteThumbnails({
                        url:
                            'https://media.repeat.is' +
                            thumbnails.storyboardPath,
                        width: thumbnails.spriteWidth,
                        height: thumbnails.spriteHeight,
                        columns: totalThumbnailCount.totalColumns,
                        rows: totalThumbnailCount.totalRows,
                        interval: thumbnails.spriteDuration,
                    });
                });
            }

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div className="wrapper">
                <div className="videocontent">
                    <div ref={videoRef} />
                </div>
            </div>
        </div>
    );
};

export default VideoJS;
