import Axios from 'axios';
import { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Modal,
    Space,
    Image,
    InputNumber,
    message,
} from 'antd';
import {
    ClockCircleOutlined,
    PushpinOutlined,
    RedoOutlined,
} from '@ant-design/icons';
import Product, { Currency } from '../../interfaces/product';
import { SubscriptionBundle } from '../../interfaces/subscription';
import { getCronLabelSimple, getProductTranslation, kr } from '../../utils';
import SubscriptionBundleCard from './SubscriptionBundleCard';
import ReactHtmlParser from 'react-html-parser';
import NewSubscriptionSettings from './NewSubscriptionSettings';

const { Paragraph } = Typography;

export type Steps =
    | 'info'
    | 'bundle_or_new'
    | 'select_date_and_more'
    | 'select_bundle'
    | 'confirm_screen';

type Props = {
    open: boolean;
    onClose: () => void;
    product: Product;
    customer: SimpleCustomer;
    currentBundles?: SubscriptionBundle[];
    initalStep?: Steps;
    onSuccess: () => void;
    language: LanguageKey;
    currencyCode: Currency['currency_code'];
};

import { SelectedCronConfig } from './NewSubscriptionSettings';
import { SelectedDeliveryOption } from './DeliveryChoicesForNewSubscription';
import { SimpleCustomer } from '../../interfaces/customer';
import translate, { LanguageKey } from '../../translate/translate';
import dayjs from 'dayjs';

export default function AddProductToSubscriptionModal({
    open,
    onClose,
    product,
    currentBundles,
    customer,
    initalStep,
    onSuccess,
    language,
    currencyCode,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<
        SelectedDeliveryOption | undefined | null
    >();
    const [quantity, setQuantity] = useState(1);
    const [selectedCronConfig, setSelectedCronConfig] =
        useState<SelectedCronConfig>();

    const [selectedStep, setSelectedStep] = useState<Steps>(
        initalStep || 'info'
    );

    useEffect(() => {
        if (open) {
            setSelectedStep(selectedStep);
        }
    }, [open]);

    const isBundles = currentBundles && currentBundles.length > 0;

    return (
        <Modal
            className="editCustomerPublicModal"
            open={open}
            onCancel={() => onClose()}
            footer={false}
            title={getProductTranslation(product, language).title}
        >
            {selectedStep === 'info' && (
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <div className="text-center">
                        <Image
                            // @ts-ignore
                            src={product.main_image}
                            style={{ maxWidth: 200 }}
                        ></Image>
                    </div>

                    <Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: (
                                <span
                                    style={{
                                        color: 'gray',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Lesa meira
                                </span>
                            ),
                            onExpand: () => {
                                // sendPostMessage();
                                // setTimeout(() => {
                                //     sendPostMessage();
                                // }, 200);
                            },
                        }}
                    >
                        {
                            ReactHtmlParser(
                                getProductTranslation(product, language)
                                    .description
                            ) as React.ReactNode
                        }
                    </Paragraph>

                    <Button
                        size="large"
                        block
                        type="primary"
                        onClick={() => {
                            const intervalEditable = product.interval_editable;
                            const deliveryEditable = product.delivery_config;
                            const isUserCustomizable =
                                intervalEditable || deliveryEditable;

                            if (isBundles && isUserCustomizable) {
                                setSelectedStep('bundle_or_new');
                            } else if (isUserCustomizable) {
                                setSelectedStep('select_date_and_more');
                            } else {
                                setSelectedStep('confirm_screen');
                            }
                        }}
                    >
                        {translate('sign_up_for_subscription', language)}
                    </Button>
                </Space>
            )}

            {selectedStep === 'bundle_or_new' && (
                <Space direction="vertical" style={{ display: 'flex' }}>
                    {currentBundles?.some((b) => {
                        console.log(b);
                        return b.date && b.interval && b.intervalType;
                    }) && (
                        <Button
                            size="large"
                            block
                            onClick={() => {
                                setSelectedStep('select_bundle');
                            }}
                        >
                            {translate(
                                'combine_with_existing_subscription',
                                language
                            )}
                        </Button>
                    )}

                    <Button
                        size="large"
                        block
                        onClick={() => {
                            setSelectedStep('select_date_and_more');
                        }}
                    >
                        {product.interval_editable
                            ? translate('choose_date', language)
                            : translate('create_new_subscription', language)}
                    </Button>
                </Space>
            )}

            {selectedStep === 'select_bundle' && (
                <Space
                    direction="vertical"
                    style={{ display: 'flex', flex: 1 }}
                    size={20}
                >
                    {isBundles && (
                        <>
                            {currentBundles
                                .filter(
                                    (b) =>
                                        b.date && b.interval && b.intervalType
                                )
                                .map((bundle, key) => {
                                    return (
                                        <div
                                            key={key}
                                            style={{
                                                border: '1px solid #e8e8e8',
                                                borderRadius: 4,
                                                padding: 20,
                                            }}
                                        >
                                            <SubscriptionBundleCard
                                                bordered={false}
                                                bundle={bundle}
                                                disabled={true}
                                                editable={false}
                                            ></SubscriptionBundleCard>
                                            <Button
                                                style={{ marginTop: 20 }}
                                                size="large"
                                                type="dashed"
                                                block
                                                disabled={loading}
                                                onClick={() => {
                                                    if (bundle.intervalType) {
                                                        setSelectedCronConfig({
                                                            interval:
                                                                bundle.interval,
                                                            interval_type:
                                                                bundle.intervalType as
                                                                    | 'MONTH'
                                                                    | 'WEEK'
                                                                    | 'YEAR',
                                                            base_date_is_signup_date:
                                                                false,
                                                            base_date:
                                                                bundle.date,
                                                        });
                                                    }

                                                    if (
                                                        bundle.deliveryInfo &&
                                                        bundle.deliveryInfo
                                                            .selectedDeliveryOption
                                                    ) {
                                                        setSelectedDeliveryOption(
                                                            bundle.deliveryInfo
                                                                .selectedDeliveryOption
                                                        );
                                                    }

                                                    setSelectedStep(
                                                        'confirm_screen'
                                                    );
                                                }}
                                            >
                                                + Bæta við
                                            </Button>
                                        </div>
                                    );
                                })}
                        </>
                    )}
                </Space>
            )}

            {selectedStep === 'select_date_and_more' && (
                <div>
                    <NewSubscriptionSettings
                        product={product}
                        onFinish={(deliveryOption, cronConfig) => {
                            setSelectedCronConfig(cronConfig);
                            setSelectedDeliveryOption(deliveryOption);
                            setSelectedStep('confirm_screen');
                        }}
                        language={language}
                    ></NewSubscriptionSettings>
                </div>
            )}

            {selectedStep === 'confirm_screen' && (
                <div>
                    <Space
                        direction="vertical"
                        size={'large'}
                        style={{ display: 'flex' }}
                    >
                        {/* <div>Þú ert að skrá þig í áskrift að: </div>
                        <div>
                            <strong>
                                {getProductTranslation(product, language).title}
                            </strong>
                        </div> */}
                        {selectedCronConfig && (
                            <div className="infoBubble">
                                <div className="infoBubbleTitle">
                                    <RedoOutlined />{' '}
                                    {translate('shipment_frequecy', language)}:{' '}
                                </div>
                                <div className="infoBubbleData">
                                    {getCronLabelSimple(
                                        selectedCronConfig.interval,
                                        selectedCronConfig.interval_type,
                                        null,
                                        null,
                                        language
                                    )}
                                </div>
                            </div>
                        )}
                        {selectedDeliveryOption && (
                            <div className="infoBubble">
                                <div className="infoBubbleTitle">
                                    <PushpinOutlined />{' '}
                                    {translate('delivery_method', language)}:{' '}
                                </div>

                                <div className="infoBubbleData">
                                    {selectedDeliveryOption.title}
                                </div>
                            </div>
                        )}

                        {selectedCronConfig && selectedCronConfig.base_date && (
                            <div className="infoBubble">
                                <div className="infoBubbleTitle">
                                    <ClockCircleOutlined />{' '}
                                    {translate('next', language)}:{' '}
                                </div>
                                <div className="infoBubbleData">
                                    {' '}
                                    <>
                                        {dayjs(
                                            selectedCronConfig.base_date
                                        ).isSame(dayjs(), 'day') ? (
                                            <>{translate('today', language)}</>
                                        ) : (
                                            <>
                                                {dayjs(
                                                    selectedCronConfig.base_date
                                                ).format('D. MMM YYYY')}
                                            </>
                                        )}
                                    </>
                                </div>
                            </div>
                        )}

                        {
                            <div className="infoBubble">
                                <div className="infoBubbleTitle">
                                    {' '}
                                    {translate('quantity', language)}{' '}
                                </div>

                                <div className="infoBubbleTitle">
                                    <InputNumber
                                        size="large"
                                        value={quantity}
                                        min={1}
                                        defaultValue={1}
                                        type="number"
                                        onChange={(value) => {
                                            if (
                                                value &&
                                                !isNaN(value) &&
                                                value > 0
                                            ) {
                                                setQuantity(value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        <Button
                            size="large"
                            block
                            type="primary"
                            loading={loading}
                            onClick={() => {
                                setLoading(true);

                                Axios.post(
                                    '/askrift/api/create_new_subscription_public/',
                                    {
                                        shop_uuid: product.shop_uuid,
                                        customer_uuid: customer.uuid,
                                        product_uuid: product.uuid,
                                        delivery_config: selectedDeliveryOption,
                                        cron_config: selectedCronConfig,
                                        quantity,
                                        currency_code: currencyCode,
                                    }
                                )
                                    .then((res) => {
                                        message.success(
                                            translate(
                                                'subscription_successfully_created',
                                                language
                                            )
                                        );
                                        setLoading(false);
                                        onClose();
                                        onSuccess();
                                    })
                                    .catch((err) => {
                                        console.log('err', err);
                                        message.error(
                                            err.response.data.message ||
                                                translate(
                                                    'error_came_up',
                                                    language
                                                )
                                        );
                                        setLoading(false);
                                    });
                            }}
                        >
                            {translate('confirm', language)}
                        </Button>
                    </Space>
                </div>
            )}
        </Modal>
    );
}
