import { Button, Form, InputNumber, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import Shop from '../../../interfaces/shop';
import axios from 'axios';
import Product from '../../../interfaces/product';
import {
    ThunderboltOutlined,
    ControlOutlined,
    ToolOutlined,
    PlusOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';

type Props = {
    shop: Shop;
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
    uuid?: string;
};

export default function ActionFormModal({
    shop,
    open,
    onCancel,
    onSuccess,
    uuid,
}: Props) {
    const [form] = Form.useForm();
    const [products, setProducts] = useState<Product[]>([]);
    const [reRender, setReRender] = useState(true);

    const isEdit = !!uuid;

    useEffect(() => {
        axios
            .get('/verslun/api/product/?shop__uuid=' + shop.uuid)
            .then((res) => {
                setProducts(res.data.results);
            });
    }, [shop]);

    useEffect(() => {
        if (uuid) {
            axios.get('/action/trigger_details/' + uuid + '/').then((res) => {
                let trigger = res.data;

                trigger.products = trigger.products.map(
                    (product: Product) => product.id
                );
                trigger.actions = trigger.actions.map((action: any) => {
                    action.products = action.products.map(
                        (product: Product) => product.id
                    );

                    return action;
                });

                form.setFieldsValue(trigger);
            });
        }
    }, [uuid]);

    return (
        <Modal
            open={open}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            footer={false}
            className="roundedBorders"
            title={uuid ? 'Breyta reglu' : 'Búa til reglu'}
        >
            <Form
                size="large"
                layout="vertical"
                form={form}
                onFinish={(values) => {
                    values.shop = shop.id;

                    if (isEdit) {
                        axios
                            .patch(
                                '/action/trigger/update/' + uuid + '/',
                                values
                            )
                            .then(() => {
                                onCancel();
                                onSuccess();
                            });
                    } else {
                        axios
                            .post('/action/trigger/create/', values)
                            .then(() => {
                                onCancel();
                                onSuccess();
                            });
                    }
                }}
                onValuesChange={() => {
                    setReRender(!reRender);
                }}
            >
                <div
                    style={{
                        padding: 20,
                        marginTop: 20,
                        border: '1px solid #dedede',
                        borderRadius: 10,
                    }}
                >
                    <h3 style={{ fontWeight: 'bold' }}>
                        <ThunderboltOutlined /> Atburður
                    </h3>

                    <Form.Item
                        name="trigger_event"
                        label="Atburður"
                        rules={[
                            {
                                required: true,
                                message: 'Þessi reitur er nauðsynlegur',
                            },
                        ]}
                    >
                        <Select
                            children={[
                                <Select.Option
                                    key="SUBSCRIPTION_TRANSACTION_CREATED"
                                    value="SUBSCRIPTION_TRANSACTION_CREATED"
                                >
                                    Áskriftarfærsla stofnuð (fjöldi)
                                </Select.Option>,
                            ]}
                        ></Select>
                    </Form.Item>

                    <Form.Item
                        name="products"
                        label="Hvaða áskrift?"
                        rules={[
                            {
                                required: true,
                                message: 'Þessi reitur er nauðsynlegur',
                            },
                        ]}
                    >
                        <Select mode="multiple">
                            {products.map((product) => (
                                <Select.Option
                                    key={product.uuid}
                                    value={product.id}
                                >
                                    {product.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div
                    style={{
                        padding: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        border: '1px solid #dedede',
                        borderRadius: 10,
                    }}
                >
                    <h3 style={{ fontWeight: 'bold' }}>
                        <ControlOutlined /> Skilyrði
                    </h3>
                    <Form.Item
                        name="trigger_check"
                        label="Hvað á að athuga?"
                        rules={[
                            {
                                required: true,
                                message: 'Þessi reitur er nauðsynlegur',
                            },
                        ]}
                    >
                        <Select
                            children={[
                                <Select.Option
                                    key="SHIPMENT_COUNT"
                                    value="SHIPMENT_COUNT"
                                >
                                    Fjöldi sendinga
                                </Select.Option>,
                            ]}
                        ></Select>
                    </Form.Item>

                    <Form.Item
                        name="trigger_value"
                        label="Viðmiðunargildi"
                        rules={[
                            {
                                required: true,
                                message: 'Þessi reitur er nauðsynlegur',
                            },
                        ]}
                    >
                        <InputNumber></InputNumber>
                    </Form.Item>

                    <Form.Item
                        name="trigger_operator"
                        label="Skilyrði"
                        rules={[
                            {
                                required: true,
                                message: 'Þessi reitur er nauðsynlegur',
                            },
                        ]}
                    >
                        <Select
                            children={[
                                <Select.Option
                                    key="LESS_THAN"
                                    value="LESS_THAN"
                                >
                                    Minna en
                                </Select.Option>,
                                <Select.Option
                                    key="GREATER_THAN"
                                    value="GREATER_THAN"
                                >
                                    Stærra en
                                </Select.Option>,
                                <Select.Option key="EQUALS" value="EQUALS">
                                    Jafnt og
                                </Select.Option>,
                            ]}
                        ></Select>
                    </Form.Item>
                </div>

                <div
                    style={{
                        padding: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        border: '1px solid #dedede',
                        borderRadius: 10,
                    }}
                >
                    <h3 style={{ fontWeight: 'bold' }}>
                        <ToolOutlined /> Aðgerðir
                    </h3>

                    <Form.List name="actions">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div
                                        key={key}
                                        style={{
                                            padding: 20,
                                            marginTop: 20,
                                            marginBottom: 20,
                                            border: '1px solid #dedede',
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'slug']}
                                            label={
                                                <div>
                                                    Aðgerð{' '}
                                                    <MinusCircleOutlined
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                    />
                                                </div>
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Þessi reitur er nauðsynlegur',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Select.Option
                                                    key="CHANGE_PRODUCT"
                                                    value="CHANGE_PRODUCT"
                                                >
                                                    Breyta í aðra vöru
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        {form.getFieldValue([
                                            'actions',
                                            name,
                                            'slug',
                                        ]) === 'CHANGE_PRODUCT' && (
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'products']}
                                                label="Í hvaða vöru viltu breyta?"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Þessi reitur er nauðsynlegur',
                                                    },
                                                ]}
                                            >
                                                <Select mode="multiple">
                                                    {products.map((product) => (
                                                        <Select.Option
                                                            key={product.uuid}
                                                            value={product.id}
                                                        >
                                                            {product.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        size="small"
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        Bæta við aðgerð
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </div>

                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Vista
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
