import { Button, Form, Input, Space, Table, message } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ContentTag } from '../../interfaces/content';
import Shop from '../../interfaces/shop';

type Props = { shop: Shop };

export default function ManageTags({ shop }: Props) {
    const [tags, setTags] = useState<ContentTag[]>([]);
    const [edit, setEdit] = useState<ContentTag | false>(false);
    const [deleteTag, setDeleteTag] = useState<ContentTag | false>(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        getTags();
    }, []);

    const getTags = () => {
        axios.get(`/efni/api/tags/${shop.uuid}/`).then((rsp) => {
            setTags(rsp.data);
        });

        return () => {
            setTags([]);
            setEdit(false);
        };
    };

    return (
        <div>
            {edit && (
                <div>
                    <div className="text-center">
                        <h2>Breyta</h2>
                    </div>

                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={(values) => {
                            axios
                                .patch(`/efni/api/tag/update/${edit.id}/`, {
                                    ...values,
                                })
                                .then(() => {
                                    message.success(
                                        'Tókst, flokki hefur verið breytt!'
                                    );
                                    setEdit(false);
                                    getTags();
                                });
                        }}
                    >
                        <Form.Item name="title" label="Titill">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="slug"
                            label="Tagg (engin bil eða íslenskir stafir)"
                        >
                            <Input />
                        </Form.Item>

                        <Button
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                        >
                            Uppfæra
                        </Button>

                        <div>
                            <div className="text-center">
                                <br />
                                <Button
                                    style={{ color: 'gray' }}
                                    type="text"
                                    onClick={() => setEdit(false)}
                                    size="small"
                                >
                                    Hætta við að breyta
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}

            {deleteTag && (
                <>
                    <h2>Eyða flokk</h2>
                    <p>Alveg viss? Þessi aðgerð er ekki afturkræf</p>

                    <Space>
                        <Button
                            loading={loading}
                            danger
                            onClick={() => {
                                setLoading(true);

                                axios
                                    .delete(
                                        `/efni/api/tag/delete/${deleteTag.id}/`
                                    )
                                    .then((rsp) => {
                                        message.success(
                                            'Tókst, flokki hefur verið eytt!'
                                        );
                                        setDeleteTag(false);
                                        getTags();
                                    })
                                    .catch((err) => {
                                        message.error(
                                            'Villa kom upp, ekki tókst að eyða flokki'
                                        );
                                        setLoading(false);
                                        setDeleteTag(false);
                                    });
                            }}
                        >
                            Já, eyða
                        </Button>
                        <Button
                            onClick={() => setDeleteTag(false)}
                            loading={loading}
                        >
                            Nei, hætta við
                        </Button>
                    </Space>
                </>
            )}

            {!deleteTag && !edit && (
                <Table
                    size="small"
                    dataSource={tags}
                    columns={[
                        {
                            title: 'Titill',
                            dataIndex: 'title',
                            key: 'title',
                        },
                        {
                            title: 'Tagg',
                            dataIndex: 'slug',
                            key: 'slug',
                        },
                        {
                            title: 'Breyta',
                            render: (tag) => (
                                <Space>
                                    <EditOutlined
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setEdit(tag);
                                            form.setFieldsValue(tag);
                                        }}
                                    />

                                    <DeleteOutlined
                                        style={{
                                            color: 'red',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setDeleteTag(tag)}
                                    />
                                </Space>
                            ),
                            align: 'center',
                        },
                    ]}
                    pagination={false}
                ></Table>
            )}
        </div>
    );
}
