import { useEffect, useState } from 'react';
import Axios from 'axios';
import { SubscriptionTransaction } from '../../interfaces/transaction';
import { Alert, Badge, Card, Space } from 'antd';
import dayjs from 'dayjs';
import { kr } from '../../utils';

type Props = {
    subscriptionTransaction: SubscriptionTransaction;
};

interface ResData {
    batch_status:
        | 'Í bókunarferli'
        | 'Bókaður'
        | 'Bókað (með villum)'
        | 'Óstaðfest';
    amount: number;
    claim_error?: {
        code?: string;
        message?: string;
    };
    claim_successfully_created: boolean;
    created: string;
    due_date: string;
}

export default function TransactionClaimStatus(props: Props) {
    const [resData, setResData] = useState<ResData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.subscriptionTransaction &&
            Axios.post(
                '/payment/api/get_subscription_transaction_claim_statuses/',
                {
                    subscription_transaction_uuid:
                        props.subscriptionTransaction.uuid,
                }
            ).then((rsp) => {
                setLoading(false);
                setResData(rsp.data);
            });
    }, []);

    return (
        <div>
            <Space direction="vertical">
                <div>
                    <div>
                        <strong>
                            Kröfur v. sendingar (
                            {kr(props.subscriptionTransaction.amount)})
                        </strong>
                    </div>
                    <div>
                        {props.subscriptionTransaction.subscription.customer
                            .ssid
                            ? 'Kennitala: ' +
                              props.subscriptionTransaction.subscription
                                  .customer.ssid
                            : ''}
                    </div>
                </div>
                {loading && (
                    <Card
                        style={{ minWidth: 300 }}
                        size="small"
                        title="Sæki upplýsingar frá banka..."
                        loading={true}
                    ></Card>
                )}
                {resData.map((obj, i) => {
                    return (
                        <Card
                            title="Stofnupplýsingar"
                            size="small"
                            extra={kr(obj.amount)}
                        >
                            <Space>
                                <div>
                                    <Badge
                                        status={
                                            obj.claim_successfully_created
                                                ? 'success'
                                                : 'error'
                                        }
                                        text={
                                            obj.claim_successfully_created
                                                ? 'Stofnun kröfu tókst'
                                                : 'Villa við stofnun kröfu:'
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: 13,
                                        color: 'gray',
                                        fontSize: 12,
                                    }}
                                >
                                    <div>
                                        {`Stofnuð : ${dayjs(obj.created).format(
                                            'DD. MMM YYYY'
                                        )}`}
                                    </div>
                                    <div>
                                        {`Gjalddagi : ${dayjs(
                                            obj.due_date
                                        ).format('DD. MMM YYYY')}`}
                                    </div>
                                </div>
                            </Space>
                            {obj.claim_error && (
                                <Alert
                                    style={{ marginTop: 10, borderRadius: 10 }}
                                    type="error"
                                    description={
                                        <Space direction="vertical">
                                            <div>{obj.claim_error.code}</div>
                                            <div>{obj.claim_error.message}</div>
                                        </Space>
                                    }
                                    message="Villa"
                                ></Alert>
                            )}
                        </Card>
                    );
                })}
            </Space>
        </div>
    );
}
