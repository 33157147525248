import { createContext } from 'react';
import User from './interfaces/user';
import Shop from './interfaces/shop';

interface Ctxt {
    user?: User;
    shops?: Shop[];
    selectedShop?: Shop;
    setSelectedShop?: (shop: Shop) => void;
    shopsLoading?: boolean;
    userScopes?: string[] | 'ADMIN';
}

const AuthContext = createContext<Ctxt>({});

export default AuthContext;
