import { useEffect, useState } from 'react';
import { Select, InputNumber, Popover } from 'antd';
import translate, { LanguageKey } from '../../translate/translate';

import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
    editable?: boolean;
    yearly: boolean | undefined;
    monthly: boolean | undefined;
    weekly: boolean | undefined;
    onChange: Function;
    lang?: LanguageKey;
    showTitle?: boolean;
    initalFrequency?: number;
    initalFrequencyType?: 'MONTH' | 'WEEK' | 'YEAR';
    weeklyOptions?: number[];
    monthlyOptions?: number[];
    yearlyOptions?: number[];
    inputSize?: 'small' | 'middle' | 'large';
    align?: 'left' | 'center' | 'right';
};

export default function SubscriptionCronSettings(props: Props) {
    const disabled =
        props.editable === undefined || props.editable === null
            ? false
            : !props.editable;

    const { lang = 'is', showTitle = true } = props;
    const { yearly, monthly, weekly, onChange } = props;
    const [selectedFrequency, setSelectedFrequency] = useState<number | ''>(
        props.initalFrequency || ''
    );
    const [selectedFrequencyType, setSelectedFrequencyType] = useState(
        props.initalFrequencyType
    );

    const [availableOptions, setAvailableOptions] = useState<
        number[] | undefined
    >();

    useEffect(() => {
        onChange({
            interval: selectedFrequency,
            interval_type: selectedFrequencyType,
            // base_date: new Date().toISOString(),
            // base_date_is_signup_date: true,
        });
    }, [selectedFrequency, selectedFrequencyType]);

    useEffect(() => {
        if (props.initalFrequency && !selectedFrequency)
            setSelectedFrequency(props.initalFrequency);
        if (props.initalFrequencyType && !selectedFrequencyType)
            setSelectedFrequencyType(props.initalFrequencyType);

        if (!props.initalFrequencyType && !selectedFrequencyType) {
            if ([weekly, monthly, yearly].filter((x) => x).length === 1) {
                if (weekly) {
                    setSelectedFrequencyType('WEEK');
                }
                if (monthly) {
                    setSelectedFrequencyType('MONTH');
                }
                if (yearly) {
                    setSelectedFrequencyType('YEAR');
                }
            }
        }
    }, [props.initalFrequency, props.initalFrequencyType, selectedFrequency]);

    useEffect(() => {
        if (selectedFrequencyType === 'MONTH') {
            setAvailableOptions(
                props.monthlyOptions && props.monthlyOptions.length
                    ? props.monthlyOptions
                    : undefined
            );
        }

        if (selectedFrequencyType === 'YEAR') {
            setAvailableOptions(
                props.yearlyOptions && props.yearlyOptions.length
                    ? props.yearlyOptions
                    : undefined
            );
        }

        if (selectedFrequencyType === 'WEEK') {
            setAvailableOptions(
                props.weeklyOptions && props.weeklyOptions?.length
                    ? props.weeklyOptions
                    : undefined
            );
        }
    }, [selectedFrequencyType]);

    useEffect(() => {
        if (availableOptions && availableOptions.length)
            if (!selectedFrequency) {
                setSelectedFrequency(availableOptions[0]);
            }
    }, [availableOptions]);

    return (
        <>
            {(yearly || monthly || weekly) && (
                <div
                    className="attributeContainer"
                    style={{ textAlign: props.align || 'center' }}
                >
                    {showTitle && (
                        <h4 className="attributeLabel">
                            <Popover
                                title={
                                    <h3 className="mb0">
                                        {translate('shipment_frequecy', lang)}
                                    </h3>
                                }
                                content={translate(
                                    'shipment_frequency_tooltip',
                                    lang
                                )}
                            >
                                <QuestionCircleOutlined />{' '}
                            </Popover>
                            {translate('shipment_frequecy', lang)}
                        </h4>
                    )}
                    {
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: props.align || 'center',
                                gap: 5,
                            }}
                        >
                            {availableOptions ? (
                                <Select
                                    size={props.inputSize || 'middle'}
                                    disabled={disabled}
                                    value={selectedFrequency}
                                    onChange={(nr) => {
                                        setSelectedFrequency(nr);
                                    }}
                                    children={availableOptions.map(
                                        (nr, key) => {
                                            return (
                                                <Select.Option
                                                    key={key}
                                                    value={nr}
                                                >
                                                    {nr}
                                                </Select.Option>
                                            );
                                        }
                                    )}
                                ></Select>
                            ) : (
                                <InputNumber
                                    size={props.inputSize || 'middle'}
                                    disabled={disabled}
                                    value={selectedFrequency}
                                    onChange={(
                                        val: number | undefined | string | null
                                    ) => {
                                        if (val) {
                                            const nr = Number(val);
                                            if (isNaN(nr) || nr < 1) {
                                                setSelectedFrequency(1);
                                            } else {
                                                setSelectedFrequency(nr);
                                            }
                                        }
                                    }}
                                ></InputNumber>
                            )}{' '}
                            <Select
                                size={props.inputSize || 'middle'}
                                disabled={disabled}
                                style={{ minWidth: 95 }}
                                defaultValue={props.initalFrequencyType}
                                value={selectedFrequencyType}
                                onChange={(
                                    value: 'YEAR' | 'MONTH' | 'WEEK'
                                ) => {
                                    setSelectedFrequencyType(value);

                                    if (value === 'WEEK') {
                                        if (
                                            props.weeklyOptions &&
                                            props.weeklyOptions.length
                                        ) {
                                            if (
                                                !selectedFrequency ||
                                                !props.weeklyOptions.includes(
                                                    selectedFrequency
                                                )
                                            ) {
                                                setSelectedFrequency(
                                                    props.weeklyOptions[0]
                                                );
                                            }
                                        }
                                    }

                                    if (value === 'MONTH') {
                                        if (
                                            props.monthlyOptions &&
                                            props.monthlyOptions.length
                                        ) {
                                            if (
                                                !selectedFrequency ||
                                                !props.monthlyOptions.includes(
                                                    selectedFrequency
                                                )
                                            ) {
                                                setSelectedFrequency(
                                                    props.monthlyOptions[0]
                                                );
                                            }
                                        }
                                    }

                                    if (value === 'YEAR') {
                                        if (
                                            props.yearlyOptions &&
                                            props.yearlyOptions.length
                                        ) {
                                            if (
                                                !selectedFrequency ||
                                                !props.yearlyOptions.includes(
                                                    selectedFrequency
                                                )
                                            ) {
                                                setSelectedFrequency(
                                                    props.yearlyOptions[0]
                                                );
                                            }
                                        }
                                    }
                                }}
                            >
                                {yearly && (
                                    <Select.Option value="YEAR">
                                        {translate('years', lang)}
                                    </Select.Option>
                                )}

                                {monthly && (
                                    <Select.Option value="MONTH">
                                        {translate('months', lang)}
                                    </Select.Option>
                                )}

                                {weekly && (
                                    <Select.Option value="WEEK">
                                        {translate('weeks', lang)}
                                    </Select.Option>
                                )}
                            </Select>
                            <span>
                                {' '}
                                {translate('shipment_frequency_suffix', lang)}
                            </span>
                        </div>
                    }
                </div>
            )}
        </>
    );
}
