import React from 'react';
import Product from '../../interfaces/product';
import { PublicSubscription } from '../../interfaces/subscription';
import { Card } from 'antd';
import translate, { LanguageKey } from '../../translate/translate';

type Props = {
    products: Product[];
    subscriptions: PublicSubscription[];
    language: LanguageKey;
};

export default function AddOns({ products, subscriptions, language }: Props) {
    const subscriptionProductUUIDs = subscriptions.map((s) => s.product.uuid);
    let addOnProducts = products
        .filter((product) => product.type === 'ADD_ON')
        .filter((addonProduct) =>
            addonProduct.add_on_available_for?.some((uuid) =>
                subscriptionProductUUIDs.includes(uuid)
            )
        );

    return addOnProducts.length > 0 ? (
        <Card title={translate('add_on', language)}>
            <p>Viltu bæta við næstu sendingu?</p>
            <div>
                {addOnProducts.map((p) => (
                    <>{p.title}</>
                ))}
            </div>
        </Card>
    ) : (
        <></>
    );
}
