import React, { useState } from 'react';
import { Form, Card, Input, Button, Select, Switch, message } from 'antd';
import Axios from 'axios';

// Typescript
import Product from '../../interfaces/product';

type Props = {
    product: Product;
};

export default function ProductWoocommerceSettings(props: Props) {
    const woocommerce = props.product.woocommerce_info;
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     Axios.get(`/verslun/api/product/${props.product.uuid}/`).then((rsp) => {
    //         console.log(rsp);
    //     }, []);
    // });

    return (
        <div>
            <div className="text-right">
                <Button
                    onClick={() => {
                        Axios.get(
                            `/askrift/get_all_woocommerce_products/${props.product.shop_uuid}/`
                        );
                    }}
                >
                    Woocommerce vörulisti
                </Button>
            </div>

            <Form
                layout="vertical"
                onFinish={(values) => {
                    Axios.post(
                        '/verslun/api/create_or_update_product_woocommerce_settings/',
                        {
                            product_uuid: props.product.uuid,
                            ...values,
                        }
                    )
                        .then(() => {
                            message.success('Tókst! 🥳');
                        })
                        .catch(() => {
                            message.error('Það kom upp villa :/');
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }}
                initialValues={{
                    product_id: woocommerce?.product_id,
                    create_woocommerce_order:
                        woocommerce?.create_woocommerce_order || false,
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label="Product ID"
                    name="product_id"
                >
                    <Input size="large" autoComplete="off" />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label='Viltu búa til Woocommerce "order" þegar þessi vara er seld?'
                    name="create_woocommerce_order"
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren="JÁ"
                        unCheckedChildren="NEI"
                    ></Switch>
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Vista
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
