import { useState, useEffect, useCallback } from 'react';
import { Select } from 'antd';
import Shop from '../../interfaces/shop';
import Axios from 'axios';
import Product from '../../interfaces/product';
import { debounce } from 'lodash';

type Props = {
    shop: Shop;
    multiple?: boolean;
} & (
    | { multiple: true; onChange: (value: Product[]) => void }
    | { multiple?: false; onChange: (value: Product) => void }
);
export default function ProductSearchBox(props: Props) {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [waitingForHandler, setWaitingForHandler] = useState(false);

    useEffect(() => {
        // Fetch initial products on component mount
        getProducts();
    }, []);

    useEffect(() => {
        // Fetch products when searchTerm changes
        if (searchTerm !== '') {
            getProducts();
        } else {
            // When search term is cleared, fetch initial products
            getProducts();
        }
    }, [searchTerm]);

    const searchProducts = (term: string) => {
        setSearchTerm(term);
    };

    const getProducts = () => {
        setLoading(true);
        let url =
            '/verslun/api/admin_product/?shop__uuid=' +
            props.shop.uuid +
            '&ordering=-created';
        let pageSize = searchTerm ? 100 : 10;

        if (searchTerm) {
            url += '&search=' + searchTerm;
        }

        url += '&page_size=' + pageSize;
        Axios.get(url)
            .then((rsp) => {
                setProducts(rsp.data.results);
                setLoading(false);
                setWaitingForHandler(false);
            })
            .catch(() => {
                setLoading(false);
                setWaitingForHandler(false);
            });
    };

    const handler = useCallback(debounce(searchProducts, 400), []);

    const getSelectedProductsDetails = (uuids: string[]) => {
        let promises = [];

        for (let uuid of uuids) {
            promises.push(Axios.get(`/verslun/api/product/${uuid}/`));
        }

        return Promise.all(promises);
    };

    return (
        <Select
            mode={props.multiple ? 'multiple' : undefined}
            loading={loading || waitingForHandler}
            onChange={(value) => {
                if (props.multiple) {
                    if (value.length === 0) {
                        props.onChange([]);
                        return;
                    } else {
                        getSelectedProductsDetails(value).then((rsp) => {
                            props.onChange(rsp.map((r) => r.data));
                        });
                    }
                } else {
                    getSelectedProductsDetails([value]).then((rsp) =>
                        props.onChange(rsp[0].data)
                    );
                }
            }}
            options={products.map((product) => ({
                value: product.uuid,
                label: product.title,
                key: product.uuid,
            }))}
            showSearch
            style={{
                width: '100%',
            }}
            placeholder="Leita"
            onSearch={(term) => {
                setWaitingForHandler(true);

                if (term && term.length > 1) {
                    handler(term);
                } else {
                    setSearchTerm(''); // Clear search term to trigger initial load
                    setWaitingForHandler(false);
                }
            }}
            filterOption={false} // Disable default filtering to rely on server-side search
        />
    );
}
