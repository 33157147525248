import { useEffect, useState } from 'react';
import axios from 'axios';
import Shop from '../../interfaces/shop';
import { Badge, Button, Modal, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import LogDetails from './LogDetails';
import { RequestLogOverview } from '../../interfaces/requestLog';
dayjs.locale('is'); // use locale

type Props = {
    service?: RequestLogOverview['service'];
    shop?: Shop;
    admin: boolean;
    customer?: string;
    hiddenColumns?: string[];
};

export default function LogOverview(props: Props) {
    const [apiResponses, setApiResponses] = useState<RequestLogOverview[]>();
    const [featured, setFeatured] = useState<RequestLogOverview | false>(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedPage)
            axios
                .get(
                    `/api/external_request_logs?service=${
                        props.admin ? '' : props.service || ''
                    }&shop__uuid=${
                        props.admin ? '' : props.shop?.uuid
                    }&ordering=-created&page=${selectedPage}&page_size=${50}${
                        props.customer ? '&customers=' + props.customer : ''
                    }`
                )
                .then((rsp) => {
                    setApiResponses(rsp.data.results);
                    setCount(rsp.data.count);
                    setLoading(false);
                });
    }, [selectedPage]);

    let columns = [
        {
            title: 'Status',
            key: 'status',
            render: (item: RequestLogOverview) => {
                return (
                    <>
                        <Badge
                            status={item.success ? 'success' : 'error'}
                        ></Badge>{' '}
                        {item.status_code}
                    </>
                );
            },
        },
        {
            title: 'Aðferð',
            key: 'method',
            render: (item: RequestLogOverview) => {
                return item.method;
            },
        },
        {
            title: 'Tegund',
            key: 'service',
            render: (item: RequestLogOverview) => {
                return (
                    <div>
                        <div>
                            <Tooltip title={item.url}>
                                <div>
                                    <span>
                                        {(item.service || '').split('')[0]}
                                    </span>
                                    <span
                                        style={{
                                            textTransform: 'lowercase',
                                        }}
                                    >
                                        {item.service
                                            ?.split('')
                                            .slice(1)
                                            .join('')}
                                    </span>
                                </div>
                            </Tooltip>
                        </div>

                        {props.admin && (
                            <small>
                                <Button
                                    href={'/stjornbord/' + item.shop.uuid}
                                    size="small"
                                    type="text"
                                    style={{
                                        textAlign: 'left',
                                        padding: 0,
                                        fontSize: 12,
                                        color: 'gray',
                                    }}
                                >
                                    {item.shop.name}
                                </Button>
                            </small>
                        )}

                        <div>
                            <small style={{ color: 'gray' }}>
                                {dayjs(item.created).format(
                                    'D. MMM YYYY - HH:mm'
                                )}
                            </small>
                        </div>
                    </div>
                );
            },
            className: 'logUrl',
        },

        {
            key: 'payload',
            render: (item: RequestLogOverview) => {
                return (
                    <Button
                        size="small"
                        onClick={() => {
                            setFeatured(item);
                        }}
                    >
                        Nánar
                    </Button>
                );
            },
        },
        // {
        //     title: 'Viðskiptavinur',
        //     render: (item: RequestLogOverview) => {
        //         return item.customer ? (
        //             <Button href={`/vidskiptavinur/${item.customer}/`}>
        //                 <img
        //                     style={{ width: 23 }}
        //                     src="/static/images/svg/user.svg"
        //                     alt="User"
        //                 />
        //             </Button>
        //         ) : (
        //             ''
        //         );
        //     },
        // },
    ];

    if (props.hiddenColumns) {
        columns = columns.filter(
            (column) => !(props.hiddenColumns || []).includes(column.key)
        );
    }

    // if (props.admin)
    //     columns.unshift({
    //         title: 'Fyrirtæki',
    //         render: (item: RequestLogOverview) => {
    //             console.log('item', item);
    //             return item.shop.title;
    //         },
    //     });

    return (
        <>
            <Table
                id="logOverviewTable"
                className="white_table"
                loading={loading}
                dataSource={apiResponses}
                pagination={{
                    hideOnSinglePage: true,
                    defaultPageSize: 50,
                    showSizeChanger: false,
                    total: count,
                    onChange: (pageNumber) => {
                        setLoading(true);
                        setSelectedPage(pageNumber);
                    },
                }}
                columns={columns}
            />

            <Modal
                onCancel={() => {
                    setFeatured(false);
                }}
                open={!!featured}
                footer={null}
            >
                <div style={{ width: '100%' }}>
                    {!!featured && <LogDetails log={featured}></LogDetails>}
                </div>
            </Modal>
        </>
    );
}
