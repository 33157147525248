import { useEffect, useState } from 'react';
import { Button, Alert, Space, Card, Tabs } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import Axios from 'axios';
import Shop from '../../interfaces/shop';
import LogOverview from '../../components/LogOverview/LogOverview';

const { TabPane } = Tabs;

interface Props {
    shop: Shop;
}

export default function LogOverviewPage({ shop }: Props) {
    return (
        <>
            <Tabs>
                <>
                    <TabPane tab="Allt" key="all">
                        <LogOverview admin={false} shop={shop}></LogOverview>
                    </TabPane>

                    <TabPane tab="Vefkrókar" key="webhooks">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="WEBHOOK"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Shopify" key="shopify">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="SHOPIFY"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Woocommerce" key="woocommerce">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="WOOCOMMERCE"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="DK" key="dk">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="DK"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Open banking" key="openbanking">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="OPENBANKING"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Dropp" key="dropp">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="DROPP"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Pósturinn" key="posturinn">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="POSTURINN"
                        ></LogOverview>
                    </TabPane>

                    <TabPane tab="Payday" key="payday">
                        <LogOverview
                            admin={false}
                            shop={shop}
                            service="PAYDAY"
                        ></LogOverview>
                    </TabPane>
                </>
            </Tabs>
        </>
    );
}
