import { useState } from 'react';
import { Button, Modal, Form, Switch, Alert } from 'antd';
import Axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { SubscriptionTransactionFilterRequest } from '../../../interfaces/transaction';

type Props = {
    onSuccess: (obj: Record<string, boolean | string>) => void;
    onCancel: () => void;
    open: boolean;
    title: string;
    options: {
        mark_fulfilled?: boolean;
        mark_paid?: boolean;
        comment?: boolean;
    };
    filterRequest: SubscriptionTransactionFilterRequest;
};

interface FormValues {
    is_paid?: boolean;
    is_fulfilled?: boolean;
    staff_comment?: string;
}

export default function EditTransactionsModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [isPaid, setIsPaid] = useState(props.options.mark_paid || false);
    const [isFulfilled, setIsFulfilled] = useState(
        props.options.mark_fulfilled || false
    );

    const { mark_paid, mark_fulfilled } = props.options;

    const [form] = Form.useForm();

    const onFinish = (values: FormValues) => {
        setLoading(true);

        const obj: Record<string, boolean | string> = {};

        Object.keys(values).forEach((key) => {
            if (key === 'staff_comment' && values.staff_comment) {
                obj.staff_comment = values.staff_comment;
            } else if (key !== 'staff_comment') {
                obj[key] = !!values[key as keyof FormValues];
            }
        });

        Axios.post('/askrift/update_subscription_transactions/', {
            ...obj,
            selection: props.filterRequest,
        }).then(() => {
            setLoading(false);
            props.onSuccess(obj);
        });
    };

    return (
        <Modal
            open={props.open}
            maskClosable={false}
            title={props.title}
            onCancel={props.onCancel}
            footer={false}
        >
            {mark_paid || (mark_fulfilled && <h3>Merkja sem:</h3>)}

            {mark_paid && (
                <Alert
                    message='ATH. Þú ert aðeins að MERKJA sem greitt/ógreitt. Veldu
                frekar "Rukka kort" ef þú vilt rukka og merkja sem greitt.'
                    type="warning"
                />
            )}
            <br />

            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                name="basic"
            >
                {mark_paid && (
                    <Form.Item
                        label="Greitt"
                        name="is_paid"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    is_paid: checked,
                                });

                                setIsPaid(checked);
                            }}
                        />
                    </Form.Item>
                )}

                {props.options.mark_fulfilled && (
                    <Form.Item
                        label="Uppfyllt"
                        name="is_fulfilled"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    is_fulfilled: checked,
                                });

                                setIsFulfilled(checked);
                            }}
                        />
                    </Form.Item>
                )}

                {props.options.comment && (
                    <Form.Item
                        label={
                            'Athugasemd ' +
                            (mark_fulfilled || mark_paid ? '(má sleppa)' : '')
                        }
                        name="staff_comment"
                    >
                        <TextArea></TextArea>
                    </Form.Item>
                )}

                <h3>
                    {mark_paid || mark_fulfilled ? (
                        <>
                            Merkja {props.filterRequest.selectedCount} sendingar
                            sem:{' '}
                            <strong>
                                {' '}
                                {mark_paid
                                    ? isPaid
                                        ? '"greitt"'
                                        : '"ógreitt"'
                                    : isFulfilled
                                    ? '"uppfyllt"'
                                    : '"óuppfyllt"'}
                            </strong>
                            ?
                        </>
                    ) : (
                        <></>
                    )}
                </h3>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                        className="btn-success"
                        block
                        size="large"
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        Staðfesta
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
