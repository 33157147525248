import { ReactElement, useState } from 'react';
import { Button, message } from 'antd';
import { displayCurrencyPrice } from '../../utils';
import Axios from 'axios';
import Payment from '../../interfaces/payment';

interface Props {
    paymentTransaction: Payment;
    onCancel: () => void;
    onSuccess: () => void;
}

export default function RefundPaymentTransaction(props: Props): ReactElement {
    const [loading, setLoading] = useState(false);
    const amount = (props.paymentTransaction.amount || 1) / 100;

    return (
        <>
            <div>
                Ertu viss um að þú viljir endurgreiða þessa færslu? (
                {displayCurrencyPrice(
                    amount,
                    props.paymentTransaction.currency_code
                )}
                )
            </div>
            <br></br>
            <Button
                onClick={() => {
                    setLoading(true);
                    Axios.post('/payment/api/refund_payment_transaction/', {
                        id: props.paymentTransaction.id,
                    })
                        .then((rsp) => {
                            setLoading(false);
                            message.success(
                                'Tókst! Færslan hefur verið endurgreidd.'
                            );
                            props.onCancel();
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error(
                                'Því miður tókst ekki að endurgreiða þessa færslu.'
                            );
                            props.onCancel();
                        });
                }}
                size="large"
                type="primary"
                className="btn-success"
                block
                loading={loading}
            >
                Já, endurgreiða
            </Button>
        </>
    );
}
