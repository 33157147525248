import React, { useState } from 'react';
import { Button, Modal, Form, Switch, message } from 'antd';
import Axios from 'axios';
import { SubscriptionFilter } from '../../../interfaces/subscription';

interface Props {
    shop_uuid: string;
    filters: SubscriptionFilter;
    selectionList: string[];
    allSelected: boolean;
    onCancel: () => void;
    open: boolean;
    selectedCount: number;
    title: JSX.Element;
}

export default function CreateTransactionsModal(props: Props) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
        setLoading(true);

        const payload = {
            shop: props.shop_uuid,
            filters: props.filters,
            all_selected: props.allSelected,
            selection_list: props.selectionList,
            is_fulfilled: values.is_fulfilled,
            charge_payment: values.charge_payment,
            /* perform_payment_time: values.charge_payment ? (values.charge_right_away ? moment().toISOString() : values.date.toISOString()) : null, */
        };

        Axios.post('/askrift/create_subscription_transactions/', payload)
            .then((rsp) => {
                props.onCancel();
                message.success('Stofnun sendinga hefur verið sett af stað 🎉');
                message.success(
                    'Athugaðu að sendingar taka smá að verða til. Þú getur endurhlaðið síðunni eftir smá stund til að sjá þær.',
                    10
                );
            })
            .catch((err) => {
                message.error('Villa kom upp! 💀');
            });
    };

    return (
        <Modal
            footer={null}
            open={props.open}
            maskClosable={false}
            title="Stofna sendingar"
            onCancel={props.onCancel}
        >
            <>
                <div
                    style={{
                        background: '#f5f5f5',
                        borderRadius: '5px',
                        padding: '15px',
                        color: '#717171',
                    }}
                >
                    <>
                        Þú ert með <strong>{props.selectedCount}</strong>{' '}
                        {props.selectedCount > 1
                            ? 'áskriftir valdar'
                            : 'áskrift valda'}
                    </>
                </div>
                <br></br>

                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        initialValue={false}
                        label={'Merkja sem "uppfyllt"?'}
                        name="is_fulfilled"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    is_fulfilled: checked,
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={false}
                        label={'Rukka sendingar?'}
                        name="charge_payment"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    charge_payment: checked,
                                });
                            }}
                        />
                    </Form.Item>

                    {/* {form.getFieldValue('charge_payment') && (
                        <Form.Item initialValue={true} label='Rukka strax?' name='charge_right_away' valuePropName='checked'>
                            <Switch
                                checkedChildren='JÁ '
                                unCheckedChildren='NEI'
                                onChange={(checked) => {
                                    form.setFieldsValue({
                                        charge_right_away: checked,
                                    });
                                }}
                            />
                        </Form.Item>
                    )}

                    {form.getFieldValue('charge_right_away') === false && form.getFieldValue('charge_payment') && (
                        <Form.Item name='date' label='Hvenær?' rules={[{required: true, message: 'Þú verður að velja dagsetningu'}]}>
                            <DatePicker></DatePicker>
                        </Form.Item>
                    )} */}

                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            className="btn-success"
                            block
                            size="large"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Skrá
                        </Button>
                    </Form.Item>
                </Form>
            </>
        </Modal>
    );
}
