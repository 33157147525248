import React from 'react';
import Subscription from '../../../interfaces/subscription';
import { Modal } from 'antd';
import DeliveryChoices from '../../../components/SubscriptionCard/DeliveryChoices';
import languages, { LanguageKey } from '../../../translate/translate';

type Props = {
    open: boolean;
    onCancel: () => void;
    subscription: Subscription;
    onSuccess: (resData: any) => void;
    language?: LanguageKey;

    //Response if you want to call the server and get the response
    //Payload if you want to get the payload to make your own request (made this for the public facing page since the user is not logged in and i need to make a different kind of request)
    returnType?: 'response' | 'payload';
};

export default function SubscriptionDeliveryOptionModal({
    open,
    onCancel,
    subscription,
    onSuccess,
    returnType,
    language,
}: Props) {
    return (
        <Modal
            open={open}
            onCancel={() => onCancel()}
            footer={null}
            title={languages('delivery_method', language)}
        >
            <DeliveryChoices
                onSuccess={(resData) => onSuccess(resData)}
                subscription={subscription}
                returnType={returnType || 'response'}
            ></DeliveryChoices>
        </Modal>
    );
}
