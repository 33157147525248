import React from 'react';
import Email from '../../interfaces/email';
import Shop from '../../interfaces/shop';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { DomElement } from 'htmlparser2';

type Props = {
    email: Email;
    shop: Shop;
};

export default function SentEmailDetails({ email, shop }: Props) {
    const transform = (node: DomElement, index: number) => {
        if (node.type === 'tag' && node.name === 'svg') {
            if (node.attribs && node.attribs.viewbox) {
                const child = node.children[0];
                const { width, height, viewbox, preserveaspectratio } =
                    node.attribs;
                return (
                    <svg
                        key={index}
                        width={width}
                        height={height}
                        viewBox={viewbox}
                        preserveAspectRatio={preserveaspectratio}
                    >
                        {
                            convertNodeToElement(
                                child,
                                index,
                                transform
                            ) as React.ReactNode
                        }
                    </svg>
                );
            }
        }

        return convertNodeToElement(node, index, transform);
    };

    return (
        <>
            <h4>
                Sent á:{' '}
                {email.recipient_count > 2
                    ? email.recipient_count + ' aðila'
                    : (email.to.length ? email.to : email.bcc).join(', ')}
            </h4>

            <div>
                Fyrirsögn:
                <h4>{email.subject}</h4>
            </div>

            {shop && (
                <div style={{ border: '1px solid #d7d7d7', padding: 15 }}>
                    <div className="text-center" style={{ marginBottom: 20 }}>
                        <img
                            src={shop.logo.image}
                            alt="Logo"
                            style={{ maxWidth: 150 }}
                        />
                    </div>

                    <p>
                        {
                            ReactHtmlParser(email.html, {
                                transform: (node, index) => {
                                    return transform(node, index);
                                },
                            }) as React.ReactNode
                        }
                    </p>

                    <hr style={{ opacity: 0.3 }} />

                    <div
                        className="text-center"
                        style={{ fontSize: 11, marginTop: 20 }}
                    >
                        <div>
                            <strong>{shop.name}</strong>
                        </div>

                        <div>Kt: {shop.ssid}</div>
                    </div>
                </div>
            )}
            <br />
            <div style={{ fontSize: 10, textAlign: 'center' }}>
                ATH: Þetta er endurgerð á tölvupóstinum. Hann gæti litið
                smávægilega öðruvísi út raunverulega.
            </div>
        </>
    );
}
