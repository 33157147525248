import { useEffect, useState } from 'react';
import { CronConfig } from '../../interfaces/subscription';
import { Space, Calendar, Button, message, Card } from 'antd';

import moment from 'moment';
import 'moment/locale/is';
moment.locale('is');

import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import SubscriptionCronSettings from '../../components/SubscriptionCronSettings/SubscriptionCronSettings';
import Product from '../../interfaces/product';
import DeliveryChoicesForNewSubscription, {
    SelectedDeliveryOption,
} from './DeliveryChoicesForNewSubscription';
import translate, { LanguageKey } from '../../translate/translate';
dayjs.locale('is'); // use locale

export interface SelectedCronConfig {
    interval?: number;
    interval_type?: 'MONTH' | 'WEEK' | 'YEAR';
    base_date?: string;
    base_date_is_signup_date?: boolean;
    uuid?: string;
}

interface Props {
    onFinish: (
        selectedDeliveryOption?: SelectedDeliveryOption | undefined | null,
        selectedCronConfig?: SelectedCronConfig
    ) => void;
    product: Product;
    language: LanguageKey;
}

// interface ComponentMenuItem extends Props {
//     component: any;
// }

// interface ClickMenuItem extends Props {
//     click: any;
// }

// type MenuItem = ComponentMenuItem | ClickMenuItem;

export default function NewSubscriptionSettings({
    product,
    onFinish,
    language,
}: Props) {
    const [selectedCronConfig, setSelectedCronConfig] =
        useState<SelectedCronConfig>();
    const [selectedBaseDate, setSelectedBaseDate] = useState<string>();
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<
        SelectedDeliveryOption | undefined | null
    >();

    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (product.interval_editable) {
            setSelectedCronConfig({
                base_date_is_signup_date: false,
                base_date: moment().toISOString(),
            });
        }
    }, []);

    useEffect(() => {
        let deliveryConfReady = true;
        if (product.delivery_config) {
            if (selectedDeliveryOption) {
                deliveryConfReady = true;
            } else {
                deliveryConfReady = false;
            }
        }

        let intervalConfReady = true;
        if (product.interval_editable) {
            if (
                selectedCronConfig?.interval &&
                selectedCronConfig?.interval_type &&
                selectedCronConfig?.base_date
            ) {
                intervalConfReady = true;
            } else {
                intervalConfReady = false;
            }
        }

        setIsReadyToSubmit(deliveryConfReady && intervalConfReady);
    }, [selectedCronConfig, selectedBaseDate, selectedDeliveryOption]);

    return (
        <div>
            <Space direction="vertical" style={{ display: 'flex' }}>
                {product.interval_editable && (
                    <Card
                        title={translate('choose_frequency', language)}
                        className="noBorder"
                    >
                        <Space direction="vertical">
                            <SubscriptionCronSettings
                                lang={language}
                                yearly={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'YEAR'
                                    )
                                        ? true
                                        : false
                                }
                                monthly={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'MONTH'
                                    )
                                        ? true
                                        : false
                                }
                                weekly={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'WEEK'
                                    )
                                        ? true
                                        : false
                                }
                                weeklyOptions={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'WEEK'
                                    )?.count_options || undefined
                                }
                                monthlyOptions={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'MONTH'
                                    )?.count_options || undefined
                                }
                                yearlyOptions={
                                    product?.interval_combinations?.find(
                                        (c) => c.type === 'YEAR'
                                    )?.count_options || undefined
                                }
                                onChange={(config: CronConfig) => {
                                    if (!config) {
                                        setSelectedCronConfig({
                                            ...(selectedCronConfig || {}),
                                            interval: undefined,
                                            interval_type: undefined,
                                        });
                                        return;
                                    } else {
                                        setSelectedCronConfig({
                                            ...(selectedCronConfig || {}),
                                            interval: config.interval,
                                            interval_type: config.interval_type,
                                        });
                                    }
                                }}
                                showTitle={true}
                            ></SubscriptionCronSettings>
                        </Space>
                    </Card>
                )}

                {product.interval_editable && (
                    <Card
                        title={translate('choose_base_date', language)}
                        className="noBorder"
                    >
                        <div style={{ marginTop: 20 }}>
                            <>
                                <p>
                                    {translate(
                                        'transactions_are_from_date',
                                        language
                                    )}
                                </p>

                                <Calendar
                                    disabledDate={(current) => {
                                        return current.isBefore(
                                            moment()
                                                .subtract(1, 'day')
                                                .endOf('day')
                                        );
                                    }}
                                    defaultValue={moment()}
                                    fullscreen={false}
                                    onChange={(_moment) => {
                                        if (!_moment) {
                                            if (selectedCronConfig) {
                                                setSelectedCronConfig({
                                                    ...selectedCronConfig,
                                                    base_date: undefined,
                                                });
                                            }
                                            return;
                                        }
                                        setSelectedCronConfig({
                                            ...(selectedCronConfig || {}),
                                            base_date_is_signup_date: false,
                                            base_date: _moment.toISOString(),
                                        });
                                    }}
                                ></Calendar>
                            </>
                        </div>
                    </Card>
                )}

                {product.delivery_config && (
                    <Card
                        className="noBorder"
                        title={translate('choose_delivery_option', language)}
                    >
                        <DeliveryChoicesForNewSubscription
                            language={language}
                            onSuccess={(resData) =>
                                setSelectedDeliveryOption(resData)
                            }
                            product={product}
                        ></DeliveryChoicesForNewSubscription>
                    </Card>
                )}
                <>
                    <Button
                        size="large"
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            if (!isReadyToSubmit) {
                                message.error(
                                    'Þú verður að klára að fylla út allar upplýsingar'
                                );
                                return;
                            }

                            onFinish(
                                selectedDeliveryOption,
                                selectedCronConfig
                            );
                        }}
                        block
                    >
                        {translate('next_step', language)}
                    </Button>
                </>
            </Space>
        </div>
    );
}
