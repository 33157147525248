import { ParcelPointOption, PostboxOption } from '../../interfaces/delivery';
import Axios, { AxiosResponse } from 'axios';

interface UsePosturinn {
    getPostalCodeLocations: {
        (postalCode: string, type: 'DPO'): Promise<
            AxiosResponse<PostboxOption[]>
        >;
        (postalCode: string, type: 'DPT'): Promise<
            AxiosResponse<ParcelPointOption[]>
        >;
    };
}

const usePosturinn = (shopUuid: string): UsePosturinn => {
    const getPostalCodeLocations = (
        postalCode: string,
        type: 'DPO' | 'DPT'
    ) => {
        const url =
            type === 'DPO'
                ? '/shipping/api/posturinn_postboxes'
                : '/shipping/api/posturinn_parcelpoints';
        return Axios.post(url, {
            shop_uuid: shopUuid,
            postal_code: postalCode,
        });
    };

    return {
        getPostalCodeLocations,
    };
};

export default usePosturinn;
