import { useState } from 'react';
import SaltPay from '../../../components/SaltPay/SaltPay';
import { Button, DatePicker, Input } from 'antd';

type Props = {
    uuid: string;
};

export default function CardRequests({ uuid }: Props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState<[string, string] | null>(null);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    marginBottom: 10,
                    justifyContent: 'flex-end',
                }}
            >
                <DatePicker.RangePicker
                    onChange={(values) => {
                        const start = values
                            ? values[0]?.startOf('day').toISOString()
                            : null;
                        const end = values
                            ? values[1]?.endOf('day').toISOString()
                            : null;

                        if (start && end) {
                            setDateRange([start, end]);
                        } else {
                            setDateRange(null);
                        }
                    }}
                    bordered
                ></DatePicker.RangePicker>
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
                <Input.Search
                    onSearch={(term) => {
                        setSearchTerm(term);
                        console.log('searching for', term);
                    }}
                    allowClear
                ></Input.Search>
            </div>
            <SaltPay
                isPublic={false}
                url={
                    '/payment/api/payment_requests/?page_size=50&ordering=-created&shop_uuid=' +
                    uuid +
                    '&search=' +
                    searchTerm +
                    (dateRange
                        ? `&created__gte=${dateRange[0]}&created__lte=${dateRange[1]}`
                        : '')
                }
            ></SaltPay>
        </>
    );
}
