import './Customer.less';
import { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import {
    Modal,
    Card,
    Form,
    Tabs,
    Button,
    Space,
    message,
    Dropdown,
} from 'antd';
import {
    InfoCircleFilled,
    EditFilled,
    PlusCircleFilled,
    MailOutlined,
    UserOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import { getUrlParameter } from '../../utils';

/* Components */
// import EmailOverview from '../../components/EmailOverview/EmailOverview';
import CustomerTransactions from './CustomerTransactions/CustomerTransactions';
import EmailModal from '../../components/EmailModal/EmailModal';
import SavedCardsOverview from '../../components/SavedCardsOverview/SavedCardsOverview';
// import SaltPay from '../../components/SaltPay/SaltPay';
import CreateSubscription from '../../components/CreateSubscription/CreateSubscription';
// import BankingTransactionOverview from '../../components/BankingTransactionOverview/BankingTransactionOverview';
/* Interfaces */

import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import CustomerAppwriteDetails from './CustomerAppwriteDetails/CustomerAppwriteDetails';
import AuthContext from '../../authContext';
import translate from '../../translate/translate';
import CustomerTimeline from './CustomerTimeline/CustomerTimeline';
import CustomerCardInfo from './CustomerCardInfo/CustomerCardInfo';
import CustomerDetailsForm from './CustomerDetailsForm/CustomerDetailsForm';
import CustomerSubscriptions from './CustomerSubscriptions';
import Customer from '../../interfaces/customer';
import CustomerClaimsOverview from '../../components/CustomerClaimsOverview/CustomerClaimsOverview';
import CustomerWooMembershipDetails from './CustomerWooMembershipDetails/CustomerWooMembershipDetails';
import SupercastDetails from './SupercastDetails/SupercastDetails';

dayjs.locale('is'); // use locale
const { TabPane } = Tabs;

/* Declerations */
/* Types */
/* Interfaces */
interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;

export default function CustomerDetails() {
    /* Ref */
    const [form] = Form.useForm();

    const { setSelectedShop, user } = useContext(AuthContext);

    /* States */
    const [customer, setCustomer] = useState<Customer>();
    const [activeChanges, setActiveChanges] = useState<object | undefined>();
    const [addCustomerCommentModal, setAddCustomerCommentModal] =
        useState<Boolean>(false);
    const [newComment, setNewComment] = useState('');
    const [commentSaveLoading, setCommentSaveLoading] = useState(false);

    const [emailModal, setEmailModal] = useState<
        { subject?: string; body?: string } | boolean
    >(false);
    const [createSubscriptionModal, setCreateSubscriptionModal] =
        useState(false);
    const [savedCardsModal, setSavedCardsModal] = useState(false);
    const [editCustomer, setEditCustomer] = useState(false);
    const [customerClaimsModal, setCustomerClaimsModal] = useState(false);

    useEffect(() => {
        /* Get customer */
        const p1 = Axios.get(`/verslun/api/customer/${w.uuid}`).then((rsp) => {
            setCustomer(rsp.data);

            if (setSelectedShop) {
                setSelectedShop(rsp.data.shop);
            }

            form.setFieldsValue({
                ...rsp.data,
            });
        });

        const createNewSubscription = getUrlParameter('create_subscription');

        if (createNewSubscription) {
            p1.then(() => {
                setCreateSubscriptionModal(true);
            });
        }
    }, []);

    return (
        <>
            <Card
                id="adminCustomer"
                className="basePageCard noMaxWidth"
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <img
                                style={{ height: 40 }}
                                src="/static/images/svg/user.svg"
                                alt=""
                            />{' '}
                            <span className="capitalize">Viðskiptavinur</span>
                        </div>
                    </div>
                }
                extra={
                    <div>
                        {w.me === 1 && (
                            <Space>
                                <Button
                                    href={`/bakendi/shop/customer/${customer?.uuid}/change/`}
                                >
                                    Breyta
                                </Button>
                            </Space>
                        )}
                    </div>
                }
            >
                <div id="adminCustomerWrapper">
                    <div id="topLine">
                        <div id="topLineTop">
                            <Card
                                title={
                                    <>
                                        <UserOutlined /> Viðskiptavinur
                                    </>
                                }
                                extra={
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            setEditCustomer(true);
                                        }}
                                    >
                                        <EditFilled /> Breyta
                                    </Button>
                                }
                            >
                                <div className="customerDetails">
                                    <div className="customerDetailsInner">
                                        <div>
                                            <div>
                                                <label>Nafn</label>
                                            </div>

                                            {customer?.name}
                                        </div>
                                        <div>
                                            <div>
                                                <label>
                                                    {translate('email', 'is')}
                                                </label>
                                            </div>

                                            <span>{customer?.email}</span>
                                        </div>

                                        {customer?.phone && (
                                            <div>
                                                <label>
                                                    {translate('phone', 'is')}
                                                </label>
                                                <div>
                                                    <span>
                                                        {customer?.phone}
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        {customer?.ssid && (
                                            <div>
                                                <label>
                                                    {translate('ssid', 'is')}
                                                </label>
                                                <div>
                                                    <span>
                                                        {customer?.ssid}
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        {customer?.street && (
                                            <div>
                                                <label>
                                                    {translate('address', 'is')}
                                                </label>
                                                <div>
                                                    <span>
                                                        {customer?.street}

                                                        {customer.postal_code &&
                                                            customer.town && (
                                                                <>
                                                                    ,{' '}
                                                                    <span>
                                                                        {
                                                                            customer?.postal_code
                                                                        }{' '}
                                                                        {
                                                                            customer?.town
                                                                        }
                                                                    </span>
                                                                </>
                                                            )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Card>

                            <Card
                                title="Kort"
                                extra={
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            setSavedCardsModal(true);
                                        }}
                                    >
                                        <InfoCircleFilled /> Nánar
                                    </Button>
                                }
                            >
                                {customer && (
                                    <CustomerCardInfo
                                        customer={customer}
                                    ></CustomerCardInfo>
                                )}
                            </Card>
                        </div>
                        <div id="topLineInner">
                            <Card
                                title={<>Áskriftir</>}
                                extra={
                                    <Button
                                        size="small"
                                        type="text"
                                        onClick={() => {
                                            setCreateSubscriptionModal(true);
                                        }}
                                    >
                                        <PlusCircleFilled /> Bæta við
                                    </Button>
                                }
                            >
                                {customer && (
                                    <CustomerSubscriptions
                                        customer={customer}
                                    ></CustomerSubscriptions>
                                )}
                            </Card>

                            {w.uuid && customer?.shop_uuid && (
                                <CustomerTransactions
                                    customer_uuid={w.uuid}
                                    shop_uuid={customer.shop_uuid}
                                />
                            )}
                        </div>
                    </div>
                    {customer && (
                        <>
                            <div id="rightStuff">
                                <Space
                                    style={{ display: 'flex' }}
                                    direction="vertical"
                                >
                                    <Dropdown.Button
                                        className="mysubscriptionButton"
                                        href={`/askrifandi/${customer?.shop_uuid}/${customer?.uuid}/`}
                                        menu={{
                                            items: [
                                                {
                                                    label: (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <MailOutlined />{' '}
                                                            Senda hlekk á
                                                            viðskiptavin
                                                        </div>
                                                    ),
                                                    onClick: () => {
                                                        customer &&
                                                            setEmailModal({
                                                                subject:
                                                                    customer
                                                                        .shop
                                                                        .name +
                                                                    ' - Hlekkur á þínar síður',
                                                                body: `


<p>Meðfylgjandi er hlekkur á þínar síður hjá ${customer.shop.name}.
Þar getur þú breytt kortaupplýsingum, sótt kvittanir og fleira: <a href="${window.location.protocol}//${window.location.host}/askrifandi/${customer.shop_uuid}/${w.uuid}/">Smelltu hér</a></p>
`,
                                                            });
                                                    },
                                                    key: 'send_link',
                                                },
                                            ],
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <UserOutlined /> Mínar síður
                                        </div>
                                    </Dropdown.Button>

                                    <Button
                                        block
                                        onClick={() => {
                                            setEmailModal(true);
                                        }}
                                    >
                                        <MailOutlined />
                                        Senda tölvupóst
                                    </Button>

                                    <Button
                                        block
                                        onClick={() => {
                                            setAddCustomerCommentModal(true);
                                        }}
                                    >
                                        <CommentOutlined /> Bæta við athugasemd
                                    </Button>

                                    {user?.is_staff &&
                                        customer.shop.banking && (
                                            <Button
                                                block
                                                onClick={() => {
                                                    setCustomerClaimsModal(
                                                        true
                                                    );
                                                }}
                                            >
                                                Sjá allar kröfur
                                            </Button>
                                        )}
                                </Space>

                                {customer &&
                                    customer.shop.appwrite_project_id &&
                                    customer.shop
                                        .manage_permission_based_content && (
                                        <CustomerAppwriteDetails
                                            customer={customer}
                                        ></CustomerAppwriteDetails>
                                    )}

                                {customer && customer?.external_user_id && (
                                    <CustomerWooMembershipDetails
                                        customer={customer}
                                    ></CustomerWooMembershipDetails>
                                )}

                                {customer.shop.supercast_api_key && (
                                    <SupercastDetails
                                        customer={customer}
                                    ></SupercastDetails>
                                )}

                                <CustomerTimeline
                                    customer={customer}
                                ></CustomerTimeline>
                            </div>
                        </>
                    )}
                </div>

                <div id="customerView">
                    <div className="customerContainer">
                        {/* <Card
                            title={
                                <div className="capitalize">
                                    {customer?.name}
                                </div>
                            }
                            extra={
                                <Dropdown.Button
                                    size="small"
                                    href={`/askrifandi/${customer?.shop_uuid}/${customer?.uuid}/`}
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <>
                                                        <MailOutlined /> Senda
                                                        hlekk á viðskiptavin
                                                    </>
                                                ),
                                                onClick: () => {
                                                    customer &&
                                                        setEmailModal({
                                                            subject:
                                                                customer.shop
                                                                    .name +
                                                                ' - Hlekkur á þínar síður',
                                                            body: `


<p>Meðfylgjandi er hlekkur á þínar síður hjá ${customer.shop.name}.
Þar getur þú breytt kortaupplýsingum, sótt kvittanir og fleira: <a href="${window.location.protocol}//${window.location.host}/askrifandi/${customer.shop_uuid}/${w.uuid}/">Smelltu hér</a></p>
`,
                                                        });
                                                },
                                                key: 'send_link',
                                            },
                                        ],
                                    }}
                                >
                                    <UserOutlined></UserOutlined> Mínar síður
                                </Dropdown.Button>

                                // <Button
                                //     size="small"
                                //     href={`/askrifandi/${customer?.shop_uuid}/${customer?.uuid}/`}
                                // >
                                //     <UserOutlined />
                                //     Mínar síður
                                // </Button>
                            }
                        >
                            
                        </Card> */}

                        {/* <Card title="Áskriftir, sendingar og greiðslur">
                        <Tabs
                            style={{ overflow: 'visible' }}
                            defaultActiveKey="subscriptions"
                        >
                            {customer?.shop?.banking && (
                                <TabPane tab="Bankakröfur" key="claims">
                                    {w.uuid && customer && (
                                        <BankingTransactionOverview
                                            shop_uuid={customer?.shop_uuid}
                                            customer_uuid={customer?.uuid}
                                        ></BankingTransactionOverview>
                                    )}
                                </TabPane>
                            )}

                          
                        </Tabs>
                    </Card> */}
                    </div>

                    {createSubscriptionModal && customer && (
                        <Modal
                            title="Stofna áskrift"
                            open={createSubscriptionModal}
                            footer={null}
                            onCancel={() => {
                                setCreateSubscriptionModal(false);
                            }}
                        >
                            <CreateSubscription
                                customer={customer}
                                onSuccess={() => {
                                    setCreateSubscriptionModal(false);
                                    w.location.href =
                                        w.location.origin + w.location.pathname;
                                }}
                            ></CreateSubscription>
                        </Modal>
                    )}

                    {!!addCustomerCommentModal && (
                        <Modal
                            title="Bæta við athugasemd"
                            open={!!addCustomerCommentModal}
                            onCancel={() => {
                                setAddCustomerCommentModal(false);
                            }}
                            footer={null}
                        >
                            <Space
                                style={{ display: 'flex' }}
                                direction="vertical"
                            >
                                <TextArea
                                    onChange={(change) => {
                                        setNewComment(
                                            change.currentTarget.value
                                        );
                                    }}
                                    rows={6}
                                />
                                <Button
                                    loading={commentSaveLoading}
                                    onClick={() => {
                                        if (!newComment.trim()) {
                                            message.error(
                                                'Þú verður að skrifa eitthvað'
                                            );
                                        } else {
                                            setCommentSaveLoading(true);

                                            Axios.post(
                                                '/verslun/api/add_customer_comment/',
                                                {
                                                    comment: newComment,
                                                    customer_uuid: w.uuid,
                                                }
                                            ).then((rsp) => {
                                                const d = new Date()
                                                    .toISOString()
                                                    .toString();
                                                setAddCustomerCommentModal(
                                                    false
                                                );
                                                setCommentSaveLoading(false);
                                                if (customer) {
                                                    setCustomer({
                                                        ...customer,
                                                        staff_comments: [
                                                            {
                                                                created: d,
                                                                updated: d,
                                                                user: 'Þú',
                                                                message:
                                                                    newComment,
                                                            },
                                                            ...customer.staff_comments,
                                                        ],
                                                    });
                                                }
                                            });
                                        }
                                    }}
                                    block
                                    type="primary"
                                    size="large"
                                >
                                    Vista athugasemd
                                </Button>
                            </Space>
                        </Modal>
                    )}

                    {!!emailModal && customer && (
                        <EmailModal
                            dataType="EMAIL_LIST"
                            to={[customer.email || '']}
                            shop={customer.shop_uuid}
                            body={
                                typeof emailModal === 'object'
                                    ? emailModal.body
                                    : ''
                            }
                            subject={
                                typeof emailModal === 'object'
                                    ? emailModal?.subject
                                    : ''
                            }
                            onCancel={() => {
                                setEmailModal(false);
                            }}
                            open={!!emailModal}
                        ></EmailModal>
                    )}

                    {savedCardsModal && customer && (
                        <Modal
                            title="Vistuð kort"
                            open={savedCardsModal}
                            onCancel={() => {
                                setSavedCardsModal(false);
                            }}
                            footer={null}
                        >
                            <Space
                                direction="vertical"
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setEmailModal({
                                            subject:
                                                'Kortaupplýsingarnar þínar þarfnast endurnýjunar',
                                            body: `
<p>Ekki tókst að rukka kortið þitt.</p>
<br>
<p>Vinsamlegast uppfærðu greiðsluupplýsingarnar þínar með því að smella
<a href="${window.location.protocol}//${window.location.host}/askrifandi/${customer.shop_uuid}/${w.uuid}/">HÉR</a>
</p>
`,
                                        });
                                    }}
                                >
                                    <MailOutlined /> Biðja viðskiptavin um að
                                    uppfæra korta upplýsingar
                                </Button>

                                <SavedCardsOverview
                                    customer={customer}
                                    type="list"
                                ></SavedCardsOverview>
                            </Space>
                        </Modal>
                    )}
                </div>
            </Card>

            {editCustomer && customer && (
                <Modal
                    footer={null}
                    open={editCustomer}
                    onCancel={() => {
                        setEditCustomer(false);
                    }}
                >
                    <CustomerDetailsForm
                        customer={customer}
                        onCustomerChange={(customer) => {
                            setCustomer(customer);
                            setEditCustomer(false);
                        }}
                    ></CustomerDetailsForm>
                </Modal>
            )}

            {customer && user?.is_staff && (
                <Modal
                    title="Kröfur"
                    open={!!customerClaimsModal}
                    onCancel={() => {
                        setCustomerClaimsModal(false);
                    }}
                    footer={null}
                >
                    <CustomerClaimsOverview
                        customerUUID={customer?.uuid}
                    ></CustomerClaimsOverview>
                </Modal>
            )}
        </>
    );
}
