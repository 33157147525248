import { useState, useEffect } from 'react';
import {
    Alert,
    Form,
    Space,
    Modal,
    Button,
    Table,
    Select,
    message,
} from 'antd';
import Axios, { AxiosResponse } from 'axios';
import Shop from '../../interfaces/shop';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dayjs from 'dayjs';
import 'dayjs/locale/is';
import ApiKey from '../../interfaces/apiKey';
dayjs.locale('is'); // use locale

interface Props {
    shop: Shop;
}

export default function APIKeys({ shop }: Props) {
    // const [apiKeys, setAPIKeys] = useState([]);
    const [modal, setModal] = useState(false);
    const [keyType, setKeyType] = useState('public');
    const [keys, setKeys] = useState<ApiKey[]>([]);

    useEffect(() => {
        Axios.get(`/api/keys/?shop__uuid=${shop.uuid}&ordering=-created`).then(
            (rsp: AxiosResponse<ApiKey[]>) => {
                setKeys(rsp.data);
            }
        );
    }, []);

    return (
        <>
            <>
                <Space direction="vertical" style={{ display: 'flex' }}>
                    <div>
                        <Button
                            onClick={() => {
                                setModal(true);
                            }}
                            type="primary"
                        >
                            + Búa til lykil
                        </Button>
                    </div>
                    <Alert
                        message="Mikilvægt"
                        description={
                            <>
                                <div>
                                    Það er tvær tegundir lykla: "Opið efni" og
                                    "Læst efni".{' '}
                                </div>
                                <p>
                                    "Opið efni" lykla má nota á framenda
                                    (javascript í vafra).
                                </p>
                                <p>Aldrei nota "Læst efni" lykla á framenda!</p>
                                <p>
                                    API skjölun er að finna{' '}
                                    <a href="/api/v1/">HÉR</a>
                                </p>
                            </>
                        }
                        type="info"
                    />

                    <>
                        <div>Shop UUID er:</div>
                        <div
                            style={{
                                fontWeight: 'normal',
                                fontSize: 12,
                            }}
                        >
                            {shop.uuid}
                        </div>
                        <div
                            style={{
                                fontWeight: 'normal',
                                fontSize: 12,
                            }}
                        >
                            Þú þarft það þegar þú kallar í API þjónustuna
                        </div>
                    </>
                    <Table
                        className="white_table"
                        dataSource={keys}
                        locale={{
                            emptyText: 'Engir lyklar fundust',
                        }}
                        columns={[
                            {
                                title: 'Stofnaður',
                                render: (obj: { created: string }) => {
                                    return (
                                        <div>
                                            {dayjs(obj.created).format(
                                                'D. MMM YYYY - HH:mm YYYY'
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: 'Virkur',
                                render: (key) => {
                                    return key.active ? 'Nei' : 'Já';
                                },
                            },
                            {
                                title: 'Tegund lykils',
                                render: (key) => {
                                    return key.is_public
                                        ? 'Opið efni'
                                        : 'Læst efni';
                                },
                            },
                            {
                                title: 'Stofnað af',
                                dataIndex: 'user',
                            },
                            {
                                title: 'API Lykill',
                                render: (item) => {
                                    return (
                                        <>
                                            <span
                                                style={{
                                                    maxWidth: '180px',
                                                    display: 'block',
                                                }}
                                            >
                                                {item.api_key}
                                            </span>

                                            {/* @ts-ignore */}
                                            <CopyToClipboard
                                                text={item.api_key}
                                                onCopy={() =>
                                                    message.success(
                                                        'Tókst, lykillinn hefur verið afritaður!'
                                                    )
                                                }
                                            >
                                                <Button size="small">
                                                    Afrita kóða
                                                </Button>
                                            </CopyToClipboard>
                                        </>
                                    );
                                },
                            },
                        ]}
                        pagination={false}
                    ></Table>
                </Space>
            </>

            {shop && (
                <Modal
                    open={!!modal}
                    footer={null}
                    onCancel={() => {
                        setModal(false);
                    }}
                >
                    <Form
                        layout="vertical"
                        onFinish={(data) => {
                            Axios.post('/api/create_api_key/', {
                                shop_uuid: shop.uuid,
                                public: data.type === 'public',
                            }).then(
                                (rsp: AxiosResponse<{ api_key: ApiKey }>) => {
                                    setModal(false);
                                    message.success('Tókst!');
                                    setKeys([rsp.data.api_key, ...keys]);
                                }
                            );
                        }}
                    >
                        <Form.Item
                            label="Tegund lykils"
                            extra={
                                keyType === 'private' && (
                                    <>
                                        ATH, bakenda lykla á einungis að nota á
                                        þjóninum (server) en ekki með javascript
                                        (þeas. vafra megin). Þetta er mjög
                                        mikilvægt!
                                    </>
                                )
                            }
                            name="type"
                            initialValue={keyType}
                        >
                            <Select
                                value={keyType}
                                onChange={(value) => {
                                    setKeyType(value);
                                }}
                            >
                                <Select.Option value="public">
                                    Opið efni
                                </Select.Option>
                                <Select.Option value="private">
                                    Læst efni (bakenda lykill!)
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Búa til lykil
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    );
}
