import { Input, InputRef } from 'antd';
import { useState, useEffect, useRef } from 'react';
import Product, { Currency } from '../../interfaces/product';
import {
    displayCurrencyPrice,
    kr,
    subscriptionProductSuffix,
} from '../../utils';
import translate, { LanguageKey } from '../../translate/translate';

type Props = {
    product: Product;
    quantity: number;
    oneTimePurchase: Boolean;
    onChange: Function;
    lang: LanguageKey;
    selectedCurrency: Currency['currency_code'];
};

export default function SalePagePrice(props: Props) {
    const priceInputRef = useRef<InputRef>(null);
    const [customPriceSelected, setCustomPriceSelected] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>();
    const [selectedPriceValue, setSelectedPriceValue] = useState<number>();

    const { lang } = props;

    useEffect(() => {
        if (customPriceSelected && priceInputRef)
            priceInputRef.current?.focus();
    }, [customPriceSelected]);

    useEffect(() => {
        props.onChange(selectedPriceValue);
    }, [selectedPriceValue, props]);

    const currencyPrice = props.product.prices.find(
        (obj) => obj.currency.currency_code === props.selectedCurrency
    );

    return props.product.customizable_price ? (
        <>
            {' '}
            <div className="mb20">
                <h3>{translate('amount', lang)}</h3>
                <div id="price_options">
                    {props.product.offer_price_options &&
                        (props.product.price.custom_price_options || []).map(
                            (price, i) => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            setCustomPriceSelected(false);
                                            setSelectedIndex(i);
                                            setSelectedPriceValue(price);
                                        }}
                                        className={`price_option ${
                                            i === selectedIndex && 'selected'
                                        }`}
                                    >
                                        {kr(price)}{' '}
                                    </div>
                                );
                            }
                        )}

                    {props.product.offer_price_options &&
                        props.product.show_customer_price_input && (
                            <div
                                onClick={() => {
                                    setCustomPriceSelected(true);
                                    setSelectedIndex(null);
                                    setSelectedPriceValue(undefined);
                                }}
                                className={`price_option ${
                                    customPriceSelected && 'selected'
                                }`}
                            >
                                {translate('choose_amount', lang)}
                            </div>
                        )}
                </div>
            </div>
            {customPriceSelected && (
                <div className="mb20 mt20">
                    <h3>
                        {translate('chosen_amount', lang)}{' '}
                        {props.product.minimum_custom_price
                            ? `(${translate('minimum', lang)}: ${kr(
                                  props.product.minimum_custom_price
                              )})`
                            : ''}
                    </h3>
                    <div>
                        <Input
                            onChange={(e) => {
                                setSelectedPriceValue(e.target.valueAsNumber);
                            }}
                            ref={priceInputRef}
                            style={{ width: 150, textAlign: 'center' }}
                            size="large"
                            disabled={!props.product.show_customer_price_input}
                            type="number"
                            min={props.product.minimum_custom_price || 0}
                        />
                    </div>
                </div>
            )}
        </>
    ) : (
        <>
            {currencyPrice?.amount_before_discount && (
                <h3 id="product_price_before_discount">
                    {displayCurrencyPrice(
                        currencyPrice?.amount_before_discount,
                        currencyPrice?.currency.currency_code
                    )}
                </h3>
            )}

            <h3 id="product_price">
                {props.product.custom_prefix && props.product.custom_prefix}{' '}
                {displayCurrencyPrice(
                    (currencyPrice?.amount || 0) * (props.quantity || 1),
                    props.selectedCurrency
                )}
                {!props.oneTimePurchase &&
                    subscriptionProductSuffix(props.product, lang)}
            </h3>
        </>
    );
}
