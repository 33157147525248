import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'antd';
import { FileProtectOutlined } from '@ant-design/icons';
import { displayCurrencyPrice } from '../../utils';
import languages, { LanguageKey } from '../../translate/translate';
import dayjs from 'dayjs';
import { SimpleCustomer } from '../../interfaces/customer';
import { SubscriptionTransactionPublic } from '../../interfaces/transaction';

type Props = { url: string; language: LanguageKey; customer?: SimpleCustomer };

export default function ReceiptOverview({ url, language, customer }: Props) {
    const [payments, setPayments] = useState([]);
    const [bankClaimTransactions, setBankClaimTransactions] = useState<
        SubscriptionTransactionPublic[]
    >([]);

    useEffect(() => {
        axios.get(url).then((rsp) => {
            setPayments(rsp.data.results);
        });

        axios
            .get(
                '/askrift/api/public/subscription_transactions/?customer_uuid=' +
                    customer?.uuid +
                    '&payment_method=BANK_CLAIM&is_paid=true'
            )
            .then((rsp) => {
                setBankClaimTransactions(rsp.data.results);
            });
    }, []);

    return (
        <div>
            {customer && (
                <Table
                    dataSource={[...payments, ...bankClaimTransactions].sort(
                        (a, b) => {
                            return (
                                new Date(b.created).getTime() -
                                new Date(a.created).getTime()
                            );
                        }
                    )}
                    columns={[
                        {
                            title: languages('date', language),
                            key: 'created',
                            render: (record) => {
                                return (
                                    <div>
                                        {dayjs(record.created).format(
                                            'D MMM YYYY'
                                        )}
                                    </div>
                                );
                            },
                        },
                        {
                            title: languages('amount', language),
                            key: 'amount',
                            render: (record) => {
                                const isTransaction =
                                    record.hasOwnProperty('is_fulfilled');

                                const amount = isTransaction
                                    ? record.amount
                                    : record.amount / 100;

                                return (
                                    <div>
                                        {displayCurrencyPrice(
                                            amount,
                                            record.currency_code
                                        )}
                                    </div>
                                );
                            },
                        },

                        {
                            title: languages('link', language),
                            key: 'link',
                            render: (record) => {
                                const isTransaction =
                                    record.hasOwnProperty('is_fulfilled');

                                return isTransaction ? (
                                    <Button
                                        target="_blank"
                                        href={`/askriftarfaersla/kvittun/${record.uuid}/${customer.uuid}/`}
                                    >
                                        <FileProtectOutlined />{' '}
                                        {languages('open_receipt', language)}
                                    </Button>
                                ) : (
                                    <Button
                                        target="_blank"
                                        href={`/sending/kvittun/${record.id}/${customer.uuid}/`}
                                    >
                                        <FileProtectOutlined />{' '}
                                        {languages('open_receipt', language)}
                                    </Button>
                                );
                            },
                        },
                    ]}
                ></Table>
            )}
        </div>
    );
}
