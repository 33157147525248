import { useState } from 'react';
import { Card, Input, Form, Upload, Popover, Table, Space, List } from 'antd';

// Libraries
import Axios from 'axios';

/* Interfaces */
import Product, { ProductAttribute } from '../../interfaces/product';

const { TextArea } = Input;

/* Types */
/* Interfaces */
interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export default function ImportSubscriptions() {
    const [product, setProduct] = useState<Product | undefined>();
    const [initalAttributesSet, setInitalAttributesSet] = useState(false);

    // useEffect(() => {
    //     // Axios.get('/verslun/api/product/' + w.uuid + '/').then((rsp) => {
    //     //     setProduct(rsp.data);
    //     // });

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //     /* Mikilvægt því annars er ekkert attribute sjálfkrafa valið (og þá kemur server error ef notandinn breytir ekki) */
    //     if (!initalAttributesSet) {
    //         if (product?.attributes) {
    //             product.attributes.forEach((attribute) => {
    //                 if (attribute.options?.length) {
    //                     const option_id = setDefaultSelect(attribute);
    //                     changeSelectOption(attribute, option_id);
    //                 }
    //             });
    //         }
    //     }

    //     if (product) {
    //         setInitalAttributesSet(true);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [product]);

    // const changeSelectOption = (
    //     attribute: ProductAttribute,
    //     option_id: number
    // ) => {
    //     if (product) {
    //         const attributes = product.attributes?.map((attr) => {
    //             return attr.id === attribute.id
    //                 ? { ...attr, option_answer: [option_id] }
    //                 : attr;
    //         });

    //         setProduct({ ...product, attributes: attributes });
    //     }
    // };

    // const setDefaultSelect = (attribute: ProductAttribute) => {
    //     const optionCount = attribute.options?.length;
    //     let option_id;

    //     if (optionCount < 3) {
    //         option_id = attribute.options[attribute.options.length - 1].id;
    //     } else {
    //         option_id = attribute.options[Math.ceil(optionCount / 2) - 1].id;
    //     }

    //     return option_id;
    // };

    return (
        <>
            <Card
                // style={{ maxWidth: 1500 }}
                className="basePageCard"
                title="Bæta áskriftum við með Excel sniðmáti"
            >
                <Space style={{ display: 'flex' }} direction="vertical">
                    <div>
                        <p>
                            Smelltu{' '}
                            <a href="/static/files/repeat_import_subscriptions_template.xlsx">
                                Hér
                            </a>{' '}
                            til að sækja sniðmátið
                        </p>
                    </div>

                    <div>Hér er dæmi um þá dálka sem í boði eru:</div>
                    <Table
                        pagination={false}
                        size="small"
                        style={{
                            background: 'transparent',
                        }}
                        dataSource={[
                            {
                                nafn: 'Jón Jónsson',
                                ssid: '5010159628',
                                email: 'jon@jon.is',
                                phone: '8677777',
                                street: 'Dúfnahólar 10',
                                town: 'Reykjavík',
                                postal_code: '111',
                                amount: '990',
                                virtual_number: '9991...',
                                exp_month: '12',
                                exp_year: '28',
                                card_token: 'tm_lvF...',
                                comment: 'Var jákvkæð....',
                            },
                        ]}
                        columns={[
                            {
                                title: (
                                    <Popover content={<>yoooooo</>}>
                                        Nafn
                                    </Popover>
                                ),
                                dataIndex: 'nafn',
                            },
                            {
                                title: 'Kennitala',
                                dataIndex: 'ssid',
                            },
                            {
                                title: 'Netfang',
                                dataIndex: 'email',
                            },
                            {
                                title: 'Símanúmer',
                                dataIndex: 'phone',
                            },
                            {
                                title: 'Heimilisfang',
                                dataIndex: 'street',
                            },
                            {
                                title: 'Bæjarfélag',
                                dataIndex: 'town',
                            },
                            {
                                title: 'Póstnúmer',
                                dataIndex: 'postal_code',
                            },
                            {
                                title: 'Sérverð',
                                dataIndex: 'amount',
                            },
                            {
                                title: 'Sýndarnúmer',
                                dataIndex: 'virtual_number',
                            },
                            {
                                title: 'Gildistími (MM)',
                                dataIndex: 'exp_month',
                            },
                            {
                                title: 'Gildistími (YY)',
                                dataIndex: 'exp_year',
                            },
                            {
                                title: '"Card token"',
                                dataIndex: 'card_token',
                                responsive: ['lg'],
                            },
                            {
                                title: 'Athugasemd',
                                dataIndex: 'comment',
                                responsive: ['lg'],
                            },
                        ]}
                    ></Table>

                    <div>
                        <>
                            <List.Item>
                                <List.Item.Meta
                                    title="Nafn"
                                    description="Nafni er má ekki sleppa"
                                />
                            </List.Item>

                            <List.Item>
                                <List.Item.Meta
                                    title="Kennitala"
                                    description='Kennitölu má aðeins
                                                            sleppa EF sýndarnúmer
                                                            eða "card token" eru til
                                                            staðar.'
                                />
                            </List.Item>

                            <List.Item>
                                <List.Item.Meta
                                    title="Sérverð"
                                    description="Ef þessi reitur er tómur
                                                            þá verður vöruverð
                                                            notað. ATH að mikilvægt
                                                            er taka fram sérverð ef
                                                            varan er með verðum sem
                                                            viðskiptavinurinn velur
                                                            sjálfur. Þú getur sett 0
                                                            ef viðskiptavinurinn á
                                                            að fá þetta frítt."
                                />
                            </List.Item>
                        </>
                    </div>
                    <Form
                        onFinish={(values) => {
                            console.log(values);
                        }}
                        layout="vertical"
                    >
                        <Form.Item
                            name="asset"
                            label="Skjal"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload.Dragger
                                multiple={false}
                                // beforeUpload={(file) => {
                                //     console.log(file);
                                //     const isOk =
                                //         /.*\.([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)/g;

                                //     const t = isOk.test(file.type);

                                //     if (!isOk) {
                                //         message.error(
                                //             `${file.name} er ekki leyfileg tegund skráar.`
                                //         );
                                //     }

                                //     console.log(
                                //         'helloo',
                                //         t || Upload.LIST_IGNORE
                                //     );

                                //     return t || Upload.LIST_IGNORE;
                                // }}
                                customRequest={({
                                    file,
                                    onSuccess,
                                    onError,
                                }) => {
                                    console.log('yoooo');
                                    let formData = new FormData();
                                    formData.append('file', file);

                                    return Axios.post(
                                        `/subscription_excel_import/`,
                                        formData,
                                        {
                                            headers: {
                                                'Content-Type':
                                                    'multipart/form-data',
                                            },
                                        }
                                    );
                                }}
                            >
                                <>
                                    <h2>Smelltu hér</h2>

                                    <h4>eða dragðu skjal hingað</h4>
                                </>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form>
                </Space>
            </Card>
        </>
    );
}
