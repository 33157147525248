import { Modal, Form, Input, Button, message, Tabs } from 'antd';
import Axios from 'axios';
import Shop from '../../interfaces/shop';
import TabPane from 'antd/lib/tabs/TabPane';
import ManageTags from './ManageTags';

type Props = {
    open: boolean;
    onClose: () => void;
    shop: Shop;
};

export default function AddTagModal({ open, onClose, shop }: Props) {
    const [form] = Form.useForm();

    return (
        <Modal
            title="Sýsla með flokka"
            open={open}
            footer={null}
            onCancel={() => {
                onClose();
            }}
        >
            <Tabs
                defaultActiveKey="1"
                onChange={() => {
                    form.resetFields();
                }}
            >
                <TabPane tab="Flokkar" key="1">
                    <ManageTags shop={shop} />
                </TabPane>
                <TabPane tab="+ Ný flokkur" key="2">
                    <Form
                        layout="vertical"
                        onFinish={(values) => {
                            Axios.post(`/efni/api/tag/`, {
                                ...values,
                                shop: shop.id,
                            }).then(() => {
                                message.success(
                                    'Tókst, nú getur þú bætt flokknum við efnið þitt!'
                                );
                                onClose();
                            });
                        }}
                    >
                        <Form.Item name="title" label="Titill">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="slug"
                            label="Tagg (engin bil eða íslenskir stafir)"
                        >
                            <Input />
                        </Form.Item>

                        <Button
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                        >
                            Vista
                        </Button>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
}
