import React, { useState } from 'react';
import { Space, Button, Modal, Form, Input } from 'antd';
import Axios from 'axios';

import CustomerSearch from '../../../components/CustomerSearch/CustomerSearch';
import Customer from '../../../interfaces/customer';

type Props = {
    onSuccess: Function;
    shop_uuid: string;
    onCancel: Function;
    open: boolean;
};

type Type = {
    id: number;
    slug: string;
    title: string;
};

export default function CreateSubscriptionModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [newCustomer, setNewCustomer] = useState(false);

    return (
        <Modal
            onCancel={() => {
                props.onCancel();
            }}
            maskClosable={false}
            title="Stofna áskrift"
            open={props.open}
            footer={false}
        >
            {!newCustomer && (
                <Space
                    direction="vertical"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        flexGrow: 1,
                    }}
                >
                    <CustomerSearch
                        style={{ flex: 1 }}
                        onClick={(customer: Customer) => {
                            // @ts-ignore
                            window.top.location.href = `/vidskiptavinur/${customer.uuid}/?create_subscription=true`;
                        }}
                    ></CustomerSearch>

                    <div>eða</div>

                    <Button
                        onClick={() => {
                            setNewCustomer(true);
                        }}
                        type="primary"
                    >
                        + Búa til nýjan
                    </Button>
                </Space>
            )}

            {newCustomer && (
                <Form
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);
                        values.shop = props.shop_uuid;

                        Axios.post(
                            '/verslun/api/update_or_create_customer/',
                            values
                        ).then((rsp) => {
                            // @ts-ignore
                            window.top.location.href = `/vidskiptavinur/${rsp.data.uuid}/?create_subscription=true`;
                        });
                    }}
                >
                    <Form.Item name="name" label="Nafn">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="ssid" label="Kennitala">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="email" label="Netfang">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="street" label="Heimilisfang">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="postal_code" label="Póstnúmer">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="town" label="Bæjarfélag">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item name="phone" label="Símanúmer">
                        <Input size="large"></Input>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={loading}
                            block
                            size="large"
                            htmlType="submit"
                            type="primary"
                        >
                            Stofna
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
}
