import { useState, useEffect, Key } from 'react';
import { Table, Modal, message, Alert } from 'antd';
import Axios from 'axios';
import dayjs from 'dayjs';
import { kr } from '../../../../utils';
import { SubscriptionTransaction } from '../../../../interfaces/transaction';

type Props = {
    open: boolean;
    onCancel: () => void;
    subscriptionTransactions: SubscriptionTransaction[];
    preselectedTransactionUuids: string[];
};

export default function SendToPaydayModal({
    open,
    onCancel,
    subscriptionTransactions,
    preselectedTransactionUuids,
}: Props) {
    const [waitingForShopify, setWaitingForShopify] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(
        preselectedTransactionUuids || []
    );

    return (
        <Modal
            confirmLoading={waitingForShopify}
            onCancel={() => onCancel()}
            onOk={() => {
                if (selectedRowKeys.length === 0) {
                    message.error('Veldu að minnsta kosti eina sendingu');
                    return;
                }
                setWaitingForShopify(true);
                Axios.post('/askrift/create_payday_invoice_from_transactions', {
                    transaction_uuids: selectedRowKeys,
                })
                    .then(() => {
                        message.success('Tókst 🎉');
                        onCancel();
                    })
                    .finally(() => setWaitingForShopify(false));
            }}
            title="Búa til reikning í Payday"
            open={open}
        >
            <p>
                Athugaðu að ef það er þegar til reikningur þá verður samt annar
                til.
            </p>

            <Table
                dataSource={subscriptionTransactions}
                rowKey="uuid"
                pagination={false}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                }}
                columns={[
                    {
                        title: 'Titill',
                        render: (t) => t.subscription.product.title,
                    },
                    {
                        title: 'Stofnað',
                        dataIndex: 'created',
                        key: 'created',
                        render: (created: string) =>
                            dayjs(created).format('D. MMM YYYY HH:mm'),
                    },
                    {
                        title: 'Verð',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (amount: number) => kr(amount),
                    },
                ]}
            ></Table>

            <Alert description="Allt sem þú velur sameinast í einn reikning í Payday. Sendingarkostnaður reiknast sjálfkrafa eftir stillingum (ef við á)."></Alert>
        </Modal>
    );
}
