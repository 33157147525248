import React, { useEffect, useState } from 'react';
import { Button, Alert, Space, Card, Switch, Form, Input, message } from 'antd';
import Axios from 'axios';
import Shop from '../../interfaces/shop';

interface Props {
    shop: Shop;
}

export default function ShopifySettings({ shop }: Props) {
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);

    return (
        <Form
            layout="vertical"
            initialValues={{
                ...shop.shopify,
            }}
            onFinish={(values) => {
                setLoading(true);
                Axios.post(
                    '/verslun/api/create_or_update_store_shopify_settings/',
                    {
                        shop_uuid: shop.uuid,
                        ...values,
                    }
                )
                    .then(() => {
                        message.success('Tókst! 🥳');
                    })
                    .catch(() => {
                        message.error('Það kom upp villa :/');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        >
            <Space
                direction="vertical"
                style={{ display: 'flex' }}
                size="large"
            >
                <Alert
                    message='Stofna þarf "Private App" á Shopify'
                    description={
                        <>
                            <p>
                                Nánar um "Private Apps" hér:{' '}
                                <a
                                    href="https://help.shopify.com/en/manual/apps/private-apps"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://help.shopify.com/en/manual/apps/private-apps/
                                </a>
                            </p>
                        </>
                    }
                    type="info"
                />

                <div>Virkja shopify tengingu?</div>
                <Switch
                    onClick={() => setActive(!active)}
                    checked={active}
                    checkedChildren="JÁ "
                    unCheckedChildren="NEI"
                ></Switch>

                {active && (
                    <div>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Vinsamlegast sláðu inn API kóða',
                                },
                            ]}
                            label="Admin API access token"
                            name="admin_api_access_token"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Vinsamlegast sláðu inn "Store name"',
                                },
                            ]}
                            label="Store name (þú getur séð þetta nafn með því að fara á /admin/ á síðunni þinni)"
                            name="store_name"
                        >
                            <Input size="large" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="Viltu að Shopify sendi kvittanir?"
                            name="send_receipt"
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="JÁ "
                                unCheckedChildren="NEI"
                            ></Switch>
                        </Form.Item>
                    </div>
                )}
            </Space>

            <Form.Item>
                <Button
                    loading={loading}
                    block
                    className="btn-success mt20"
                    size="large"
                    htmlType="submit"
                >
                    Vista
                </Button>
            </Form.Item>
        </Form>
    );
}
