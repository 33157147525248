import { useState } from 'react';
import Axios from 'axios';
import Product from '../../interfaces/product';
import { Button, Form, Input, Modal, message } from 'antd';
import { LanguageKey } from '../../translate/translate';
import translate from '../../translate/translate';

type Props = {
    open: boolean;
    productUUID: string;
    title: string;
    onCancel: () => void;
    lang: LanguageKey;
};

export default function WaitingListModal(props: Props) {
    const [form] = Form.useForm();
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    return (
        <Modal
            title={
                translate('join_waiting_list', props.lang) + ' - ' + props.title
            }
            footer={null}
            onCancel={() => {
                props.onCancel();
            }}
            open={props.open}
        >
            {!submitSuccess ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        Axios.post('/verslun/api/join_waiting_list/', {
                            product: props.productUUID,
                            ...values,
                        })
                            .then(() => {
                                setSubmitSuccess(true);
                            })
                            .catch(() => {
                                message.error(
                                    translate('error_came_up', props.lang)
                                );
                            });
                    }}
                >
                    <Form.Item
                        name="name"
                        label={translate('name', props.lang)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.lang
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={translate(
                                'name',
                                props.lang
                            ).toString()}
                        ></Input>
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label={translate('email', props.lang)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.lang
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={translate(
                                'email',
                                props.lang
                            ).toString()}
                        ></Input>
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'field_required',
                                    props.lang
                                ),
                            },
                        ]}
                        label={translate('phone', props.lang)}
                    >
                        <Input
                            size="large"
                            placeholder={translate(
                                'phone',
                                props.lang
                            ).toString()}
                        ></Input>
                    </Form.Item>

                    <Button htmlType="submit" size="large" type="primary" block>
                        {translate('confirm', props.lang)}
                    </Button>
                </Form>
            ) : (
                <div>
                    {translate('waiting_list_signup_success', props.lang)}
                </div>
            )}
        </Modal>
    );
}
