import { useEffect, useState } from 'react';
import { Button, DatePicker, Space, Table, message } from 'antd';
import Axios from 'axios';
import { kr } from '../../utils';
import dayjs from 'dayjs';
import moment from 'moment';

type Props = {
    shopUUID: string;
};

type BankPayment = {
    Key: {
        Claimant: string;
        Account: string;
        DueDate: string;
    };
    PayorID: string;
    Amount: number;
    FinalDueDate: string;
    Identifier: string;
    Reference: string;
    CategoryCode: string;
    RedeemingBank: string;
    TransactionDate: string;
    BookingDate: string;
    ValueDate: string;
    // Payment type['Complete', 'Partial', 'Refund']
    PaymentType: 0 | 1 | 2;
    AmountDeposited: number;
    TotalAmount: number;
    CapitalGainsTax: number;
    BillNumber: string;
    CustomerNumber: string;
    NoticeChargeAmount: number;
    DefaultChargeAmount: number;
    OtherCostsAmount: number;
    OtherDefaultCostsAmount: number;
    DefaultInterestAmount: number;
    DiscountAmount: number;
    Currency: {
        Currency: string;
        Rate: number;
    };
    BatchNumber: string;
    SecondaryCollectionIdentifier?: string;
    LegalCollectionIdentifier?: string;
};

export default function ShopClaimPaymentOverview(props: Props) {
    const [payments, setPayments] = useState<BankPayment[]>([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [repeatTotal, setRepeatTotal] = useState(0);
    const [dates, setDates] = useState({
        from: moment().subtract(1, 'day'),
        to: moment(),
    });

    useEffect(() => {
        getClaimData();
    }, []);

    useEffect(() => {
        setTotal(
            payments.reduce(
                (acc: number, obj: BankPayment) =>
                    (obj.PaymentType === 0 ? obj.Amount : 0) + acc,
                0
            )
        );

        setRepeatTotal(
            payments.reduce(
                (acc: number, obj: BankPayment) =>
                    (obj.PaymentType === 0 && obj.Reference === 'Repeat'
                        ? obj.Amount
                        : 0) + acc,
                0
            )
        );

        return () => {
            setTotal(0);
            setRepeatTotal(0);
        };
    }, [payments]);

    const getClaimData = () => {
        Axios.post('/payment/api/get_shop_claim_payments/', {
            shop_uuid: props.shopUUID,
            from: dates.from,
            to: dates.to,
        }).then((rsp) => {
            setPayments(rsp.data || []);
            setLoading(false);
        });
    };

    return (
        <Space direction="vertical" style={{ display: 'flex' }}>
            <div>
                Alls fyrir tímabil: {kr(total)} (þar af repeat.is:{' '}
                {kr(repeatTotal)})
            </div>
            <div>
                Fjöldi: {payments.length} (þar af repeat.is:{' '}
                {payments.filter((obj) => obj.Reference === 'Repeat').length} )
            </div>
            <hr />
            <Space>
                <label>Frá: </label>
                <DatePicker
                    defaultValue={dates.from}
                    onChange={(val) => val && setDates({ ...dates, from: val })}
                ></DatePicker>

                <label>Til: </label>
                <DatePicker
                    defaultValue={dates.to}
                    onChange={(val) => val && setDates({ ...dates, to: val })}
                ></DatePicker>

                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                        if (!dates.from || !dates.to) {
                            message.error('Þú verður að velja dagsetningar');
                            return;
                        }
                        setLoading(true);
                        getClaimData();
                    }}
                >
                    Leita
                </Button>
            </Space>

            <Table
                loading={loading}
                pagination={false}
                dataSource={payments}
                columns={[
                    {
                        title: 'Upphæð',
                        render: (claim: BankPayment) => {
                            return <div>{kr(claim.Amount)}</div>;
                        },
                    },
                    {
                        title: 'Dags. greiðslu',
                        render: (claim: BankPayment) => {
                            return (
                                <div>
                                    {dayjs(claim.TransactionDate).format(
                                        'D. MMM YYYY'
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Eindagi',
                        render: (claim: BankPayment) => {
                            return (
                                <div>
                                    {dayjs(claim.FinalDueDate).format(
                                        'D. MMM YYYY'
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Tegund greiðslu',
                        render: (claim: BankPayment) => {
                            return (
                                <div>
                                    {claim.PaymentType === 0 && (
                                        <div>Greiðsla</div>
                                    )}
                                    {claim.PaymentType === 1 && (
                                        <div>Greitt inn á kröfu</div>
                                    )}
                                    {claim.PaymentType === 2 && (
                                        <div>Endurgreiðsla</div>
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Kröfunúmer',
                        render: (claim: BankPayment) => {
                            return <div>{claim.Key.Account}</div>;
                        },
                    },
                    {
                        title: 'Ref',
                        render: (claim: BankPayment) => {
                            return <div>{claim.Reference}</div>;
                        },
                    },
                ]}
            ></Table>
        </Space>
    );
}
