import './SalesPage.less';
import { useEffect, useState } from 'react';
import { Button, Select, Typography, InputNumber, message } from 'antd';
import { kr, getUrlParameter, displayCurrencyPrice } from '../../utils';

// Libraries
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

//Components
import ProductPaymentModal from '../../components/ProductPaymentModal/ProductPaymentModal';
import SubscriptionCronSettings from '../../components/SubscriptionCronSettings/SubscriptionCronSettings';
import SalesPagePrice from './SalePagePrice';

/* Interfaces */
import Product, {
    Currency,
    ProductAttribute,
    ProductAttributeWithAnswer,
} from '../../interfaces/product';
import translate, { LanguageKey } from '../../translate/translate';
import { CronConfig } from '../../interfaces/subscription';
import PersonalInformation from '../../interfaces/personalInformation';
import WaitingListModal from '../../components/WaitingListModal/WaitingListModal';

const { Paragraph } = Typography;

/* Types */
/* Interfaces */
interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;

export default function SalesPage(props: { product?: Product }) {
    const [product, setProduct] = useState<Product | undefined>();
    const [personalInfoList, setPersonalInfoList] = useState<
        PersonalInformation[]
    >([]);
    const [paymentModal, setPaymentModal] = useState(false);
    const [waitingListModal, setWaitingListModal] = useState<boolean>(false);
    const [height, setHeight] = useState<number | undefined>();
    const [showQuantity, setShowQuantity] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [quantityEditable, setQuantityEditable] = useState(true);
    const [oneTimePurchase, setOneTimePurchase] = useState(false);
    const [monthlyCronSettings, setMonthlyCronSettings] = useState(false);
    const [weeklyCronSettings, setWeeklyCronSettings] = useState(false);
    const [yearlyCronSettings, setYearlyCronSettings] = useState(false);
    const [cronSettings, setCronSettings] = useState<CronConfig>();
    const [customName, setCustomName] = useState('');
    const [customSsid, setCustomSsid] = useState('');
    const [customEmail, setCustomEmail] = useState('');
    const [customTitle, setCustomTitle] = useState('');
    const [disableDetailInputs, setDisableDetailInputs] = useState(false);
    const [selectedCustomPrice, setselectedCustomPrice] = useState<
        number | null
    >();
    const [externalRef, setExternalRef] = useState<string>();
    const [language, setLanguage] = useState<LanguageKey>('is');
    const [currency, setCurrency] = useState<Currency['currency_code']>('ISK');

    const [weeklyOptions, setweeklyOptions] = useState<number[]>();
    const [yearlyOptions, setYearlyOptions] = useState<number[]>();
    const [monthlyOptions, setMonthlyOptions] = useState<number[]>();

    useEffect(() => {
        const Quantity = getUrlParameter('quantity');
        if (Quantity) {
            setQuantity(parseInt(quantity.toString(), 10) || 1);
            setShowQuantity(true);
        }

        const QuantityEditable = getUrlParameter('quantity_editable');
        if (QuantityEditable === 'false') setQuantityEditable(false);

        const Language =
            (getUrlParameter('lang') as LanguageKey) ||
            (getUrlParameter('language') as LanguageKey);
        if (Language) setLanguage(Language);

        const _currency = getUrlParameter('currency');
        if (_currency) {
            console.log(_currency);
            setCurrency(_currency as Currency['currency_code']);
        }

        const OneTimePurchase = getUrlParameter('one_time_purchase');
        if (OneTimePurchase === 'true') setOneTimePurchase(true);

        const mCron = getUrlParameter('monthly_cron');
        if (mCron === 'true') setMonthlyCronSettings(true);

        const wCron = getUrlParameter('weekly_cron');
        if (wCron === 'true') setWeeklyCronSettings(true);

        const yCron = getUrlParameter('yearly_cron');
        if (yCron === 'true') setYearlyCronSettings(true);

        const cName = getUrlParameter('customer_name');
        if (cName) setCustomName(decodeURIComponent(cName));

        const cSsid = getUrlParameter('customer_ssid');
        if (cSsid) setCustomSsid(cSsid);

        const cEmail = getUrlParameter('customer_email');
        if (cEmail) setCustomEmail(cEmail);

        const cTitle = getUrlParameter('custom_title');
        if (cTitle) setCustomTitle(decodeURIComponent(cTitle));

        const ExternalRef = getUrlParameter('external_ref');
        if (ExternalRef) setExternalRef(decodeURIComponent(ExternalRef));

        const DisableDetailInputs = getUrlParameter('disable_detail_inputs');
        if (DisableDetailInputs === 'true') setDisableDetailInputs(true);

        const wOptions = getUrlParameter('weekly_options');
        if (wOptions)
            setweeklyOptions(
                [...wOptions.split(',')].map((nr) => parseInt(nr, 10))
            );

        const yOptions = getUrlParameter('yearly_options');
        if (yOptions)
            setYearlyOptions(
                [...yOptions.split(',')].map((nr) => parseInt(nr, 10))
            );

        const mOptions = getUrlParameter('monthly_options');
        if (mOptions)
            setMonthlyOptions(
                [...mOptions.split(',')].map((nr) => parseInt(nr, 10))
            );

        Axios.get('/verslun/api/personal_info_list/').then((rsp) => {
            setPersonalInfoList(rsp.data.results);
        });

        if (props.product) {
            setProduct(props.product);
        } else {
            Axios.get('/verslun/api/product/' + w.uuid + '/').then((rsp) => {
                setProduct(rsp.data);
            });
        }

        window.onload = () => {
            sendPostMessage();

            setTimeout(() => {
                sendPostMessage();
            }, 200);

            setTimeout(() => {
                sendPostMessage();
            }, 400);

            setTimeout(() => {
                sendPostMessage();
            }, 600);

            setTimeout(() => {
                sendPostMessage();
            }, 800);

            setTimeout(() => {
                sendPostMessage();
            }, 1000);
        };

        window.onresize = () => sendPostMessage();

        const postMessageTicker = setInterval(() => {
            sendPostMessage();
        }, 5000);

        return () => {
            clearInterval(postMessageTicker);
        };
    }, []);

    useEffect(() => {
        if (product && height) {
            w.parent.postMessage(
                {
                    frameHeight: height + 40,
                    uuid: product.uuid,
                },
                '*'
            );
        }
    }, [height]);

    const changeSelectOption = (
        attribute: ProductAttribute,
        option_id: number
    ) => {
        if (product) {
            const attributes = product.attributes?.map((attr) => {
                return attr.id === attribute.id
                    ? { ...attr, option_answers: [option_id] }
                    : attr;
            });

            setProduct({ ...product, attributes: attributes });
        }
    };

    const checkIfAllAttributesAreSelected = (product: Product) => {
        if (
            product.attributes?.some(
                (attr: ProductAttributeWithAnswer) =>
                    attr.show_on_frontend && !attr.options?.length
            )
        ) {
            //Now we check if the number of attributes with option_answers is the same as the number of attributes with options
            const attributesWithOption = product.attributes?.filter(
                (attr: ProductAttributeWithAnswer) => attr.options?.length
            );
            return attributesWithOption.every(
                (attr: ProductAttributeWithAnswer) =>
                    attr.option_answers?.length
            );
        } else {
            return true;
        }
    };

    const sendPostMessage = () => {
        const elementHeights = [0];

        const modals = document.getElementsByClassName('ant-modal');

        const pc = document.getElementById('product_container');
        const productContainerHeight = pc ? pc.offsetHeight : 0;

        if (modals.length) {
            //@ts-ignore
            elementHeights.push(modals[0].offsetHeight);
        }

        const currentHeight = Math.max(
            ...elementHeights,
            productContainerHeight
        );

        if (height !== currentHeight) {
            const newHeight = currentHeight + 40;
            setHeight(newHeight);
        }
    };

    const productPrice =
        product?.prices.find(
            (price) => price.currency.currency_code === currency
        )?.amount || product?.price?.base_price;
    0;

    return (
        <>
            {product?.title && (
                <>
                    <div id="sales_page">
                        <div id="sales_page_inner">
                            {product && (
                                <div id="product_container">
                                    {product?.main_image && (
                                        <div id="product_image">
                                            <img
                                                id="main_image"
                                                style={{ maxWidth: 300 }}
                                                src={product.main_image.image}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <div id="product_details">
                                        <h2 id="product_title">
                                            {customTitle || product.title}
                                        </h2>
                                        {product.description && (
                                            <Paragraph
                                                className="productDescription"
                                                ellipsis={
                                                    product.use_description_ellipsis
                                                        ? {
                                                              rows: 16,
                                                              expandable: true,
                                                              symbol: (
                                                                  <span
                                                                      style={{
                                                                          color: 'inherit',
                                                                          fontWeight:
                                                                              'bold',
                                                                      }}
                                                                  >
                                                                      {translate(
                                                                          'read_more',
                                                                          language
                                                                      )}
                                                                  </span>
                                                              ),
                                                              onExpand: () => {
                                                                  sendPostMessage();
                                                                  setTimeout(
                                                                      () => {
                                                                          sendPostMessage();
                                                                      },
                                                                      200
                                                                  );
                                                              },
                                                          }
                                                        : false
                                                }
                                            >
                                                {
                                                    ReactHtmlParser(
                                                        product.description
                                                    ) as React.ReactNode
                                                }
                                            </Paragraph>
                                        )}

                                        <div className="text-center">
                                            {product.attributes?.length ? (
                                                <>
                                                    {product.attributes.map(
                                                        (
                                                            attribute,
                                                            attr_key
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        attr_key
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {attribute.show_on_frontend &&
                                                                    attribute
                                                                        .options
                                                                        ?.length ? (
                                                                        <>
                                                                            <h4
                                                                                style={{
                                                                                    borderTop:
                                                                                        '1px solid #dedede',
                                                                                    paddingTop: 10,
                                                                                    margin: 0,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    attribute.label
                                                                                }
                                                                            </h4>
                                                                            <Select
                                                                                size="large"
                                                                                defaultActiveFirstOption={
                                                                                    attribute
                                                                                        .options
                                                                                        ?.length ===
                                                                                    1
                                                                                }
                                                                                onChange={(
                                                                                    option_id
                                                                                ) => {
                                                                                    changeSelectOption(
                                                                                        attribute,
                                                                                        option_id
                                                                                    );
                                                                                }}
                                                                                placeholder={`${translate(
                                                                                    'choose',
                                                                                    language
                                                                                )} ${attribute.label.toLocaleLowerCase()}`}
                                                                                style={{
                                                                                    marginBottom: 20,
                                                                                    minWidth: 200,
                                                                                    maxWidth:
                                                                                        '100%',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {attribute.options?.map(
                                                                                    (
                                                                                        option,
                                                                                        key
                                                                                    ) => {
                                                                                        const extraInfo =
                                                                                            option.extra_price
                                                                                                ? ' (' +
                                                                                                  kr(
                                                                                                      option.extra_price
                                                                                                  ) +
                                                                                                  ')'
                                                                                                : '';

                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={
                                                                                                    key
                                                                                                }
                                                                                                value={
                                                                                                    option.id
                                                                                                }
                                                                                            >
                                                                                                {option.title +
                                                                                                    extraInfo}
                                                                                            </Select.Option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Select>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ) : (
                                                <SalesPagePrice
                                                    lang={language}
                                                    onChange={(
                                                        value:
                                                            | number
                                                            | undefined
                                                            | null
                                                    ) => {
                                                        setselectedCustomPrice(
                                                            value
                                                        );
                                                    }}
                                                    quantity={quantity || 1}
                                                    product={product}
                                                    oneTimePurchase={
                                                        oneTimePurchase
                                                    }
                                                    selectedCurrency={currency}
                                                ></SalesPagePrice>
                                            )}
                                        </div>

                                        {product.inventory.in_stock ? (
                                            <>
                                                {showQuantity && (
                                                    <div
                                                        className="attributeContainer"
                                                        id="quantity_container"
                                                    >
                                                        <h4
                                                            id="quantity_label"
                                                            className="attributeLabel"
                                                        >
                                                            {product.quantity_text
                                                                ? product.quantity_text
                                                                : translate(
                                                                      'quantity',
                                                                      language
                                                                  )}
                                                        </h4>
                                                        {quantityEditable ? (
                                                            <InputNumber
                                                                id="quantity"
                                                                defaultValue={
                                                                    quantity ||
                                                                    1
                                                                }
                                                                disabled={
                                                                    !quantityEditable
                                                                }
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    if (
                                                                        val &&
                                                                        Number(
                                                                            val
                                                                        ) > 0
                                                                    )
                                                                        setQuantity(
                                                                            Number(
                                                                                val
                                                                            )
                                                                        );
                                                                }}
                                                            ></InputNumber>
                                                        ) : (
                                                            quantity || 1
                                                        )}{' '}
                                                        x{' '}
                                                        {product.custom_quantity_price_text
                                                            ? product.custom_quantity_price_text
                                                            : displayCurrencyPrice(
                                                                  productPrice ||
                                                                      0,
                                                                  currency
                                                              )}
                                                    </div>
                                                )}

                                                {(yearlyCronSettings ||
                                                    monthlyCronSettings ||
                                                    weeklyCronSettings) && (
                                                    <SubscriptionCronSettings
                                                        lang={language}
                                                        onChange={(
                                                            cSettings: CronConfig
                                                        ) => {
                                                            setCronSettings(
                                                                cSettings
                                                            );
                                                        }}
                                                        yearly={
                                                            yearlyCronSettings
                                                        }
                                                        monthly={
                                                            monthlyCronSettings
                                                        }
                                                        weekly={
                                                            weeklyCronSettings
                                                        }
                                                        weeklyOptions={
                                                            weeklyOptions
                                                        }
                                                        monthlyOptions={
                                                            monthlyOptions
                                                        }
                                                        yearlyOptions={
                                                            yearlyOptions
                                                        }
                                                        initalFrequency={1}
                                                        initalFrequencyType={
                                                            monthlyCronSettings
                                                                ? 'MONTH'
                                                                : weeklyCronSettings
                                                                  ? 'WEEK'
                                                                  : 'YEAR'
                                                        }
                                                    ></SubscriptionCronSettings>
                                                )}

                                                <Button
                                                    className="btn-success"
                                                    type="primary"
                                                    onClick={() => {
                                                        if (
                                                            checkIfAllAttributesAreSelected(
                                                                product
                                                            )
                                                        ) {
                                                            setPaymentModal(
                                                                true
                                                            );
                                                            w.parent.postMessage(
                                                                {
                                                                    scrollToTop:
                                                                        true,
                                                                    uuid: product.uuid,
                                                                },
                                                                '*'
                                                            );
                                                        } else {
                                                            message.error(
                                                                'Þú verður að velja allar eiginleikar áður en þú getur keypt vöruna'
                                                            );
                                                        }
                                                    }}
                                                    size="large"
                                                    id="product_buy_button"
                                                    disabled={
                                                        product.customizable_price &&
                                                        (!selectedCustomPrice ||
                                                            selectedCustomPrice <
                                                                (product.minimum_custom_price ||
                                                                    1))
                                                    }
                                                >
                                                    {oneTimePurchase
                                                        ? product.one_time_purchase_button_text ||
                                                          translate(
                                                              'buy',
                                                              language
                                                          )
                                                        : product.buy_button_text ||
                                                          translate(
                                                              'confirm',
                                                              language
                                                          )}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: 24,
                                                    }}
                                                >
                                                    {translate(
                                                        'out_of_stock',
                                                        language
                                                    )}
                                                </div>
                                                <div>
                                                    {product.out_of_stock_waiting_list && (
                                                        <>
                                                            <div>
                                                                {
                                                                    product.out_of_stock_waiting_list_desc
                                                                }
                                                            </div>

                                                            <Button
                                                                onClick={() => {
                                                                    setWaitingListModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {product.out_of_stock_waiting_list_button_text ||
                                                                    translate(
                                                                        'join_waiting_list',
                                                                        language
                                                                    )}
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            {waitingListModal && product && (
                <WaitingListModal
                    onCancel={() => {
                        setWaitingListModal(false);
                    }}
                    productUUID={product.uuid}
                    title={product.title}
                    lang={language}
                    open={waitingListModal}
                />
            )}

            {paymentModal && product?.info_to_collect && (
                <ProductPaymentModal
                    currency={currency}
                    lang={language}
                    selectedCustomPrice={selectedCustomPrice}
                    product={product}
                    quantity={quantity}
                    cronSettings={cronSettings}
                    oneTimePurchase={oneTimePurchase}
                    open={paymentModal}
                    title={translate('payment', language).toString()}
                    customerInfo={
                        customName || customSsid
                            ? {
                                  name: customName,
                                  ssid: customSsid,
                                  email: customEmail,
                              }
                            : undefined
                    }
                    disableDetailInputs={disableDetailInputs}
                    externalRef={externalRef}
                    personal_info_list={
                        personalInfoList
                            ? personalInfoList.filter((obj) =>
                                  product.info_to_collect?.includes(obj.id)
                              )
                            : []
                    }
                    onCancel={() => {
                        setTimeout(() => {
                            sendPostMessage();
                        }, 100);

                        setPaymentModal(false);
                    }}
                    onOpen={sendPostMessage}
                ></ProductPaymentModal>
            )}
        </>
    );
}
