import React, { useContext, useEffect, useState } from 'react';
import { Table, Modal, Button } from 'antd';
import Email from '../../interfaces/email';
import Customer from '../../interfaces/customer';
import Axios from 'axios';
import AuthContext from '../../authContext';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import SentEmailDetails from './SentEmailDetails';
dayjs.locale('is'); // use locale

type Props = {
    data?: Email[];
    url?: string;
    customer?: Customer;
    shopUUID?: string;
    pagination?: boolean;
};

export default function EmailOverview(props: Props) {
    const { selectedShop } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [emails, setEmails] = useState<Email[]>([]);
    const [modal, setModal] = useState<Email | false>(false);
    const [nextPageUrl, setNextPageUrl] = useState();
    const [moreLoading, setMoreLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            setEmails(props.data);
            setLoading(false);
        } else if (props.customer) {
            Axios.post(`/tolvupostur/api/get_user_emails/`, {
                customer_uuid: props.customer.uuid,
                shop_id: props.customer.shop.id,
            }).then((rsp) => {
                setEmails(rsp.data);
                setLoading(false);
                if (rsp.data.next && props.pagination)
                    setNextPageUrl(rsp.data.next);
            });
        } else {
            if (props.url) {
                Axios.get(props.url).then((rsp) => {
                    setEmails(rsp.data.results);
                    setLoading(false);
                    if (rsp.data.next && props.pagination)
                        setNextPageUrl(rsp.data.next);
                });
            }
        }
    }, [props.url]);

    return (
        <>
            <Table
                footer={() => {
                    return (
                        nextPageUrl && (
                            <Button
                                block
                                loading={moreLoading}
                                onClick={() => {
                                    setMoreLoading(true);
                                    Axios.get(nextPageUrl).then((rsp) => {
                                        setNextPageUrl(rsp.data.next);
                                        setEmails([
                                            ...emails,
                                            ...rsp.data.results,
                                        ]);
                                        setMoreLoading(false);
                                    });
                                }}
                            >
                                Sækja meira
                            </Button>
                        )
                    );
                }}
                className="white_table"
                rowClassName="pointer"
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: <>Kerfið hefur ekki sent út neina tölvupósta</>,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            setModal(record);
                        },
                    };
                }}
                columns={[
                    {
                        title: 'Fyrirsögn',
                        render: (item) => {
                            return (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 10,
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>{item.subject}</div>
                                        <div>
                                            <small>
                                                {dayjs(item.created).format(
                                                    'D. MMM YYYY HH:mm'
                                                )}
                                            </small>
                                        </div>
                                    </div>
                                    <div style={{ color: 'gray' }}>
                                        {item.recipient_count > 2
                                            ? item.recipient_count + ' aðila'
                                            : (item.to.length
                                                  ? item.to
                                                  : item.bcc
                                              ).join(', ')}
                                    </div>
                                </div>
                            );
                        },
                    },
                ]}
                dataSource={emails?.map((email, key) => {
                    const data = email;
                    //@ts-ignore
                    data.key = key;
                    return data;
                })}
            />

            {modal && selectedShop && (
                <Modal
                    footer={null}
                    open={!!modal}
                    onCancel={() => {
                        setModal(false);
                    }}
                >
                    <SentEmailDetails shop={selectedShop} email={modal} />
                </Modal>
            )}
        </>
    );
}
