import React from 'react';
import Customer, { SimpleCustomer } from '../../../interfaces/customer';
import { Alert, Button, Form, Input } from 'antd';
import translate, { LanguageKey } from '../../../translate/translate';
import axios from 'axios';

type Props = {
    customer: Customer | SimpleCustomer;
    language: LanguageKey;
};

export default function OnPay({ customer, language }: Props) {
    return (
        <div>
            <Form
                size="large"
                layout="vertical"
                onFinish={(values) => {
                    axios
                        .get(
                            `/payment/get_onpay_collect_card_url/?name=${encodeURIComponent(
                                customer.name
                            )}&email=${encodeURIComponent(
                                customer.email || 'emailmissing@onpay.io'
                            )}&shop_uuid=${
                                customer.shop?.uuid || customer.shop_uuid
                            }`
                        )
                        .then((rsp) => {
                            if (rsp.data) {
                                window.top?.location.replace(rsp.data);
                            }
                        });
                }}
            >
                <Form.Item
                    name="name"
                    label={translate('name', language)}
                    initialValue={customer.name}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={translate('email', language)}
                    initialValue={customer.email || ''}
                >
                    <Input />
                </Form.Item>

                <Alert
                    className="mb20 text-center"
                    description={
                        <>
                            <div>
                                {translate(
                                    'you_are_redirected_and_back',
                                    language
                                )}
                            </div>
                            <div className="mt20">
                                <img
                                    src={'/static/images/svg/onpay.svg'}
                                    alt="onpay"
                                    style={{
                                        width: '100px',
                                        filter: 'grayscale(1)',
                                        opacity: 0.7,
                                    }}
                                />
                            </div>
                        </>
                    }
                ></Alert>

                <Form.Item>
                    <Button htmlType="submit" block>
                        {translate('go_to_issuer_to_save_card', language)}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
