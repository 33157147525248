import { useState } from 'react';
import { Modal, Input, Button, message, InputNumber } from 'antd';
import Subscription from '../../interfaces/subscription';
import 'moment/locale/is';
import Axios from 'axios';
import { displayCurrencyPrice, kr } from '../../utils';

type Props = {
    open: boolean;
    subscription: Subscription;
    onCancel: Function;
    onSuccess: Function;
};

export default function CancelSubscriptionModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(
        props.subscription.subscription_product.price
    );

    const subscriptionCurrency =
        props.subscription.subscription_product.currency_code;
    const baseCurrencyPrice = props.subscription.product.prices.find(
        (c) => c.currency.currency_code === subscriptionCurrency
    )?.amount;

    return (
        <Modal
            open={props.open}
            maskClosable={false}
            title="Breyta verði á áskrift (bara fyrir þennan viðskiptavin)"
            footer={false}
            onCancel={() => props.onCancel()}
        >
            <div>
                Almennt vöruverð er:{' '}
                <strong>
                    {displayCurrencyPrice(
                        baseCurrencyPrice || 0,
                        subscriptionCurrency
                    )}
                </strong>
            </div>
            <br />

            <div>
                <strong>Verð viðskiptavinar</strong>
            </div>
            <InputNumber
                size="large"
                type="number"
                min={0}
                value={price}
                onChange={(change) => {
                    if (change || change === 0) {
                        setPrice(change);
                    }
                }}
            ></InputNumber>
            <br />
            <Button
                loading={loading}
                onClickCapture={() => {
                    console.log(price);
                    if (!price && price !== 0) {
                        message.error('Vinsamlegast fyllið inn gilt verð');
                        return;
                    }
                    setLoading(true);
                    Axios.patch(
                        `/askrift/api/subscription_product/${props.subscription.subscription_product.id}/`,
                        { price: price }
                    )
                        .then((rsp) => {
                            setLoading(false);
                            message.success(
                                'Verði áskriftar hefur verið breytt'
                            );
                            props.onSuccess(price);
                        })
                        .catch((err) => {
                            setLoading(false);
                            message.error('Eitthvað fór úrskeiðis :/');
                        });
                }}
                size="large"
                block
                type="primary"
                className="mt20"
            >
                Vista
            </Button>
        </Modal>
    );
}
