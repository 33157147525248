import { useEffect, useState } from 'react';
import { Alert, Button, Modal, Table, Tabs } from 'antd';
import Axios from 'axios';

import RefundPaymentTransaction from '../RefundPaymentTransaction/RefundPaymentTransaction';
import Payment from '../../interfaces/payment';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import languages, { LanguageKey } from '../../translate/translate';
import PaymentTransactionDetails from './PaymentTransactionDetails';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

dayjs.locale('is'); // use locale

interface IWindow extends Window {
    me?: number;
}
const w: IWindow = window;
interface Props {
    data?: Payment[];
    url?: string;
    hideShowMore?: boolean;
    columns?: string[];
    isPublic: boolean;
    language?: LanguageKey;
}

export default function SaltPay(props: Props) {
    const lang = props.language || 'is';
    const [paymentTransactions, setPaymentTransactions] = useState<Payment[]>(
        []
    );
    const [loading, setLoading] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [next, setNext] = useState('');
    const [paymentDetailsModal, setPaymentDetailsModal] = useState<
        Payment | false
    >(false);

    useEffect(() => {
        if (props.data) {
            setPaymentTransactions(props.data);
            setLoading(false);
        } else if (props.url) {
            Axios.get(props.url).then((rsp) => {
                setPaymentTransactions(rsp.data.results);
                setLoading(false);

                if (!props.hideShowMore) setNext(rsp.data.next);
            });
        }
    }, [props.url, props.data]);

    const get_more = () => {
        setMoreLoading(true);

        Axios.get(next).then((rsp) => {
            setPaymentTransactions([
                ...paymentTransactions,
                ...rsp.data.results,
            ]);
            setNext(rsp.data.next);
            setMoreLoading(false);
        });
    };

    const columns = [];

    if (!props.columns || props.columns.includes('name')) {
        columns.push({
            title: languages('name', lang),
            render: (paymentTransaction: Payment) => {
                let customer = paymentTransaction.customer;

                const { endpoint, response } = paymentTransaction;
                const requestType = paymentTransaction.request_type;
                const isRefundAttempt =
                    requestType === 'REFUND' || endpoint.includes('refund');
                const isSuccess =
                    response.ActionCode === '000' ||
                    response.isSuccess ||
                    response?.data?.paid ||
                    response?.data?.status === 'finished' ||
                    response?.data?.status === 'active';
                return (
                    <div>
                        <div style={{ fontSize: 14 }}>
                            <a
                                className="capitalize"
                                style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                                href={`/vidskiptavinur/${customer?.uuid}/`}
                            >
                                {customer?.name || '-'}
                            </a>
                        </div>

                        <div style={{ color: 'gray' }}>
                            {paymentTransaction.response.PaymentMethod?.PAN ||
                                paymentTransaction.payload?.PAN ||
                                paymentTransaction.card_token?.PAN ||
                                paymentTransaction.payload.cardNumber}
                        </div>
                    </div>
                );
            },
        });
    }

    if (!props.columns || props.columns.includes('type')) {
        columns.push({
            title: languages('action', lang),
            render: (transaction: Payment) => {
                return (
                    <PaymentTransactionDetails
                        paymentTransaction={transaction}
                        isPublic={props.isPublic}
                        language={lang}
                        onRefundSuccess={() => {
                            setPaymentTransactions([
                                ...paymentTransactions.filter(
                                    (t) => t.id !== transaction.id
                                ),
                                {
                                    ...transaction,
                                    is_refunded: true,
                                },
                            ]);
                        }}
                    ></PaymentTransactionDetails>
                );
            },
        });
    }

    if (!props.columns || props.columns.includes('order')) {
        columns.push({
            title: 'Pöntun',
            render: (transaction: Payment) => {
                return (
                    <>
                        {transaction.order ? (
                            <Button size="small">
                                <a
                                    href={
                                        '/pontun/' +
                                        transaction.order.uuid +
                                        '/'
                                    }
                                >
                                    Skoða pöntun
                                </a>
                            </Button>
                        ) : (
                            'Stofnað í bakenda'
                        )}
                    </>
                );
            },
        });
    }

    if (!props.columns || props.columns.includes('seeMore')) {
        columns.push({
            title: languages('details', lang),
            render: (transaction: Payment) => {
                return (
                    <Button
                        onClick={() => {
                            setPaymentDetailsModal(transaction);
                        }}
                    >
                        Nánar
                    </Button>
                );
            },
        });
    }

    return (
        <>
            <Table
                className="white_table saltPayTable"
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: (
                        <div>
                            <h3>
                                Engin samskipti virðast hafa átt sér stað við
                                færsluhirði
                                <span role="img" aria-label="Poki">
                                    💰
                                </span>
                            </h3>
                        </div>
                    ),
                }}
                footer={() => {
                    return (
                        <>
                            {next && (
                                <Button
                                    loading={moreLoading}
                                    onClick={get_more}
                                >
                                    Sækja næstu síðu
                                </Button>
                            )}
                        </>
                    );
                }}
                columns={columns}
                dataSource={paymentTransactions}
                rowKey="id"
            />

            {paymentDetailsModal && (
                <Modal
                    onCancel={() => setPaymentDetailsModal(false)}
                    open={!!paymentDetailsModal}
                    title={'Ítarlegar upplýsingar samskipti'}
                    footer={false}
                    width={1000}
                >
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={'large'}
                        items={[
                            {
                                label: 'Payload',
                                key: 'payload',
                                children: [
                                    (SyntaxHighlighter as any)({
                                        language: 'json',
                                        style: dracula,
                                        showLineNumbers: false,
                                        wrapLines: true,
                                        wrapLongLines: true,
                                        children: JSON.stringify(
                                            paymentDetailsModal.endpoint,
                                            null,
                                            2
                                        ),
                                    }),

                                    (SyntaxHighlighter as any)({
                                        language: 'json',
                                        style: dracula,
                                        showLineNumbers: true,
                                        wrapLines: true,
                                        wrapLongLines: true,
                                        children: JSON.stringify(
                                            paymentDetailsModal.payload,
                                            null,
                                            2
                                        ),
                                    }),
                                ],
                            },
                            {
                                label: 'Response',
                                key: 'response',
                                children: [
                                    (SyntaxHighlighter as any)({
                                        language: 'json',
                                        style: dracula,
                                        showLineNumbers: true,
                                        wrapLines: true,
                                        wrapLongLines: true,
                                        children: JSON.stringify(
                                            paymentDetailsModal.response,
                                            null,
                                            2
                                        ),
                                    }),
                                ],
                            },
                            {
                                label: 'Ítarlegt',
                                key: 'detailed',
                                children: [
                                    (SyntaxHighlighter as any)({
                                        language: 'json',
                                        style: dracula,
                                        showLineNumbers: false,
                                        wrapLines: true,
                                        wrapLongLines: true,
                                        children: JSON.stringify(
                                            paymentDetailsModal,
                                            null,
                                            2
                                        ),
                                    }),
                                ],
                            },
                        ]}
                    />
                </Modal>
            )}
        </>
    );
}
