import { useContext } from 'react';
import { Layout, Menu, Button, Avatar, Space, Select } from 'antd';
import SVG from 'react-inlinesvg';
import CustomerSearch from '../../components/CustomerSearch/CustomerSearch';
import './Header.less';
import brandingConfig from '../../utils/brandingConfig';
import AuthContext from '../../authContext';
import { LockOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;

export default function Header() {
    const { user, selectedShop, userScopes } = useContext(AuthContext);

    const hasUserScope = (scope: string) => {
        return userScopes === 'ADMIN' || userScopes?.includes(scope);
    };

    return (
        <AntHeader id="repeatHeader" className="header">
            <div className="logoAndSearch">
                <div id="main_logo_container">
                    <a
                        href={user ? '/stjornbord/' : '/'}
                        style={{
                            display: 'flex',
                        }}
                    >
                        <SVG
                            id="header_logo"
                            style={{ height: '50px', padding: 10 }}
                            baseURL="/"
                            cacheRequests={true}
                            description="Logo"
                            src={'/static/images/logo/logo.svg'}
                            title="Logo"
                            key="header_logo"
                            uniqueHash="header_logo"
                            uniquifyIDs={false}
                            fill={brandingConfig.primaryColor}
                        />
                    </a>
                </div>
                {window.location.pathname !== '/' && hasUserScope('SEARCH') && (
                    <CustomerSearch></CustomerSearch>
                )}
                <div>
                    {user ? (
                        <Space>
                            <Menu
                                className="main_menu"
                                theme="dark"
                                items={[
                                    {
                                        label: (
                                            <Avatar
                                                style={{
                                                    margin: '16px',
                                                    backgroundColor:
                                                        brandingConfig.primaryColor,
                                                    lineHeight: '36px',
                                                }}
                                            >
                                                {(user?.signature_name || '')
                                                    .charAt(0)
                                                    .toUpperCase()}
                                            </Avatar>
                                        ),
                                        style: { marginLeft: 'auto' },
                                        key: 'user',
                                        children: [
                                            {
                                                key: 'verslun',
                                                label: (
                                                    <a href="/ny_verslun/">
                                                        + Stofna nýja verslun
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: 'stillingar',
                                                label: (
                                                    <a href="/stillingar/">
                                                        Minn aðgangur
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: 'logout',
                                                label: (
                                                    <a href="/utskra/">
                                                        Útskrá
                                                    </a>
                                                ),
                                            },
                                        ],
                                    },
                                ]}
                                mode="horizontal"
                            ></Menu>

                            <a
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                    display: user?.is_staff ? 'block' : 'none',
                                }}
                                href={'/staff/'}
                            >
                                <LockOutlined />
                            </a>
                        </Space>
                    ) : (
                        <Space>
                            <Button className="btn-primary" type="default">
                                <a style={{ color: '#fff' }} href="/nyskra/">
                                    Nýskráning
                                </a>
                            </Button>

                            <Button type="dashed">
                                <a href="/innskra/">Innskrá</a>
                            </Button>
                        </Space>
                    )}
                </div>
            </div>
        </AntHeader>
    );
}
