import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
    message,
    Card,
    Table,
    Badge,
    Button,
    Dropdown,
    Menu,
    Row,
    Col,
    Checkbox,
    Select,
    Input,
    DatePicker,
    TimePicker,
} from 'antd';
import {
    DownOutlined,
    FileExcelOutlined,
    ShoppingCartOutlined,
} from '@ant-design/icons';
import Order from '../../interfaces/order';
import Product from '../../interfaces/product';
import ExcelModal from '../../components/ExcelModal/ExcelModal';
import { getDeliveryIcon } from '../../utils';
import useDropp from '../../customHooks/Dropp/Dropp';
import { DroppLocationRes } from '../../interfaces/delivery';
import AuthContext from '../../authContext';

import moment from 'moment';
import 'moment/locale/is';
import dayjs from 'dayjs';
moment.locale('is');

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}
const w: IWindow = window;

export default function OrderBox() {
    const { selectedShop } = useContext(AuthContext);
    const { droppLocationList } = useDropp();
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true);
    const [hideFulfilled, setHideFulfilled] = useState(false);
    const [showUnfinished, setShowUnfinished] = useState(false);
    const [orderCount, setOrderCount] = useState(0);
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedProductUUIDs, setSelectedProductUUIDs] = useState<string[]>(
        []
    );
    const [queryParams, setQueryParams] = useState('');
    const [excelModal, setExcelModal] = useState<{} | false>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [allShops, setAllShops] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');

    useEffect(() => {
        if (!selectedShop) return;

        let queryParam = hideFulfilled ? '&is_fulfilled=false' : '';

        if (showUnfinished) {
            queryParam += '&show_unfinished=true';
        }

        if (fromDate && toDate) {
            if (fromTime && toTime) {
                queryParam += `&created__gte=${fromDate}T${fromTime}Z&created__lte=${toDate}T${toTime}Z`;
            } else {
                queryParam += `&created__gte=${fromDate}&created__lte=${toDate}`;
            }
        }

        if (selectedProductUUIDs.length > 0) {
            queryParam += `&products__product__uuid__in=${selectedProductUUIDs.join(
                ','
            )}`;
        }

        if (searchTerm) {
            queryParam += `&search=${searchTerm}`;
        }

        let url = `/verslun/api/orders/?page_size=25&page=${pageNumber}&ordering=-created${queryParam}`;

        if (!allShops) {
            url += `&shop__uuid=${selectedShop.uuid}`;
        }

        setQueryParams(url);
    }, [
        hideFulfilled,
        selectedProductUUIDs,
        selectedShop,
        showUnfinished,
        searchTerm,
        pageNumber,
        allShops,
        fromDate,
        toDate,
        fromTime,
        toTime,
    ]);

    useEffect(() => {
        if (queryParams === '') return;

        axios.get(queryParams).then((rsp) => {
            setOrders(rsp.data.results);
            setLoading(false);
            setOrderCount(rsp.data.count);
        });
    }, [queryParams]);

    useEffect(() => {
        if (!selectedShop) {
            setProducts([]);
            return;
        }
        axios
            .get('/verslun/api/product/?shop__uuid=' + selectedShop.uuid)
            .then((rsp) => {
                setProducts(rsp.data.results);
            });
    }, [selectedShop]);

    const markOrderAsFulfilled = (order: Order, bool: boolean) => {
        axios
            .patch(`/verslun/api/order/${order.uuid}/`, {
                is_fulfilled: bool,
            })
            .then((rsp) => {
                setOrders([
                    ...orders.filter((o) => o.uuid !== order.uuid),
                    { ...order, is_fulfilled: bool },
                ]);
                message.success('Tókst!');
            })
            .catch((err) => {
                message.error('Úps, eitthvað fór úrskeiðis :/');
            });
    };

    return (
        <>
            <Card
                className="basePageCard"
                title={
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{ height: 35, marginRight: 10 }}
                                src="/static/images/svg/shopping-cart.svg"
                                alt=""
                            />{' '}
                            Pantanir
                        </div>
                    </div>
                }
                extra={
                    <Button
                        style={{
                            background: '#1d8048',
                            color: '#fff',
                            border: 'none',
                        }}
                        onClick={() => {
                            if (!selectedShop) return;

                            const payload: { [key: string]: any } = {
                                shop_uuid: selectedShop.uuid,
                            };

                            if (hideFulfilled) {
                                payload['is_fulfilled'] = false;
                            }

                            if (selectedProductUUIDs.length > 0) {
                                payload['products__product__uuid__in'] =
                                    selectedProductUUIDs;
                            }

                            setExcelModal(payload);
                        }}
                    >
                        <FileExcelOutlined></FileExcelOutlined>
                        Sækja excel skjal
                    </Button>
                }
            >
                {/* <div className="text-center" style={{ fontSize: 24, margin: 20 }}>
                <div>
                    <img
                        style={{ height: 45 }}
                        src="/static/images/svg/shopping-cart.svg"
                        alt=""
                    />{' '}
                    Pantanir
                </div>
            </div> */}

                <div
                    style={{
                        width: '100%',
                        margin: '0 auto 20px',
                        maxWidth: 500,
                    }}
                >
                    <Input.Search
                        style={{
                            textAlign: 'center',
                        }}
                        allowClear
                        size="large"
                        onSearch={(val) => {
                            setSearchTerm(val);
                        }}
                        onChange={(e) => {
                            if (e.target.value === '' && searchTerm !== '') {
                                setSearchTerm('');
                            }
                        }}
                        placeholder="Leita..."
                    ></Input.Search>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                        marginBottom: 20,
                        gap: 10,
                        justifyContent: 'center',
                    }}
                >
                    <Checkbox
                        disabled={loading}
                        name="activeChecked"
                        onChange={() => {
                            setHideFulfilled(!hideFulfilled);
                        }}
                        checked={hideFulfilled}
                    >
                        Fela uppfylltar pantanir
                    </Checkbox>

                    {w.me === 1 && (
                        <Checkbox
                            disabled={loading}
                            name="activeChecked"
                            onChange={() => {
                                setShowUnfinished(!showUnfinished);
                            }}
                            checked={showUnfinished}
                        >
                            Sýna ókláraðar pantanir
                        </Checkbox>
                    )}

                    {w.me === 1 && (
                        <Checkbox
                            disabled={loading}
                            name="activeChecked"
                            onChange={() => {
                                setAllShops(!allShops);
                            }}
                            checked={allShops}
                        >
                            Allar verslanir
                        </Checkbox>
                    )}
                </div>

                <Card
                    style={{ border: 'none' }}
                    title={
                        <div>
                            <div>
                                <Select
                                    size="large"
                                    placeholder="Sía eftir vörum í pöntun"
                                    mode="multiple"
                                    style={{
                                        width: 300,
                                        marginRight: 10,
                                    }}
                                    optionFilterProp="label"
                                    onChange={(value) => {
                                        setSelectedProductUUIDs(value);
                                        setLoading(true);
                                    }}
                                    options={products.map((product) => {
                                        return {
                                            label: product.title,
                                            value: product.uuid,
                                        };
                                    })}
                                ></Select>
                            </div>
                        </div>
                    }
                    extra={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                width: '100%',
                                gap: 10,
                            }}
                        >
                            <div>
                                <DatePicker.RangePicker
                                    onChange={(dates) => {
                                        if (dates) {
                                            if (!dates[0] || !dates[1]) return;
                                            const start =
                                                dates[0].format('YYYY-MM-DD');
                                            const end =
                                                dates[1].format('YYYY-MM-DD');
                                            setFromDate(start);
                                            setToDate(end);
                                        }
                                    }}
                                ></DatePicker.RangePicker>
                            </div>

                            <div>
                                {fromDate && toDate && (
                                    <TimePicker.RangePicker
                                        showSecond={false}
                                        onChange={(dates) => {
                                            if (dates) {
                                                if (!dates[0] || !dates[1])
                                                    return;
                                                const start =
                                                    dates[0].format('HH:mm:ss');
                                                const end =
                                                    dates[1].format('HH:mm:ss');
                                                setFromTime(start);
                                                setToTime(end);
                                            }
                                        }}
                                        defaultValue={[
                                            moment('00:00:00', 'HH:mm:ss'),
                                            moment('23:59:59', 'HH:mm:ss'),
                                        ]}
                                    ></TimePicker.RangePicker>
                                )}
                            </div>
                        </div>
                    }
                >
                    <Table
                        className="white_table"
                        style={{ width: '100%' }}
                        loading={loading}
                        pagination={{
                            hideOnSinglePage: true,
                            defaultPageSize: 25,
                            showSizeChanger: false,
                            total: orderCount,
                            onChange: (_pageNumber) => {
                                setLoading(true);
                                setPageNumber(_pageNumber);
                            },
                        }}
                        locale={{
                            emptyText: 'Engar pantanir fundust',
                        }}
                        columns={[
                            {
                                title: 'Viðskiptavinur',
                                render: (order) => {
                                    return (
                                        <div>
                                            {allShops && (
                                                <div>
                                                    <strong>
                                                        {order.shop?.name}
                                                    </strong>
                                                </div>
                                            )}

                                            <a
                                                className="capitalize"
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.65)',
                                                }}
                                                href={`/vidskiptavinur/${order.customer.uuid}`}
                                            >
                                                {order.customer.name}
                                            </a>
                                            <div
                                                style={{
                                                    color: '#b1b1b1',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {dayjs(order.created).format(
                                                    'D. MMM YYYY - HH:mm'
                                                )}
                                            </div>

                                            <Button
                                                href={`/pontun/${order.uuid}/`}
                                                type="dashed"
                                                size="small"
                                                style={{ marginTop: 5 }}
                                            >
                                                <ShoppingCartOutlined />{' '}
                                                Staðfestingarsíða
                                            </Button>

                                            {w.me === 1 && (
                                                <Button
                                                    size="small"
                                                    type="dashed"
                                                    style={{
                                                        marginTop: 5,
                                                        marginLeft: 5,
                                                    }}
                                                    href={`/bakendi/shop/order/${order.uuid}/change/`}
                                                >
                                                    Bakendi
                                                </Button>
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: 'Vörur',
                                render: (order: Order) => {
                                    return (
                                        <>
                                            {order.products
                                                .map((product) => {
                                                    return product.product
                                                        .title;
                                                })
                                                .join(', ')}
                                        </>
                                    );
                                },
                            },
                            {
                                title: 'Afhending',
                                render: (order) => {
                                    const droppLocation =
                                        droppLocationList?.find(
                                            (dl: DroppLocationRes) => {
                                                return (
                                                    dl.id ===
                                                    order.external_delivery_location_id
                                                );
                                            }
                                        );

                                    return (
                                        <>
                                            {order.delivery_option && (
                                                <>
                                                    <div>
                                                        {order?.delivery_option && (
                                                            <>
                                                                <div>
                                                                    {getDeliveryIcon(
                                                                        order
                                                                            .delivery_option
                                                                            .shipping_provider,
                                                                        30
                                                                    )}{' '}
                                                                    {
                                                                        order
                                                                            .delivery_option
                                                                            .title
                                                                    }
                                                                </div>
                                                                {order
                                                                    .delivery_option
                                                                    .shipping_provider ===
                                                                    'DROPP_PICKUP' &&
                                                                    droppLocation && (
                                                                        <div
                                                                            style={{
                                                                                paddingLeft: 35,
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {
                                                                                    droppLocation?.name
                                                                                }
                                                                            </div>
                                                                            <small>
                                                                                {
                                                                                    droppLocation?.address
                                                                                }
                                                                            </small>
                                                                        </div>
                                                                    )}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                title: 'Uppfyllt',
                                render: (order) => {
                                    return (
                                        <div style={{ display: 'flex' }}>
                                            <Dropdown
                                                overlay={
                                                    <Menu>
                                                        <Menu.Item
                                                            onClick={() => {
                                                                markOrderAsFulfilled(
                                                                    order,
                                                                    true
                                                                );
                                                            }}
                                                            icon={
                                                                <Badge status="success" />
                                                            }
                                                        >
                                                            {' '}
                                                            Merkja sem uppfyllt
                                                        </Menu.Item>

                                                        <Menu.Item
                                                            onClick={() => {
                                                                markOrderAsFulfilled(
                                                                    order,
                                                                    false
                                                                );
                                                            }}
                                                            icon={
                                                                <Badge status="error" />
                                                            }
                                                        >
                                                            {' '}
                                                            Merkja sem óuppfyllt
                                                        </Menu.Item>

                                                        {/* <Menu.Item onClick={() => {}} icon={<CommentOutlined />}>
                                                    {' '}
                                                    Bæta við athugasemd
                                                </Menu.Item> */}
                                                    </Menu>
                                                }
                                            >
                                                <Button size="small">
                                                    <Badge
                                                        status={
                                                            order.is_fulfilled
                                                                ? 'success'
                                                                : 'error'
                                                        }
                                                    />{' '}
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {order.is_fulfilled
                                                            ? ' Uppfyllt'
                                                            : ' Óuppfyllt'}{' '}
                                                    </span>
                                                    <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        rowKey={(order) => order.uuid}
                        dataSource={orders.sort((a, b) => {
                            return a.created < b.created ? 0 : 1;
                        })}
                    />
                </Card>

                <ExcelModal
                    open={!!excelModal}
                    onClose={() => setExcelModal(false)}
                    orderFilterPayload={excelModal}
                ></ExcelModal>
            </Card>
        </>
    );
}
